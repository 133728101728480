import React, { ReactElement } from 'react';
import { useTranslation } from '../../hooks/use-translate';
import './terms-links.scss';

export default function TermsLinks(): ReactElement {
  const t = useTranslation('COMPONENTS.TERMS_LINKS');
  return (
      <div className="terms-links">
        <a className="link" 
        onClick={() => window.open('/#/privacy', "_blank")}>
          { t('LINK_PRIVACY_POLICY') }
        </a>&nbsp;
        { t('TEXT_AND') }&nbsp;
        <a className="link"  
        onClick={() =>  window.open('/#/terms', "_blank")}>
          { t('LINK_TERMS_OF_SERVICE') }
        </a>
      </div>
  );
}
