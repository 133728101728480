import { Action, createReducer } from 'deox';
import { Option } from '../../models';
import { ChatBuilderActions } from './actions';
import { ChatBuilderState } from './state';
import { isEdge, isNode } from 'reactflow';
import produce from 'immer';
import { isBoolean, remove } from 'lodash';
import { Suggestion } from 'react-mde';
import { TemplateComponentKey, Method, NodeObjectKey, NodeType, HeaderForamt, DataType, FormatDate } from '../../enums/enums'
import { getOptionID } from '../../helpers/wrokflow-builder/get-id';
import { ButtonTemplate, TemplateObject } from '../../components/node/templates-node';
import { WAListAction } from '../../components/node/whatsapp-list-node';
import { FilterAction, LogicalOperator, OperatorLabel } from '../../components/node/filter_node';



const initialState = new ChatBuilderState();

const findByID = (id: string) => (item: { id: string }) => item.id === id




const reducer = createReducer(initialState, (handleAction) => [
  handleAction(ChatBuilderActions.setCurrentBuilder, (state, { payload: { element } }) => produce(state, (draft) => {
    draft.currentBuilder = element;
    draft.chatScheme = element.chat_builder.length ? element.chat_builder : draft.startScheme;
    const suggestion: Suggestion[] = []
    draft.chatScheme.filter(isNode).forEach((node) => {
      (node.data.name && node.data.name !== '') &&
        suggestion.push({ preview: node.data?.name, value: `@${node.data?.name}` })
    })
    draft.suggestion = suggestion
  })),
  handleAction(ChatBuilderActions.setCurrentBuilderNull, () => ({
    ...initialState
  })),
  handleAction(ChatBuilderActions.setCurrentBuilderActive, (state) => produce(state, (draft) => {
    draft.currentBuilder!.status = true
  })),
  handleAction(ChatBuilderActions.addElement, (state, { payload: { element } }) => produce(state, (draft) => {
    draft.chatScheme.push(element);
    if (isNode(element)! && (element.type === NodeType.END || element.type === NodeType.NOTIFICATION)) {
      draft.suggestion.push({ preview: element.data?.name, value: `@${element.data?.name}` })
    }
  })),
  handleAction(ChatBuilderActions.addElements, (state, { payload: { elements } }) => produce(state, (draft) => {
    draft.chatScheme.push(...elements);
  })),
  handleAction(ChatBuilderActions.setElement, (state, { payload: { element } }) => produce(state, (draft) => {
    const index = draft.chatScheme.findIndex(findByID(element.id));
    if (index > -1) {
      draft.chatScheme[index] = element;
    }
  })),
  handleAction(ChatBuilderActions.removeElement, (state, { payload: { id } }) => produce(state, (draft) => {
    const removingElement = state.chatScheme.find(findByID(id));
    if (removingElement) {
      const _isNode = (isNode(removingElement))
      const removingElementsIDS = _isNode
        ? [id, ...draft.chatScheme.filter(isEdge).filter((edge) => [edge.source, edge.target].includes(id)).map((edge) => edge.id)]
        : [id];
      remove(draft.chatScheme, (item) => removingElementsIDS.includes(item.id));
      if (_isNode) {
        remove(draft.suggestion, (item) => { item.preview === removingElement.data?.name; })
        //draft.removedArr.push(id)
      }

    }
  })),
  handleAction(ChatBuilderActions.toggleSelectNode, (state, { payload: { id, isSelected } }) => produce(state, (draft) => {
    draft.chatScheme.filter(isNode).filter(findByID(id)).forEach((node) => {
      const isSelect = isBoolean(isSelected) ? isSelected : !node.data?.isSelected;
      node.data!.isSelected = isSelect;
      node.draggable = !isSelect;
      node.selected = true
    });
  })),
  handleAction(ChatBuilderActions.unselectAllNodes, (state) => produce(state, (draft) => {
    draft.chatScheme.filter(isNode).forEach((node) => {
      node.data.isSelected = false;
      node.draggable = true;
      node.selected = false
    });
  })),
  handleAction(ChatBuilderActions.onSelectSearchNode, (state, { payload: { id } }) => produce(state, (draft) => {
    draft.chatScheme.filter(isNode).forEach((node) => {
      if (node.id === id) {
        node.selected = true
        node.data.isSelect = true
      } else {
        node.selected = false
        node.data.isSelect = false
      }
    });
  })),
  handleAction(ChatBuilderActions.setActiveSource, (state, { payload: { id } }) => produce(state, (draft) => {
    draft.activeSourceID = id;
  })),
  handleAction(ChatBuilderActions.changeNodesText, (state, { payload: { id, text } }) => produce(state, (draft) => {
    draft.chatScheme.filter(isNode).filter(findByID(id)).forEach((node) => {
      node.data.text = text;
    });
  })),
  handleAction(ChatBuilderActions.SwitchBetweenNodeAndOpionEdge, (state, { payload: { id, optionID, optionsIsEmpty } }) => produce(state, (draft) => {
    draft.chatScheme.filter(isNode).filter(findByID(id)).forEach(() => {
      draft.chatScheme.filter(isEdge).filter((edge) => [edge.source].includes(id)).forEach((edge) => {
        edge.sourceHandle = optionsIsEmpty ? optionID : id
      });
    });
  })),
  handleAction(ChatBuilderActions.addNewOption, (state, { payload: { id, optionID } }) => produce(state, (draft) => {
    draft.chatScheme.filter(isNode).filter(findByID(id)).forEach((node) => {
      node.data.options.push({ id: optionID, text: '' });
    });
  })),
  handleAction(ChatBuilderActions.changeOption, (state, { payload: { id, optionID, value } }) => produce(state, (draft) => {
    draft.chatScheme.filter(isNode).filter(findByID(id)).forEach((node) => {
      node.data.options.filter(findByID(optionID)).forEach((option: Option) => {
        option.text = value;
      });
    });
  })),  
  handleAction(ChatBuilderActions.changeOptionChecked, (state, { payload: { id, optionID, checked } }) => produce(state, (draft) => {
    draft.chatScheme.filter(isNode).filter(findByID(id)).forEach((node) => {
      node.data.options.filter(findByID(optionID)).forEach((option: Option) => {
        option.checked = checked;
      });
    });
  })),




  handleAction(ChatBuilderActions.removeOption, (state, { payload: { id, optionID } }) => produce(state, (draft) => {
    draft.chatScheme.filter(isNode).filter(findByID(id)).forEach((node) => {
      remove(node.data.options, findByID(optionID));
    });
    const removeEdgeSourceHandle = draft.chatScheme.filter(isEdge).filter((edge) => [edge.sourceHandle].includes(optionID)).map((edge) => edge.id)
    remove(draft.chatScheme, (item) => removeEdgeSourceHandle.includes(item.id))
  })),
  handleAction(ChatBuilderActions.changeObj, (state, { payload: { id, key, value, value2 } }) => produce(state, (draft) => {
    draft.chatScheme.filter(isNode).filter(findByID(id)).forEach((node) => {
      node.data.obj[key] = value
      let temp: any = ''
      switch (key) {
        case NodeObjectKey.JSON:
          !value && (node.data.obj[NodeObjectKey.JSON] = '{}')
          break;
        case NodeObjectKey.METHOD:
          if (value === Method.POST) {
            node.data.text = ''
          }
          break;
        case NodeObjectKey.TYPE:
          if (value === NodeType.END || value === NodeType.POPUP) {
            const removingEdgeSource = draft.chatScheme.filter(isEdge).filter((edge) => [edge.source].includes(id)).map((edge) => edge.id)
            remove(draft.chatScheme, (item) => removingEdgeSource.includes(item.id));
          }
          break;
        case NodeObjectKey.JUMP:
          node.data.obj.jumpTo = value2;
          break;
        case NodeObjectKey.TEMPLATE:
          temp = (value as TemplateObject).components?.find((c) => c.type === TemplateComponentKey.HEADER)
          node.data.text = (value as TemplateObject).components?.find((c) => c.type === TemplateComponentKey.BODY)?.text || '';
          node.data.obj.header = temp?.text || '';
          node.data.obj.headerForamt = temp?.format !== HeaderForamt.TEXT ? temp?.format : undefined
          node.data.obj.mediaFileName = ''
          node.data.obj.footer = (value as TemplateObject).components?.find((c) => c.type === TemplateComponentKey.FOOTER)?.text || '';
          node.data.obj.template.actionButtons = [];
          (value as TemplateObject).components?.find((c) => c.type === TemplateComponentKey.BUTTONS)?.buttons?.map((x) => {
            if (x.type === 'QUICK_REPLY') {
              node.data.options.push({ id: getOptionID(), text: x.text })
            } else {
              const obj: ButtonTemplate = { type: x.type, text: x.text }
              switch (x.type) {
                case "PHONE_NUMBER":
                  obj.phone_number = x.phone_number
                  break;
                case "URL":
                  obj.url = x.url
                  break;
              }
              node.data.obj.template.actionButtons.push(obj);

            }
            //x.type === 'QUICK_REPLY' && node.data.options.push({ id: getOptionID(), text: x.text })
          }) || [];
          node.data.obj.template.paramters = {
            bodyParam: node.data.text.match(/\{{.+?\}}/g) || [],
            headerParam: node.data.obj.header.match(/\{{.+?\}}/g) || [],
            actionButtonsParam: [...node.data.obj.template.actionButtons.reduce((acc: any, curr: any) => {
              const match = curr.url?.match(/\{{.+?\}}/g);
              if (match) acc.push(...match);
              return acc;
            }, [])]
          }
          break;

      }
    });
  })),
  handleAction(ChatBuilderActions.changeAnimated, (state, { payload: { edgeID, remove } }) => produce(state, (draft) => {
    draft.chatScheme.filter(isEdge).filter(findByID(edgeID)).forEach((edge) => {
      if (remove) {
        edge.animated = false
        
      }else{
        edge.animated = !edge.animated
      }
      draft.chatScheme.filter(isNode).filter((node) => [edge.source, edge.target].includes(node.id)).forEach((node) => {
        node.data.isSelect = draft.chatScheme.filter(isEdge).filter((_edge) =>
          [_edge.source, _edge.target].includes(node.id) || _edge.id === edgeID).some((__edge) => __edge.animated)

      })
    })

  })),
  handleAction(ChatBuilderActions.changeNodeName, (state, { payload: { id, newValue } }) => produce(state, (draft) => {
    draft.chatScheme.filter(isNode).filter(findByID(id)).forEach((node) => {
      const index = draft.suggestion.findIndex((s) => s.preview === node.data.name)
      if (index > -1) {
        draft.suggestion[index] = {
          preview: newValue,
          value: `@${newValue}`
        }
      }
      node.data.name = newValue
    })
  })),
  handleAction(ChatBuilderActions.onError, (state, { payload: { id, bool } }) => produce(state, (draft) => {
    draft.chatScheme.filter(isNode).filter(findByID(id)).forEach((node) => {
      node.data.isError = bool
    })
  })),
  handleAction(ChatBuilderActions.changeBuilderName, (state, { payload: { newBuilderName } }) => produce(state, (draft) => {
    draft.currentBuilder!.builder_name = newBuilderName
  })),
  handleAction(ChatBuilderActions.onUpdateNodeTemplate, (state, { payload: { id, key, index, value } }) => produce(state, (draft) => {
    draft.chatScheme.filter(isNode).filter(findByID(id)).forEach((node) => {
      if (key === "actionButtonsParam") {
        node.data.obj.template.actionButtons = node.data.obj.template.actionButtons?.map((x: ButtonTemplate) => {
          if (x?.url?.includes(node.data.obj.template.paramters[key][index])) {
            x.url = x.url.replace(node.data.obj.template.paramters[key][index], `{{${value}}}`);
          }
          return x;
        });
      }
      node.data.obj.template.paramters[key][index] = `{{${value}}}`
    });
  })),
  handleAction(ChatBuilderActions.onChangeWAList, (state, { payload: { nodeID, key, sectionIndex, value, rowID } }) => produce(state, (draft) => {
    draft.chatScheme.filter(isNode).filter(findByID(nodeID)).forEach((node) => {
      let tempValue: any;
      switch (key) {
        case WAListAction.BUTTON:
          node.data.obj.waList.button = value
          break;
        case WAListAction.SECTION_TITLE:
          node.data.obj.waList.sections[sectionIndex!].title = value
          break;
        case WAListAction.ADD_ROW:
          tempValue = getOptionID()
          node.data.obj.waList.sections[sectionIndex!].rows.push({
            title: '',
            description: '',
            id: tempValue
          })
          node.data!.options.push({ id: tempValue, text: '' })
          break;
        case WAListAction.REMOVE_ROW:
          remove(node.data.obj.waList.sections[sectionIndex!].rows, findByID(rowID!));
          remove(node.data.options, findByID(rowID!));
          tempValue = draft.chatScheme.filter(isEdge).filter((edge) => [edge.sourceHandle].includes(rowID!)).map((edge) => edge.id)
          remove(draft.chatScheme, (item) => tempValue.includes(item.id))
          node.data.obj.waList.sections[sectionIndex!].rows.length === 0 &&
            node.data.obj.waList.sections.splice(sectionIndex!, 1)
          break;
        case WAListAction.ROW_TITLE:
          node.data.obj.waList.sections[sectionIndex!].rows.find((row: any) => row.id === rowID)!.title = value
          node.data.options.find((option: Option) => option.id === rowID)!.text = value
          break;
        case WAListAction.ROW_DESCRIPTION:
          node.data.obj.waList.sections[sectionIndex!].rows.find((row: any) => row.id === rowID)!.description = value
          break;
        case WAListAction.ADD_SECTION:
          tempValue = getOptionID()
          node.data.obj.waList.sections.push({
            title: '', isOpen: true, rows: [{
              title: '',
              description: '',
              id: tempValue
            }]
          })
          node.data!.options.push({ id: tempValue, text: '' })
          break;
        case WAListAction.SECTION_STATE:
          node.data.obj.waList.sections[sectionIndex!].isOpen = !node.data.obj.waList.sections[sectionIndex!].isOpen
          break;
      }
    });
  })),
  handleAction(ChatBuilderActions.onChangeBuilderSettings, (state, { payload: { key, value } }) => produce(state, (draft) => {
    draft.currentBuilder!.configuration[key] = value
  })),
  handleAction(ChatBuilderActions.onChangeBuilderLanguage, (state, { payload: { language } }) => produce(state, (draft) => {
    draft.currentBuilder!.configuration.language = language
  })),
  handleAction(ChatBuilderActions.UpdateChatChema, (state, { payload: { newChatScheme } }) => produce(state, (draft) => {
    if (newChatScheme) {
      draft.chatScheme = newChatScheme
    }
  })),
  handleAction(ChatBuilderActions.ToggleSave, (state, { payload: { isSaved } }) => produce(state, (draft) => {
    draft.isSaved = isSaved
  })),
  handleAction(ChatBuilderActions.ToggleNodeDraggable, (state, { payload: { id, bool } }) => produce(state, (draft) => {
    draft.chatScheme.filter(isNode).filter(findByID(id)).forEach((node) => {
      node.draggable = bool
    })
  })),
  handleAction(ChatBuilderActions.onChangeFilterNode, (state, { payload: { nodeID, key, groupID, conditionIndex, val } }) => produce(state, (draft) => {
    draft.chatScheme.filter(isNode).filter(findByID(nodeID)).forEach((node) => {
      let tempID = ''
      let conditions;
      switch (key) {
        case FilterAction.ADD_GROUP:
          tempID = getOptionID()
          node.data.obj.filterObject.groups.push({
            id: tempID,
            preview: '',
            conditions: [{
              field: '',
              operator: { label: OperatorLabel.EQUAL, dataType: DataType.TEXT },
              value: '',
              formatDate: FormatDate.DD_MM_YYYY
            }]
          })
          node.data!.options.push({ id: tempID, text: '' })
          break;
        case FilterAction.REMOVE_GROUP:
          remove(node.data.obj.filterObject.groups, findByID(groupID!));
          remove(node.data.options, findByID(groupID!));
          break;
        case FilterAction.CHANGE_GROUP_LOGICAL:
          node.data.obj.filterObject.groups.find(findByID(groupID!)).logical = val
          break;
        case FilterAction.CHANGE_FIELD:
          node.data.obj.filterObject.groups.find(findByID(groupID!)).conditions[conditionIndex!].field = val
          break;
        case FilterAction.CHANGE_OPERATOR:
          node.data.obj.filterObject.groups.find(findByID(groupID!)).conditions[conditionIndex!].operator = val
          break;
        case FilterAction.CHANGE_VALUE:
          node.data.obj.filterObject.groups.find(findByID(groupID!)).conditions[conditionIndex!].value = val
          break;
        case FilterAction.CHANGE_LOGICAL_OPERATOR:
          node.data.obj.filterObject.groups.find(findByID(groupID!)).conditions[conditionIndex!].logicalOperator = val
          break;
        case FilterAction.ADD_CONDITION:
          node.data.obj.filterObject.groups.find(findByID(groupID!)).conditions.push({
            field: '',
            operator: { label: OperatorLabel.EQUAL, dataType: DataType.TEXT },
            value: '',
            formatDate: FormatDate.DD_MM_YYYY
          });
          node.data.obj.filterObject.groups.find(findByID(groupID!)).conditions[conditionIndex!].logicalOperator = LogicalOperator.AND
          break;
        case FilterAction.REMOVE_CONDITION:
          conditions = node.data.obj.filterObject.groups.find(findByID(groupID!)).conditions
          conditions.splice(conditionIndex!, 1);
          if (conditions.length === 1) {
            conditions[0].logicalOperator = null
          }
          break
        case FilterAction.CHANGE_FORMAT_DATE:
          node.data.obj.filterObject.groups.find(findByID(groupID!)).conditions[conditionIndex!].formatDate = val;
          break;
        case FilterAction.CHANGE_FIELD_MAP:
          node.data.obj.filterObject.groups.find(findByID(groupID!)).conditions[conditionIndex!].fieldMap = val;
          break;
        case FilterAction.CHANGE_VALUE_MAP:
          node.data.obj.filterObject.groups.find(findByID(groupID!)).conditions[conditionIndex!].valueMap = val;
          break;
      }
    })
  }))
  // handleAction(ChatBuilderActions.onSaveVariables, (state, { payload: { variables } }) => produce(state, (draft) => {
  //   draft.currentBuilder!.settings = draft.currentBuilder!.settings || {};
  //   draft.currentBuilder!.settings.variables = draft.currentBuilder!.settings.variables || [];
  //   draft.currentBuilder!.settings!.variables = variables!
  // }))
  // handleAction(ChatBuilderActions.onChangePluginAI, (state, { payload: { nodeID, action, value } }) => produce(state, (draft) => {
  //   draft.chatScheme.filter(isNode).filter(findByID(nodeID)).forEach((node) => {
  //     node.data.obj.pluginAI[action] = value
  //   })
  // }))
]);

export function chatBuilderReducer(state: ChatBuilderState | undefined, action: Action<any>): ChatBuilderState {
  return reducer(state, action);
}
