import { Node } from 'reactflow';
import { ChatbotNodeData } from '../../models';
import { action, actionWithPayload } from '../action-factory';
import { TranformSearch } from './state';

export class ChatBotActionsActions {
  public static resetState = action(
    '[ChatbotPageActions] Reset state'
  );

  public static saveChatScheme = action(
    '[ChatbotPageActions] Save chat scheme'
  );

  public static resetSearch = action(
    '[ChatbotPageActions] Reset Search'
  );

  public static onChangeInputSearch = actionWithPayload<{ input: string }>(
    '[ChatbotPageActions] On Change Input Search'
  );

  public static onChangeSearchArray = actionWithPayload<{ searchArray: Array<Node<ChatbotNodeData>> }>(
    '[ChatbotPageActions] On Change Search Array'
  );

  public static changeTransform = actionWithPayload<{ transform: TranformSearch }>(
    '[ChatbotPageActions] Set Transform'
  );

  public static goNextSearchArray = action(
    '[ChatbotPageActions] go Next Search Array'
  );

  public static goPrevSearchArray = action(
    '[ChatbotPageActions] go Prev Search Array'
  );

  public static changePreviewNode = actionWithPayload<{ id: string }>(
    '[ChatbotPageActions] Change Preview Node'
  )

  public static setPreviewNodeNull = action(
    '[ChatbotPageActions] Set Preview Node Null'
  )

  public static changeActiveNode = actionWithPayload<{ id: string | null }>(
    '[ChatbotPageActions] Change Active Node'
  )
}
