import React, { ReactElement } from 'react';
import { GoogleMaps } from '../../google-maps/google-maps';





export default function SendLocaionWeb({ data }: {
    data: { latitude: string, longitude: string, text: string }
}

): ReactElement {
    return (
        <>
            <div className="message-media">
                <GoogleMaps
                    position={{
                        lat: Number(data.latitude),
                        lng: Number(data.longitude)
                    }}
                    text={data.text}
                />
            </div>
        </>
    );
}
