import { TFunction } from 'i18next';
import * as Yup from 'yup';

export class MetaForm {
    public webhook: string
    public accountID: string;
    public phoneID:string;
    public token: string;
    public logCallback:string


  constructor(phoneID?:string, accountID?:string, token?:string, logCallback?:string) {
    this.webhook = "Webhook URL"
    this.accountID = accountID || "";
    this.phoneID = phoneID || '';
    this.token = token || '';
    this.logCallback = logCallback || '';

  }


  public static getValidationSchema(t: TFunction): Yup.SchemaOf<MetaForm> {
    const regex = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
    return Yup.object()
      .shape({
        phoneID:Yup.number().required(t('REQUIRED')),
        accountID: Yup.string().required(t('REQUIRED')),
        token: Yup.string().required(t('REQUIRED')),
        logCallback: Yup.string().matches(regex, t('ENTER_CORRECT_URL'))
      }).defined();
  }
}
