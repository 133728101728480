import React, { ReactElement, useCallback } from 'react';
import { Method, NodeType, NodeObjectKey } from '../../enums/enums';
import { ChatbotNodeData } from '../../models/chat-bot-node';
import Node from './node';
import './node.scss';
import SourceHandle from './source-handle';
import TargetHandle from './target-handle';
import classNames from 'classnames';
import { useRTL } from '../../hooks/use-rtl';
import { useDispatch, useSelector } from 'react-redux';
import { ChatBuilderActions } from '../../redux/chat-builder';
import { useTranslation } from '../../hooks/use-translate';
import Editor from '@monaco-editor/react';
import { GetHighLightElement } from '../../helpers/wrokflow-builder/highlight-search-text';
import { ChatbotActionsSelectors } from '../../redux/chatbot-actions/selectors';
import AutoComplete from '../auto-complete/auto-complete';
import OptionInput from '../form/option-input';








function WebhookNode({ data }: { data: ChatbotNodeData }): ReactElement {
  const isRTL = useRTL();
  const dispatch = useDispatch();
  const t = useTranslation('COMPONENTS.NODE');
  const searchValue = useSelector(ChatbotActionsSelectors.inputSearchValue)
  const description = `${t('DESCRIPTION')}: ${data.obj?.description}`


  const onChangeOption = (key: NodeObjectKey, value: string): void => {
    dispatch(ChatBuilderActions.changeObj({ id: data.id, key, value }));
  };



  const actionsMethods = [Method.GET, Method.POST].map((key) => ({
    value: key,
    label: key,
    action: () => onChangeOption(NodeObjectKey.METHOD, key)
  }));






  const highLightCallBack = useCallback(() =>
    GetHighLightElement(searchValue, description, <span>{description}</span>),
    [searchValue, data.obj?.description])

  return (
    <Node data={data}
      type={NodeType.WEBHOOK}
      className="node-webhook">
      <TargetHandle id={data.id} />
      {(data.obj!.type !== NodeType.END || data.obj!.method !== Method.GET) && <SourceHandle id={data.id} />}
      <div className={classNames(['node-options', { 'rtl': isRTL }])}>
        {data.isSelected ?
          <>
            <div className="node-option-obj">
              <OptionInput
                onChange={(value) => onChangeOption(NodeObjectKey.DESCRIPTION, value)}
                value={data.obj?.description ?? ''}
                placeholder={`${t('DESCRIPTION')} ${t('OPTIONAL')}`}
                className="node-option-input"

              />
            </div>
            <div className="node-option-obj">
              <OptionInput onChange={(value) =>
                onChangeOption(NodeObjectKey.URL, value)}
                value={data.obj?.url ?? ''}
                placeholder={t('URL')}
                className="node-option-input"
              />

            </div>
            <div className="node-option-obj">
              <AutoComplete
                items={actionsMethods}
                value={data.obj?.method ?? ''}
                disableFilter
                variant="filled"
                centerItem
                centerInput
                isRTL={isRTL}
                classNameInput="method"
                width="100%"
                position="relative"
                zIndex={10}
              />

            </div>
            <div className="node-option-obj"
              dir="ltr">
              <Editor
                height="20vh"
                defaultLanguage="json"
                value={data.obj?.json}
                theme="vs-light"
                className="node-option-input"
                onChange={(value) => onChangeOption(NodeObjectKey.JSON, value ?? '')}

                options={{
                  minimap: { enabled: false },
                  scrollbar: {
                    vertical: "hidden",
                    horizontal: "hidden"
                  }
                }} />
            </div>
          </> :
          <>
            <span className="node-description">
              {t('METHOD')}: {data.obj?.method}
            </span>
            <br />
            <span className="node-description">
              {data.obj?.description && highLightCallBack()}
            </span>
          </>
        }
      </div>
    </Node>
  );
}


const areEqual = (prevProps: any, nextProps: any) => prevProps.data === nextProps.data;

export default React.memo(WebhookNode, areEqual);