import { User } from "../../models";

export class AuthState {
  public isAuthenticated: boolean;
  public isAuthenticating: boolean;
  public user: User;
  public officelyUser:boolean | string
  public isFreeBasic:boolean
  public accessTeam: boolean

  constructor() {
    this.isAuthenticated = false;
    this.isAuthenticating = false;
    this.officelyUser = false
    this.isFreeBasic = false
    this.accessTeam = false 
  }
}
