import { Node } from 'reactflow';
import { useDispatch } from 'react-redux';
import { ChatBotActionsActions } from '../redux/chatbot-actions';
import { ChatBuilderActions } from '../redux/chat-builder';


export function useTransformByNode(): (node: Node) => void {
    const dispatch = useDispatch();


    return (node: Node) => {
        const x = node.position.x + (node.width ?? 400 / 2);
        const y = node.position.y + (node.height ?? 200 / 2);
        const zoom = 0.8;
        dispatch(ChatBotActionsActions.changeTransform({ transform: { x, y, zoom } }))
        dispatch(ChatBuilderActions.onSelectSearchNode({ id: node.id }))
        dispatch(ChatBotActionsActions.changeActiveNode({ id: node.id }))

    }

}
