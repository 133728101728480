import { TFunction } from 'i18next';

import * as Yup from 'yup';



export class SignupForm {
  public phone: string;
  public bs_name: string;
  public email: string;
  public verificationCode: string;
  public checkbox: boolean;
  public countryCode: any;


  constructor() {
    this.phone = '';
    this.bs_name = '';
    this.email = '',
    this.verificationCode = '';
    this.countryCode = '';
    this.checkbox = false
  }


  public static getValidationSchema(t: TFunction, isVerification: boolean): Yup.SchemaOf<SignupForm> {

    return Yup.object()
      .shape({
        phone: Yup.string().required(t("REQUIRED")),
        bs_name: Yup.string().required(t("REQUIRED")),
        email: Yup.string().email(t("TEXT_EMAIL_ERROR")).required(t("REQUIRED")),
        checkbox: Yup.bool().oneOf([true], t("REQUIRED")),
        verificationCode: Yup.string()
          .when('phone', {
            is: () => isVerification,
            then: Yup.string().required(t('REQUIRED'))
          })
      }).defined();
  }
}
