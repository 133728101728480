import classNames from 'classnames';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import ReactMde from 'react-mde';
import { useDispatch, useSelector } from 'react-redux';
import { NodeType } from '../../enums/enums';
import { GetHighLightElement, GetHighlightMdeText } from '../../helpers/wrokflow-builder/highlight-search-text';
import { useRTL } from '../../hooks/use-rtl';
import { ChatbotNodeData } from '../../models/chat-bot-node';
import { ChatbotActionsSelectors } from '../../redux/chatbot-actions/selectors';
import FormText from '../form/text';
import Node from './node';
import './node.scss';
import SourceHandle from './source-handle';
import TargetHandle from './target-handle';
import { format } from '@flasd/whatsapp-formatting';
import { ChatBuilderActions } from '../../redux/chat-builder/actions';
import PopUpForm from '../form/popup-form';
import { useTranslation } from '../../hooks/use-translate';


export interface TemplateObject {
  name?: string;
  components?: Array<ComponentTemplate>;
  language?: string;
  status?: string;
  category?: string;
  id?: string;
  paramters?: {
    bodyParam: Array<string>,
    headerParam: Array<string>,
    actionButtonsParam: Array<string>
  }
  actionButtons?: Array<ButtonTemplate>
}

export interface ComponentTemplate {
  type: string;
  text: string;
  format?: string;
  buttons?: Array<ButtonTemplate>;

}



export interface ButtonTemplate {
  type: string;
  text: string;
  url?: string;
  phone_number?: string;
}




function TemplatesNode({ data }: { data: ChatbotNodeData }): ReactElement {
  const t = useTranslation('COMPONENTS.NODE');
  const dispatch = useDispatch();
  const searchValue = useSelector(ChatbotActionsSelectors.inputSearchValue)
  const isRTL = useRTL();
  const [popUpParam, setPopUpParam] = useState<{type:string, open:boolean, arr:Array<string>}>()

  const onChnage = (index: number, value: string) => {
    switch (popUpParam?.type) {
      case 'bodyParam':
        onChangeBodyParam(index, value)
        break;
      case 'actionButtonsParam':
        onChangeActionButtonsParam(index, value)
        break;
      default:
        break;
    }
  }

  const onChangeBodyParam = (index: number, value: string) => {
    const oldParam = data.obj?.template?.paramters?.bodyParam[index]
    const text = data.text.replace(`${oldParam}`, `{{${value}}}`)
    popUpParam!.arr[index] = value
    dispatch(ChatBuilderActions.changeNodesText({ id: data.id, text }));
    dispatch(ChatBuilderActions.onUpdateNodeTemplate({ id: data.id, index, key: 'bodyParam', value }));
    setPopUpParam({...popUpParam!})

  }


  const onChangeActionButtonsParam = (index: number, value: string) => {
    //url only!
    const oldParam = data.obj!.template!.paramters!.actionButtonsParam[index]
    const button = data.obj!.template!.actionButtons?.find((x) => x?.url?.includes(oldParam))
    if (!button?.url) return
    popUpParam!.arr[index] = value
    dispatch(ChatBuilderActions.onUpdateNodeTemplate({ id: data.id, index, key: 'actionButtonsParam', value }));
    setPopUpParam({...popUpParam!})
  }


  const highLightCallBack = useCallback((value: string) =>
    GetHighLightElement(searchValue, value),
    [searchValue])

  const highLightMdeCallBack = useCallback(() => {
    const text = data.isSelected ? data.text : data.text.replaceAll('{{', '').replaceAll('}}', '')
    return GetHighlightMdeText(searchValue, text, data.isSelected)
  },
    [searchValue, data.text, data.isSelected])


  useEffect(() => {
    !data.isSelected && OnClosePopUp()
  }, [data.isSelected])

  const OpenPopUpParam = (arr:Array<string>, type:string) => {
    const tempArr = arr.map((x) => x.replace('{{', '').replace('}}', ''))
    setPopUpParam({type, open: true, arr:tempArr })
  }

  const OnClosePopUp = () => {
    setPopUpParam({type:'', open: false, arr: [] })
  }


  return (
    <Node data={data}
      type={NodeType.TEMPLATES}
      className="node-template">
      <TargetHandle id={data.id} />
      {popUpParam && <PopUpForm
        //fieldsArr={data.obj?.template?.paramters?.bodyParam.map((x) => x.replace('{{', '').replace('}}', '')) || []}
        fieldsArr={popUpParam.arr}
        onChange={onChnage}
        toggleClose={OnClosePopUp}
      />
      }
      <div className={classNames([{ 'rtl': isRTL, "input": data.isSelected && data.obj?.template?.paramters?.bodyParam.length }])}
        //onClick={() => setPopUpParam(true)}>
        onClick={() => OpenPopUpParam(data.obj?.template?.paramters?.bodyParam || [], 'bodyParam')}
        >
        <ReactMde
          value={highLightMdeCallBack()}
          selectedTab="preview"
          disablePreview={true}
          readOnly={true}
          generateMarkdownPreview={(markdown) =>
            Promise.resolve(format(markdown))
          }
        />
      </div>
      <div className="node-options">
        {
          (data.options?.length) ?
            data.options?.map((option) =>
              <div key={option.id} className={classNames(['node-option', { 'rtl': isRTL }])}
              >
                {(data.isSelected)
                  ? <FormText
                    value={option.text}
                    className="node-option-input"
                    disabled={true}
                  />
                  :
                  <span className="node-option-text">
                    {highLightCallBack(option.text || t("TITLE"))}
                  </span>
                }
                <SourceHandle id={option.id} />
              </div>
            )
            :
            <>
              {data.obj?.template?.actionButtons?.map((action, index) =>
                <div key={index} className={classNames(["node-option", { rtl:isRTL }])} 
                >
                  {(data.isSelected) ?
                    <FormText
                      value={action.url ?? action.phone_number ?? action.text}
                      //className="node-option-input"
                      dir="ltr"
                      className={classNames({
                        'node-option-input': action.url && data.obj?.template?.paramters?.actionButtonsParam.length
                      })}
                      readOnly={true}
                      onClick={() => action.url && OpenPopUpParam(data.obj?.template?.paramters?.actionButtonsParam || [], 'actionButtonsParam')}

                    /> :
                    <span className="node-option-text">
                      {highLightCallBack(action.text)}
                    </span>
                  }
                </div>)
              }
              <SourceHandle id={data.id} />
            </>
        }
      </div>
    </Node>
  );
}

const areEqual = (prevProps:any, nextProps:any) => prevProps.data === nextProps.data;

export default React.memo(TemplatesNode, areEqual);