import { createSelector } from 'reselect';
import { AppState } from '../ConfigureStore';
import { AgentState } from './state';

const selectFeature = (state: AppState): AgentState => state.dataSources;

export class AgentSelectors {
  public static agents = createSelector(
    selectFeature,
    (state) => state.agents ?? []
  );

  public static currentAgent = createSelector(
    selectFeature,
    (state) => state.currentAgent
  );



 
}
