import React, { ReactElement } from "react";
import classNames from 'classnames';


interface Props {
    children: React.ReactNode;
    isLimited?: boolean;
    previewMode?: boolean
    nodeListActions?: boolean
  }


export default function SideBarChatBuilder({ children, isLimited, previewMode, nodeListActions }: Props): ReactElement {

    return (
        <aside className={classNames(["sidebar-chat-builder", { limited: isLimited, previewMode, nodeListActions }])}>
            {children}
        </aside>
    )

}