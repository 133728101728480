import React, { ReactElement, useCallback } from "react";
import UseGetNames from 'src/hooks/team-builder/use-get-names';
import ReactMde, { Suggestion } from 'react-mde';
import { systemVariables } from 'src/components/team-builder/interfaces';


export function Editor({ id, value, onChange, textAreaClass }: {
    id: string,
    value: string,
    onChange: (value: string) => void,
    textAreaClass?: string
  }): ReactElement {
  
    const useNodeNames = UseGetNames()
  
  
    const allVariables = useCallback((): Suggestion[] => {
      const names = useNodeNames(id)
      const variables = [...names, ...systemVariables]
      return variables.map((s) => ({
        preview: `${s}`,
        value: `{{${s}}}`
      }))
    }, [id])
  
    
  
  
    const loadSuggestions = async (text: string) =>
      new Promise<Suggestion[]>((accept) => {  
        setTimeout(() => {
          const suggestions: Suggestion[] = allVariables()
            .filter((i) =>
              i.value.includes(text.toLowerCase()))
            .sort((a, b) => a.value < b.value ? -1 : 1)
          accept(suggestions);
        }, 250);
  
  
      });
  
  
  
    return (
      <ReactMde
        value={value}
        onChange={onChange}
        loadSuggestions={loadSuggestions}
        classes={{
          reactMde: "mde",
          suggestionsDropdown: 'suggestions',
          textArea: `form-control ${textAreaClass}`,
          toolbar: 'toolbar'
        }}
        suggestionsAutoplace={false}
        suggestionTriggerCharacters={["@", "#", '{', '}']}
  
  
      />
    )
  }