import { Edge, Node } from 'reactflow';
import { v4 } from 'uuid';
import { NodeType } from '../../enums/enums';
import { ChatbotNodeData } from '../../models';
import { immerable } from 'immer';
import { Suggestion } from 'react-mde';
import { Builder } from './interfaces';

const startID = v4();

const startScheme: Array<Node<ChatbotNodeData> | Edge> = [
  {
    id: startID,
    type: NodeType.START,
    data: {
      id: startID,
      text: '',
      orderOfType: 1,
      isError: false,
      isSearch: false
    },
    position: { x: 50, y: 150 }
  }
]






export class ChatBuilderState {
  [immerable] = true;

  public chatScheme: Array<Node<ChatbotNodeData> | Edge>;
  public activeSourceID: string | null;
  public startScheme: Array<Node<ChatbotNodeData> | Edge>;
  public removedArr: Array<string | null>;
  public suggestion: Array<Suggestion>;
  public currentBuilder: Builder | null;
  public isSaved: boolean;

  constructor() {
    this.chatScheme = [];
    this.startScheme = startScheme;
    this.removedArr = [];
    this.suggestion = []
    this.activeSourceID = null;
    this.isSaved = true;
  }
}
