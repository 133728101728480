import React, {MouseEvent, RefObject, forwardRef, Ref, useRef, useState, useEffect } from "react";
import { useDisclosure, InputProps, FormControl, FormLabel, Input, ButtonGroup, Button, Popover, PopoverContent, PopoverArrow, PopoverCloseButton, Stack, Box } from "@chakra-ui/react";
import FocusLock from "react-focus-lock"
import { useRTL } from "../../hooks/use-rtl";
import { useTranslation } from "../../hooks/use-translate";


interface TextInputProps extends InputProps {
    label: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const TextInput = forwardRef((props: TextInputProps, ref: Ref<HTMLInputElement>) => {
    const { id, label, onChange, ...rest } = props;

    return (
        <FormControl>
            <FormLabel htmlFor={id}>{label}</FormLabel>
            <Input maxLength={50} onChange={onChange} ref={ref} id={id} {...rest} onDoubleClick={(e) => e.stopPropagation()} />
        </FormControl>
    );
});

interface FormProps {
    FieldRef: RefObject<HTMLInputElement>;
    label: string
    onCancel: () => void;
    onSave: (text: string) => void;
    text: string
}

const Form: React.FC<FormProps> = ({ text, label, FieldRef, onCancel, onSave }) => {
    const [value, setValue] = useState<string>(text)
    const tSwal = useTranslation('COMPONENTS.SWAL')


    useEffect(() => {
        window.addEventListener("keypress", handleKeyPress)
        return () => {
            window.removeEventListener("keypress", handleKeyPress)
        }
    }, [value])

    const handleKeyPress = (k: KeyboardEvent) => {
        k.key === "Enter" && onSave(value)
    }

    const handleCancel = () => {
        setValue(text)
        onCancel()
    }

    return (
        <Stack spacing={4} onClick={(e) => e.stopPropagation()} >
            <TextInput
                label={label}
                ref={FieldRef}
                defaultValue={text}
                onChange={(e) => setValue(e.target.value)}
                overflow={"hidden"}
            />
            <ButtonGroup display="flex" justifyContent="flex-end">
                <Button variant="outline" onClick={handleCancel}>
                    {tSwal("CANCEL")}
                </Button>
                <Button onClick={() => onSave(value)} colorScheme="primary">
                    {tSwal("SAVE")}
                </Button>
            </ButtonGroup>
        </Stack>
    )

}

export const PopoverEditText = ({ isOpen, text, label, onSave, onClose }: {
    text: string,
    label: string,
    isOpen: boolean,
    onSave: (text: string) => void
    onClose: () => void
}) => {
    const { onOpen } = useDisclosure();
    const FieldRef = useRef<HTMLInputElement>(null);
    const closeButtonSpacing = "var(--chakra-space-3)"
    const isRTL = useRTL()

    const handleSave = (text: string) => {
        onSave(text)
        onClose()
    }

    const handleClickClose = (e: MouseEvent) => {
        e.stopPropagation()
        onClose()
    }

    useEffect(() => {
        if (isOpen) {
            onOpen()
        }
    }, [isOpen])

    return (
        <>
            <Popover
                isOpen={isOpen}
                initialFocusRef={FieldRef}
                onOpen={onOpen}
                onClose={onClose}
                closeOnBlur={true}
            >
                <PopoverContent p={5} mt={55} zIndex={5}>
                    <FocusLock returnFocus persistentFocus={false}>
                        <PopoverArrow />
                        <PopoverCloseButton onClick={handleClickClose} style={isRTL ? { left: closeButtonSpacing, right: "unset" } : {}} />
                        {isOpen && <Form text={text} label={label} FieldRef={FieldRef} onCancel={onClose} onSave={handleSave} />}
                    </FocusLock>
                </PopoverContent>
            </Popover>
            <Box display="inline-block" maxW={"100%"} height={30} >
                {text}
            </Box>
        </>
    );
};

