import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import ChatBuilder from '../../components/chat-builder/chat-builder';

import ChatbotActions from '../../components/chatbot/actions';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useRTL } from '../../hooks/use-rtl';
import classNames from 'classnames';
import { ChatBuilderSelectors } from '../../redux/chat-builder/selectors';
import { useTranslation } from '../../hooks/use-translate';
import { useFetchOfficely } from '../../hooks/use-fetch-officely';
import { Method, PathName } from '../../enums/enums';
import { ChatBuilderActions } from '../../redux/chat-builder/actions';
import NodeTypeList from '../../components/chat-builder/nodes-list-actions';
import './flowbuilder-page.scss';
import Icon, { IconTheme, IconVariant } from '../../components/icon/icon';
import PreviewMode from '../../components/chat-builder/preview-mode';
import { ChatBotActionsActions, ChatbotActionsSelectors } from '../../redux/chatbot-actions';
import { Builder, ConfigurationBuilderType } from '../../redux/chat-builder';
import { ReactFlowProvider } from 'reactflow';
import { Alert, AlertActions, ModalPopup } from '../../helpers/dialog';
import { PreviewReplaceLogo, StyleBuilder } from 'src/components/officely-web-componnents';






function FlowBuilderPage(): ReactElement {
  const dispatch = useDispatch();
  const tSwal = useTranslation('COMPONENTS.SWAL')
  const t = useTranslation("PAGES.CHAT_BOT")
  const currentBuilder: Builder | null = useSelector(ChatBuilderSelectors.currentBuilder)
  const isRTL = useRTL();
  const navigate = useNavigate();
  const [tempTitle, setTempTitle] = useState(currentBuilder?.builder_name)
  const isActive = currentBuilder?.status ?? false
  const [previewMode, togglePreview] = useState(false)
  const [renderPreview, setRenderPreview] = useState(false)
  const PreviewNodeID = useSelector(ChatbotActionsSelectors.previewNodeID)
  const [dialogActions, setDialogActions] = useState<AlertActions | null>(null)
  const [isOpenDialog, setIsOpenDialog] = useState(false)
  const [toggleVar, setToggleVar] = useState(false)
  const [fitView, onFitView] = useState(false)
  const [openConfiguration, setOpenConfiguration] = useState<ConfigurationBuilderType | null>(null)


  useEffect(() => {
    if (!currentBuilder) {
      navigate(PathName.BUILDERS);
      return;
    }

    const handleBeforeUnload = () => Beforeunload();
    window.addEventListener("beforeunload", handleBeforeUnload);

    document.title = t("TITLE")

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }
  }, [])

  useEffect(() => {
    if (PreviewNodeID.id) {
      TogglePreview()
    }
  }, [PreviewNodeID.render])

  const Beforeunload = () => {
    setOpenConfiguration(null)
    dispatch(ChatBuilderActions.setCurrentBuilderNull())
    dispatch(ChatBotActionsActions.resetState());
  }


  const handleEditTitle = (e: ChangeEvent<HTMLInputElement>): void => {
    setTempTitle(e.target.value)
  }


  const onBlurInput = async (): Promise<void> => {
    const newTitle = tempTitle !== '' ? tempTitle || currentBuilder!.builder_name : currentBuilder!.builder_name
    const res = await useFetchOfficely(`updateTitleBuilder/${currentBuilder!.builder_id}/${newTitle}`, Method.POST)
    if (!res.success) { ErrorFetchDialog() }
    dispatch(ChatBuilderActions.changeBuilderName({ newBuilderName: newTitle }));
    setTempTitle(newTitle)
  }


  const ErrorFetchDialog = () => {
    OpenDialiog({
      title: tSwal("ERROR"),
      content: tSwal("ERROR_FETCH"),
      confirm: tSwal("OK"),
      colorButton: "primary",
      isMessage: true
    })
  }



  const TogglePreview = () => {
    togglePreview(true);
    setRenderPreview(!renderPreview)
  }

  const OpenDialiog = (alertProps: AlertActions) => {
    setDialogActions({ ...alertProps })
    setIsOpenDialog(true)
  }


  const getSettingComponent = (): ReactElement => {
    switch (openConfiguration) {
      case ConfigurationBuilderType.STYLE:
        return <StyleBuilder onClose={() => setOpenConfiguration(null)} />;
      case ConfigurationBuilderType.LOGO:
        return <PreviewReplaceLogo  onClose={() => setOpenConfiguration(null)} />
      default:
        return <></>

    }
  }

  return (
    <div className="page chatbot column">
      <header className="page-header">
        <div className="page-header-left">
          <div className="chatbot-header-left" >
            <span className="chatbot-header-left-toggle" onClick={() => navigate(PathName.BUILDERS)}>
              <span className={classNames(["chatbot-header-left-toggle-icon", { 'rtl': isRTL }])}>
                <Icon variant={IconVariant.BACK_SMALL} theme={IconTheme.BLACK} />
              </span>
              <span className={classNames(["chatbot-header-left-toggle-text", { 'rtl': isRTL }])}>
                {t("MAIN")}
              </span>
            </span>
            {/* <LangSelect /> */}
            <span className="chatbot-header-left-line"></span>
            <input
              onChange={handleEditTitle}
              value={tempTitle}
              className={classNames(["chatbot-header-left-toggle-title", { 'rtl': isRTL }])}
              onBlur={onBlurInput}
              maxLength={50}
            />
          </div>
        </div>
        <div className="page-header-right">
          <ChatbotActions
            isActive={isActive}
            togglePreview={() => TogglePreview()}
            setOpenSetting={(type) => setOpenConfiguration(type)}
          />
        </div>
      </header>
      <div className="chatbot-content">
        <NodeTypeList onFitView={() => onFitView(!fitView)} />
        <ReactFlowProvider>
          <ChatBuilder
            isActive={isActive}
            onFitView={fitView}
            toggleVar={() => setToggleVar(!toggleVar)}
          />
        </ReactFlowProvider>
        {previewMode && <PreviewMode
          render={renderPreview}
          isOpen={previewMode}
          PreviewNodeID={PreviewNodeID.id ?? ''}
          toggleClose={() => togglePreview(false)}
        />}
        {/* {openSetting !== null && <SideBar
          isOpen={openSetting === SettingBuilderType.STYLE}
          toggleMenu={() => setOpenSetting(null)}
          itemName="Style"
          itemID={SideBarView.STYLE}
          overflowHidden
        />} */}

        <ModalPopup
          isOpen={openConfiguration !== null}
          onClose={() => setOpenConfiguration(null)}
          closeOnOverlayClick={false}
          //modalOverlay={false}
        >
        {getSettingComponent()}
        </ModalPopup>
      </div>
      {isOpenDialog && <Alert
        isOpen={isOpenDialog}
        onClose={() => setIsOpenDialog(false)}
        actions={dialogActions!}
      />}
    </div>
  );
}

export default React.memo(FlowBuilderPage);


