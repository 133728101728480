export enum TeamFlowNodeType {
  LLM = 'LLM',
  RETRIVER = 'Retriver',
  FILTER = 'Filter',
  PYTHON = 'Python',
  TEXT = 'Text',
  JUMP = 'Jump',
}



export enum TeamNodeType {
  WORKFLOW = 'workflow',
  PLACEHOLDER = 'placeholder',
}


export enum SchemaType {
  STRING = "string",
  BOOL = "boolean",
  NUMBER = "number",
  FLOAT = "float",

}



export interface Schema {
  key: string;
  type: SchemaType;
  description: string;
  is_enum: boolean;
  enums?: Array<string>;
}




export const systemVariables = ["chat_history", "question"]


// .map((s) => ({
//   preview: `${s}`,
//   value: `{${s}}`
// }))