import React from 'react'; //eslint-disable-line
import PlaceholderEdge from './placeholder-edge';
import WorkflowEdge from './workflow-edge';

export const edgeTypes = {
  placeholder: PlaceholderEdge,
  workflow: WorkflowEdge
};

export default edgeTypes;
