import { getIncomers, NodeProps, useReactFlow } from 'reactflow';
import { ModelType, TeamFlowNodeType } from 'src/components/team-builder';
import { LLMNodeData } from 'src/models';
import { v4 } from 'uuid'
import useRandomImage from './use-random-images';


// this hook implements the logic for clicking a placeholder node
// on placeholder node click: turn the placeholder and connecting edge into a workflow node
export function usePlaceholderClick(id: NodeProps['id']) {
  const {getNodes, getEdges, getNode, setNodes, setEdges } = useReactFlow();
  const getRandomImage = useRandomImage();



  const getDataWorkFlow = (id: string) => {
    const data: LLMNodeData = {
      id,
      description: "",
      model: ModelType.GPT4_O,
      system_prompt:"",
      prompt: "",
      name: id,
      type: TeamFlowNodeType.LLM,
      with_schema: false,
      urlImage: getRandomImage()
      
    }
    return data

  }


  const onClick = () => {
    // we need the parent node object for getting its position
    const parentNode = getNode(id);
    const nodes = getNodes();
    const edges = getEdges();


    if (!parentNode) {
      return;
    }
    const incomers = getIncomers(parentNode, nodes, edges)

    if (incomers.length > 0 && incomers[0].data.type === TeamFlowNodeType.JUMP) {
      alert('You can not add a node to a jump node');
      return;
    }

    // create a unique id for the placeholder node that will be added as a child of the clicked node
    const childPlaceholderId = v4();

    // create a placeholder node that will be added as a child of the clicked node
    const childPlaceholderNode = {
      id: childPlaceholderId,
      // the placeholder is placed at the position of the clicked node
      // the layout function will animate it to its new position
      position: { x: parentNode.position.x, y: parentNode.position.y },
      type: 'placeholder',
      data: { label: '+' }
    };

    // we need a connection from the clicked node to the new placeholder
    const childPlaceholderEdge = {
      id: `${parentNode.id}=>${childPlaceholderId}`,
      source: parentNode.id,
      target: childPlaceholderId,
      type: 'placeholder'
    };
    const data = getDataWorkFlow(id);

    setNodes((nodes) =>
      nodes
        .map((node) => {
          // here we are changing the type of the clicked node from placeholder to workflow
          if (node.id === id) {
            return {
              ...node,
              type: 'workflow',
              data
            };
          }
          return node;
        })
        // add the new placeholder node
        .concat([childPlaceholderNode])
    );

    setEdges((edges) =>
      edges
        .map((edge) => {
          // here we are changing the type of the connecting edge from placeholder to workflow
          if (edge.target === id) {
            return {
              ...edge,
              type: 'workflow'
            };
          }
          return edge;
        })
        // add the new placeholder edge
        .concat([childPlaceholderEdge])
    );
  };

  return onClick;
}

export default usePlaceholderClick;
