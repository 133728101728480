import {
  Button,
  chakra,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuItemProps,
  MenuList,
  Portal,
  Spinner,
  Stack
} from '@chakra-ui/react'
import React, { ReactNode } from 'react'

export type GroupItems<T> = {
  title?: string;
  items: T[];
};


type Props<T> = {
  currentItem?: string
  onItemSelect?: (item: T, groupTitle?: string) => void
  items: T[] | GroupItems<T>[]
  withGroup?: boolean
  center?: boolean
  placeholder?: string
  isLoading?: boolean
  closeOnSelect?: boolean //defualt true
  className?: string
  itemProps?:MenuItemProps
}

const Item = <T,>(props: { 
  item: T;
  onItemSelect?: (item: T, groupTitle?: string) => void, 
  center?: boolean
  itemProps?:MenuItemProps
}) => {
  const { item, onItemSelect, center, itemProps } = props
  return (
    <MenuItem
      key={item as unknown as string}
      //maxW="500px"
      overflow="hidden"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      justifyContent={center ? "center" : 'start'}
      _focus={{ background: "gray.100" }}
      {...itemProps}

      m={0}
      onClick={() => onItemSelect && onItemSelect(item)}
    >
      {item as unknown as ReactNode}
    </MenuItem>
  )
}

export const ChakraMenu = <T,>({
  currentItem,
  className,
  onItemSelect,
  items,
  withGroup,
  center,
  placeholder,
  isLoading,
  closeOnSelect,
  itemProps,
  ...props
}: Props<T> & MenuButtonProps) => {


  const handleMenuItemClick = (operator: T, groupTitle?: string) => () => {
    onItemSelect && onItemSelect(operator, groupTitle)
  }

  return (
    <Menu matchWidth placement="bottom-start" closeOnSelect={closeOnSelect}>
      <MenuButton
        as={Button}
        colorScheme="gray"
        justifyContent="space-between"
        className={className}
        {...props}
      >
        <chakra.span
          noOfLines={1} display="block" >
          {(currentItem ?? placeholder) as unknown as ReactNode}
        </chakra.span>
      </MenuButton>
      <Portal>
        <MenuList zIndex={1500} width={'100%'}>
          <Stack maxH={'35vh'} textAlign="center"
            alignItems={isLoading ? "center" : ""}
            overflowY={isLoading ? "hidden" : "auto"}
            spacing="0">
            {isLoading ?
              <Spinner
                emptyColor="gray.200"
                color="primary.500"
                size="xs" />
              :
              <>
                {
                  withGroup ?
                    <>
                      {
                        (items as GroupItems<T>[]).map((group, ig) => (
                          <MenuGroup key={ig} title={group.title} textAlign={center ? "center" : "start"}>
                            {group.items.map((item, ii) => (
                              <Item key={ii} item={item} center={center} onItemSelect={handleMenuItemClick(item, group.title)} />
                            ))}
                            {ig !== items.length - 1 && <MenuDivider />}
                          </MenuGroup>
                        ))
                      }
                    </>
                    :
                    <>
                      {(items as T[]).map((item, i) => (
                        <Item key={i} center={center} item={item} onItemSelect={handleMenuItemClick(item)} itemProps={itemProps} />
                      ))}
                    </>
                }
              </>
            }


          </Stack>
        </MenuList>
      </Portal>
    </Menu >
  )
}

