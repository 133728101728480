import { Method, NodeType } from "../../enums/enums";
import { useFetchOfficely } from "../../hooks/use-fetch-officely";
import { Chat, selfData, WidgetBody, WidgetButtons } from "../../pages/web-channel/interfaces";

export const GetNewQuestionOfficelyChannel = async (
    body: WidgetBody,
    isChat: boolean,
    selfData?: selfData
): Promise<selfData | boolean> => {

    const res = await useFetchOfficely('officelyChannel', Method.POST, body)
    if (res.success && res.data) {
        if (!isChat) {
            const last_element: Chat = res.data.chat[res.data.chat.length - 1]
            if (last_element?.is_error) {
                const filterdChat = selfData!.chat!.filter((chat: Chat) => chat.type === 'outbound')
                res.data = { ...selfData, chat: [...filterdChat, last_element] }
            }
        }

        return res.data as selfData
    }
    return false
}

export const CalculateShowInput = (isChat: boolean, selfData?: selfData): boolean => {
    const notActive = NodeType.NOT_ACTIVE === selfData?.ques_data?.ques.type;
    if (notActive) return false;
    if (isChat) return true;
    const endType = [NodeType.END, NodeType.NOTIFICATION].includes(selfData?.ques_data?.ques.type ?? NodeType.END);
    const hasNoAnswers = (selfData?.ques_data?.answers?.length ?? 0) === 0;
    return !endType && hasNoAnswers;
}

export const ClickBtn = (payload: WidgetButtons, chat: Array<Chat>) => {
    chat.forEach((msg: any) => {
        msg.buttons?.forEach((btn: WidgetButtons) => {
            if (btn.id === payload?.id) btn.disable = true;
        });
    });
}


// export const handleSubmit = ({ answer, payload, value, extraElement }: {
//     answer?: string,
//     payload?: WidgetButtons,
//     value?: string,
//     extraElement?: ExtraElementChat
// }) => {
//     if ((!answer?.trim() && !payload && !value && !extraElement)) return false;

//     return payload ? payload.title : value ? value : answer?.trim();


// }

// export const GetAnswerFromAI = async (body:any): Promise<Array<Chat> | boolean> => {
//     const res = await useFetchOfficely('ai_chat', Method.POST, body)
//     if (res.success && res.data) {
//         return res.data as Array<Chat>
//     }
//     else return false

// }

