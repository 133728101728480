import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { NodeType } from '../../enums/enums';
import { useRTL } from '../../hooks/use-rtl';
import { ChatbotNodeData } from '../../models/chat-bot-node';
import Node from './node';
import './node.scss';
import SourceHandle from './source-handle';
import TargetHandle from './target-handle';



function SendLocation({ data }: { data: ChatbotNodeData }): ReactElement {
  const isRTL = useRTL();
  return (
    <Node data={data}
      type={NodeType.SEND_LOCATION}
      className="node-send-location">
      <TargetHandle id={data.id} />
      <SourceHandle id={data.id} />
      {!data.isSelected &&
        <div className={classNames(["node-description", { rtl: isRTL }])}>
          {data.obj?.text}
          {/* <span className="node-description" >
          {data.obj?.text}
        </span> */}
        </div>
      }
    </Node>
  );
}


const areEqual = (prevProps:any, nextProps:any) => prevProps.data === nextProps.data;

export default React.memo(SendLocation, areEqual);
