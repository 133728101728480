import React from "react"
import { AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogCloseButton, AlertDialogBody, AlertDialogFooter, Button } from "@chakra-ui/react"
import classNames from "classnames"
import { useRTL } from "../../hooks/use-rtl"
import { AlertActions } from "./interfaces"

export const Alert = ({ isOpen, actions, onClose }: {
    isOpen: boolean,
    actions: AlertActions
    onClose: () => void
}) => {
    const cancelRef = React.useRef<HTMLButtonElement | null>(null)
    const isRTL = useRTL()
    const closeButtonSpacing = "var(--chakra-space-3)"

    const handleClickConfirm = () => {
        actions?.onConfirm && actions.onConfirm()
        onClose()
    }

    const handleClickCancel = () => {
        actions?.onCancel && actions.onCancel()
        onClose()
    }

    return (
        <>
            <AlertDialog
                motionPreset="slideInBottom"
                onClose={onClose}
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                isCentered
            >
                <AlertDialogOverlay />
                <AlertDialogContent alignItems={actions?.isMessage ? 'center' : 'unset'}  className={classNames({ rtl: isRTL })}>
                    <AlertDialogHeader>{actions?.title}</AlertDialogHeader>
                    <AlertDialogCloseButton style={isRTL ? { left: closeButtonSpacing, right: "unset" } : {}} />
                    <AlertDialogBody>
                        {actions?.content}
                    </AlertDialogBody>
                    <AlertDialogFooter gap={4} >
                        <Button colorScheme={actions?.colorButton ?? 'primary'}  onClick={handleClickConfirm}>
                            {actions?.confirm}
                        </Button>
                        {actions?.cancel && <Button ref={cancelRef} onClick={handleClickCancel}>
                            {actions?.cancel}
                        </Button>}
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    )
}