import classNames from 'classnames';
import React, { ReactElement, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { NodeType } from '../../enums/enums';
import { GetHighLightElement } from '../../helpers/wrokflow-builder/highlight-search-text';
import { useRTL } from '../../hooks/use-rtl';
import { useTranslation } from '../../hooks/use-translate';
import { ChatbotActionsSelectors } from '../../redux/chatbot-actions/selectors';
import Icon from '../icon/icon';
import { getIconNodeVariant } from '../node-preview/node-preview';
import './node.scss';

function NodeLabel({ type }: {
  type: NodeType;
}): ReactElement {

  const tCommon = useTranslation('COMPONENTS.NODE_PREVIEW');
  const searchValue = useSelector(ChatbotActionsSelectors.inputSearchValue)
  const isRTL = useRTL();

  const highLightCallBack = useCallback(() =>
    GetHighLightElement(searchValue, tCommon(`TEXT_${NodeType[type]}`)),
    [searchValue])

  return (
    <span className="node-label">
      <Icon variant={getIconNodeVariant(type)} />
      <span className={classNames([{ 'rtl': isRTL }])}>
        {highLightCallBack()}
        </span>
        
    </span>
  );
}


const areEqual = (prevProps: any, nextProps: any) => prevProps.data === nextProps.data;

export default React.memo(NodeLabel, areEqual);
