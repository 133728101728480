import React, { ReactElement, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NodeObjectKey, NodeType } from '../../enums/enums';
import { ChatbotNodeData } from '../../models/chat-bot-node';
import { ChatBuilderActions } from '../../redux/chat-builder/actions';
import Node from './node';
import './node.scss';
import SourceHandle from './source-handle';
import { useTranslation } from '../../hooks/use-translate';
import classNames from 'classnames';
import { useRTL } from '../../hooks/use-rtl';
import { GetHighLightElement } from '../../helpers/wrokflow-builder/highlight-search-text';
import { ChatbotActionsSelectors } from '../../redux/chatbot-actions/selectors';
import AutoComplete from '../auto-complete/auto-complete';
import OptionInput from '../form/option-input';


function ApiNode({ data }: { data: ChatbotNodeData }): ReactElement {
  const dispatch = useDispatch();
  const tPreivew = useTranslation('COMPONENTS.NODE_PREVIEW');
  const t = useTranslation('COMPONENTS.NODE');
  const isRTL = useRTL();
  const searchValue = useSelector(ChatbotActionsSelectors.inputSearchValue)
  const description = `${t('DESCRIPTION')}: ${data.obj?.description}`

  const onChangeOption = (key: NodeObjectKey, value: string): void => {
    dispatch(ChatBuilderActions.changeObj({ id: data.id, key, value }));
  };

  const apiTypes = [NodeType.START, NodeType.POPUP]
    .map((key) => ({
      value: tPreivew(`TEXT_${key}`),
      label: tPreivew(`TEXT_${key}`),
      action: () => onChangeOption(NodeObjectKey.TYPE, key)
    }));

  const highLightCallBack = useCallback(() =>
    GetHighLightElement(searchValue, description),
    [searchValue, data.obj?.description])


  return (
    <Node
      data={data}
      type={NodeType.API}
      className="node-api">
      {(data.obj?.type === NodeType.START) && <SourceHandle id={data.id} />}
      <div className={classNames(['node-options', { 'rtl': isRTL }])}>
        {data.isSelected ?
          <div className="node-option-obj">
            <AutoComplete
              items={apiTypes}
              value={`${tPreivew(`TEXT_${data.obj?.type}`)}`}
              disableFilter
              centerItem
              centerInput
              //margin={"20px auto"}
              classNameInput="type"
              variant="filled"
              isRTL={isRTL}
            />
            <OptionInput
              onChange={(value) => onChangeOption(NodeObjectKey.DESCRIPTION, value)}
              value={data.obj?.description ?? ''}
              placeholder={`${t('DESCRIPTION')} ${t('OPTIONAL')}`}
              className="node-option-input description"
            />
          </div>
          :
          <>
            <span className="node-description">
              {data.obj?.description && highLightCallBack()}
            </span>
          </>}
      </div>
    </Node>
  );
}

const areEqual = (prevProps: any, nextProps: any) => prevProps.data === nextProps.data;


export default React.memo(ApiNode, areEqual);
