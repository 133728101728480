import React, { useState } from 'react';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { Handle, Position, NodeProps } from 'reactflow';
import { Alert, AlertActions } from 'src/helpers/dialog';
import usePlaceholderClick from 'src/hooks/team-builder/usePlaceholderClick';
import { useTranslation } from 'src/hooks/use-translate';
import { AuthSelectors } from 'src/redux/auth';

import './team_node.scss';

const PlaceholderNode = ({ id, data }: NodeProps) => {

  const fullAccess = useSelector(AuthSelectors.accessTeam) as boolean;
  const [dialogActions, setDialogActions] = useState<AlertActions | null>(null)
  const tSwal = useTranslation("COMPONENTS.SWAL")

  const handlePlaceholderClick = usePlaceholderClick(id);

  const onClick = () => {
    if (!fullAccess) {
      setDialogActions({
        title: tSwal("ERR_TEAM_UPGRADE_TITLE"),
        content: tSwal("ERR_TEAM_UPGRADE"),
        confirm: tSwal("TO_UPGRADE"),
        onConfirm: () =>  window.open('https://cal.com/officely-ai/demo', '_blank', 'noopener,noreferrer'),
        cancel: tSwal("CANCEL"),
        colorButton: "primary",
        isOpen: true
      })
      return;
    }
    handlePlaceholderClick(); // Use the hook return value
  };


  return (
    <div onClick={() => onClick()} className="placeholder" title="click to add a node">
      {data.label}
      <Handle
        className="handle"
        type="target"
        position={Position.Top}
        isConnectable={false}
      />
      <Handle
        className="handle"
        type="source"
        position={Position.Bottom}
        isConnectable={false}
      />
      <Alert
        isOpen={dialogActions?.isOpen ?? false}
        onClose={() => setDialogActions(null)}
        actions={dialogActions!}
      />
    </div>
  );
};

export default memo(PlaceholderNode);
