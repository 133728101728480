import React, { ReactElement } from 'react';
import classNames from 'classnames';
import './form.scss';
import { FormTextProps, getError, getValue, onBlur } from './text';
import FormError from './error';


export default function FormCheckBox<T>({
    formik,
    name,
    className,
    children,
    ...inputProps
}: FormTextProps<T>): ReactElement {
    const error = getError<T>(formik, name);

    return (
        <div
        
            className={classNames([
                "form-group",
                {
                    'form-group-error': error
                },
                className
            ])}
        >
            <input
                onChange={formik?.handleChange}
                className="form-checkbox"
                onBlur={() => onBlur(formik, name)}
                name={name}
                checked={Boolean(getValue<T>(formik, name))}
                type="checkbox"
                {...inputProps}
            />
            &nbsp;
            {children}
            {error && <FormError error={error} />}
        </div>
    );
}
