import { Method } from "../enums/enums";

export interface FetchResult<T> {
  success: boolean;
  data?: T;
  error?: any;
}

export async function useFetch<T>(url: string, method: Method, headers?: Headers, body?: any): Promise<FetchResult<T>> {
  try {
    const response = await fetch(url, { method, headers, body: JSON.stringify(body) });

    if (!response.ok) {
      throw new Error('Request failed');
    }

    const json = await response.json();
    return {
      success: true,
      data: json as T
    };
  } catch (error) {
    return {
      success: false,
      error
    };
  }
}
