import { Action, createReducer } from 'deox';
import produce from 'immer';
import { ChatBotActionsActions } from './actions';
import { ChatbotActions } from './state';


const initialState = new ChatbotActions();

const reducer = createReducer(initialState, (handleAction) => [
  handleAction(ChatBotActionsActions.resetState, () => initialState),
  handleAction(ChatBotActionsActions.resetSearch, (state) => ({ ...state,
    inputSearchValue:'',
    activeSearch: 0,
    searchArray:[]
  })),
  handleAction(ChatBotActionsActions.goNextSearchArray, (state) => ({ ...state,
    activeSearch: state.activeSearch + 1 <= state.searchArray.length   ? state.activeSearch + 1 : state.activeSearch
  })),
  handleAction(ChatBotActionsActions.goPrevSearchArray, (state) => ({ ...state, 
    activeSearch: state.activeSearch - 1 > 0  ? state.activeSearch - 1 : state.activeSearch
  })),
  handleAction(ChatBotActionsActions.onChangeInputSearch, (state, { payload: { input } }) => produce(state, (draft) => {
    draft.inputSearchValue = input;
    draft.activeSearch = 0;
  })),
  handleAction(ChatBotActionsActions.onChangeSearchArray, (state, { payload: { searchArray } }) => produce(state, (draft) => {
    draft.searchArray = searchArray;
    if (!searchArray.length) {
      draft.activeSearch = 0;
    }
  })),
  handleAction(ChatBotActionsActions.changeTransform, (state, { payload: { transform } }) => produce(state, (draft) => {
    draft.transformSearch = transform;
  })),
  handleAction(ChatBotActionsActions.changePreviewNode, (state, { payload: { id } }) => produce(state, (draft) => {
    draft.previewNodeID.id = id;
    draft.previewNodeID.render = !draft.previewNodeID.render;
  })),
  handleAction(ChatBotActionsActions.setPreviewNodeNull, (state) => produce(state, (draft) => {
    draft.previewNodeID.id = null;
  })),
  handleAction(ChatBotActionsActions.changeActiveNode, (state, { payload: { id } }) => produce(state, (draft) => {
    draft.activeNodeID = id;
  }))
]);

export function chatbotPageReducer(state: ChatbotActions | undefined, action: Action<any>): ChatbotActions {
  return reducer(state, action);
}
