import classNames from 'classnames';
import React, { ReactElement, useEffect, useState } from 'react';
import { TFunction } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { HeaderForamt, Method, NodeObjectKey } from '../../../enums/enums';
import { useFetchOfficely } from '../../../hooks/use-fetch-officely';
import { ChatbotNodeData } from '../../../models/chat-bot-node';
import { AuthSelectors } from '../../../redux/auth';
import { ChatBuilderActions } from '../../../redux/chat-builder/actions';
import PopUpForm from '../../form/popup-form';
import FormText from '../../form/text';
import { TemplateObject } from '../templates-node';
import AutoComplete from '../../auto-complete/auto-complete';
import OptionInput from '../../form/option-input';



export function ExtraTemplates({ data, t, onChangeObj }: {
    data: ChatbotNodeData,
    t: TFunction
    onChangeObj: (key: NodeObjectKey, value: any) => void
}): ReactElement {

    const dispatch = useDispatch();
    const user = useSelector(AuthSelectors.user)
    const [allTemplates, setAllTemplates] = useState<Array<TemplateObject>>([])
    const [actionsType, setActionsType] = useState<any[]>([])
    const [popUpParam, setPopUpParam] = useState(false)


    useEffect(() => {
        (async function fetchMyAPI() {
            const res = await useFetchOfficely(`getTemplatesMeta/${user.uphone_number}`, Method.GET)
            if (res.success) {
                setAllTemplates(res.data)
            }
        }())
        return () => {
            setAllTemplates([])
        }
    }, [])



    useEffect(() => {
        setActionsType(allTemplates
            .sort((a, b) => a.name! < b.name! ? -1 : 1)
            .map((s) => ({
                value: `${s.name} - [${s.language}]`,
                label: `${s.name} - [${s.language}]`,
                action: () => onChangeTemplate(s.id!)
            })))
    }, [allTemplates])


    const onChangeTemplate =(id: string) => {
        const template = allTemplates.find((t) => t.id === id)
        if (template) {
            onChangeObj(NodeObjectKey.TEMPLATE, template)
        }

    }

    const onChnage = (index: number, value: string) => {
        const oldParam = data.obj?.template?.paramters?.headerParam[index]
        const text = data.obj?.header?.replace(`${oldParam}`, `{{${value}}}`)
        dispatch(ChatBuilderActions.changeObj({ id: data.id, key: NodeObjectKey.HEADER, value: text }))
        dispatch(ChatBuilderActions.onUpdateNodeTemplate({ id: data.id, index, key: 'headerParam', value }));

    }



    return (
        <>
            <>
                {data.isSelected && !data.obj?.template ?
                    <div className="node-option-obj">
                        <AutoComplete  items={actionsType} value={''} />
                    </div>
                    :
                    <>

                        {popUpParam && <PopUpForm
                            fieldsArr={data.obj?.template?.paramters?.headerParam.map((x) => x.replace('{{', '').replace('}}', '')) || []}
                            onChange={onChnage}
                            toggleClose={() => setPopUpParam(false)}
                        />
                        }
                        {data.obj?.headerForamt ?
                            <>
                                <OptionInput
                                    value={data?.obj?.header ?? ''}
                                    className="node-option-input"
                                    placeholder={t('MEDIA_LINK')}
                                    onChange={(value) => onChangeObj(NodeObjectKey.HEADER, value)}
                                />
                                {data.obj.headerForamt ===  HeaderForamt.DOCUMENT &&
                                 <OptionInput
                                 value={data?.obj!.mediaFileName ?? ''}
                                 placeholder={t("MEDIA_FILE_NAME")}
                                 onChange={(value) => onChangeObj(NodeObjectKey.MEDIA_FILE_NAME, value)}
                                 className="node-option-input"
                             />}

                            </>
                            :
                            <FormText
                                value={data?.obj?.header}
                                className={classNames({ 'node-option-input': data.obj?.template?.paramters?.headerParam.length })}
                                placeholder={data?.obj?.header ? t('HEADER') : t('WITHOUT_HEADER')}
                                readOnly={true}
                                onClick={() => setPopUpParam(true)}
                            />}

                        <FormText
                            value={data?.obj?.footer}
                            placeholder={data?.obj?.footer ? t("FOOTER") : t("WITHOUT_FOOTER")}
                            readOnly={true}
                        />
                    </>
                }
            </>

        </>
    );
}


export default ExtraTemplates;