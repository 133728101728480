import React, { ChangeEvent, useEffect, useState } from 'react';
import { IconVariant } from '../icon/icon';
import FormText from './text';



interface OptionInputProps {
    value: string;
    onChange: (value: string) => void;
    maxLength?: number;
    style?: React.CSSProperties;
    className?: string
    placeholder?: string
    icon?: IconVariant;
    iconRealtive?: boolean;
    dir?:string
    onRemove?: () => void;
  }
  
  const OptionInput: React.FC<OptionInputProps> = ({ 
    value, 
    className, 
    placeholder,
    maxLength, 
    style,
    icon,
    iconRealtive,
    dir,
    onRemove, 
    onChange 
  }) => {

    const [localText, setLocalText] = useState(value);
  
    useEffect(() => {
      setLocalText(value);
    }, [value]);
  
    const onChangeOptionLocal = (event: ChangeEvent<HTMLInputElement>) => {
      setLocalText(event.target.value);
    };
  
    const onBlurOption = () => {
      onChange(localText);
    };
  
    return (
      <FormText 
        dir={dir}
        style={style}
        onChange={onChangeOptionLocal}
        onRemove={onRemove}
        value={localText}
        placeholder={placeholder}
        className={className}
        onBlur={onBlurOption}
        maxLength={maxLength}
        icon={icon}
        iconRealtive={iconRealtive}
      />
    );
  };

export default OptionInput;