import { action, actionWithPayload } from '../action-factory';
import { AgentDeatils, FieldCoindtion, LoaderType } from './enums';
import { Loader } from './interfaces';
import { Agent } from './state';



export class AgentActions {

    public static SetAgents = actionWithPayload<{ agents: Array<Agent> }>(
        '[AgentActions] Set Agents'
    )

    public static SetCurrentAgent = actionWithPayload<{ currentAgent: Agent }>(
        '[AgentActions] Set CurrentAgent'
    )

    public static SetCurrentAgentNull = actionWithPayload(
        '[AgentActions] Set Current Agent Null'
    )

    public static DeleteAgent = actionWithPayload<{ id: string }>(
        '[AgentActions] Delete Agent'
    )

    public static AddAgent = actionWithPayload<{ agent: Agent }>(
        '[AgentActions] Add Agent'
    )

    public static onChangeAgentDeatils = actionWithPayload<{ value: string | Array<string> | object, agentDeatils: AgentDeatils }>(
        '[AgentActions] On Change Agent Field'
    )

    public static onChangeCondition = actionWithPayload<{ value: string, index: number, field: FieldCoindtion }>(
        '[AgentActions] On Change Condition'
    )

    public static onAddCondition = action(
        '[AgentActions] On Add Condition'
    )

    public static onRemoveCondition = actionWithPayload<{ index: number }>(
        '[AgentActions] On Delete Condition'
    )

    public static onAddLoader = actionWithPayload<{ loader: Loader }>(
        '[AgentActions] On Add Loader'
    )

    public static onRemoveLoader = actionWithPayload<{ loader_id:string }>(
        '[AgentActions] On Remove Loader'
    )

    public static onChangeImage = actionWithPayload<{ img: string | undefined }>(
        '[AgentActions] On Change Image'
    )

    public static onChangeStyle = actionWithPayload<{ value: string }>(
        '[AgentActions] On Change Style'
    )


    public static onChangeCheckedLoader = actionWithPayload<{loaderType:LoaderType, id: string, checked: boolean }>(
        '[AgentActions] On Change Checked Loader'
    )

    public static onAddCustomUrl = actionWithPayload<{ url: string }>(
        '[AgentActions] On Add Custom Url'
    )




}