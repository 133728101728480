import React, { memo } from "react";

import { Handle, Position, NodeProps } from "reactflow";
import cx from "classnames";

import "./team_node.scss";
import { Box, VStack } from "@chakra-ui/react";
import { TeamFlowNodeType } from "../../interfaces";

const WorkflowNode = ({ data }: NodeProps) => {

  const isFunctions = [TeamFlowNodeType.PYTHON, TeamFlowNodeType.FILTER, TeamFlowNodeType.RETRIVER, TeamFlowNodeType.TEXT, TeamFlowNodeType.JUMP].includes(data.type);
  return (
    <div
      className={cx(["team-node", "team-node-workflow", `team-node-${data.type}`, { "functions": isFunctions }])}
      style={{
        backgroundImage: `url(${data.urlImage})`
      }}
    >
      <VStack spacing={2} pos="relative" top={115} left={0}>
        <Box>
          {data.name.replace(/_/g, " ")}
        </Box>
        <Box color="primary.500" fontSize="small" fontWeight={0}>
          {data.description}
        </Box>
      </VStack>
      <Handle
        className="handle"
        type="target"
        position={Position.Top}
        isConnectable={false}
      />
      <Handle
        className="handle"
        type="source"
        position={Position.Bottom}
        isConnectable={false}
      />
    </div>

  )

}


export default memo(WorkflowNode);


