import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import logo from '../../assets/images/logo.svg';
import logoLight from '../../assets/images/logo-light.svg';
import { RootSelectors } from '../../redux/selectors';

import classNames from 'classnames';
import { configuration } from '../../configurations/configuration';
import { PathName, Theme } from '../../enums/enums';
import { useNavigate, useLocation } from 'react-router-dom';

export default function Logo({isntHerf, isOriginal, className }: { isOriginal?: boolean, className?: string, isntHerf?:boolean }): ReactElement {
  const theme = useSelector(RootSelectors.theme);
  const location = useLocation();
  const navigate = useNavigate();

  

  const handleClickLogo = () => {
    if (isntHerf) return
    const page = location.pathname
    if (page === PathName.LOGIN || page === PathName.SIGNUP || page.startsWith(PathName.WIDGET)){
      window.location.href = configuration.landingUrl
    }else{
      navigate(configuration.homePage)
    }
  }

  return (
    <a onClick={() => handleClickLogo()}  className={classNames([className])}>
      <img src={(theme === Theme.DEFAULT || isOriginal) ? logo : logoLight} width="120" height="30" />
    </a>
  );
}
