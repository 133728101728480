import {Node, Edge } from 'reactflow';
import { Plan } from '../../enums/enums';
import { Channels, ChatbotNodeData, User } from '../../models';
import { action, actionWithPayload } from '../action-factory';
import { Builder } from '../chat-builder';

export class AuthActions {
  public static setUser = actionWithPayload<{ user: User }>(
    '[Auth] Set User'
  );

  public static SetBuilders = actionWithPayload<{ builders: Array<Builder> }>(
    '[Auth] Set Builders'
  );

  public static onUpdateChannels = actionWithPayload<{channels:Channels}>(
    '[Auth] Update Channels'
  );

  public static onUpdateDeatils = actionWithPayload<{ bs_name: string, email?:string, partner?:string, new_phone?:string }>(
    '[Auth] On Change Deatils'
  );

  public static onChangeImage = actionWithPayload<{ image: string | undefined }>(
    '[Auth] On Change Image'
  );

  public static onChangePlan = actionWithPayload<{ plan:Plan }>(
    '[Auth] On Change Plan'
  );

  public static UpdateChatBuildersByID = actionWithPayload<{ chat_builder:  Array<Node<ChatbotNodeData> | Edge>, id:number }>(
    '[Auth] Update Chat Builders By ID'
  );

  public static onChangeTitleBuilder = actionWithPayload<{id:number, newTitle:string}>(
    '[Auth] On Change Title Builder'
  );


  public static onAddBuilder = actionWithPayload<{element: Builder}>(
    '[Auth] On Duplicate Builder'
  );

  public static onDeleteBuilder = actionWithPayload<{id:number}>(
    '[Auth] On Delete Builder'
  );

  public static setBuilderActive = actionWithPayload<{ id:number }>(
    '[Auth] Set Builder Active'
  );

  public static setBuilderUnActive = actionWithPayload<{ id:number }>(
    '[Auth] Set Builder UnActive'
  );

  public static authorize = actionWithPayload<{ user: User }>(
    '[Auth] Authorize'
  );

  public static setOfficelyUser = actionWithPayload<{officely_token:string | boolean}>(
    '[Auth] Set Officely User'
  );

  public static authorizeSuccess = action(
    '[Auth] Authorize success'
  );

  public static authorizeFailure = action(
    '[Auth] Authorize failure'
  );

  public static unauthorize = action(
    '[Auth] Unauthorize'
  );



}
