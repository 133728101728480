import { Checkbox, Icon as CIcon } from '@chakra-ui/react'
import classNames from 'classnames'
import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { AuthSelectors } from '../../redux/auth'
import { ChannelType } from '../../enums/enums'

import { Chat } from '../web-channel/interfaces'
import { GetConversations } from 'src/helpers/fetch/fetch-user'
import LiveMode from '../web-channel/modes/live-mode'
import { ChakraMenu } from 'src/components/chakra/menu-item'
import { ReactElement } from 'react-imask/dist/mixin'

import './live-page.scss'
import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons'
import LogoChannel, { LogoChannelVariant } from 'src/components/logoChannel/logo-channel'
import Icon, { IconTheme, IconVariant } from 'src/components/icon/icon'



interface CustomerConversation {
    channel: ChannelType
    conversation: Array<Chat>
    name: string
    id: string
    img?: string

}






const LivePage = () => {
    const user = useSelector(AuthSelectors.user);
    const channels = [ChannelType.WEB, ChannelType.META, ChannelType.GREEN_API, ChannelType.MAYTAPI, ChannelType.ZENDESK]

    const [conversations, setConversations] = useState<Array<CustomerConversation>>([]);
    const [currentConversation, setCurrentConversation] = useState<CustomerConversation>();
    const [selectedChannel, setSelectedChannel] = useState<Array<ChannelType>>(channels);
    const [searchInput, setSearchInput] = useState('');
    const [selectAll, setSelectAll] = useState(true);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 760);

    useEffect(() => {
        function handleResize() {
            setIsMobileView(window.innerWidth <= 760);
        }

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [window.innerWidth]);





    useEffect(() => {
        let isMounted = true;
        const fetchApi = async () => {
            const res = await GetConversations(user.token!);
            if (res.success && isMounted) {
                setConversations(res.data);
            }
        };

        fetchApi();
        return () => { isMounted = false; };

    }, []);

    const handleChannelChange = (channel: ChannelType) => {

        if (selectedChannel.includes(channel)) {
            setSelectedChannel(selectedChannel.filter((item) => item !== channel));
        } else {
            setSelectedChannel([...selectedChannel, channel]);
        }
    };

    useEffect(() => {
        setSelectAll(selectedChannel.length === channels.length)
    }, [selectedChannel])

    const onHandleSelectAll = (checked: boolean) => {
        if (checked) {
            setSelectedChannel(channels)
        } else { setSelectedChannel([]) }
    }


    useEffect(() => {
        if (!isMobileView) { return }
        const elementTrigger = document.querySelector('.account-menu-trigger');
        const elementLogo = document.querySelector('.account-logo');
        const elementHeader = document.querySelector('.page-header');


        if (currentConversation) {
            elementTrigger?.classList.add('none');
            elementLogo?.classList.add('none');
            elementHeader?.classList.add('none');

        } else {
            elementTrigger?.classList.remove('none');
            elementLogo?.classList.remove('none');
            elementHeader?.classList.remove('none');
        }
    }, [currentConversation])



    const filteredConversations = useMemo(() => {
        let filtered = conversations;

        if (selectedChannel) {
            filtered = conversations.filter((conversation) => {
                if (selectedChannel.includes(ChannelType.WEB)) {
                    return [...selectedChannel, ChannelType.AI, ChannelType.OPEN_AI_ASSISTANT].includes(conversation.channel)
                }
                return selectedChannel.includes(conversation.channel)
            });
        }

        if (searchInput) {
            filtered = filtered.filter((c) =>
                c.name.toLowerCase().includes(searchInput.toLowerCase()) ||
                c.id.includes(searchInput));
        }


        if (!currentConversation && !isMobileView && filtered) {
            setCurrentConversation(filtered[0])
        }
        return filtered


    }, [conversations, selectedChannel, searchInput]);


    const selectAllElement = (
        <Checkbox
            onChange={(e) => onHandleSelectAll(e.target.checked)}
            isChecked={selectAll}
            zIndex={1}
            colorScheme="primary"
            mb={1}
        >
            Select All
        </Checkbox>
    );

    const getLogoChannelVariant = (channel: ChannelType) => {
        switch (channel) {
            case ChannelType.AI:
            case ChannelType.WEB:
            case ChannelType.OPEN_AI_ASSISTANT:
                return LogoChannelVariant.OFFICELY_SMALL
            case ChannelType.META:
                return LogoChannelVariant.META_SMALL
            case ChannelType.GREEN_API:
                return LogoChannelVariant.WHATSAPP_SMALL
            case ChannelType.MAYTAPI:
                return LogoChannelVariant.MAYTAPI_SMALL
            case ChannelType.ZENDESK:
                return LogoChannelVariant.ZENDESK_SMALL
            default:
                return LogoChannelVariant.OFFICELY_SMALL
        }
    }



    const channelsElements = [
        selectAllElement,
        ...channels.map((channel) => (
            <Checkbox
                key={channel}
                onChange={() => handleChannelChange(channel)}
                isChecked={selectedChannel.includes(channel)}
                colorScheme="primary"
                mb={1}
                width="100%"
            >

                {channel}
            </Checkbox>
        ))
    ];









    return (
        <div className="page">
            <header className="page-header"></header>
            <div className="page-content">

                <div className="live-page">
                    <div className={classNames(["live-page-sidebar", { none: currentConversation }])}>
                        <div className="filter">
                            <ChakraMenu<ReactElement>
                                items={channelsElements as any}
                                center={false}
                                textAlign="start"
                                background="white"
                                fontWeight="100"
                                currentItem={(<span> Channels <CIcon as={ChevronDownIcon} /></span>) as any}
                                closeOnSelect={false}


                            />

                        </div>
                        <div className="search">
                            <CIcon as={SearchIcon} />
                            <input value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className="search-input" placeholder="Search Customer" />
                        </div>
                        <div className="contacts">
                            {filteredConversations.map((customer, i) => (
                                <div key={i}
                                    className={classNames(['contact', { active: customer.conversation === currentConversation?.conversation }])}
                                    onClick={() => setCurrentConversation(customer)}
                                >

                                    <div className="contact-item">
                                        <div className="contact-item-img">
                                            <LogoChannel variant={getLogoChannelVariant(customer.channel)} />
                                        </div>
                                        <div className="contact-item-texts">
                                            <div className="contact-item-texts-name">{
                                                [ChannelType.WEB, ChannelType.AI, ChannelType.OPEN_AI_ASSISTANT].includes(customer.channel) ?
                                                    customer.name !== '' ? customer.name : 'Unknown'
                                                    : customer.id
                                            }
                                            </div>
                                            <div className="contact-item-texts-message">
                                                {customer.conversation[customer.conversation.length - 1].text}
                                            </div>
                                        </div>
                                        <div className="contact-item-time">
                                            <div className="contact-item-time-text">
                                                {customer.conversation[customer.conversation.length - 1].timestamp?.fullDate}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            ))}
                        </div>
                    </div>
                    {currentConversation && <div className="live-page-conversation-panel">
                        <div className="only-mobile deatils-conversation">
                            <LogoChannel variant={getLogoChannelVariant(currentConversation.channel)} />

                            {
                                [ChannelType.WEB, ChannelType.AI, ChannelType.OPEN_AI_ASSISTANT].includes(currentConversation.channel) ?
                                    currentConversation.name !== '' ? currentConversation.name : 'Unknown'
                                    : currentConversation.id
                            }
                        </div>
                        <div className="only-mobile close-conversation" onClick={() => setCurrentConversation(undefined)}>
                            <Icon variant={IconVariant.REMOVE_BIG} theme={IconTheme.BLACK} />
                        </div>

                        <LiveMode
                            chatHistory={currentConversation.conversation}
                            img={currentConversation.img}
                        />
                    </div>}
                </div>
            </div>
        </div>
    );
}

export default React.memo(LivePage);