import { createSelector } from 'reselect';
import { AppState } from './ConfigureStore';
import { RootState } from './state';

const selectFeature = (state: AppState): RootState => state.root;

export class RootSelectors {
  public static theme = createSelector(
    selectFeature,
    (state: RootState) => state.theme
  );
  public static language = createSelector(
    selectFeature,
    (state: RootState) => state.language
  );
}
