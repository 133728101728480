import { TFunction } from 'i18next';

import * as Yup from 'yup';

declare module 'yup' {
  interface StringSchema {
    phone(message: string): StringSchema;
  }
}

export class ProfileFrom {
    public bs_name: string
    public email:string;
    public partner: string;
    public phone: string;
    public partner_code:string


  constructor(bs_name:string, email:string, phone?:string, partner_code?:string) {
    this.bs_name = bs_name,
    this.email = email,
    this.phone = phone || ''
    this.partner_code = partner_code || ''


  }



  public static getValidationSchema(t: TFunction): Yup.SchemaOf<ProfileFrom> {




    return Yup.object()
      .shape({
        bs_name:Yup.string().required(t('REQUIRED')),
        email: Yup.string().email(t("TEXT_EMAIL_ERROR")).required(t("REQUIRED"))
      }).defined();
  }
}
