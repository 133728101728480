import React, { ReactElement, useEffect } from "react";
import { useTranslation } from "../../hooks/use-translate";

export function PrivacyPage(): ReactElement {
    const t = useTranslation('PAGES.PRIVACY_TERMS');
    useEffect(() => {
        document.title = t("TITLE")
    },[])
    return (
        <div className="terms_privacy">
            <iframe src="https://app.termly.io/document/privacy-policy/6e8e10e4-9760-4402-ad82-d447b4288e20"
            width="100%"
            height="100%"/>
        </div>
    )
}
