import classNames from "classnames";
import React, { ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthActions, AuthSelectors } from "../../redux/auth";
import { useDispatch, useSelector } from "react-redux";
import { useRTL } from "../../hooks/use-rtl";
import { useTranslation } from "../../hooks/use-translate";
import { ChatBuilderActions } from "../../redux/chat-builder/actions";
import Button, { ButtonVariant } from "../../components/button/button";
import Icon, { IconVariant } from "../../components/icon/icon";
import { useFetchOfficely } from "../../hooks/use-fetch-officely";
import { Method } from "../../enums/enums";
import StatusChatBot from "../../components/status-chat-bot/status-chat-bot";
import "../menu-page.scss"
import { ChatBotActionsActions } from "../../redux/chatbot-actions";
import { User } from "../../models";
import { Card, CardBody, CardFooter, CardHeader, Flex, Heading, IconButton, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react"
import { AlertActions, PopoverEditText, Alert, ModalInput, InputModal } from "../../helpers/dialog";
import { Builder, ChatBuilderSelectors, Language, styleDefualt } from "../../redux/chat-builder";
import BetaTag from "../../components/beta-tag/beta-tag";
import OpenAiIcon from '../../assets/images/open-ai-big.svg'
import NewChatIcon from '../../assets/images/new-chat-big.svg';
import { GetBuilders, GetChatBuilders } from "../../helpers/fetch/fetch-user";


export enum actionsCardBuilder {
  DUPLICATE = "DUPLICATE",
  RENAME = "RENAME",
  DELETE = "DELETE",
  PUBLISH = "PUBLISH",
  PREVIEW = "PREVIEW",
  UNPUBLISH = "UNPUBLISH",
  CHANNELS = "CHANNELS",
  SETTING = "SETTING",
  EDIT = "EDIT"
}





const CardNewChat = ({ title, colorTitle, onClick, icon }:
  {
    icon: string,
    title: string,
    colorTitle: string,
    onClick: () => void
    rtl?: boolean
  }) => (
  <Card
    alignItems="center"
    cursor="pointer"
    minW={350}
    height={320}
    borderRadius={30}
    _hover={{
      filter: "drop-shadow(0px 4px 4px rgba(155, 155, 155, 0.5))"
    }}
    onClick={onClick}
  >
    <CardBody display="flex" alignItems="center">
      <img src={icon} />
    </CardBody>
    <CardFooter color={colorTitle} fontSize={18}>
      {title}
    </CardFooter>

  </Card>
);



function MainBuildersPage(): ReactElement {
  const user: User = useSelector(AuthSelectors.user)
  const isFreeBasic = useSelector(AuthSelectors.isFreeBasic);
  const startSchema = useSelector(ChatBuilderSelectors.startScheme)
  const t = useTranslation("PAGES.BUILDERS");
  const tSwal = useTranslation("COMPONENTS.SWAL")
  const dispatch = useDispatch();
  const isRTL = useRTL();
  const navigate = useNavigate();
  const [currentBuilder, setcurrentBuilder] = useState<Builder | null>()
  const [modalInput, setModalInput] = useState<InputModal>({ isOpen: false })
  const [dialogActions, setDialogActions] = useState<AlertActions | null>(null)
  const [isOpenDialog, setIsOpenDialog] = useState(false)
  const cardRef = React.useRef<HTMLDivElement>(null);
  const [isOpenEditName, setIsOpenEditName] = useState<number | null>(null)
  let isMounted = true;

  useEffect(() => {
    const fetchApi = async (): Promise<void> => {
      const res = await GetBuilders(user.token!)
      if (res.success && res.data) {
        dispatch(AuthActions.SetBuilders({ builders: res.data }))
      }
    }
    if (isMounted) {
      document.title = t("TITLE")
      dispatch(ChatBuilderActions.setCurrentBuilderNull())
      dispatch(ChatBotActionsActions.resetState());
      fetchApi()
    }

    return () => {
      isMounted = false
    }
  }, [])









  function padTo2Digits(num: number) {
    return num.toString().padStart(2, "0");
  }

  const formatDate = (date: Date) => [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear()
  ].join("/");


  const handleAction = (e: React.MouseEvent, key: actionsCardBuilder) => {
    e.preventDefault(); // prevent the default behavior of the click event
    e.stopPropagation();
    switch (key) {
      case actionsCardBuilder.DUPLICATE:
        onDuplicateBuilder()
        break;
      case actionsCardBuilder.DELETE:
        OpenDialiog({
          title: tSwal("PAY_ATTENTION"),
          content: tSwal("VALID_DELETE_BUILDER"),
          confirm: tSwal("DELETE"),
          cancel: tSwal("CANCEL"),
          colorButton: "red",
          onConfirm: () => onDeleteBuilder()
        })
        break;
      case actionsCardBuilder.UNPUBLISH:
        OpenDialiog({
          title: tSwal("UNPUBLISH_TITLE"),
          content: tSwal("VALID_UNPUBLISH"),
          confirm: tSwal("UNPUBLISH"),
          cancel: tSwal("STAY_IN_PUBLISH"),
          colorButton: "red",
          onConfirm: () => onUnpublishBuilder()
        })
        break;
      case actionsCardBuilder.RENAME:
        setIsOpenEditName(currentBuilder!.builder_id)

        break;
    }
  }

  const handleEditTitle = async (value: string): Promise<void> => {
    closeEditTitle()
    if (!value) return
    dispatch(AuthActions.onChangeTitleBuilder({ id: currentBuilder!.builder_id, newTitle: value }));
    const res = await useFetchOfficely(`updateTitleBuilder/${currentBuilder!.builder_id}/${value}`, Method.POST)
    if (!res.success) {
      OpenDialiog({
        title: tSwal("ERROR"),
        content: tSwal("ERROR_FETCH"),
        confirm: tSwal("OK"),
        colorButton: "primary",
        isMessage: true
      })
    }


  }

  const closeEditTitle = (): void => {
    setIsOpenEditName(null)
  }

  const InsertBuilder = async (builder: Builder): Promise<number> => {
    const body = {
      uphone_number: user.uphone_number,
      builder
    }
    const res = await useFetchOfficely("insertBuilder", Method.POST, body)
    if (res.success) {
      const newBuilder: Builder = {
        ...body.builder,
        builder_id: res.data["builder_id"]
      }
      dispatch(AuthActions.onAddBuilder({ element: newBuilder }));
      return newBuilder.builder_id!

    } else {
      OpenDialiog({
        title: tSwal("ERROR"),
        content: tSwal("ERROR_FETCH"),
        confirm: tSwal("OK"),
        colorButton: "primary",
        isMessage: true
      });
      return 0;
    }


  }


  const onDuplicateBuilder = async (): Promise<void> => {
    if (isFreeBasic) {
      UpgrageError(tSwal("ERR_DUPLICATE_UPGRADE"))
      return
    }
    const builder = {
      ...currentBuilder,
      builder_name: `${currentBuilder?.builder_name}${t("TEXT_COPY")}`,
      status: false
    }
    const res = await GetChatBuilders(builder.builder_id!, user.token!)
    if (res.success && res.data) {
      builder.chat_builder = (res.data as Builder).chat_builder
      const builder_id = await InsertBuilder(builder as Builder)
      builder.builder_id = builder_id
      //onSelectBuilder(builder as Builder)
      dispatch(ChatBuilderActions.setCurrentBuilder({ element: builder as Builder }))
      navigate(`/chatbot`)
    }
  }

  const onDeleteBuilder = async (): Promise<void> => {
    const body = {
      uphone_number: user.uphone_number,
      builder_id: currentBuilder!.builder_id
    }
    const res = await useFetchOfficely("deleteBuilder", Method.POST, body)
    if (res.success) { dispatch(AuthActions.onDeleteBuilder({ id: currentBuilder!.builder_id })) }
    else {
      OpenDialiog({
        title: tSwal("ERROR"),
        content: tSwal("ERROR_FETCH"),
        confirm: tSwal("OK"),
        colorButton: "primary",
        isMessage: true
      })
    }
  }

  const onUnpublishBuilder = async (): Promise<void> => {
    const body = {
      uphone_number: user.uphone_number,
      builder_id: currentBuilder!.builder_id
    }
    const res = await useFetchOfficely("UnPublishBuilder", Method.POST, body)
    if (res.success) { dispatch(AuthActions.setBuilderUnActive({ id: currentBuilder!.builder_id })) }
    else {
      OpenDialiog({
        title: tSwal("ERROR"),
        content: tSwal("ERROR_FETCH"),
        confirm: tSwal("OK"),
        colorButton: "primary",
        isMessage: true
      })
    }
  }

  const onSelectBuilder = async (builder: Builder): Promise<void> => {
    if (!builder.status) {
      NavigateChatBot(builder)
    } else {
      OpenDialiog({
        title: tSwal("PAY_ATTENTION"),
        content: tSwal("VALID_ENTER_ACTIVE_CHAT"),
        confirm: tSwal("ENTER_ACTIVE"),
        cancel: tSwal("DUPLICATE"),
        colorButton: "primary",
        onConfirm: () => NavigateChatBot(builder),
        onCancel: () => onDuplicateBuilder()
      })
    }
  }

  const NavigateChatBot = async (builder: Builder) => {
    const res = await GetChatBuilders(builder.builder_id!, user.token!)
    if (res.success) {
      dispatch(ChatBuilderActions.setCurrentBuilder({ element: res.data }))
      navigate(`/chatbot`)
    }
  }

  const onOpenModalNewChatInput = (): void => {
    if (isFreeBasic && user.chat_builders?.length) {
      UpgrageError(tSwal("ERR_TEMPLATE_UPGRADE"))
      return
    }
    setModalInput({
      isOpen: true,
      textArea: false,
      title: t("YOUR_TEMPLATE_NAME"),
      saveAction: onAddBlankTemplate,
      spinner: null,
      error: null
    })
  }

  const onOpenModeGenerateAI = (): void => {
    if (isFreeBasic && user.chat_builders?.length) {
      UpgrageError(tSwal("ERR_TEMPLATE_UPGRADE"))
      return
    }
    setModalInput({
      isOpen: true,
      textArea: true,
      title: t("NEW_AI_CHAT"),
      saveAction: onGenerateGPT,
      error: null,
      spinner: false
    })
  }

  const UpgrageError = (content: string) => {
    OpenDialiog({
      title: tSwal("ERROR_UPGRADE"),
      content,
      confirm: tSwal("TO_UPGRADE"),
      onConfirm: () => navigate("/subscriptions"),
      cancel: tSwal("CANCEL"),
      colorButton: "primary"
    })
  }




  const onAddBlankTemplate = async (builder_name: string): Promise<void> => {
    const builder: Builder = {
      builder_id: 0,
      builder_name: builder_name,
      chat_builder: startSchema,
      status: false,
      configuration:{
        style:styleDefualt,
        language:Language.EN

      }

    }
    const newBuilderID = await InsertBuilder(builder)
    if (newBuilderID !== 0) {
      builder.builder_id = newBuilderID
      onSelectBuilder(builder)
    }
  }

  const OpenDialiog = (alertProps: AlertActions) => {
    setDialogActions({ ...alertProps })
    setIsOpenDialog(true)
  }



  const handleDropDownMenu = (e: React.MouseEvent, item: Builder) => {
    setcurrentBuilder(item)
    e.stopPropagation()
  }

  const onGenerateGPT = async (value: string): Promise<void> => {
    const body = {
      text: value,
      uphone_number: user.uphone_number,
      isRTL
    };
    setModalInput((prevModalInput) => ({ ...prevModalInput, spinner: true }));
    const res = await useFetchOfficely("GenerateAI", Method.POST, body);
    if (res.success) {
      const builder = res.data as Builder;
      dispatch(AuthActions.onAddBuilder({ element: builder }));
      setModalInput((prevModalInput) => ({ ...prevModalInput, isOpen: false, spinner: false }));
      onSelectBuilder(builder);
    } else {
      setModalInput((prevModalInput) => ({ ...prevModalInput, spinner: false, error: t("GENERATE_AI_ERR") }));
    }
  };

  const actions = [actionsCardBuilder.RENAME, actionsCardBuilder.DUPLICATE,
  currentBuilder?.status ? actionsCardBuilder.UNPUBLISH : actionsCardBuilder.DELETE]
    .map((key) => ({
      key,
      warn: key === actionsCardBuilder.DELETE || key === actionsCardBuilder.UNPUBLISH
    }));

  if (user.chat_builders) {
    return (
      <div className="page menu-page ">
        <header className="page-header">
          <div className="page-header-left">
          </div>
          <div className="page-header-right">
            {!!user.chat_builders.length && <>
              <Button variant={ButtonVariant.WHITE}
                className={classNames(["page-action", { rtl: isRTL }])}
                onClick={onOpenModeGenerateAI}>
                <Flex dir="ltr" fontFamily="var(--font-family-ltr)">
                  <Icon variant={IconVariant.OPEN_AI} />
                  {t("GENERATE_AI")}
                </Flex>
                <BetaTag left={150} />
              </Button>
              <Button variant={ButtonVariant.OUTLINE}
                className={classNames(["page-action", { rtl: isRTL }])}
                onClick={onOpenModalNewChatInput}>
                {t("BUTTON_CREATE")}
              </Button>
            </>}
          </div>
        </header>
        <div className="page-content">
          {user.chat_builders.length ?

            <div className="menu-page-items">
              {
                user.chat_builders?.map((item) => <Card
                  ref={cardRef}
                  size="lg"
                  key={item.builder_id}
                  onClick={() => onSelectBuilder(item)}
                  className={classNames(["menu-page-items-card", { rtl: isRTL, active: currentBuilder?.builder_id === item.builder_id }])}
                >
                  <CardHeader className="card-header" dir="ltr">
                    <StatusChatBot isActive={item.status!} />
                    <Menu >
                      <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<Icon variant={IconVariant.REST_HORIZONTAL} />}
                        variant="outline"
                        _hover={{ opacity: 0.3 }}
                        _active={{ bg: "transpernt" }}
                        border="none"
                        zIndex={1}
                        onClick={(e) => handleDropDownMenu(e, item)}
                      />
                      <MenuList className={classNames({ rtl: isRTL })} >
                        {actions.map((action) => <MenuItem zIndex={1}
                          color={action.warn ? 'red.400' : "black"}
                          key={action.key}
                          onClick={(e) => handleAction(e, action.key)}>
                          {t(`TEXT_${action.key}`)}
                        </MenuItem>)}
                      </MenuList>
                    </Menu>

                  </CardHeader>
                  <CardBody >
                    <Heading size="s" fontFamily="unset" pt={5} >
                      <PopoverEditText
                        onClose={closeEditTitle}
                        isOpen={isOpenEditName === item.builder_id}
                        text={item.builder_name}
                        label={t("TEXT_RENAME")}
                        onSave={(value) => handleEditTitle(value)} />
                    </Heading>

                  </CardBody>
                  <CardFooter justifyContent="center" color="gray">
                    <>
                      {isRTL ?
                        formatDate(new Date(item.last_updated!))
                        :
                        new Date(item.last_updated!).toLocaleDateString()
                      }
                    </>
                  </CardFooter>
                </Card>)

              }
            </div>
            :
            <Flex gap={10} minHeight="50vh" justifyContent="center" wrap="wrap" alignItems="center">
              <CardNewChat colorTitle="black" icon={OpenAiIcon} title={t("GENERATE_AI")} onClick={onOpenModeGenerateAI} />
              <CardNewChat rtl colorTitle="primary" icon={NewChatIcon} title="New Chat" onClick={onOpenModalNewChatInput} />
            </Flex>
          }


        </div>
       {modalInput.isOpen && <ModalInput
          isOpen={modalInput.isOpen}
          onClose={() => setModalInput({ isOpen: false })}
          headerTitle={modalInput.title ?? ''}
          onSave={modalInput.saveAction}
          textArea={modalInput.textArea}
          spinner={modalInput.spinner}
          error={modalInput.error}
        />}
        {isOpenDialog && <Alert
          isOpen={isOpenDialog}
          onClose={() => setIsOpenDialog(false)}
          actions={dialogActions!}
        />}
      </div >
    );
  } else return <></>
}


export default MainBuildersPage;
