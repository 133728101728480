import React, { useState, useEffect, useMemo } from 'react';
import '../agent.scss';
import { AgentSelectors, LoaderType, URLLoader } from 'src/redux/agents';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import UrlLoaderComponent from './url-loaders';
import FileLoaderComponent from './file_loaders';
import Button, { ButtonVariant } from 'src/components/button/button';









function AgentData({ onContinue }: { onContinue: () => void }) {

    const currentAgent = useSelector(AgentSelectors.currentAgent)
    const [loaderActive, setLoaderActive] = useState<'URL' | 'Files'>('URL');

    const loaders = ['URL', 'Files']


    useEffect(() => {
        if (!currentAgent?.loaders?.length) return
        const typeLoader = currentAgent.loaders[0].type
        const active = typeLoader === LoaderType.URL ? 'URL' : 'Files'
        setLoaderActive(active as any)
    }, [])








    const onChangeLoaderActive = (loader: 'URL' | 'Files') => {
        setLoaderActive(loader)
    }

    const computeNumFiles = () => {
        let num = 0
        currentAgent?.loaders?.forEach((loader) => {
            if (loader.type === LoaderType.URL) {
                num += (loader as URLLoader).urls.filter((url) => url.checked).length
            } else {
                if (loader.checked) { num += 1 }
            }
        })
        return num
    }

    const numberDocs = useMemo(() => computeNumFiles(), [currentAgent?.loaders])





    return (
        <div className="agent agent-data">
            <div className="loaders-tabs">
                {loaders.map((loader) => <div
                    key={loader}
                    onClick={() => onChangeLoaderActive(loader as any)}
                    className={classNames(["loaders-tabs-tab", { active: loader === loaderActive }])}
                >
                    {loader}
                </div>)}

            </div>
            {loaderActive === 'URL' ?
                <UrlLoaderComponent />
                :
                <FileLoaderComponent />
            }

            <Button
                variant={ButtonVariant.PRIMARY}
                isBlock
                className="continue-button"
                disabled={numberDocs === 0}
                onClick={onContinue}
            >

                {`Continue with ${numberDocs} documents`}
            </Button>

        </div>
    );
}


export default React.memo(AgentData)