import React, { ReactElement } from 'react';
import { NodeType } from '../../enums/enums';
import { ChatbotNodeData } from '../../models/chat-bot-node';
import Node from './node';
import './node.scss';
import TargetHandle from './target-handle';

function FakeEndNode({data}: { data: ChatbotNodeData }): ReactElement {
  return (
    <Node data={data} type={NodeType.FAKE_END} className="node-end">
      <TargetHandle id={data.id} />
    </Node>
  );
}


const areEqual = (prevProps:any, nextProps:any) => prevProps.data === nextProps.data;

export default React.memo(FakeEndNode, areEqual);