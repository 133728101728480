import React, { ReactElement, useState, useEffect } from 'react';
import { Switch } from '@chakra-ui/react';

import { useSelector } from 'react-redux';
import { ChatBuilderSelectors } from '../../../redux/chat-builder';
import AutoComplete from '../../auto-complete/auto-complete';
import OptionInput from '../../form/option-input';


export default function FieldValueInput({ value, placeholder, isMap, onChange, onSwitch }: {
    value: string,
    placeholder: string,
    isMap?: boolean,
    onChange: (value: string) => void
    onSwitch: (bool:boolean) => void
}): ReactElement {
    const [isChecked, setIschecked] = useState(isMap);
    const [actionsType, setActionsType] = useState<any[]>([])
    const allVariables = useSelector(ChatBuilderSelectors.allVariables);


    useEffect(() => {
        setActionsType(allVariables
            .sort((a, b) => a.value < b.value ? -1 : 1)
            .map((x) => ({
                value: x.preview,
                label: x.preview,
                action: () => onChange(x.preview)
            }))
        )
    }, [allVariables])

    const onChangeMap = (bool:boolean) => {
        setIschecked(bool)
        onSwitch(bool)
    }

    const onChangeInput = (value: string) => {
        // const res = /^[a-zA-Z0-9@#]+$/.test(value) || value.length === 0;
        // res && onChange(value)
        onChange(value)
    }

    return (
        <div className="field-input">
            <Switch colorScheme="primary" isChecked={isChecked} onChange={(e) => onChangeMap(e.target.checked)} />
            {
                isMap ?
                    <OptionInput
                        value={value}
                        onChange={(value) => onChangeInput(value)}
                        placeholder={placeholder}
                    />
                    : <AutoComplete
                        items={actionsType}
                        value={value || placeholder}
                        position="relative"
                        width="100%"
                        classNameInput="filter-group-operator"
                    />
            }

        </div>
    )
}