import { TFunction } from 'i18next';
import * as Yup from 'yup';

export class GreenAPIForm {
    public instanceID:string;
    public token: string;
    public logCallback:string
    public status:boolean


  constructor(instanceID?:string, token?:string,  logCallback?:string, status?:boolean) {
    this.instanceID = instanceID || "";
    this.token = token || "";
    this.logCallback = logCallback || '';
    this.status = status || false

  }


  public static getValidationSchema(t: TFunction): Yup.SchemaOf<GreenAPIForm> {
    const regex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[a-zA-Z0-9-._~:?#&=]*)?(\?[a-zA-Z0-9-._~:?#&=]*)?(#[a-zA-Z0-9-._~:?#&=]*)?$/
    return Yup.object()
      .shape({
        logCallback: Yup.string().matches(regex, t('ENTER_CORRECT_URL'))
      }).defined();
  }
}
