import React, { memo } from 'react';

import { Handle, Position, NodeProps } from 'reactflow';
import cx from 'classnames';

import './team_node.scss';

const HelperNode = ({ data }: NodeProps) => <div
  className={cx('team-node', 'team-node-helper', `team-node-${data.type}`)}
  title="click to add a child node"
>
  {data.name}
  <Handle
    className="handle"
    type="target"
    position={Position.Top}
    isConnectable={false}
  />
  <Handle
    className="handle"
    type="source"
    position={Position.Bottom}
    isConnectable={false}
  />
</div>



export default memo(HelperNode);


