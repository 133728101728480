import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from '../../hooks/use-translate';
import './biling-page.scss';
import { useSelector } from 'react-redux';
import { AuthSelectors } from '../../redux/auth/selectors';
import { Method } from '../../enums/enums';
import { useFetchOfficely } from '../../hooks/use-fetch-officely';




function BilingPage2(): ReactElement {
  const user = useSelector(AuthSelectors.user)
  const t = useTranslation('PAGES.BILING');

  const [portalUrl, setPortalUrl] = useState<string | null>(null);
  const [shouldRenderPricingTable, setShouldRenderPricingTable] = useState(true);
  let isMounted = true;

  const stripePricingTable = React.createElement(
    'stripe-pricing-table',
    {
      "pricing-table-id": "prctbl_1Mw8OkFFdq2uieRyY1Ahzbqd",
      "publishable-key": "pk_live_51Mg8lhFFdq2uieRyPxEFUA0beao4s7seHWnXffLVNwg1oO4jWOY2RJWeAOJETc7R8NDb9QfEtT4MZVYp3SWJb1Ug009BKyfb9y",
      // "pricing-table-id":"prctbl_1OKNDvFFdq2uieRyRg8w5Qa5",
      // "publishable-key":"pk_test_51Mg8lhFFdq2uieRyDXkL1XWMPi3f3vRH8G8WCCEcUR9Wt2SNQARfZxSkwLtL7PBDPVXFr2RoKEhQsgmQblZYh8q700VwARUMMD",
      "customer-email": user!.email,
      "customer-id": user!.stripe_id
    }
  );



// <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
// <stripe-pricing-table pricing-table-id="prctbl_1PVU79FFdq2uieRyRGMztxY7"
// publishable-key="pk_test_51Mg8lhFFdq2uieRyDXkL1XWMPi3f3vRH8G8WCCEcUR9Wt2SNQARfZxSkwLtL7PBDPVXFr2RoKEhQsgmQblZYh8q700VwARUMMD"
// customer-email="orel@officely.ai",
// customer-id="cus_QMD2ZWIdFJQI32"
                      
//                       >
// </stripe-pricing-table> 





  useEffect(() => {
    document.title = t("TITLE")
    if (user.stripe_id ) {
      const getUrlPortal = async () => {
        const res = await useFetchOfficely(`create-customer-portal-session/${user.stripe_id}`, Method.GET);
        //const res = await useFetchOfficely(`create-customer-portal-session/cus_Nqng1Y6UjJ1RuZ`, Method.GET);
        if (res.success) {
          setPortalUrl(res.data);
          setShouldRenderPricingTable(false);
        }
      };

      if (isMounted) {
        getUrlPortal();
      }
    }
    return () => {
      isMounted = false
    }
  }, []);

  if (portalUrl && !shouldRenderPricingTable) {
    window.location.href = portalUrl;
  }

  return (
    <div className="page menu-page billing">
      <header className="page-header min-height">
        <div className="page-header-left"></div>
        <div className="page-header-right"></div>
      </header>

      <div className="page-content">
        {shouldRenderPricingTable ? stripePricingTable : null}
      </div>
    </div>
  );
}

export default React.memo(BilingPage2)