// import classNames from "classnames";
// import React, { ReactElement, useState } from "react";
// import { FileUploader } from "react-drag-drop-files";
// import { Method, NodeType } from "../../../enums/enums";
// import { useFetchOfficely } from "../../../hooks/use-fetch-officely";
// import { ExtraElementChat } from "../../../pages/web-channel/interfaces";

// const fileTypes = ["JPG", "JPEG", "PNG", "PDF", "CSV"];

// export default function ReceivingMediaWeb({ handleClick }: {
//   handleClick: (ans: string, extraElement: ExtraElementChat) => void;
// }): ReactElement {

//   const [isOpen, setIsOpen] = useState(true);

//   const handleChange = (file: File) => {
//     const reader = new FileReader()
//     reader.onload = async () => {
//       const body = {
//         file: reader.result,
//         mimeType: file!.type

//       }
//       const res = await useFetchOfficely('uploadUserFile', Method.POST, body)
//       if (res.success && res.data) {
//         const extraElement = {
//           type: NodeType.MEDIA,
//           data: { [NodeType.MEDIA]: res.data }
//         }
//         handleClick(res.data, extraElement)
//         setIsOpen(false)
//       }
//     }
//     reader.readAsDataURL(file)

//   };
//   return (
//     <FileUploader
//       handleChange={handleChange}
//       name="file"
//       label="Upload or drop a file right here"
//       classes={classNames(["file-uploader", { "open": isOpen }])}
//       maxSize={5}
      
//     />
//   );
// }
import classNames from "classnames";
import React, { useState, ReactElement } from 'react';
import { NodeType } from '../../../enums/enums';
import { ExtraElementChat } from '../../../pages/web-channel/interfaces';
import { FileUploader } from "react-drag-drop-files";
import { configuration } from "../../../configurations/configuration"


export default function ReceivingMediaWeb({ handleClick }: {
  handleClick: (ans: string, extraElement:ExtraElementChat) => void;
}): ReactElement {
  const [isOpen, setIsOpen] = useState(true);

  const handleChange = async (file:File) => {
    const formData = new FormData();
    formData.append('file', file);

    // Adjust the URL and headers according to your API
    const response = await fetch(`${configuration.api_url}/newUploadUserFile`, {
      method: 'POST',
      body: formData // This is your file from the form
    });

    const res = await response.json(); // adjust this line if your API response differs

    if (res.success && res.data) {
      const extraElement = {
        type: NodeType.MEDIA,
        data: { [NodeType.MEDIA]: res.data }
      };
      handleClick(res.data, extraElement)
      setIsOpen(false);
    }
  };

  return (
    <FileUploader
      handleChange={handleChange}
      name="file"
      label="Upload or drop a file right here"
      classes={classNames(["extra-element", "file-uploader", { "open": isOpen }])}
      maxSize={5}
    />
  );
}
