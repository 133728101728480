import React, { ReactElement, useState, useEffect } from "react";
import { useTranslation } from "../../hooks/use-translate";
import { useDispatch, useSelector } from "react-redux";
import { AuthSelectors } from "../../redux/auth";
import { PathName, Plan } from "../../enums/enums";
import { Alert, AlertActions } from "../../helpers/dialog";
import Button from "../button/button";
import { AgentActions, AgentSelectors } from "src/redux/agents";
import PreviewAgent from "src/pages/agents/preview-agent";
import { Spinner } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { GenerateEmbed, GenerateLink } from "src/pages/agents/agents-page"
import { IconVariant } from "../icon/icon";
import './agent.scss'
import { AgentService } from "src/helpers/fetch/fetch-agent";




function AgentOverview({ onChangeStage }: { 
    onChangeStage: (index: number) => void 
}): ReactElement {
    const agentService = new AgentService()
    const tSwal = useTranslation("COMPONENTS.SWAL")
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const currentAgent = useSelector(AgentSelectors.currentAgent)
    const user = useSelector(AuthSelectors.user)
    const [isOpenDialog, setIsOpenDialog] = useState(false)
    const [dialogActions, setDialogActions] = useState<AlertActions | null>(null)
    const [isCopied, setIsCopied] = useState<string | null>(null)
    const [isPreview, setPreviewOpen] = useState(false)
    let isMounted = true

    const itemActions = [
        {
            id: 'copy-link',
            icon: IconVariant.COPY,
            text: "Copy Link"
        },
        {
            id: 'copy-embed',
            icon: IconVariant.COPY,
            text: "Copy Embed"
        }
    ]

    const onClosePreview = () => {
        setPreviewOpen(false)
        navigate(PathName.AGENTS)
    }

    const fetchApi = async (): Promise<void> => {
        const res = await agentService.IngestAgent(currentAgent!, user.token!);
        if (res.success) {
            dispatch(AgentActions.AddAgent({ agent: res.data }))
            res && setPreviewOpen(true);
        } else {
            setIsOpenDialog(true)
            const err_message = ['AGENT_MAX_TOTAL_DOCS', "ERROR_AZURE_CONFIG", "ERROR_BEDROCK_CONFIG"]
            setDialogActions({
                title: tSwal("ERROR"),
                content: err_message.includes(res.err) ? tSwal(res.err) : tSwal("ERROR_FETCH"),
                confirm: tSwal("OK"),
                onConfirm: () => onChangeStage(0),
                colorButton: "primary",
                isMessage: true
            })
        }
    }



    useEffect(() => {

        if (isMounted) { fetchApi() }

        return () => { isMounted = false }


    }, []);



    const handleCopy = (actionID: string) => {
        if (user.plan === Plan.FREE) {
            setIsOpenDialog(true)
            setDialogActions({
                title: tSwal("ERROR_UPGRADE"),
                content: tSwal("ERR_AGENTS_UPGRADE_LINK"),
                confirm: tSwal("TO_UPGRADE"),
                onConfirm: () => navigate("/subscriptions"),
                cancel: tSwal("CANCEL"),
                colorButton: "primary"
            })
            return
        }
        setIsCopied(actionID)
        setTimeout(() => {
            setIsCopied(null)
        }, 1000)
        const copyText = actionID === 'copy-link' ? GenerateLink(currentAgent!.agent_id) : GenerateEmbed(currentAgent!.agent_id)
        navigator.clipboard.writeText(copyText)
    }




    const buttonHeaders = () => (
        itemActions.map((action: any, ai: number) => <React.Fragment key={ai}>
            <Button className="preview-action" isIcon onClick={() => handleCopy(action.id)}>
                {action.text && <span> {
                    isCopied === action.id ?
                        'Copied' :
                        action.text
                } </span>}

            </Button>
        </React.Fragment>

        )

    )





    return (
        <div className="agent agent-overview">
            {!isPreview && <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="primary.500"
                size="xl"
                margin="100px auto" />

            }


            <PreviewAgent
                isOpen={isPreview}
                onClose={onClosePreview}
                title={currentAgent!.name ?? ''}
                src={`${GenerateLink(currentAgent!.agent_id)}/preview`}
                buttonsHeader={buttonHeaders()}
                divider={true}
            />
            <Alert
                isOpen={isOpenDialog}
                onClose={() => setIsOpenDialog(false)}
                actions={dialogActions!}
            />
        </div>
    );
}


export default React.memo(AgentOverview)