import classNames from 'classnames';
import React, { ChangeEvent, ReactElement, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NodeType } from '../../enums/enums';
import { GetHighLightElement } from '../../helpers/wrokflow-builder/highlight-search-text';
import { useRTL } from '../../hooks/use-rtl';
import { useTranslation } from '../../hooks/use-translate';
import { ChatbotNodeData } from '../../models/chat-bot-node';
import { ChatBuilderActions } from '../../redux/chat-builder';
import { ChatbotActionsSelectors } from '../../redux/chatbot-actions/selectors';
import Button, { ButtonSize, ButtonVariant } from '../button/button';
import OptionInput from '../form/option-input';
import Icon, { IconTheme, IconVariant } from '../icon/icon';
import Node from './node';
import './node.scss';
import SourceHandle from './source-handle';
import TargetHandle from './target-handle';

export interface WAListObject {
  button: string;
  sections: Array<{
    isOpen: boolean;
    title: string;
    rows: Array<{
      id: string;
      title: string;
      description: string;
    }>
  }>
}

export enum WAListAction{
  BUTTON = 'button',
  SECTION_TITLE = 'sectionTitle',
  ROW_TITLE = 'rowTitle',
  ROW_DESCRIPTION= 'rowDescription',
  ADD_ROW = 'addRow',
  REMOVE_ROW = 'removeRow',
  ADD_SECTION = 'addSection',
  SECTION_STATE = 'sectionState',
}

function WhatsAppListNode({ data }: { data: ChatbotNodeData }): ReactElement {
  const t = useTranslation('COMPONENTS.NODE');
  const dispatch = useDispatch();
  const searchValue = useSelector(ChatbotActionsSelectors.inputSearchValue)
  const isRTL = useRTL();
  const onRemoveOption = (sectionIndex: number, rowID: string): void => {
    dispatch(ChatBuilderActions.onChangeWAList({ nodeID: data.id, key: WAListAction.REMOVE_ROW, sectionIndex, rowID }));
  };

  const onAddNewRow = (index: number): void => {
    dispatch(ChatBuilderActions.onChangeWAList({ nodeID: data.id, key: WAListAction.ADD_ROW, sectionIndex: index }));
  };

  const onChangeButton = (value: string): void => {
    dispatch(ChatBuilderActions.onChangeWAList({ nodeID: data.id, key: WAListAction.BUTTON, value }));
  }

  const onChangeTitleSection = (value: string, index: number): void => {
    dispatch(ChatBuilderActions.onChangeWAList({ nodeID: data.id, key: WAListAction.SECTION_TITLE, sectionIndex: index, value }));
  }

  const onChangeRow = (value: string, sectionIndex: number, rowID: string, key: WAListAction) => {
    dispatch(ChatBuilderActions.onChangeWAList({ nodeID: data.id, key, sectionIndex, rowID, value }));
  }

  const onAddNewSection = (): void => {
    dispatch(ChatBuilderActions.onChangeWAList({ nodeID: data.id, key: WAListAction.ADD_SECTION }));
  }

  const onChangeSectionState = (sectionIndex: number): void => {
    dispatch(ChatBuilderActions.onChangeWAList({ nodeID: data.id, key: WAListAction.SECTION_STATE, sectionIndex }));
  }

  const highLightCallBack = useCallback((value: string) =>
    GetHighLightElement(searchValue, value),
    [searchValue])

  return (
    <Node data={data}
      type={NodeType.WHATSAPP_LIST}
      className="node-select">
      <TargetHandle id={data.id} />
      <div className={classNames(['node-walist node-options ', { 'rtl': isRTL }])}>
        {data.isSelected &&
            <OptionInput style={{ textAlign: 'center' }}
              value={data.obj?.waList?.button ?? ''}
              onChange={(value) => onChangeButton(value)}
              placeholder={t("WA_LIST_MENU_BUTTON")}
              className={classNames(['node-option-input', { 'rtl': isRTL }])}
              icon={IconVariant.WA_LIST_BUTTON}
              iconRealtive
              />
        }
        {data.obj?.waList?.sections?.map((section, index) =>
          <div key={index}>
            {(data.isSelected) ?
              <>
                <Button isIcon={true} variant={ButtonVariant.LINK} size={ButtonSize.SMALL} className="node-walist-arrow"
                  onClick={() => onChangeSectionState(index)}>
                  <Icon theme={IconTheme.GRAY} variant={section.isOpen ? IconVariant.BOTTOM : IconVariant.BACK_SMALL} />
                </Button>
                <input
                  value={section.title}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeTitleSection(e.target.value, index)}
                  placeholder={t("SECTION_TITLE")}
                  className={classNames(['node-walist-section-title', { 'rtl': isRTL }])}
                  maxLength={20} />
              </>
              :
              <h4 className="node-option-text">
                {highLightCallBack(section.title)}
              </h4>

            }
            {section.rows.map((row, rowIndex) =>
              <div key={rowIndex}>
                <div className="node-option">
                  {(data.isSelected && section.isOpen) ?
                    <div className="node-walist-row" >
                      <OptionInput
                        value={row.title}
                        placeholder={t("TITLE")}
                        //maxLength={24}
                        onChange={(value) =>
                          onChangeRow(value, index, row.id, WAListAction.ROW_TITLE)}
                        className={classNames(['node-option-input bottom', { 'rtl': isRTL }])} />
                      <OptionInput
                        value={row.description}
                        onRemove={((data.options?.length ?? 0) > 1 || data.obj!.waList!.sections.length > 1) ? () => onRemoveOption(index, row.id) : undefined}
                        onChange={(value) =>
                          onChangeRow(value, index, row.id, WAListAction.ROW_DESCRIPTION)}
                        placeholder={t("DESCRIPTION")}
                        //maxLength={72}
                        className={classNames(["node-option-input ", { 'rtl': isRTL }])} />

                    </div>
                    :
                    <>
                      <span className="node-option-text">
                        {highLightCallBack(row.title || t("TITLE"))}
                      </span>
                    </>

                  }
                  <SourceHandle id={row.id} />
                </div>
              </div>)}
            {(data.isSelected && section.isOpen && (data.options?.length ?? 0) < 10) &&
              <div className={classNames(['node-options-add', { 'rtl': isRTL }])}>
                <Button onClick={() => onAddNewRow(index)} className={classNames({ 'rtl': isRTL })}
                  onTouchStart={() => onAddNewRow(index)} variant={ButtonVariant.PRIMARY} isBlock={true}>
                  {t('BUTTON_ADD_OPTION')}
                </Button>
              </div>}
          </div>)}
        {data.isSelected && (data.options?.length ?? 0) < 10 && <div className="node-walist-add">
          <Button onClick={onAddNewSection} className={classNames({ 'rtl': isRTL })}
            onTouchStart={onAddNewSection} variant={ButtonVariant.OUTLINE} isBlock={true}>
            {t('BUTTON_ADD_SECTION')}
          </Button>
        </div>}
      </div>
    </Node>
  );
}

const areEqual = (prevProps:any, nextProps:any) => prevProps.data === nextProps.data;

export default React.memo(WhatsAppListNode, areEqual);
