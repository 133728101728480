import classNames from "classnames";
import React, { ChangeEvent, DetailedHTMLProps, InputHTMLAttributes, ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRTLD } from "../../hooks/use-rtl";
import { ChatBuilderSelectors, ChatBuilderActions, ConfigurationBuilderType } from "../../redux/chat-builder";
import Button, { ButtonVariant } from "../button/button";
import Icon, { IconTheme, IconVariant } from "../icon/icon";
import Editor from "@monaco-editor/react";
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import { Box, Flex, Icon as CIcon, Text } from '@chakra-ui/react'

import Compressor from '@uppy/compressor';
import AwsS3 from '@uppy/aws-s3';
import ImageEditor from '@uppy/image-editor'
import { configuration } from "../../configurations/configuration"
import { AttachmentIcon, SmallCloseIcon } from "@chakra-ui/icons";
import { FileLinkState } from "./widget-content";






type InputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>





const InputAnswer = ({ answer, chatTextRef, rtl, handleChange, handleClick }: {
    answer: string;
    chatTextRef?: boolean;
    handleChange: (e: ChangeEvent<HTMLTextAreaElement>) => void,
    handleClick: () => void
    rtl: boolean
}): ReactElement => {

    const textareaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        const textArea = textareaRef.current;
        if (textArea) {
            textArea.style.height = "0";
            const scrollHeight = textArea.scrollHeight;
            textArea.style.height = scrollHeight + "px";
            textareaRef.current.addEventListener("keypress", handleKeyPress);
        }
        return () => {
            if (textareaRef.current) {
                textareaRef.current.removeEventListener("keypress", handleKeyPress);
            }
        }
    }, [answer, chatTextRef]);



    const handleKeyPress = useCallback((event: any) => {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            handleClick();
        }
        else if (event.key === "\n" || (event.shiftKey && event.keyCode === "Enter")) {
            event.target.value += "\n"
            handleChange(event);
        }
    }, [answer, chatTextRef, handleClick]);


    return (
        <>
            <textarea ref={textareaRef} rows={1}
                className={classNames([{ rtl }])}
                onChange={handleChange} value={answer} maxLength={1000} />
            <span className={classNames(["node-text-maxLength widget-maxLength", { rtl }])} >{`${answer?.toString().length}/${1000}`}</span>
        </>
    )

}

const ButtonAttachment = ({ handleClick }: {
    handleClick: (link: string) => void
    variant?: ButtonVariant
}): ReactElement => {


    const uppy = useRef<Uppy>();

    const txtOrPdf = ['text/plain', 'application/pdf', "text/html"]
    const csvOrExcel = ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
    const image = ['image/*']

    useEffect(() => {
        const uppyInstance = new Uppy({
            restrictions: {
                allowedFileTypes: [...txtOrPdf, ...csvOrExcel, ...image],
                maxNumberOfFiles: 1
            }
        })
            .use(AwsS3, {
                companionUrl: `${configuration.api_url}/uppy-chat`
            })
            .use(Compressor)
            .use(Dashboard, {
                inline: false,
                trigger: '.attachment-trigger',
                closeAfterFinish: true
            })

        uppy.current = uppyInstance;

        // uppy.current.on('file-added', (file) => {
        //     uppy.current!.setFileMeta(file.id, { name: currentAgent!.agent_id });
        // });

        uppy.current.on('complete', (result) => {
            if (result.successful.length) {
                const end_point = "https://officelyfiles.s3.amazonaws.com/uppy-chat/"
                const name = result.successful[0].name
                console.log(result.successful[0].name)
                const url = end_point + name
                //handleClick(`<link>${url}</link>`)
                handleClick(url)
                console.log(url)
            }
        });



        return () => {
            uppyInstance.close();
        };
    }, []);



    return (
        <Button isIcon className="attachment-trigger" >
            <CIcon as={AttachmentIcon} boxSize={4} />
        </Button>
    );

}

export const ButtonAnswer = ({ disabled, handleClick, variant }: {
    handleClick: () => void
    disabled: boolean
    variant?: ButtonVariant
}): ReactElement => (
    <Button  variant={variant}
        disabled={disabled}
        onClick={handleClick}
        isIcon
    >
        <Icon variant={IconVariant.BUTTTON_SEND} theme={IconTheme.BLACK} />
    </Button>
);


export const TextInputAnswer = ({ answer, chatTextRef, disableButton, fileLinkState, handleChange, handleClick, ...inputProps }: {
    answer: string;
    chatTextRef?: boolean;
    disableButton: boolean
    fileLinkState?:FileLinkState
    handleChange: (e: ChangeEvent<HTMLTextAreaElement>) => void,
    handleClick: () => void
} & InputProps): ReactElement => {
    const isRTLD = useRTLD();
    const { fileLink, setFileLink } = fileLinkState ?? {}


    const handeleFile = (l: string) => {
        if (!setFileLink) return;
        setFileLink(l)
    }

    const removeLink = () => {
        if (!setFileLink) return;
    }







    return (
        <div className={classNames(["answer-input", { rtl: isRTLD(answer) }])}>
            {fileLink && <Box
                position="absolute"
                display="flex"
                alignItems="center"
                top={20}
                left={0}
                dir="ltr"

            >
                <CIcon
                    as={SmallCloseIcon} boxSize={5}
                    onClick={removeLink}
                    cursor="pointer"
                    _hover={{ opacity: 0.5 }}
                />
                <Text textAlign="center">{"File Added"}</Text>
            </Box>}
            <InputAnswer
                answer={answer}
                chatTextRef={chatTextRef}
                handleChange={handleChange}
                handleClick={handleClick}
                rtl={isRTLD(answer)}
                {...inputProps} />
            <Flex className="vff-animate f-fade-in f-enter">
                {setFileLink && <ButtonAttachment handleClick={handeleFile} />}
                <ButtonAnswer disabled={disableButton} handleClick={handleClick} />
            </Flex>


        </div>
    )
}

export const StyleBuilder = ({ onClose }: {
    onClose: () => void
}): ReactElement => {
    const dispatch = useDispatch()
    const currentBuilder = useSelector(ChatBuilderSelectors.currentBuilder)
    const [valueStyle, setValueStyle] = useState(currentBuilder?.configuration.style ?? '')

    const onChangeStyle = () => {
        dispatch(ChatBuilderActions.onChangeBuilderSettings({ key: ConfigurationBuilderType.STYLE, value: valueStyle }))
    }


    const onSave = () => {
        onChangeStyle()
        onClose()
    }


    return (

        <div className="builder-style-editor">
            <Editor
                height={"100%"}
                defaultLanguage="css"
                value={valueStyle}
                theme="vs-light"
                onChange={(value) => value && setValueStyle(value)}
                options={{
                    minimap: { enabled: false },
                    scrollbar: {
                        vertical: "hidden",
                        horizontal: "hidden"
                    },
                    padding: {
                        top: 10,
                        bottom: 10
                    }
                }} />
            <Button
                variant={ButtonVariant.PRIMARY}
                isHalfBlock
                onClick={onSave}
                className="continue-button"
            >
                Save
            </Button>
        </div>
    );
}

export const PreviewReplaceLogo = ({ onClose }: {
    onClose: () => void
}): ReactElement => {
    const dispatch = useDispatch();
    const uppy = useRef<Uppy>();
    const currentBuilder = useSelector(ChatBuilderSelectors.currentBuilder)



    const onChangeLogo = async (value: string) => {
        dispatch(ChatBuilderActions.onChangeBuilderSettings({ key: ConfigurationBuilderType.LOGO, value }))
        onClose()
    }

    useEffect(() => {
        const uppyInstance = new Uppy({
            restrictions: {
                allowedFileTypes: ['image/*'],
                maxNumberOfFiles: 1
            }
        })
            .use(AwsS3, {
                companionUrl: `${configuration.api_url}/builder-logo/${currentBuilder!.builder_id}`
            })
            .use(Compressor)
            .use(Dashboard, {
                inline: true,
                target: '#myUploadContainer',
                width: "100%",
                proudlyDisplayPoweredByUppy: false,
                hideProgressAfterFinish: true


            })
            .use(ImageEditor, { target: Dashboard });


        uppy.current = uppyInstance;

        uppy.current.on('complete', (result) => {
            if (result.successful.length) {
                const end_point = "https://officely-builderlogo.s3.amazonaws.com/"
                const name = `${currentBuilder?.builder_id}/${result.successful[0].meta.name}`
                const url = end_point + name
                onChangeLogo(url)
            }
        });



        return () => {
            uppyInstance.close();
        };
    }, []);



    return (
        <div id="myUploadContainer"></div>

    )
}


export const TypingBubble = (): ReactElement =>
    <div>
        <div className="typing-bubble typing-1"></div>
        <div className="typing-bubble typing-2"></div>
        <div className="typing-bubble typing-3"></div>
    </div>


