import React, { ReactElement, useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button, { ButtonVariant } from "../button/button";
import { Avatar } from "@chakra-ui/react";
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import Compressor from '@uppy/compressor';
import AwsS3 from '@uppy/aws-s3';
import ImageEditor from '@uppy/image-editor'
import { AgentActions, AgentDeatils as AgentField, AgentSelectors} from "src/redux/agents";
import { ChakraMenu } from "../chakra/menu-item";
import './agent.scss'
import "@uppy/core/dist/style.css";
import '@uppy/image-editor/dist/style.min.css';
import { configuration } from "../../configurations/configuration"
import { useFetchOfficely } from "src/hooks/use-fetch-officely";
import { Method } from "src/enums/enums";
import { AuthSelectors } from "src/redux/auth";


interface Assistant {
    name: string;
    id: string;
}

export default function OpenAIDeatils({ onContinue }: {
    onContinue: () => void
}): ReactElement {
    const user = useSelector(AuthSelectors.user)
    const currentAgent = useSelector(AgentSelectors.currentAgent)
    const [isLoading, setIsLoading] = useState(true)
    const [assistants, setAssistants] = useState<Array<Assistant>>([]);
    const [items, setItems] = useState<Array<string>>([]);
    const [isFetched, setIsFetched] = useState(false);
    const dispatch = useDispatch();
    const uppy = useRef<Uppy>();


    const onChangeAgentDeatils = (value: string, agentDeatils: AgentField) => {
        dispatch(AgentActions.onChangeAgentDeatils({ value, agentDeatils }))
    }

    useEffect(() => {
        const uppyInstance = new Uppy({
            restrictions: {
                allowedFileTypes: ['image/*'],
                maxNumberOfFiles: 1
            }
        })
            .use(AwsS3, {
                companionUrl: `${configuration.api_url}/agent-porfile`
            })
            .use(Compressor)
            .use(Dashboard, {
                inline: false,
                trigger: '.avatar-trigger',
                closeAfterFinish: true
            })
            .use(ImageEditor, { target: Dashboard });


        uppy.current = uppyInstance;

        uppy.current.on('file-added', (file) => {
            uppy.current!.setFileMeta(file.id, { name: currentAgent!.agent_id });
        });

        uppy.current.on('complete', (result) => {
            if (result.successful.length) {
                const end_point = "https://agent-picture.s3.amazonaws.com/"
                const name = result.successful[0].meta.name
                const url = end_point + name
                onChangeAgentDeatils(url, AgentField.IMG)
            }
        });



        return () => {
            uppyInstance.close();
        };
    }, []);




    const onAvatarClick = () => {
        if (uppy.current) {
            const dashboardPlugin = uppy.current.getPlugin('Dashboard') as Dashboard;
            dashboardPlugin.openModal();
        }
    };

    const handleAsistantSelect = (name: string) => {
        const item = assistants.find((item) => item.name === name);
        if (!item) return
        onChangeAgentDeatils(item.id, AgentField.ASSISTANT_ID);
        onChangeAgentDeatils(name, AgentField.NAME);
    }

    const handleOnFocus = async (): Promise<void> => {
        if (!isFetched) {
            const res = await useFetchOfficely(`/getAssistantsOpenAI/${currentAgent?.openai_api_key}`, Method.GET, {}, user!.token)
            if (res.success && res.data){
                const assistants = res.data as Array<Assistant>
                setAssistants(assistants)
                const items = assistants.map((item) => item.name)
                setItems(items)
                setIsFetched(true)
            }
            setIsLoading(false)

        }
    }




    const disabledButton = currentAgent?.openai_api_key && currentAgent.assistant_id ? false : true




    return (
        <div className="agent agent-deatils">
            <div className="img" onClick={onAvatarClick}>
                <label className="avatar-trigger">
                    <span>
                        <Avatar src={currentAgent?.img} cursor="pointer" size="2xl" />
                    </span>
                </label>
            </div>
            <div className="form">
                <div className="form-field" >
                    <span className="form-field-label">API Token</span>
                    <input
                        className="form-field-text"
                        value={currentAgent?.openai_api_key}
                        onChange={(e) => onChangeAgentDeatils(e.target.value, AgentField.OPENAI_API_KEY)}
                        placeholder="Sk- ••••••••••••••••••"
                        type="password"
                        />
                </div>
                <div className="form-field">
                    <span className="form-field-label"> Asistant </span>
                    <ChakraMenu<string>
                        className="form-field-text"
                        currentItem={currentAgent?.name}
                        onItemSelect={handleAsistantSelect}
                        items={items}
                        center={false}
                        textAlign="start"
                        background="white"
                        fontWeight="100"
                        onFocus={handleOnFocus}
                        isLoading={isLoading}
                        placeholder="Select Assistant From OpenAI"
                        disabled={!currentAgent?.openai_api_key}

                    />
                </div>
            </div>
            <Button
                variant={ButtonVariant.PRIMARY}
                isBlock
                className="continue-button"
                disabled={disabledButton}
                onClick={onContinue}
            >

                Continue
            </Button>

        </div>
    );
}


