import {
    Modal, ModalBody, ModalCloseButton, ModalContent,
    ModalHeader, ModalOverlay, Text, InputGroup, Input, InputRightElement,
    ModalFooter, InputLeftAddon, InputRightAddon, InputLeftElement
} from "@chakra-ui/react"
import classNames from "classnames"
import React, { ReactNode, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Plan } from "../../enums/enums"
import { useRTL } from "../../hooks/use-rtl"
import { useTranslation } from "../../hooks/use-translate"
import { AuthSelectors } from "../../redux/auth"
import Button from "../button/button"
import Icon, { IconVariant, IconTheme } from "../icon/icon"




const InputAddOn = ({ title, isRTL }: { title: string, isRTL: boolean }) => <>
    {
        isRTL ?
            <InputRightAddon>
                {title}
            </InputRightAddon>
            :
            <InputLeftAddon>
                {title}
            </InputLeftAddon>
    }
</>


const InputElement = ({ children, isRTL }: { children: ReactNode, isRTL: boolean }) => <>

    {
        isRTL ?
            <InputLeftElement>
                {children}
            </InputLeftElement>
            :
            <InputRightElement>
                {children}
            </InputRightElement>
    }
</>






const Link = ({ title, link }: { title: string, link: string }) => {
    const [copy, setIsCopied] = useState(false)
    const isRTL = useRTL()
    const t = useTranslation('FORMS');
    const handleCopy = () => {
        setIsCopied(true)
        setTimeout(() => {
            setIsCopied(false)
        }, 1000)
        navigator.clipboard.writeText(link)
    }
    return (
        <InputGroup size="md">
            <InputAddOn title={title} isRTL={isRTL} />
            <Input
                type="text"
                placeholder={link}
                textOverflow="ellipsis"
                readOnly
                dir="ltr"
                paddingInlineStart={isRTL ? "var(--chakra-space-10)" : "var(--chakra-space-4)"}
                paddingInlineEnd={isRTL ? "var(--chakra-space-4)" : "var(--chakra-space-10)"}
            />
            <InputElement isRTL={isRTL} >
                <Button isIcon onClick={handleCopy}>
                    {copy ?
                        <Text fontSize="xs">{t('COPIED')}</Text>
                        : <Icon variant={IconVariant.COPY} theme={IconTheme.BLACK} />}
                </Button>
            </InputElement>
        </InputGroup>

    )
}




export default function PublishPopUp({ isOpen, onClose }: { isOpen: boolean, onClose: () => void }) {
    //className={classNames({ rtl: !IsRTL })}
    const isRTL = useRTL()
    const navigate = useNavigate();
    const user = useSelector(AuthSelectors.user)
    const host = window.location.host
    const t = useTranslation('COMPONENTS.POPUPS');
    const dir = isRTL ? "left" : "right"

    const embed_code = `<script src=https://${host}/chat-widget.js></script>
    <script>
        window.addChatWidget({
        id:'${user.code_user}',
        type:"chat", //can be chat or form
        //position: "side", //can be side or center
        //buttonColor:"#5b61eb", 
        //iconLink:"your icon link (optional)",
        //autoOpen: true 
        })
        </script>`

    return (
        <Modal isCentered size="xl" isOpen={isOpen} onClose={onClose} >
            <ModalOverlay
                bg="blackAlpha.300"
                backdropFilter="blur(10px) "
            />
            <ModalContent p={8} className={classNames({ rtl: isRTL })}>
                <ModalCloseButton style={{ [dir]: "var(--chakra-space-2)" }} right="unset" />
                <ModalHeader>{t("PUBLISH_TITLE")} 🎉</ModalHeader>
                <ModalBody>
                    {user.plan === Plan.FREE ?
                        <>
                            {t("FREE_PUBLISH_1")} <b>{t("FREE_PUBLISH_2")}</b> <br />
                            {t("FREE_PUBLISH_3")} <b> {t("FREE_PUBLISH_4")}</b>
                            <a className="link" onClick={() => navigate("/subscriptions")}> {t("FREE_PUBLISH_UPGRADE")} </a>
                        </>
                        :
                        <>
                            {t("PUBLISH_1")} <b>{t("PUBLISH_2")}</b> &nbsp;
                            {t("PUBLISH_3")} <b> {t("PUBLISH_4")}</b>
                        </>

                    }


                </ModalBody>
                <ModalFooter gap={4} flexDir="column">
                    <Link title={t("CHAT")} link={`https://${host}/#/widget/${user.code_user}/chat`} />
                    <Link title={t("FORM")} link={`https://${host}/#/widget/${user.code_user}/form`} />
                    <Link title={t("EMBED")} link={embed_code} />


                </ModalFooter>
            </ModalContent>
        </Modal>


    )
}