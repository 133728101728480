import React, { ReactElement, useEffect } from 'react';
import { Method, NodeObjectKey, NodeType } from '../../../enums/enums';
import { ChatbotNodeData } from '../../../models/chat-bot-node';
import Node from '../node';
import '../node.scss';
import TargetHandle from '../target-handle';
import { useDispatch, useSelector } from 'react-redux';
import { ChatBuilderActions } from '../../../redux/chat-builder';
import ProfileIMG from "../../../assets/images/profile.svg"
import { Avatar, Button, Flex, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { Agent, AgentActions, AgentSelectors } from '../../../redux/agents';
import { useFetchOfficely } from '../../../hooks/use-fetch-officely';
import { AuthSelectors } from '../../../redux/auth';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useTranslation } from '../../../hooks/use-translate';




function PluginAgentNode({ data }: { data: ChatbotNodeData }): ReactElement {
    const dispatch = useDispatch();
    const t = useTranslation('COMPONENTS.NODE');
    //const [agents, setAgents] = useState<Agent[]>([])
    const agents = useSelector(AgentSelectors.agents)
    const user = useSelector(AuthSelectors.user)
    const currentAgent = agents.find((a) => a.agent_id === data?.obj?.agent?.agent_id) || null
    let isMounted = true



    useEffect(() => {
        const fetchApi = async (): Promise<void> => {
            const res = await useFetchOfficely('GetAgents', Method.GET, null, user?.token)
            if (res.success && res.data) {
                dispatch(AgentActions.SetAgents({ agents: res.data }))
            }
        }
        if (isMounted) {
            fetchApi()
        }

        return () => {
            isMounted = false
        }
    }, [data.isSelected])



    const onChangeAgent = (agent: Agent) => {
        dispatch(ChatBuilderActions.changeObj({ id: data.id, key: NodeObjectKey.AGENT, value: agent }));
    }








    return (
        <Node data={data}
            type={NodeType.PLUGIN_AGENT}
            className="node-pluginAI">
            <TargetHandle id={data.id} />
            {/* <SourceHandle id={data.id} /> */}
            {data.isSelected ?
                <Menu closeOnSelect matchWidth>
                    <MenuButton
                        className="node-option-input"
                        mt="20pt" 
                        w="100%"
                        mb={0} 
                        as={Button}
                        rightIcon={<ChevronDownIcon />}
                    >
                        {currentAgent ? currentAgent.name : t("SELECT_AGENT")}
                    </MenuButton>
                    <MenuList zIndex={3}>
                        {agents.map((agent) => (
                            <MenuItem key={agent.agent_id} onClick={() => onChangeAgent(agent)}>
                                <Flex align="center">
                                    <Avatar
                                        cursor="pointer"
                                        size="sm"
                                        src={agent.img ? agent.img : ProfileIMG}
                                        mr={2} // Adds a bit of margin to the right of the avatar
                                    />
                                    <Text>{agent.name}</Text>
                                </Flex>
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
                :
                <Text fontSize="2xl">{currentAgent?.name || t("NO_SELECTED_AGENT")}</Text>



            }
        </Node>
    );
}


const areEqual = (prevProps: any, nextProps: any) => prevProps.data === nextProps.data;

export default React.memo(PluginAgentNode, areEqual);