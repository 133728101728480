import React from "react";
import { useGoogleLogin } from '@react-oauth/google';
import { Method, PathName } from "src/enums/enums";
import { useFetchOfficely } from "src/hooks/use-fetch-officely";
import { User } from "src/models";
import { useDispatch } from "react-redux";
import { AuthActions } from "src/redux/auth";
import { useNavigate } from "react-router-dom";
import { Divider, Flex } from "@chakra-ui/react";
import Button, { ButtonVariant } from "../button/button";
import Icon, { IconVariant } from "../icon/icon";
import { useTranslation } from "src/hooks/use-translate";


export default function GoogleOAuth({ queryParams }: { queryParams?: any }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const t = useTranslation('PAGES.LOGIN');


  const onSuccess = async (credentialResponse: any) => {
    const newUser = new User()
    const body = {
      credsGoogle: credentialResponse,
      newUser
    }
    let url = "/auth_google"
    if (queryParams && queryParams.get('auth') === 'intercom') {
      url = `${url}?intercom_id=${queryParams.get('intercom_id')}&code=${queryParams.get('code')}`
    }
    const res = await useFetchOfficely(url, Method.POST, body)
    if (res.success && res.data) {
      const resData = res.data.user as User
      if (res.data.is_signup) {
        dispatch(AuthActions.authorizeSuccess());
        dispatch(AuthActions.setUser({ user: resData }))
        navigate(`/mybuilders`)
      } else {
        dispatch(AuthActions.setUser({ user: resData }))
        dispatch(AuthActions.authorizeSuccess());
        dispatch(AuthActions.setOfficelyUser({ officely_token: resData.officely_user ? resData.token! : false }))
        navigate(PathName.DASHBOARD)

      }
    }
  }

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => onSuccess(tokenResponse)
  });





  return (
    <Flex alignItems="center" flexDirection="column" gap={5} mt="10pt">
      <Divider />
      <Button variant={ButtonVariant.OUTLINE} onClick={() => login()} isBlock>
        <Flex alignItems="center" justifyContent="center">
          <Icon variant={IconVariant.GOOGLE} />
          {t("CONTINUE_WITH_GOOGLE")}
        </Flex>

      </Button>


    </Flex>
  );
}
