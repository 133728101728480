import classnames from 'classnames';
import React, { useState, useEffect, FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Icon, { IconVariant } from 'src/components/icon/icon';
import './ingest-agent.scss'
import { customAgentSatges, openAIAssistantStages } from 'src/components/agent-componnets/agent-variables'

import Badge from 'src/components/badge/badge';
import { Tabs, TabList, Tab, TabPanels, TabPanel, Box } from '@chakra-ui/react';
import TeamBuilder from 'src/components/team-builder/team-builder';
import { useSelector } from 'react-redux';
import { AuthSelectors } from 'src/redux/auth';
import { AgentSelectors } from 'src/redux/agents';
import { PathName } from 'src/enums/enums';



export type IngestStages = {
    isPro?: boolean,
    name: string,
    subStages: string[],
    done: boolean,
    component: FC<ComponentProps>;
}

type ComponentProps = {
    onContinue: () => void;
    onChangeStage: (number: number) => void;

};


function Agent() {
    const location = useLocation();
    const isCustomAgent = location.state.isCustomAgent ?? false
    const stagesArr = isCustomAgent ? customAgentSatges : openAIAssistantStages



    const [currentStage, setCurrentStage] = useState<IngestStages | null>(null);
    const [stages, setStages] = useState<IngestStages[]>(stagesArr);


    useEffect(() => {
        setCurrentStage(stages[0])
    }, [])

    const onContinue = () => {
        const index = stages.findIndex((s) => s.name === currentStage!.name);
        const updatedStages = stages.slice();
        updatedStages[index] = {
            ...updatedStages[index],
            done: true
        };
        setStages(updatedStages);


        if (index === stages.length - 1) {
            alert('Done');
        } else {
            setCurrentStage(updatedStages[index + 1]);
        }
    };

    const onChangeStage = (index: number) => {
        setCurrentStage(stages[index]);
    }




    return (
        <div className="ingest-agent">
            <div className="layout">
                <div className={classnames("gray-bubble", `length-${stages.length}`)}></div>
                <div className="layout-content">
                    {stages.map((s, si) => (
                        <div className="step-container" key={si}>
                            <div className="step-line">
                                <div
                                    className={classnames(["step", { done: s.done, active: currentStage?.name === s.name }])}>
                                    {s.done ?
                                        <Icon variant={IconVariant.CHECK} />
                                        :
                                        <>
                                            {si + 1}
                                        </>
                                    }
                                </div>
                                <div className="step-title">
                                    {s.name}
                                    {s.isPro && <Badge
                                        colorScheme="secondly"
                                        text="Pro" />
                                    }
                                </div>
                            </div>
                            <ul className="sub-stages">
                                {s.subStages.map((sub, subi) => (
                                    <li className={classnames(["sub-stage", { done: s.done, active: currentStage?.name === s.name }])}
                                        key={subi}>
                                        {sub}
                                    </li>
                                ))}
                            </ul>
                        </div>

                    ))}
                </div>
                <div className="main-content">
                    <h1 className="title only-mobile">{currentStage?.name}</h1>
                    {currentStage && <currentStage.component
                        onContinue={() => onContinue()}
                        onChangeStage={(index) => onChangeStage(index)}
                    />}
                </div>
            </div>


        </div>
    );
}


function IngestAgent() {
    const currentAgent = useSelector(AgentSelectors.currentAgent)
    const navigate = useNavigate();
    const fullAccess = useSelector(AuthSelectors.accessTeam);
    const [teamActiveTab, setTeamActiveTab] = useState(fullAccess);



    useEffect(() => {
        if (!currentAgent) {
            navigate(PathName.AGENTS)
        }


    }, [currentAgent])

    const handleTabChange = (index: number) => {
        setTeamActiveTab(index === 1)
    };



    return (
        <div className="page">
            <div className="page-content">
                {currentAgent?.team?.nodes && !currentAgent?.assistant_id ?
                    <Tabs mt={10} isManual variant="enclosed" onChange={handleTabChange} defaultIndex={fullAccess ? 1 : 0} >
                        <TabList>
                            <Tab>Agent</Tab>
                            <Tab>Team</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <Agent />
                            </TabPanel>
                            {teamActiveTab && <TabPanel height="92vh">
                                <TeamBuilder />
                            </TabPanel>}
                        </TabPanels>
                    </Tabs>
                    :
                    <Box mt={20}>
                        <Agent />
                    </Box>
                }
            </div>
        </div>
    )

}

export default IngestAgent


