import { connectRouter } from 'connected-react-router';
import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, combineReducers, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as modal } from 'redux-modal';
import createSagaMiddleware from 'redux-saga';
import thunkMiddleware from 'redux-thunk';
import { configuration } from '../configurations/configuration';
import history from '../history';
import { rootSaga } from './saga';
import { authReducer } from './auth';
import { chatbotPageReducer } from './chatbot-actions';
import { chatBuilderReducer } from './chat-builder';
import { dataSourcesReducer } from './agents';


// import { combineReducers } from 'redux';
// import { connectRouter } from 'connected-react-router';

// const appReducer = combineReducers({
//   router: connectRouter(history), // Replace 'history' with your history instance
//   modal,
//   root: rootReducer,
//   auth: authReducer,
//   chatBuilder: chatBuilderReducer,
//   chatbotPage: chatbotPageReducer,
//   dataSources: dataSourcesReducer,
// });



import { rootReducer } from './reducer';

export type AppState = ReturnType<typeof appReducer>;

const appReducer = combineReducers({
  router: connectRouter(history),
  modal,
  root: rootReducer,
  auth: authReducer,
  chatBuilder: chatBuilderReducer,
  chatbotPage: chatbotPageReducer,
  dataSources: dataSourcesReducer
});


const sagaMiddleware = createSagaMiddleware();
const middleware = [routerMiddleware(history), thunkMiddleware, sagaMiddleware];

const timeState = Date.now() - JSON.parse(localStorage.getItem('timeStore') ?? '0') 
const diff = timeState / 3600000
const persistedState = localStorage.getItem('reduxState') && diff < 24
                       ? JSON.parse(localStorage.getItem('reduxState') ?? '')
                       : {}

export const store: Store = (configuration.production)
  ? createStore(appReducer,persistedState, applyMiddleware(...middleware))
  : createStore(appReducer,persistedState, composeWithDevTools(applyMiddleware(...middleware)));

sagaMiddleware.run(rootSaga);
