import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { EdgeProps, getBezierPath } from 'reactflow';
import { Alert, AlertActions } from 'src/helpers/dialog';
import useEdgeClick from 'src/hooks/team-builder/useEdgeClick';
import { useTranslation } from 'src/hooks/use-translate';
import { AuthSelectors } from 'src/redux/auth';
import styles from './EdgeTypes.module.css';

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
  markerEnd
}: EdgeProps) {
  // see the hook for implementation details
  // onClick adds a node in between the nodes that are connected by this edge

  const fullAccess = useSelector(AuthSelectors.accessTeam) as boolean;
  const [dialogActions, setDialogActions] = useState<AlertActions | null>(null)
  const tSwal = useTranslation("COMPONENTS.SWAL")

  const handlePlaceholderClick = useEdgeClick(id);

  const onClick = () => {
    if (!fullAccess) {
      setDialogActions({
        title: tSwal("ERR_TEAM_UPGRADE_TITLE"),
        content: tSwal("ERR_TEAM_UPGRADE"),
        confirm: tSwal("TO_UPGRADE"),
        onConfirm: () =>  window.open('https://cal.com/officely-ai/demo', '_blank', 'noopener,noreferrer'),
        cancel: tSwal("CANCEL"),
        colorButton: "primary",
        isOpen: true
      })
      return;
    }
    handlePlaceholderClick();
  };


  const [edgePath, edgeCenterX, edgeCenterY] = getBezierPath({
    sourceX,
    sourceY: sourceY + 50,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  });

  return (
    <>
      <path
        id={id}
        style={style}
        className={styles.edgePath}
        d={edgePath}
        markerEnd={markerEnd}
      />
      <g transform={`translate(${edgeCenterX}, ${edgeCenterY})`}>
        <rect
          onClick={onClick}
          x={-10}
          y={-10}
          width={20}
          ry={4}
          rx={4}
          height={20}
          className={styles.edgeButton}
        />
        <text className={styles.edgeButtonText} y={5} x={-4}>
          +
        </text>
      </g>
      <Alert
        isOpen={dialogActions?.isOpen ?? false}
        onClose={() => setDialogActions(null)}
        actions={dialogActions!}
      />
    </>
  );
}
