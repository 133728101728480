import { useReactFlow, Node } from 'reactflow';

const imagesUrl = [
    "https://officelyfiles.s3.eu-west-1.amazonaws.com/678cd6f2-521a-459e-9bd7-5238f78fab3e.jpg",
    "https://officelyfiles.s3.eu-west-1.amazonaws.com/3980a318-1cce-46c3-926c-41e8a1b9f6bf.jpg",
    "https://officelyfiles.s3.eu-west-1.amazonaws.com/107944a9-322c-4882-9f64-5026004a2ae1.jpg",
    "https://officelyfiles.s3.eu-west-1.amazonaws.com/627bc8de-c9f2-43dd-ae47-ba29aa3ff027.jpg",
    "https://officelyfiles.s3.eu-west-1.amazonaws.com/687f8fd2-aa6d-45f4-8d79-a49ae26c1970.jpg",
    "https://officelyfiles.s3.eu-west-1.amazonaws.com/2cd2ecc2-4eb9-4e94-926e-fdd5aa509316.jpg",
    "https://officelyfiles.s3.eu-west-1.amazonaws.com/e947c77a-43ca-4c77-983e-c0e410be2eb5.jpg",
    "https://officelyfiles.s3.eu-west-1.amazonaws.com/076ec674-1362-4b6d-9b8e-3f8b272d56f6.jpg",
    "https://officelyfiles.s3.eu-west-1.amazonaws.com/c02b543f-60c0-44d2-b9ef-9483f246f186.jpg",
    "https://officelyfiles.s3.eu-west-1.amazonaws.com/d5ef4486-9620-4ef1-a51c-b23878b02998.jpg",
    "https://officelyfiles.s3.eu-west-1.amazonaws.com/f4f41922-9532-4905-945c-5dca1e83fdd0.jpg",
    "https://officelyfiles.s3.eu-west-1.amazonaws.com/f231952f-2f49-4286-b01d-9e73ec4afba0.jpg",
    "https://officelyfiles.s3.eu-west-1.amazonaws.com/1b8e5140-af7d-422a-a011-b78805cee92f.jpg",
    "https://officelyfiles.s3.eu-west-1.amazonaws.com/e2b20fd8-e33c-4ab9-87d1-1772c8aef938.jpg",
    "https://officelyfiles.s3.eu-west-1.amazonaws.com/be26b9d6-67d6-496e-b02b-569240b4daf8.jpg",
    "https://officelyfiles.s3.eu-west-1.amazonaws.com/41ba1d9f-2c09-4c32-9497-b817821b7d53.jpg",
    "https://officelyfiles.s3.eu-west-1.amazonaws.com/536f5521-2946-4095-a246-9a968531185b.jpg",
    "https://officelyfiles.s3.eu-west-1.amazonaws.com/5694147b-a0b3-4724-adf7-0c74bbe0f326.jpg",
    "https://officelyfiles.s3.eu-west-1.amazonaws.com/a998b9c4-59c0-4064-acb2-1e0bccd2d058.jpg",
    "https://officelyfiles.s3.eu-west-1.amazonaws.com/ee1439d4-284e-43ce-8b27-a40f1adb8dbb.jpg"
]


export function useRandomImage() {
    const { getNodes } = useReactFlow();

    return () => {
        const nodes = getNodes();
        const existingImages = nodes.map((node: Node) => node.data.urlImage);

        const availableImages = imagesUrl.filter((imageUrl) => !existingImages.includes(imageUrl));

        if (availableImages.length === 0) {
            throw new Error('No available images left.');
        }

        const randomIndex = Math.floor(Math.random() * availableImages.length);
        return availableImages[randomIndex];
    };
}

export default useRandomImage;
