import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { useRTL } from '../../hooks/use-rtl';
import { useTranslation } from '../../hooks/use-translate';
import './status-chat-bot.scss';

export default function StatusChatBot({ isActive }: {
    isActive: boolean
}): ReactElement {
    const t = useTranslation('COMPONENTS.PAGES.CHATBOT');
    const isRTL = useRTL();
    return (
        <div className={classNames(['type', { rtl: isRTL, active: isActive }])}>
            <span className={classNames(["type-text", { active: isActive }])}>
                {isActive ? t('TEXT_ACTIVE') : t('TEXT_DRAFT')}
            </span>
        </div>
    );
}

