import { Switch } from '@chakra-ui/react';
import { TFunction } from 'i18next';
import React, { ReactElement, useState } from 'react';
import { HeaderForamt, NodeObjectKey } from '../../../enums/enums';
import { ChatbotNodeData } from '../../../models';
import Dropdown from '../../dropdown/dropdown';
import OptionInput from '../../form/option-input';
import FormText from '../../form/text';


interface MyButtonProps {
    class: string;
    action: () => void;
    children?: string
}

const MyButton: React.FC<MyButtonProps> = (props) => <button className={props.class} onClick={props.action}>{props.children} </button>;


export default function HeaderFooter({ data, headerForamt, t, onChangeObj }: {
    data: ChatbotNodeData,
    headerForamt: boolean,
    t: TFunction,
    onChangeObj: (key: NodeObjectKey, value: string) => void
}): ReactElement {

    const [isMapHeaderFormat, setIsMapHeaderFormat] = useState(
        ![HeaderForamt.TEXT, HeaderForamt.IMAGE, HeaderForamt.DOCUMENT, HeaderForamt.VIDEO].includes(data.obj?.headerForamt as HeaderForamt))
    //const [valueInput, setValueInput] = useState(data.obj?.headerForamt ?? '')

    const actionsType = [HeaderForamt.TEXT, HeaderForamt.IMAGE, HeaderForamt.DOCUMENT, HeaderForamt.VIDEO]
        .map((key) => ({
            key,
            node: <MyButton class="dropdown-item" action={() => onChangeObj(NodeObjectKey.HEADER_FORMAT, key)}>{t(key)}</MyButton>
        }))



    return (
        <>
            {headerForamt &&
                <>
                    <Switch colorScheme="primary" dir="ltr" zIndex={1} mb={2} isChecked={isMapHeaderFormat} onChange={(e) => setIsMapHeaderFormat(e.target.checked)} />

                    {isMapHeaderFormat ? <OptionInput
                        value={data.obj?.headerForamt ?? ''}
                        onChange={(value) => onChangeObj(NodeObjectKey.HEADER_FORMAT, value)}
                        placeholder={'TEXT | IMAGE | VIDEO | DOCUMENT'}
                        className="node-option-input"
                        dir="ltr"
                    />
                        :
                        <Dropdown items={actionsType} isRight={true}>
                            <FormText
                                value={(data.obj?.headerForamt || '' in HeaderForamt) ? `${t(data.obj!.headerForamt!)}` : data.obj!.headerForamt!}
                                className="node-option-input"
                                type="button"
                            />
                        </Dropdown>
                    }

                </>

            }
            <OptionInput
                value={data?.obj!.header ?? ''}
                placeholder={data?.obj?.headerForamt === HeaderForamt.TEXT || !headerForamt ? t('HEADER') : t('HEADER_URL')}
                onChange={(value) =>
                    onChangeObj(NodeObjectKey.HEADER, value)}
                //maxLength={data.obj?.headerForamt ? data.obj.headerForamt === HeaderForamt.TEXT ? 20 : undefined : 60}
                className="node-option-input"
            />
            {data?.obj?.headerForamt === HeaderForamt.DOCUMENT &&
                <OptionInput
                    value={data?.obj!.mediaFileName ?? ''}
                    placeholder={t("MEDIA_FILE_NAME")}
                    onChange={(value) => onChangeObj(NodeObjectKey.MEDIA_FILE_NAME, value)}
                    className="node-option-input"
                />}
            <OptionInput
                value={data?.obj!.footer ?? ''}
                placeholder={t("FOOTER")}
                onChange={(value) => onChangeObj(NodeObjectKey.FOOTER, value)}
                //maxLength={60}
                className="node-option-input"
            />
        </>
    );
}
