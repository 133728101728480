import { Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, FormLabel } from '@chakra-ui/react'
import { useFormik } from 'formik'
import React from 'react'
import FormText from 'src/components/form/text'
import { ISettings } from 'src/models'
import { SettingsForm } from './settings-form'


const settingsGroup = [
    {
        label: "Azure",
        values: [{ label: "Api Key", name: "azureKey" }, { label: "Endpoint", name: "azureEndpoint" }, {label:"Embedding Deployment Name", name:"azureEmbeddingKey"}]
    },
    {
        label: "Bedrock",
        values: [{ label: "Access Key", name: "awsAccessKeyId" }, { label: "Secret Access Key", name: "awsSecretAccessKey" }, { label: "Region", name: "awsRegion" }]
    },
    {
        label: "TogtherAI",
        values: [{ label: "Api Key", name: "togtherKey" }]
    
    }
]

function SettingsTeam({ isOpen, onClose, data }: { isOpen: boolean, onClose: (values: ISettings) => void, data: ISettings }) {

    const initialValues = new SettingsForm(data.startMessage, data.azureKey, data.azureEndpoint, data.azureEmbeddingKey, data.awsAccessKeyId, data.awsSecretAccessKey, data.awsRegion);

    const formik = useFormik({
        initialValues,
        onSubmit: (_: SettingsForm) => { }, // eslint-disable-line
        enableReinitialize: false
    });





    return (
        <Drawer isOpen={isOpen} onClose={() => onClose(formik.values)} size="lg">
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader textAlign="center">Settings</DrawerHeader>
                <DrawerBody>
                    <FormText<SettingsForm> formik={formik} name="startMessage" placeholder="Start Message" />
                    {/* <FormText<SettingsForm> formik={formik} name="AzureToken" placeholder="Azure Token" type="password"/> */}
                    <Accordion allowToggle mt={5} mb={5}>
                        {settingsGroup.map((item, index) => (
                            <AccordionItem key={index}>
                                <h2>
                                    <AccordionButton>
                                        <Box as="span" flex="1" textAlign="left">
                                            {item.label}
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>

                                {item.values.map((value, iv) => (
                                    <AccordionPanel className="config" key={iv} pb={4}>
                                            <FormLabel className="label">{value.label}</FormLabel>
                                            <FormText<SettingsForm> formik={formik} name={value.name} />
                                    </AccordionPanel>
                                ))}


                            </AccordionItem>
                        ))}

                    </Accordion>
                </DrawerBody>

            </DrawerContent>
        </Drawer>
    )
}

export default SettingsTeam