import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { useRTL } from '../../hooks/use-rtl';
import { useTranslation } from '../../hooks/use-translate';
import Icon, { IconTheme, IconVariant } from '../icon/icon';
import FormText from './text';

export default function PopUpForm({ fieldsArr, onChange, toggleClose }: {
  fieldsArr: Array<string>,
  toggleClose: () => void,
  onChange: (key: number, value: string) => void
}): ReactElement {
  const isRTL = useRTL();
  const t = useTranslation('COMPONENTS.NODE');





  return (
    <>
      {
        fieldsArr.length ? <form className={classNames(['form-popup', { 'rtl': isRTL }])}>
          <a onClick={() => toggleClose()} className={classNames(['public-menu-trigger', { 'rtl': isRTL }])}>
            <Icon variant={IconVariant.REMOVE} theme={IconTheme.GRAY} />
          </a>
          {fieldsArr.map((field: string, key: number) =>
            <FormText
              className="form-popup-field"
              key={key}
              onChange={(e) => onChange(key, e.target.value)}
              value={field}
              placeholder={t('REPLACE_VARIABLE')}
            />)}
        </form>
          : null
      }
    </>

  );
}
