import classNames from 'classnames';
import React, { ButtonHTMLAttributes, DetailedHTMLProps, ReactElement } from 'react';
import './button.scss';

export enum ButtonVariant {
  PRIMARY = 'primary',
  OUTLINE = 'outline',
  WHITE = 'white',
  BLACK = 'black',
  LINK = 'link'
}

export enum ButtonSize {
  SMALL = 'small',
  NORMAL = 'normal',
  MEDIUM = 'medium',
  LARGE = 'large'
}

export interface ButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  variant?: ButtonVariant;
  size?: ButtonSize;
  isLoading?: boolean;
  isBlock?: boolean;
  isHalfBlock?:boolean;
  isRound?: boolean;
  isIcon?: boolean;
  isHidden?: boolean;

}

export default function Button({ variant, size, isLoading, className, isBlock,isHalfBlock, isRound, isIcon, isHidden, ...restProps }: ButtonProps): ReactElement {

  return (
    <button
      className={classNames([
        'button',
        `button-${variant}`,
        `button-${size ? size : 'normal'}`,
        { 'loading': isLoading },
        { 'button-block': isBlock },
        { 'button-half-block': isHalfBlock },
        { 'button-round': isRound },
        { 'button-icon': isIcon },
        { 'button-hidden': isHidden},
        className
      ])}
      {...restProps}
    />
  );
}

