import classNames from 'classnames';
import React, { ReactElement } from 'react';
import './icon.scss';

export enum IconVariant {
  MENU = 'menu',
  CROSS = 'cross',
  LEFT = 'left',
  RIGHT = 'right',
  SEARCH = 'search',
  BACK_SMALL = 'back-small',
  DOWN = 'down',
  UP = 'up',
  CHECK = 'check',
  REST = 'rest',
  CALENDAR = 'calendar',
  USER = 'user',
  SETTING = 'setting',
  CHAT = 'chat',
  EYE = 'eye',
  BLIND = 'blind',
  TOP = 'top',
  BOTTOM = 'bottom',
  BOTTOM_SMALL = 'bottom-small',
  REST_HORIZONTAL = 'rest-horizontal',
  COPY_BIG = 'copy-big',
  REMOVE_BIG = 'remove-big',
  COPY = 'copy',
  ADD = 'add',
  REMOVE = 'remove',
  REMOVE_SMALL = 'remove-small',
  MESSAGE = 'message',
  CHAT_SMALL = 'chat-small',
  FILE = 'file',
  TRASH = 'trash',
  INFO = 'info',
  CC = 'cc',
  ADD_BIG = 'add-big',
  LOGOUT = 'logout',
  VISA = 'visa',
  GOOGLE = 'google',
  MS = 'ms',
  WHATSAPP = 'whatsapp',
  BOLD = 'bold',
  STRIKE = 'strike',
  ITALIC = 'italic',
  MONOSPACE = 'monospace',
  TEXT = 'text',
  NUMBER = 'number',
  OPTIONS = 'options',
  TASK = 'task',
  FAKE_END = 'fake-end',
  END = 'end',
  SETTING_BIG = 'setting-big',
  USER_BIG = 'user-big',
  CHAT_BIG = 'chat-big',
  CHECK_BIG = 'check-big',
  TELEGRAM_BIG = 'telegram-big',
  WHATSAPP_BIG = 'whatsapp-big',
  SIGNAL_BIG = 'signal-big',
  OFFICELY_BIG = 'officely-big',
  SHEETS_BIG = 'sheets-big',
  GOOGLE_DRIVE_BIG = 'google-drive-big',
  OUTLOOK_BIG = 'outlook-big',
  ZAPIER_BIG = 'zapier-big',
  TELEGRAM_SMALL = 'telegram-small',
  WHATSAPP_SMALL = 'whatsapp-small',
  SIGNAL_SMALL = 'signal-small',
  OFFICELY_SMALL = 'officely-small',
  CHECKBOX = 'checkbox',
  NIGHT = 'night',
  LIGHT = 'light',
  PLAY = 'play',
  WEBHOOK = 'webhook',
  VALID_MAIL = 'valid-mail',
  VALID_DATE = 'valid-date',
  MEDIA = 'media',
  LOCATION = 'location',
  SEND_LOCATION = 'send_location',
  SETTING_SMALL = 'setting-small',
  MARKET = 'market',
  API = 'api',
  DISKET = 'disket',
  QUESTION_MARK = 'question-mark',
  SAVED = 'saved',
  BILING = 'biling',
  PROFILE = 'profile',
  PUBLISH = 'publish',
  ADD_SMALL = 'add-small',
  SUPPLIERS = 'suppliers',
  ZAPIER = 'zapier',
  TTEXT = 'Ttext',
  THEME_COLOR ='theme-color',
  HOME = 'home', 
  HUMAN = 'human',
  EXTRNAL_LINK = 'extrnal-link',
  POWER_WORDS = 'power-words',
  JUMP = 'jump',
  BUTTONS = 'buttons',
  SEARCH_SMALL = 'search-small',
  RECEIVING_MEDIA = 'receiving-media',
  WA_LIST = 'wa-list',
  TEMPLATES = 'templates',
  WA_LIST_BUTTON = 'wa-list-button',
  NOTIFICATION = 'notification',
  BUTTTON_SEND = 'button-send',
  START = 'start',
  MINIMIZE_APPLE = 'minimize-apple',
  MAKE = 'make',
  CHAT_BUILDER = 'chat-builder',
  INTEGRETION = 'integration',
  CARD = 'card',
  OPEN_AI = "open-ai",
  FILTER = "filter",
  VARIABELS = "variabels",
  DASHBOARD = "dashboard",
  OPEN_AI_COLOR = "open-ai-color",
  ANTHROPIC = "anthropic",
  LLAMA = "llama",
}

export enum IconTheme {
  DEFAULT = 'default',
  WHITE = 'white',
  BLACK = 'black',
  PRIMARY = 'primary',
  GRAY = 'gray'
}

export enum SrcIcon {
  FREE = `https://officely-icons.s3.eu-west-1.amazonaws.com/Free.png`,
  AFFILIATE = `https://officely-icons.s3.eu-west-1.amazonaws.com/Affiliate.png`,
  ENTERPRISE = `https://officely-icons.s3.eu-west-1.amazonaws.com/Enterprise.png`,
  JUNIOR = `https://officely-icons.s3.eu-west-1.amazonaws.com/junior.png`,
  PRO = `https://officely-icons.s3.eu-west-1.amazonaws.com/pro.png`,

}

export default function Icon({ variant, theme, tooltip, fixed, dir }: 
  { variant: IconVariant; theme?: IconTheme; tooltip?: string, fixed?:boolean, dir?:string }): ReactElement {
  return (
    <span className={classNames([{ tooltip, fixed }])}>
      <span dir={dir}
        className={classNames(['icon', `icon-${variant}`, { 'icon-mask': !!theme }, { [`icon-${theme}`]: !!theme }])}
      />
      <span className="tooltiptext tablet-desktop">{tooltip}</span>
    </span>
  );
}

