import { Card, CardHeader, CardBody, CardFooter, Avatar } from '@chakra-ui/react';
import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react'
import { SrcIcon } from '../../components/icon/icon';
import StatusChatBot from '../../components/status-chat-bot/status-chat-bot';
import { PathName, Plan } from '../../enums/enums';
import Button, { ButtonVariant } from '../../components/button/button';
import { ArrowForwardIcon, ArrowBackIcon } from '@chakra-ui/icons'
import { useNavigate, useParams } from 'react-router-dom';
import { User } from '../../models';
import { useDispatch, useSelector } from 'react-redux';
import { AuthSelectors, AuthActions } from '../../redux/auth';
import { Builder } from '../../redux/chat-builder';
import { GetDashboard } from '../../helpers/fetch/fetch-user';
import { useTranslation } from '../../hooks/use-translate';
import { Agent } from '../../redux/agents';
import { useRTL } from '../../hooks/use-rtl';
import "./dashboard-page.scss";



function DashboardPage() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    let { uphoneNumber } = useParams<{ uphoneNumber?: string }>();
    const isRTL = useRTL()
    const user: User = useSelector(AuthSelectors.user)
    const officelyUser = useSelector(AuthSelectors.officelyUser);
    const t = useTranslation("PAGES.DASHBOARD");
    const [arrDashBoard, setArrDashBoard] = React.useState<any[]>([]);
    let isMounted = true


    useEffect(() => {
        const fetchApi = async (): Promise<void> => {
            const token = officelyUser ? officelyUser  : user.token!
            uphoneNumber = uphoneNumber ? uphoneNumber : user.uphone_number
            const res = await GetDashboard(token as string, uphoneNumber)
            if (res.success && res.data) {
                const resUser = res.data['user'] as User
                dispatch(AuthActions.setUser({ user: resUser }))
                if (resUser.plan === Plan.FREE){
                    navigate(PathName.AGENTS)
                    return
                }
                mapData(res.data['dashboard'])
            }
        }
        if (isMounted) {
            document.title = t("TITLE")
            fetchApi()
        }

        return () => {
            isMounted = false
        }
    }, [])

    function padTo2Digits(num: number) {
        return num.toString().padStart(2, "0");
    }

    const formatDate = (date: Date) => [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear()
    ].join("/");

    const GetPlanDeatils = () => {
        switch (user.plan) {
            case Plan.FREE:
                return {src: SrcIcon.FREE, title:"Free", subtitle: "Try out our features in demo mode"}
            case Plan.BASIC:
                return {src: SrcIcon.JUNIOR, title:"Basic", subtitle: "Scale up your website with junior plan"}
            case Plan.PREMIUM:
                return {src: SrcIcon.PRO, title:"Pro", subtitle: "Scale up your Automation with Pro plan"}
            case Plan.VIP:
                return {src: SrcIcon.ENTERPRISE, title:"Enterprise",  subtitle: "VIP its VIP :) We are here for you :)"}
            case Plan.PARTNER:
                return {src: SrcIcon.AFFILIATE, title:"Partner", subtitle: "We love partners so no charge here :)"}
        }
    }

    const mapData = useCallback((data: any) => {
        const agents = data['agents'].map((agent: Agent) => ({
            ...agent,
            icon: <Avatar size="md" src={agent.img} />
        }))
        const builders = data['builders'].map((builder: Builder) => ({
            ...builder,
            icon: <StatusChatBot isActive={builder.status} />,
            subtitle: isRTL ? formatDate(new Date(builder.last_updated!)) : new Date(builder.last_updated!).toLocaleDateString()
        }))
        const planDeatils = GetPlanDeatils()
        const plan = [{
            title: planDeatils.title,
            subtitle: planDeatils.subtitle,
            icon: <Avatar size="lg" src={planDeatils.src}/>
        }]
        setArrDashBoard([
            { id: "BUILDERS", items: builders, navigate: PathName.BUILDERS },
            { id: "AGENTS", items: agents, navigate: PathName.AGENTS },
            { id: "PLAN", items: plan, navigate: PathName.SUBSCRIPTIONS }
        ])


    }, [isRTL])



    return (
        <div className="page ">
            <header className="page-header min-height">
                <div className="page-header-left">

                </div>
                <div className="page-header-right">

                </div>
            </header>
            <div className="page-content">
                <div className="dashboard" >
                    {
                        arrDashBoard.map((card, ci) => <Card
                            size="lg"
                            key={ci}
                            className={classNames(["dashboard-card", { plan: card.id === "PLAN" }])}
                        >
                            <CardHeader className="dashboard-card-header" dir="ltr">
                                <span className={classNames(["dashboard-card-header-title", { rtl: isRTL }])}>{t(card.id)}</span>
                            </CardHeader>
                            <CardBody className="dashboard-card-body">
                                {card.items.length ? card.items.map((item: any, i: number) => <div className="dashboard-card-body-item" key={i}>
                                    <span className="dashboard-card-body-item-icon">{item.icon}</span>
                                    <div className="dashboard-card-body-item-content">
                                        <span className="dashboard-card-body-item-content-title">{item.title}</span>
                                        <span className="dashboard-card-body-item-content-subtitle">{item.subtitle}</span>
                                    </div>
                                </div>)
                                    :
                                    t("NO_DATA")
                                }


                            </CardBody>
                            <CardFooter className="dashboard-card-footer" >
                                <Button className={classNames({ rtl: isRTL })} variant={ButtonVariant.LINK} onClick={() => navigate(card.navigate)}>
                                    {card.id === "PLAN" ? t("VIEW_PLANS") : card.items.length ? t("VIEW_ALL") : t("CREATE")}
                                    {isRTL ? <ArrowBackIcon/> : <ArrowForwardIcon/> }

                                </Button>

                            </CardFooter>
                        </Card>)
                    }
                </div>
            </div>
        </div>
    )
}




export default React.memo(DashboardPage);
