import { ChatbotNodeData } from '../../models';
import { action, actionWithPayload } from '../action-factory';
import { Edge, Node } from 'reactflow';
import { NodeObjectKey, NodeType } from '../../enums/enums';
import { WAListAction } from '../../components/node/whatsapp-list-node';
import { FilterAction } from '../../components/node/filter_node';
import { Builder, ConfigurationBuilderType, Language } from './interfaces';
//import { PluginActionType, PluginAIType} from '../../components/node/plugin_agent';




export class ChatBuilderActions {

  public static setCurrentBuilder = actionWithPayload<{ element: Builder }>(
    '[ChatBuilder] Set current Builder'
  )

  public static setCurrentBuilderNull = action(
    '[ChatBuilder] Set current Builder Null'
  );

  public static setCurrentBuilderActive = action(
    '[ChatBuilder] Set current Builder Active'
  );

  // public static setChatScheme = actionWithPayload<{ element: Array<Node<ChatbotNodeData> | Edge> }>(
  //   '[ChatBuilder] Set chat scheme'
  // );

  public static addElement = actionWithPayload<{ element: Node<ChatbotNodeData> | Edge }>(
    '[ChatBuilder] Add element'
  );

  public static addElements = actionWithPayload<{ elements: Array<Node<ChatbotNodeData> | Edge> }>(
    '[ChatBuilder] Add elements'
  );

  public static setElement = actionWithPayload<{ element: Node<ChatbotNodeData> | Edge }>(
    '[ChatBuilder] Set element'
  );

  public static removeElement = actionWithPayload<{ id: string }>(
    '[ChatBuilder] Remove element'
  );

  public static toggleSelectNode = actionWithPayload<{ id: string, isSelected?: boolean }>(
    '[ChatBuilder] Toggle select node'
  );

  public static unselectAllNodes = action(
    '[ChatBuilder] Unselect all nodes'
  );

  public static setActiveSource = actionWithPayload<{ id: string | null }>(
    '[ChatBuilder] Set active source'
  );

  public static changeNodesText = actionWithPayload<{ id: string, text: string }>(
    '[ChatBuilder] Change nodes text'
  );

  public static addNewOption = actionWithPayload<{ id: string, optionID: string }>(
    '[ChatBuilder] Add new Option'
  );

  public static SwitchBetweenNodeAndOpionEdge = actionWithPayload<{ id: string, optionID: string, optionsIsEmpty: boolean }>(
    '[ChatBuilder] Switch between node and option edge'
  );

  public static changeOption = actionWithPayload<{ id: string, optionID: string, value: string; }>(
    '[ChatBuilder] Change option'
  );

  public static changeOptionChecked = actionWithPayload<{ id: string, optionID: string, checked: boolean }>(
    '[ChatBuilder] Change option checked'
  );

  public static removeOption = actionWithPayload<{ id: string, optionID: string }>(
    '[ChatBuilder] Remove option'
  );

  public static changeObj = actionWithPayload<{ id: string, key: NodeObjectKey, value: any; value2?: any }>(
    '[ChatBuilder] Change Object'
  );

  public static changeAnimated = actionWithPayload<{ edgeID: string, remove?:boolean }>(
    '[ChatBuilder] Change Animated'
  );

  public static changeNodeName = actionWithPayload<{ id: string, newValue: string }>(
    '[ChatBuilder] Change Node Name'
  );

  public static changeBuilderName = actionWithPayload<{ newBuilderName: string }>(
    '[ChatBuilder] Change Chat Bot Title'
  );



  public static onUpdateNodeTemplate = actionWithPayload<{ id: string, key: string, index: number, value: string }>(
    '[ChatBuilder] On Update Node Template'
  );

  public static onChangeWAList = actionWithPayload<{ nodeID: string, key: WAListAction, sectionIndex?: number, value?: string, rowID?: string }>(
    '[ChatBuilder] On Change WA List'
  );


  public static unauthorize = action(
    '[ChatBuilder] unauthorize'
  );

  public static onError = actionWithPayload<{ id: string, bool: boolean, type?: NodeType }>(
    '[ChatBuilder] on Error'
  );

  public static onChangeBuilderSettings = actionWithPayload<{ key:ConfigurationBuilderType, value:string }>(
    '[ChatBuilder] On Change Builder Setting'
  );

  public static onChangeBuilderLanguage = actionWithPayload<{ language:Language }>(
    '[ChatBuilder] On Change Builder Language'
  );

  public static UpdateChatChema = actionWithPayload<{ newChatScheme: Array<Node<ChatbotNodeData> | Edge> | null }>(
    '[ChatBuilder] Update Chat Scheme'
  );

  public static ToggleSave = actionWithPayload<{ isSaved: boolean }>(
    '[ChatBuilder] Toggle Save'
  );

  public static ToggleNodeDraggable = actionWithPayload<{id:string, bool:boolean }>(
    '[ChatBuilder] Toggle Node Draggable'
  );

  public static onChangeFilterNode = actionWithPayload<{ nodeID: string, key: FilterAction, groupID?:string, conditionIndex?:number, val?:any }>(
    '[ChatBuilder] On Change Filter Node'
  );

  // public static onSaveVariables = actionWithPayload<{ variables:Variables[] }>(
  //   '[ChatBuilder] On Save Variables'
  // );

  public static onSelectSearchNode = actionWithPayload<{id:string}>(
    '[ChatBuilder] On Select Search Node'
  );



  // public static onChangePluginAI = actionWithPayload<{nodeID:string, action:PluginActionType, value: PluginAIType | string | boolean }>(
  //   '[ChatBuilder] On Change Plugin AI'
  // );


}
