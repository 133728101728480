import classNames from 'classnames';
import React, { ReactElement, ReactNode, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import './dropdown.scss';

interface Props {
  items: Array<{ node: ReactNode; key: string, filter?:string | number;}>;
  children: ReactNode;
  allowScrolling?: boolean;
  isRight?: boolean;
  isCustomContent?: boolean;
  className?: string;
}

export default function Dropdown({ items, children, isRight, allowScrolling, isCustomContent, className }: Props): ReactElement {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);


  const toggleDropdown = (e:React.MouseEvent): void => {
    e.stopPropagation()
    setIsDropdownOpen(!isDropdownOpen);
  }


  const ref = useOnclickOutside(() => {
    closeDropdown()
  });

  const closeDropdown = (): void => {
    setIsDropdownOpen(false);
  };

  return (

     <div ref={ref} 
      className={classNames('dropdown', className, {
        'dropdown-open': isDropdownOpen,
        'dropdown-right': isRight
      })}
     
      >
      <a onClick={toggleDropdown} className="dropdown-trigger" >
        {children}
      </a>
      <div onClick={closeDropdown} 
        className={classNames('dropdown-content', { 'dropdown-content-no-background': isCustomContent })} >
        <div 
          className={classNames('', {
            'dropdown-scroll': allowScrolling
          })}>
          <div className={classNames('dropdown-items', { 'dropdown-items-list': isCustomContent })}>
            {items.map(({ node, key }) => (isCustomContent)
              ? <div key={key} className="dropdown-item-wrapper"  >{node}</div>
              : <div className="dropdown-item" key={key}>
                {node}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}




