import React, { ReactElement, useEffect } from "react";
import { useTranslation } from "../../hooks/use-translate";
import './terms_privacy.scss';


export function TermsPage(): ReactElement {
    const t = useTranslation('PAGES.PRIVACY_TERMS');

    useEffect(() => {
        document.title = t("TITLE")
    },[])

    return (
        <div className="terms_privacy">
            <iframe src="https://app.termly.io/document/terms-of-use-for-saas/27f4fd3e-2947-4276-a719-c8fd1acbe2ce"
            width="100%"
            height="100%"/>
        </div>
    );
}
