import classNames from 'classnames';
import React, { ReactElement, ReactNode, MouseEvent, TouchEvent, useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NodeType } from '../../enums/enums';
import { useCopyElements } from '../../hooks/use-copy-elements';
import { ChatbotNodeData } from '../../models/chat-bot-node';
import { ChatBuilderSelectors, ChatBuilderActions } from '../../redux/chat-builder';
import NodeLabel from './node-label';
import './node.scss';
import NodeInput from './node-input';
import { isNode } from 'reactflow';
import { ChatBotActionsActions } from '../../redux/chatbot-actions/actions';
import { GetHighLightElement } from '../../helpers/wrokflow-builder/highlight-search-text';
import { ChatbotActionsSelectors } from '../../redux/chatbot-actions/selectors';
import ExtraFields from './extra_fields/extra-fields';
import { ChatBotActions, useChatBotFunctionActions, useCheckMap } from '../../hooks/user-chatbot-actions';
import useUndoRedo from '../../hooks/use-undo';
import { DeleteIcon } from '@chakra-ui/icons';
import { Menu, MenuList, MenuItem, Icon as CIcon } from '@chakra-ui/react';
import { IoDuplicateOutline, IoPlayOutline } from 'react-icons/io5';
import { useRTL } from '../../hooks/use-rtl';
import { useTranslation } from '../../hooks/use-translate';
import { Alert, AlertActions } from '../../helpers/dialog';


function Node({ data, type, children, className }: {
  data: ChatbotNodeData;
  type: NodeType;
  children: ReactNode;
  className?: string;
}): ReactElement {
  const dispatch = useDispatch();
  const t = useTranslation('COMPONENTS.NODE');
  const tSwal = useTranslation('COMPONENTS.SWAL')
  const copyElements = useCopyElements();
  const elements = useSelector(ChatBuilderSelectors.chatScheme);
  const nodes = useSelector(ChatBuilderSelectors.nodes);
  const searchValue = useSelector(ChatbotActionsSelectors.inputSearchValue)
  const selectedNodes = useSelector(ChatBuilderSelectors.selectedNodes);
  const selectedEdges = useSelector(ChatBuilderSelectors.edgesRelatedWithSelectedNodes);
  const [contextMenuNode, setContextMenuNode] = useState(false)
  const ChatBotFunctionAction = useChatBotFunctionActions()
  const onCheckMap = useCheckMap()
  const { takeSnapshot } = useUndoRedo();
  const isRTL = useRTL()
  const ref = useRef<HTMLDivElement>(null)
  const [position, setPosition] = useState<[number, number]>([0, 0]);
  const [dialogActions, setDialogActions] = useState<AlertActions | null>(null)
  const [isOpenDialog, setIsOpenDialog] = useState(false)
  // const [isOpenEditErrorHandler, setIsOpenEditErrorHandler] = useState<string | null>(null)







  useEffect(() => {
    takeSnapshot()
  }, [data, takeSnapshot])


  const onChangeText = (value: string): void => {
    dispatch(ChatBuilderActions.changeNodesText({ id: data.id, text: value }));
  };

  const onRemove = (): void => {
    dispatch(ChatBuilderActions.removeElement({ id: data.id }));
    CloseContextMenu()
  };

  const onPreview = async (): Promise<void> => {
    onCheckMap()
    if (await ChatBotFunctionAction(ChatBotActions.PREVIEW)) {
      dispatch(ChatBotActionsActions.changePreviewNode({ id: data.id }));
      CloseContextMenu()
    } else {
      setDialogActions({
        title: tSwal("ERROR"),
        content: tSwal("ERROR_FETCH"),
        confirm: tSwal("OK"),
        colorButton: "primary",
        isMessage: true
      })
      setIsOpenDialog(true)
    }

  }

  const onDuplicate = (): void => {
    let copiedElements;
    //const copiedElements = copyElements(selectedNodes, selectedEdges)
    if (selectedNodes.length) {
      copiedElements = copyElements(selectedNodes, selectedEdges)
    } else {
      const node = nodes.filter((node) => node.data.id === data.id) ?? []
      copiedElements = copyElements(node, []);
    }
    dispatch(ChatBuilderActions.unselectAllNodes());
    dispatch(ChatBuilderActions.addElements({ elements: copiedElements }));
    CloseContextMenu()
  };

  const onToggleSelect = (event: MouseEvent | TouchEvent): void => {
    if (!(event.ctrlKey || event.metaKey)) {
      dispatch(ChatBuilderActions.unselectAllNodes());
    }
    dispatch(ChatBuilderActions.toggleSelectNode({ id: data.id }));
    CloseContextMenu()

  };

  useEffect(() => {
    const handleClick = (event: any) => {
      if ((ref.current && !ref.current.contains(event.target))) {
        CloseContextMenu()
      }
    };
    document.addEventListener("click", handleClick);
    document.addEventListener("contextmenu", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("contextmenu", handleClick);
    };
  }, [ref]);



  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const res = /^[a-zA-Z0-9]+$/.test(e.target.value) || e.target.value.length === 0;
    res && dispatch(ChatBuilderActions.changeNodeName({ id: data.id, newValue: e.target.value }))
  }

  const onDuplicateName = (): void => {
    const count = elements.filter(isNode).filter((node) => node.data.name === data.name).length
    if (count > 1) {
      dispatch(ChatBuilderActions.changeNodeName({ id: data.id, newValue: `X${data.name}X` }))
    }
  }

  const onContextMenuNode = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.preventDefault()
    if (type !== NodeType.START) {
      const targetRect = (event.target as HTMLDivElement).getBoundingClientRect();
      setPosition([event.clientX - targetRect.x, event.clientY - targetRect.y]);
      setContextMenuNode(true)
    }
  }

  const CloseContextMenu = (): void => {
    setContextMenuNode(false)
  }



  const highLightCallBack = useCallback((name: string) =>
    GetHighLightElement(searchValue, name, <input value={name} onChange={onChangeName} onBlur={onDuplicateName} />),
    [searchValue, data.name])



  const showInput = (data.isSelected || data.text !== '') &&
    data.obj?.type !== NodeType.START &&
    type !== NodeType.POWER_WORDS &&
    type !== NodeType.JUMP &&
    type !== NodeType.TEMPLATES &&
    type !== NodeType.SEND_LOCATION &&
    type !== NodeType.WEBHOOK &&
    type !== NodeType.FILTER
  //type !== NodeType.PLUGIN_AGENT

  const notAllowdPreview = [NodeType.API, NodeType.POWER_WORDS].includes(type)

  return (
    <>
      <div onContextMenu={onContextMenuNode} ref={ref}
        className={classNames('node', className, { 'node-selected': data.isSelected, search: data.isSelect })}>
        <a onClick={onToggleSelect} onTouchStart={onToggleSelect} className="node-cover" />
        <header className="node-header">
          <div className="node-name">
            {(data.name || data.name === '') && <span>
              <label>@</label>
              {highLightCallBack(data.name)}
            </span>}
          </div>
          <NodeLabel type={type} />
        </header>
        {data.isSelected && <ExtraFields data={data} type={type} />}
        {showInput &&
          <NodeInput
            value={data.text}
            onChange={onChangeText}
            isSelected={!!data.isSelected}
            maxLength={type === NodeType.BUTTONS || type === NodeType.WHATSAPP_LIST ? 1024 : undefined}
          />}
        {children}
        <Menu isOpen={contextMenuNode} matchWidth>
          <MenuList
            style={{ position: 'absolute', left: position[0], top: position[1] }}
            zIndex={5}
            className={classNames({ rtl: isRTL })}
            minWidth={130}
          >
            <MenuItem icon={<CIcon as={IoPlayOutline} boxSize={5} />}
              iconSpacing={2}
              onClick={onPreview}
              hidden={notAllowdPreview}>
              {t("PREVIEW")}
            </MenuItem>
            <MenuItem
              icon={<CIcon as={IoDuplicateOutline} boxSize={5} />}
              onClick={onDuplicate}
              hidden={!contextMenuNode}
            >

              {t("DUPLICATE")}
            </MenuItem>
            <MenuItem icon={<CIcon as={DeleteIcon} boxSize={5} />}
              onClick={onRemove}
              hidden={!contextMenuNode}
            >
              {t("REMOVE")}
            </MenuItem>
          </MenuList>
        </Menu>
        {isOpenDialog && <Alert
          isOpen={isOpenDialog}
          onClose={() => setIsOpenDialog(false)}
          actions={dialogActions!}
        />}
      </div>
    </>
  );
}



export default Node

