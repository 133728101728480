import React, { useEffect, useState, useRef } from 'react'
import { WidgetHeader } from '../../../components/officely-web-componnents'
import WidgetContent from '../../../components/officely-web-componnents/widget-content'
import { Chat } from '../interfaces'
import WidgetTemp, { WidgetMode } from '../widget-temp'
import { useTranslation } from '../../../hooks/use-translate'
import { useParams } from 'react-router-dom'
import { useFetchOfficely } from 'src/hooks/use-fetch-officely'
import { Method, Plan } from 'src/enums/enums'
import { AgentService } from 'src/helpers/fetch/fetch-agent'


interface AgentDeatils {
  style: string;
  img?: string;
  thread_id?: string;
  plan:Plan;
}

export default function LocalChat() {
  const agentService = new AgentService()
  const { codeAIParam } = useParams<{ codeAIParam: string}>();
  const t = useTranslation('PAGES.WIDGET');
  const [answer, setAnswer] = useState<string | null>()
  const [loading, setLoading] = useState<boolean>()
  const [chatHistory, setChatHistory] = useState<Array<Chat>>([])
  const isPreview = false
  const [chatTextRef, setChatTextRef] = useState(false);
  const [agentDeatils, setAgentDeatils] = useState<AgentDeatils>();
  const isMounted = useRef(true);
  const abortControllerRef = useRef(new AbortController());
  const [IP, setIP] = useState('')

  const isProUser = ![Plan.FREE, Plan.BASIC].includes(agentDeatils?.plan ?? Plan.FREE)


  useEffect(() => {
    const fetchApi = async (): Promise<void> => {
      const resIP = await (await fetch('https://geolocation-db.com/json/')).json();
      setIP(resIP.IPv4)
      const res = await agentService.GetAgentDeatilsByID(codeAIParam ?? '')
      const text = ""
      const body: any = { codeAIParam, query: text, preview: isPreview, IP: resIP.IPv4 }
      if (res.success && res.data) {
        setAgentDeatils(res.data)
        if (res.data.thread_id) {
          body['thread_id'] = res.data.thread_id;
        }
      }


      await GetQuestion(body);
      document.title = t("TITLE")
    };

    if (isMounted.current) {
      setChatHistory([])
      fetchApi();
    }
    return () => {
      isMounted.current = false;
      abortControllerRef.current.abort();

    };

  }, []);


  const GetQuestion = async (body: any) => {
    setAnswer(null);
    setLoading(true)
    handleStream(body)
    // if (body?.thread_id) {
    //   //handleQuestion(body, "ai_assistant")
    //   handleStream(body, "ai_chat")
    // } else {
    //   handleStream(body, "ai_chat")

    // }
  }


  // useEffect(() => {
  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   }
  // }, [chatTextRef]);


  // const handleBeforeUnload = () => {
  //   if (!isPreview && chatHistory.length > 1) {
  //     const data = JSON.stringify({ history: chatHistory, codeAIParam });
  //     // Fallback to fetch with keeplive
  //     fetch('https://hook.eu1.make.com/swe3y6879j4rgsoew6ubwcq1nayrtmya', {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json;charset=UTF-8' },
  //       body: data,
  //       keepalive: true  // This is important for the request to continue after page unload
  //     })
  //   }

  // };





  const handleStream = async (body: any) => {
    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();


    try {
      const response = await fetch(`http://127.0.0.1:5001/chat`, {
        signal: abortControllerRef.current.signal,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      });
      if (response.body === null) { throw new Error("Error"); }
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      setLoading(false)
      setChatTextRef(true)
      const bool = true
      while (bool) {
        const { done, value } = await reader.read();
        if (done) {  break }
        const messages = decoder.decode(value, { stream: true }).split('\n').filter(Boolean);  // Split by newline and remove any empty strings
        messages?.forEach((messageStr) => {
          try {
            const message = JSON.parse(messageStr);
            // if (message.context){
            //   console.log("CONTEXT:", message.context + "\n\n")
            // }
            // else if (message.standalone_question){
            //   console.log("QUESTION:", message.standalone_question + "\n\n")
            // }
            // else if (message.docs){
            //   console.log("DOCS:", message.docs + "\n\n")
            // }
            if (message.data){
                setChatHistory(message.data)
            }
          } catch (parseError) {
            const errorMessage = `Error occurred in parser: ${parseError} from ${codeAIParam}`;
            console.error(errorMessage); //eslint-disable-line


          }
        })
      }
    } catch (err) {
      if (err instanceof Error && err.name !== 'AbortError') {
        console.error(err.message); //eslint-disable-line
        const errorMessage = `Error occurred: ${err} from ${codeAIParam}`;
        useFetchOfficely(`logMessage`, Method.POST, { "message": errorMessage })
      }
    }
    finally {
      loading && setLoading(false)
      setChatTextRef(false)
    }
};







  const handleSubmit = () => {
    const query = answer?.trim()
    if (!query || chatTextRef || loading) return;

    const tempChat: Array<Chat> = [...chatHistory, {
      type: "inbound",
      text: query ?? ''
    }];
    setChatHistory(tempChat);
    const body: any = { codeAIParam, query, chatHistory: tempChat, preview: isPreview, IP };
    if (agentDeatils?.thread_id) {
      body['thread_id'] = agentDeatils?.thread_id;
    }

    GetQuestion(body);
  }


  // const stopGenerate = () => {
  //   setLoading(false)
  //   generateRef.current = false
  // }



  const notActive = chatHistory[0]?.noActive




  return (
    <WidgetTemp className="AI">
     {(isProUser || isPreview) && <style type="text/css">
        {agentDeatils?.style}
      </style>}
      <WidgetHeader
        isChat
        mode={WidgetMode.AI}
      />
      <WidgetContent
        isChat
        chatHistory={chatHistory}
        showInput={!notActive}
        // showPowerBy={!isProUser}
        showPowerBy
        answerState={{ answer, setAnswer }}
        loadingState={{ loading, setLoading }}
        chatTextRef={chatTextRef}
        handleSubmit={handleSubmit}
        img={agentDeatils?.img}

      >
      </WidgetContent>

    </WidgetTemp>
  )
}


