import { useTranslation as i18UseTranslation } from 'react-i18next';
import { TFunction } from 'i18next';



// export function useTranslation(pathKey: string): TFunction {
//   const { t } = i18UseTranslation();
//   return (key: string, options?:any) => t(`${pathKey!}.${key}`, options);
// }

export function useTranslation(pathKey: string): TFunction {
  const { t } = i18UseTranslation();
  return (key: string | string[], options?:any) => t(`${pathKey}.${[key].join(".")}`, options);
}


