import { TFunction } from 'i18next';
import React, { ReactElement, useState } from 'react';
import { NodeObjectKey } from '../../../enums/enums';
import { ChatbotNodeData } from '../../../models';
import OptionInput from '../../form/option-input';
import FormText from '../../form/text';
import NodeInput from '../node-input';




export default function ExtraNotification({ data, t, onChangeObj }: {
    data: ChatbotNodeData,
    t: TFunction,
    onChangeObj: (key: NodeObjectKey, value: string) => void
}): ReactElement {

    const [state, setState] = useState(false)


    const onChangeText = (value: string): void => {
        onChangeObj(NodeObjectKey.MESSAGE_NOTIFICATION, value)
    };

    const onFocusText = (): void => {
        setState(true)
    }

    const onBlurText = (): void => {
        setState(false)
    }

    return (
        <>
            {
                state ?
                    <NodeInput
                        value={data?.obj!.messageNotification ?? ''}
                        onChange={onChangeText}
                        isSelected={!!data.isSelected}

                    />
                    :
                    <FormText
                        onFocus={onFocusText}
                        onBlur={onBlurText}
                        value={data?.obj!.messageNotification}
                        placeholder={t('MESSGAE_NOTIFICATION')}
                        className="node-option-input"
                        readOnly={true}
                    />
            }
            <OptionInput
                value={data?.obj!.phoneNotification ?? ''}
                placeholder={t('PHONE_NOTIFICATION')}
                onChange={(value) =>
                    onChangeObj(NodeObjectKey.PHONE_NOTIFICATION, value)}
                className="node-option-input"
            />
        </>
    );
}
