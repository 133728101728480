import React, { ReactElement, useState, useEffect } from 'react';
import Button, { ButtonVariant } from '../button/button';
import { useTranslation } from '../../hooks/use-translate';
import { useDispatch, useSelector } from 'react-redux';
import { ChatBuilderActions, ConfigurationBuilderType } from '../../redux/chat-builder';
import { Plan } from '../../enums/enums';
import { useRTL } from '../../hooks/use-rtl';
import classNames from 'classnames';
import SearchNode from '../search-node/search-node';
import { useChatBotFunctionActions, ChatBotActions, useCheckMap } from '../../hooks/user-chatbot-actions';
import { ChatBotActionsActions } from '../../redux/chatbot-actions';
import { SearchIcon } from '@chakra-ui/icons';
import PublishPopUp from './publish-popup';
import { Alert, AlertActions } from '../../helpers/dialog';
import { AuthSelectors } from '../../redux/auth';
import { useNavigate } from 'react-router-dom';
import SettingsBuilder from '../chat-builder/settings-builder';








function ChatbotActions({ isActive, togglePreview, setOpenSetting }: {
  isActive: boolean,
  togglePreview: () => void
  setOpenSetting: (type: ConfigurationBuilderType) => void
}): ReactElement {

  const t = useTranslation('COMPONENTS.PAGES.CHATBOT');
  const tSwal = useTranslation('COMPONENTS.SWAL')
  const dispatch = useDispatch();
  const isRTL = useRTL();
  const navigate = useNavigate();
  //const isFreeBasic = useSelector(AuthSelectors.isFreeBasic);
  const user = useSelector(AuthSelectors.user);
  //const nodes = useSelector(ChatBuilderSelectors.nodes);
  const ChatBotFunctionAction = useChatBotFunctionActions()
  const onCheckMap = useCheckMap()
  const [loadingEye, setLoadingEye] = useState(false)
  const [loadingPublish, setLoadingPublish] = useState(false)
  const [publishPopup, SetpublishPopup] = useState(false)
  const [searchMode, setSearchMode] = useState(false)
  const [dialogActions, setDialogActions] = useState<AlertActions | null>(null)
  const [isOpenDialog, setIsOpenDialog] = useState(false)





  const OpenErrorDialiog = (actions: AlertActions) => {
    setDialogActions({ ...actions })
    setIsOpenDialog(true)
  }

  const HandleKeyDown = (e: KeyboardEvent): void => {
    if ((e.ctrlKey || e.metaKey) && e.keyCode === 70) {
      e.preventDefault();
      setSearchMode(true)
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", HandleKeyDown)
    return () => {
      window.removeEventListener("keydown", HandleKeyDown)
    }
  }, [searchMode])



  const CheckMap = (isPreview = false): boolean => {
    // if (isFreeBasic && !isPreview) {
    //   const notAllowd = [NodeType.API, NodeType.WEBHOOK, NodeType.PLUGIN_AGENT, NodeType.FILTER,
    //   NodeType.NOTIFICATION, NodeType.BUTTONS, NodeType.WHATSAPP_LIST, NodeType.TEMPLATES]
    //   const founds = nodes.filter((x) => notAllowd.includes(x.type as NodeType))
    //   if (founds.length) {
    //     founds.forEach((item) => { dispatch(ChatBuilderActions.onError({ id: item.id, bool: true })) })
    //     OpenErrorDialiog({
    //       title: tSwal('ERROR_UPGRADE'),
    //       content: tSwal('ERR_NODES_UPGRADE'),
    //       confirm: tSwal('TO_UPGRADE'),
    //       cancel: tSwal("CANCEL"),
    //       onConfirm: () => navigate("/subscriptions"),
    //       colorButton: "primary"
    //     })
    //     return false
    //   }
    // }
    const mapStatus = onCheckMap()
    if (!mapStatus && !isPreview) {
      OpenErrorDialiog({
        title: tSwal('CONNECT_ALL'),
        content: tSwal('ERROR_SAVE'),
        confirm: tSwal('OK'),
        colorButton: "primary",
        isMessage: true
      })
    }
    return mapStatus
  }

  const onPublishBuilder = async (): Promise<void> => {
    setLoadingPublish(true)
    if (user.plan === Plan.FREE) {
      OpenErrorDialiog({
        title: tSwal("ERROR_UPGRADE"),
        content: tSwal("ERR_PUBLISH_UPGRADE"),
        confirm: tSwal("TO_UPGRADE"),
        onConfirm: () => navigate("/subscriptions"),
        cancel: tSwal("CANCEL"),
        colorButton: "primary"
      })
      setLoadingPublish(false)
      return

    }
    if (!CheckMap()) {
      setLoadingPublish(false)
      return
    }
    if (await ChatBotFunctionAction(ChatBotActions.PUBLISH, () => { SetpublishPopup(true) })) {
      dispatch(ChatBuilderActions.ToggleSave({ isSaved: true }))
    } else {
      OpenErrorDialiog({
        title: tSwal("ERROR"),
        content: tSwal("ERROR_FETCH"),
        confirm: tSwal("OK"),
        colorButton: "primary",
        isMessage: true
      })
    }

    setLoadingPublish(false)
  }

  const onPreviewAction = async (): Promise<void> => {
    setLoadingEye(true)
    CheckMap(true)
    dispatch(ChatBotActionsActions.setPreviewNodeNull());
    if (!await ChatBotFunctionAction(ChatBotActions.PREVIEW, togglePreview)) {
      OpenErrorDialiog({
        title: tSwal("ERROR"),
        content: tSwal("ERROR_FETCH"),
        confirm: tSwal("OK"),
        colorButton: "primary",
        isMessage: true
      })
    }
    setLoadingEye(false)

  }




  return (
    <div className="content">
      <SettingsBuilder
        setOpenSetting={(type) => { setOpenSetting(type) }}
      />
      <Button isIcon className="page-action"
        onClick={() => setSearchMode(!searchMode)}>
        <SearchIcon />
      </Button>
      <Button variant={ButtonVariant.WHITE} className={classNames(["page-action", { rtl: isRTL }])}
        onClick={onPreviewAction} isLoading={loadingEye}>
        {t('BUTTON_PREVIEW')}
      </Button>
      <Button variant={ButtonVariant.PRIMARY} className={classNames(["page-action", { rtl: isRTL }])}
        isLoading={loadingPublish}
        onClick={onPublishBuilder}>
        {isActive ? t('BUTTON_SAVE&PUBLISH') : t('BUTTON_PUBLISH')}
      </Button>
      <PublishPopUp isOpen={publishPopup} onClose={() => SetpublishPopup(false)} />

      <SearchNode
        isOpen={searchMode}
        toggleClose={() => setSearchMode(!searchMode)}
      />
      {isOpenDialog && <Alert
        isOpen={isOpenDialog}
        onClose={() => setIsOpenDialog(false)}
        actions={dialogActions!}
      />}
    </div>
  );
}

export default React.memo(ChatbotActions);

