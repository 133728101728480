
import React, { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthSelectors } from 'src/redux/auth';
import AccountLayout from '../layouts/account/account';
import Intercom from '@intercom/messenger-js-sdk';
import { configuration } from 'src/configurations/configuration';


export default function PrivateRoute({ isAuthenticated }: { isAuthenticated: boolean }): ReactElement {
  const user = useSelector(AuthSelectors.user)

  useEffect(() => {
    window.addEventListener("popstate", () => {
      history.go(1)
    })
  }, [])


  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return (
    <AccountLayout>
{      Intercom({
            app_id: configuration.intercom_app_id,
            user_id: user.uphone_number, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
            name: user.bs_name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
            email: user.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
            created_at: user.creationDate // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
          })}
      <Outlet />
    </AccountLayout>
  )
}

