import React, { ReactElement, useEffect } from 'react';
import './App.scss';
import ChatbotPage from './pages/flowbuilder/flowbuilder-page';
import MainBuildersPage from './pages/flowbuilder/main-builders-page';
import { useSelector } from 'react-redux';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthSelectors } from './redux/auth';
import PrivateRoute from './components/private-route';
import { RootSelectors } from './redux/selectors';
import classNames from 'classnames';
import { LoginPage } from './pages/login_signup/login';
import { SignupPage } from './pages/login_signup/signup';
import { PrivacyPage } from './pages/terms_privacy/privacy-page';
import { TermsPage } from './pages/terms_privacy/terms-page';
import { ChannelPage } from './pages/channels/channel-page';
import { Theme } from './enums/enums';
import BilingPage2 from './pages/biling/biling-page2';
import { ProfilePage } from './pages/profile/profile-page';
import { isMobile } from 'react-device-detect';
import { RequestDemoPage } from './pages/request-demo/request-demo';
import WidgetPublishMode from './pages/web-channel/modes/publish-mode';
import WidgetAIMode from './pages/web-channel/modes/ai-mode';
import DraftPage from './draft';
import AgentsPage from './pages/agents/agents-page';
import DashboardPage from './pages/dashboard/dashboard-page';
import IngestAgent from './pages/agents/ingest-agents';
import LivePage from './pages/live/live-page';
import { GoogleOAuthProvider } from '@react-oauth/google';
import LocalChat from './pages/web-channel/modes/local-chat';
import SunshineOauth from './components/sunshine/sunshine-oauth';







function App(): ReactElement {
  const isAuthenticated: boolean = useSelector(AuthSelectors.isAuthenticated);
  const officelyUser: boolean = useSelector(AuthSelectors.officelyUser) as boolean;
  const theme = useSelector(RootSelectors.theme);




  useEffect(() => {
    const handlePopState = () => {
      history.go(1);
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [history]);


  useEffect(() => {
    const handleResize = () => {
      if (isMobile) {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--doc-height', `${vh}px`);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);


  const clientId = "907002654579-1g74g93kgdn2njk237mnbrv8fbbvou8n.apps.googleusercontent.com";


  return (
    <GoogleOAuthProvider clientId={clientId}>
      <div className={classNames(['app', { 'dark': theme === Theme.DARK }])}>
        <Router>
          <Routes>
            <Route element={<PrivateRoute isAuthenticated={isAuthenticated || officelyUser} />}>
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route path="/dashboard/:uphoneNumber" element={<DashboardPage />} />
              <Route path="/ingest-agent" element={<IngestAgent />} />
              <Route path="/live" element={<LivePage />} />
              <Route path="/agents" element={<AgentsPage />} />
              <Route path="/builders" element={<MainBuildersPage />} />
              <Route path="/mybuilders" element={<AgentsPage />} />
              <Route path="/chatbot" element={<ChatbotPage />} />
              <Route path="/channels/" element={<ChannelPage />} />
              <Route path="/subscriptions" element={<BilingPage2 />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="book-demo" element={<RequestDemoPage />} />
              <Route path="oauth/sunshine" element={<SunshineOauth />} />
            </Route>
            <Route element={<PrivateRoute isAuthenticated={officelyUser} />}>
              <Route path="/draft" element={<DraftPage />} />
            </Route>

            <Route path="/login/:lang" element={<LoginPage />} />
            <Route path="/signup/:lang" element={<SignupPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/widget/:codeUserParam/:chat?" element={<WidgetPublishMode />} />
            <Route path="/AI-Chat/:codeAIParam/:preview?" element={<WidgetAIMode />} />
            <Route path="/team-chat/:codeAIParam/:preview?/:nodeID?" element={<WidgetAIMode />} />
            <Route path="/local-chat/:codeAIParam" element={<LocalChat />} />

            <Route path="*" element={<LoginPage />} />
          </Routes>
        </Router>


      </div >
    </GoogleOAuthProvider>

  );


}

export default App;
