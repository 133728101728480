import React, { ReactElement, useCallback, useEffect } from 'react';
import { useReactFlow, Node } from 'reactflow';
import FormText from 'src/components/form/text';
import { PythonNodeData } from 'src/models/team-builder-node';
import { PythonForm } from './python-form';
import { useFormik } from 'formik';
import '../config.scss'
import Editor, { useMonaco } from '@monaco-editor/react';
import UseGetNames from 'src/hooks/team-builder/use-get-names';
import { systemVariables } from 'src/components/team-builder/interfaces';





function PythonConfig({ data }: {
    data: PythonNodeData,
}): ReactElement {
    const { setNodes } = useReactFlow();
    const useNodeNames = UseGetNames()








    const initialValues = new PythonForm(data.code)

    const monaco = useMonaco();


    const allVariables = useCallback(() => {
        const names = useNodeNames(data.id)
        const variables = [...names, ...systemVariables]
        return variables.map((s) => {
            const vars = s.split('.')
            let value = 'o.inputs'
            for (const x in vars) {
                value += `['${vars[x]}']`
            }
            return {
                preview: `${s}`,
                value: `${value}`
            }

        })
    }, [data.id])


    useEffect(() => {
        if (!monaco) { return }
        const editor = monaco?.languages.registerCompletionItemProvider('python', {
            triggerCharacters: ["@"],
            provideCompletionItems: () => {
                const suggestions: any[] = []
                const variables = allVariables()
                variables.forEach((s) => {
                    suggestions.push({
                        label: `${s.preview}`,
                        kind: monaco?.languages.CompletionItemKind.Variable,
                        insertText: `${s.value}`
                    })
                })
                return { suggestions }
            }

        })
        return () => {
            editor?.dispose()
        }
    }, [monaco])



    const formik = useFormik({
        initialValues,
        onSubmit: (_: PythonForm) => { }, // eslint-disable-line
        enableReinitialize: false
    });



    const onChange = (value?: string) => {
        formik.setFieldValue('code', value)
    }

    const computeUpdatedNodes = useCallback((nodes: Array<Node>, values: PythonForm, id: string) => (
        nodes.map((node) => {
            if (node.id === id && node.type === 'workflow') {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        code: values.code
                    }
                };
            }
            return node;
        })
    ), []);

    useEffect(() => {
        setNodes((prevNodes) => computeUpdatedNodes(prevNodes, formik.values, data.id));
    }, [formik.values, data.id, computeUpdatedNodes]);







    if (!monaco) {
        return <div>loading</div>
    }
    return (
        <form className="config config-python">
            {/* <FormText<PythonForm> name="name"
                formik={formik}
                placeholder="Name"
                regex={/^[a-zA-Z0-9_]+$/}
            /> */}
            <FormText name="code" formik={formik}>
                
                <Editor
                    height="70vh"
                    defaultLanguage="python"
                    value={data.code}
                    defaultValue={'\n#def handler(o:"officely"):\n    #return "Hello world!"'}
                    //theme="vs-dark"
                    className="node-option-input"
                    onChange={onChange}


                    // options={{
                    //    "suggest":{
                    //     insertMode:"replace",
                    //     showColors: true,
                    //     showFunctions: true,
                    //    },
                       
                    // }} 
                />
            </FormText>




        </form >


    )
}





export default PythonConfig;



