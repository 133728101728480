
import classNames from 'classnames';
import React, { ReactElement, forwardRef, Ref, MutableRefObject } from 'react';
import { TypingBubble } from '..';
import DateWeb from '../web-type/date-web';
import MediaWeb from '../web-type/media_web';
import ReceivingMediaWeb from '../web-type/receiving-media-web';
import SendLocaionWeb from '../web-type/send-location-web';
import { NodeType } from '../../../enums/enums';
import { useRTLD } from '../../../hooks/use-rtl';
import { WidgetButtons, ExtraElementChat, Chat } from '../../../pages/web-channel/interfaces';
import './widget-chat.scss'
import ReactMde from 'react-mde';
import { Avatar } from '@chakra-ui/react';
import * as Showdown from "showdown";
import { DateRefEntry } from 'src/pages/web-channel/modes/live-mode';



const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true
});



type Props = {
    chatHistory: Array<Chat>;
    loading: boolean;
    isChat: boolean;
    img?: string
    handleSubmit: ({ answer, payload, value, extraElement, skip }: { answer?: string, payload?: WidgetButtons, value?: string, extraElement?: ExtraElementChat, skip?: boolean }) => void;
    handleClickBtn?: (btn: WidgetButtons) => void;
    dateRefs?: MutableRefObject<{ [key: string]: DateRefEntry }>;
}



const WidgetChat = forwardRef((props: Props, ref: Ref<HTMLDivElement>) => {
    const isRTLD = useRTLD()
    const { isChat, chatHistory, loading, img, handleSubmit, handleClickBtn, dateRefs } = props;



    const onSubmit = (payload?: WidgetButtons, value?: string, extraElement?: ExtraElementChat, skip?: boolean) => {
        handleSubmit({ payload, value, extraElement, skip })
    }


    const GetExtraElement = (extraElement: ExtraElementChat) => {
        switch (extraElement?.type) {
            case NodeType.MEDIA:
            case NodeType.BUTTONS:
                return <MediaWeb url={extraElement.data[NodeType.MEDIA]} />;
            case NodeType.VALID_DATE:
                return (
                    <DateWeb
                        handleChange={(value) => onSubmit(undefined, value)}
                    />
                );
            case NodeType.RECEIVING_MEDIA:
                return (
                    <ReceivingMediaWeb
                        handleClick={(file, extraElement) => onSubmit(undefined, file, extraElement, true)} />
                );
            case NodeType.SEND_LOCATION:
                return <SendLocaionWeb data={extraElement.data} />;
            default:
        }
        return <></>
    }

    const GetReactMde = (text: string): ReactElement => <ReactMde
        minPreviewHeight={0}
        value={text}
        selectedTab="preview"
        generateMarkdownPreview={(markdown) =>
            Promise.resolve(converter.makeHtml(markdown))
        }
        childProps={{
            writeButton: {
                hidden: true
            },
            previewButton: {
                hidden: true
            }
        }}
    />





    const isNewDay = (currentMsgDate: string, index: number): boolean => {
        if (index === 0) return true; 
        const prevMsgDate = chatHistory[index - 1].timestamp?.date;
        return currentMsgDate !== prevMsgDate;
    };



    const withLogo = (msg: Chat): boolean =>  img && (img !== 'null') && ( msg.type !== 'inbound') && isChat ? true : false
    


    return (
        <div className={classNames([`widget-content-${isChat ? 'chat' : 'form'}`, { nonImage: !(img && img !== 'null') }])} ref={ref} >

            {
                chatHistory.map((msg: Chat, iMsg: any) => <div key={iMsg}
                    ref={(el) => {
                        if (dateRefs && dateRefs.current && msg.timestamp && isNewDay(msg.timestamp.date, iMsg)) {
                            dateRefs.current[msg.timestamp.fullDate] = {
                                element: el,
                                date: msg.timestamp.date
                            }; 
                        }
                    }}
                    className={classNames(["message", {
                        inbound: msg.type === 'inbound',
                        formInbound: !isChat && msg.type === 'inbound'
                    }])}>
                    {msg.timestamp && isNewDay(msg.timestamp.date, iMsg) && iMsg !== 0 && <div className="datetime margin">
                        {msg.timestamp.date}
                    </div>}

                    <div className={classNames(["message-text animate fade-in", {
                        error: msg.is_error,
                        emptyText: !msg.text,
                        rtl: isRTLD(msg.text)
                    }])}>
                        {msg.text && <>
                            {
                                 withLogo(msg) && <Avatar
                                    size="md"
                                    position="absolute"
                                    width="var(--chakra-sizes-10)"
                                    height="var(--chakra-sizes-10)"
                                    top="-30px"
                                    left="-35px"
                                    src={img} />
                            }
                            {GetReactMde(msg.text)}
                        </>
                        }
                        {msg.timestamp && <span className="time">{msg.timestamp.time}</span>}
                    </div>
                    <div className="message-elements animate fade-in-up" >
                        {msg.extra_elements && GetExtraElement(msg.extra_elements)}
                        <div className="buttons">
                            {msg.buttons?.map((btn: any, iBtn: any) =>
                                <button
                                    disabled={btn.disable}
                                    className={classNames({ rtl: isRTLD(btn.title) })}
                                    key={iBtn}
                                    onClick={() => handleClickBtn && handleClickBtn(btn)}>
                                    {btn.title}
                                </button>)}
                        </div>

                    </div>
                </div>)

            }
            {loading && <div className="message-text">
                <TypingBubble />
            </div>}

        </div>
    );
})


const areEqual = (prevProps: any, nextProps: any) =>
    prevProps.chatHistory === nextProps.chatHistory &&
    prevProps.isChat === nextProps.isChat &&
    nextProps.loading === prevProps.loading;

export default React.memo(WidgetChat, areEqual);




