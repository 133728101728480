import React, { ReactElement} from "react";
import SideBarChatBuilder from "./sidebar-chat-builder";
import { useDispatch, useSelector } from 'react-redux';
import { AuthSelectors } from "../../redux/auth";
import Icon, { IconTheme, IconVariant } from "../icon/icon";
import { ChatBuilderActions } from "../../redux/chat-builder";
import Button, { ButtonSize, ButtonVariant } from "../button/button";
import { useTranslation } from "../../hooks/use-translate";
import WidgetPreviewMode from "../../pages/web-channel/modes/preview-mode";








function PreviewMode({ isOpen, render, PreviewNodeID, toggleClose }: {
    isOpen: boolean,
    render: boolean,
    PreviewNodeID: string
    toggleClose: () => void
}): ReactElement {
    const t = useTranslation('COMPONENTS.PAGES.CHATBOT');
    const user = useSelector(AuthSelectors.user)
    const dispatch = useDispatch()

    const WhatsappPreview = () => {
        window.open(`https://wa.me/972559933314?text=${t('TEXT_PREVIEW')}+${user.code_user}`)
    }

    const handleClose = () => {
        toggleClose()
        dispatch(ChatBuilderActions.onSelectSearchNode({ id: '' }))

    }



    return (
        <SideBarChatBuilder isLimited={!isOpen} previewMode>
            <div className="sidebar-chat-builder-header">
                <span className="whatsaap-preview" onClick={WhatsappPreview}>
                    <span className="whatsaap-preview-icon">
                        <Icon variant={IconVariant.WHATSAPP_SMALL} />
                    </span>
                    <span>
                        <span className="whatsaap-preview-title">WhatsApp Preview</span>
                    </span>
                </span>

                <span className="close-preview">
                    <Button
                        isIcon
                        isRound
                        size={ButtonSize.SMALL}
                        variant={ButtonVariant.OUTLINE}
                        onClick={handleClose}
                    >
                        <Icon variant={IconVariant.REMOVE_SMALL} theme={IconTheme.BLACK} />
                    </Button>
                </span>


            </div>
            <div className="sidebar-chat-builder-content">
                <WidgetPreviewMode nodeID={PreviewNodeID} render={!render} />
            </div>

        </SideBarChatBuilder>

    )

}




export default React.memo(PreviewMode);