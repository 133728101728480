
import { useFormik } from 'formik';
import React, { ReactElement, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Button, { ButtonVariant } from '../../components/button/button';
import { SignupForm } from '../../forms/signup';
import { useTranslation } from '../../hooks/use-translate';
import { User } from '../../models';
import { AuthActions } from '../../redux/auth';
import classNames from 'classnames';
import { useRTL } from '../../hooks/use-rtl';
import Logo from '../../components/logo/logo';
import FormVerificationCode from '../../components/form/verificationCode';
import { useFetchOfficely } from '../../hooks/use-fetch-officely';
import { Method } from '../../enums/enums';
import { useTranslation as Translation } from 'react-i18next';
import { Alert, AlertActions } from '../../helpers/dialog';
import './login-signup.scss';
import { SignUpForm } from './form';
import { isValidPhoneNumber } from 'react-phone-number-input'
import GoogleOAuth from 'src/components/google-login/google-oauth';
import Intercom from '@intercom/messenger-js-sdk';
import { configuration } from 'src/configurations/configuration';



export function SignupPage(): ReactElement {
    const { lang } = useParams<{ lang?: string }>();
    const t = useTranslation('PAGES.SIGNUP');
    const tError = useTranslation('FORMS.SIGNUP');
    const tSwal = useTranslation('COMPONENTS.SWAL')
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isRTL = useRTL();
    const { i18n } = Translation();
    const [isVerification, setIsVerification] = useState(false);
    const [seconds, setSeconds] = useState(0)
    const [minute, setMinute] = useState(0)
    const [sessionCode, setSessionCode] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [isOpenDialog, setIsOpenDialog] = useState(false)
    const [dialogActions, setDialogActions] = useState<AlertActions | null>(null)


    useEffect(() => {
        if (isVerification) {
            const timer = setInterval(() => {
                setSeconds(seconds - 1)
                if (seconds === 0) {
                    setSeconds(59)
                    setMinute(minute - 1)
                }
                if (minute === 0 && seconds === 0) {
                    setIsVerification(false)
                    dispatch(AuthActions.authorizeFailure)
                }
            }, 1000)
            return () => clearInterval(timer)
        }
    }, [isVerification, seconds])

    useEffect(() => {
        document.title = t("TITLE");
        (lang === 'he' || lang === 'en') && i18n.changeLanguage(lang)
    }, [])


    const CheckUser = async (newUser: User): Promise<void> => {
        const body = {
            phone: newUser.uphone_number,
            email: newUser.email.toLowerCase(),
            partner: newUser.partner
        }
        const res = await useFetchOfficely('checkSignup', Method.POST, body)
        if (res.success) {
            if (res.data) {
                setSessionCode(res.data)
                setIsVerification(true)
            }
        } else {
            switch (res.err) {
                case 'ERR_PHONE_NUMBER':
                    formik.setFieldError('phone', tError('ERR_PHONE_NUMBER'))
                    break;
                case 'ERR_EMAIL':
                    formik.setFieldError('email', tError('ERR_EMAIL'))
                    break;
                case 'ERR_PARTNER_CODE':
                    formik.setFieldError('partner_code', tError('ERR_PARTNER_CODE'))
                    break;
                case 'ERR_NO_WHATSAPP_PHONE':
                    formik.setFieldError('phone', tError('ERR_NO_WHATSAPP_PHONE'))
                    break;
                default:
                    break;
            }
        }

    }


    const onSigunUpSuccess = (user: User) => {
        dispatch(AuthActions.authorizeSuccess());
        dispatch(AuthActions.setUser({ user: user }))
        navigate(`/mybuilders`)
    }



    const handleSubmit = async (values: SignupForm): Promise<void> => {
        if (!isValidPhoneNumber(values.phone, values.countryCode)) {
            formik.setFieldError('phone', tError('TEXT_INVALID_PHONE'))
            return
        }
        const uphone_number = values.phone.replace('+', '')
        const newUser = new User(uphone_number, values.bs_name, values.email)
        if (isVerification) {
            setIsLoading(true)
            const resAuth = await useFetchOfficely(`AuthUserSignUp/${newUser.uphone_number}/${values.verificationCode.toString()}/${sessionCode}`, Method.GET)
            if (resAuth.success) {
                const res = await useFetchOfficely('insertNewUser', Method.POST, newUser);
                if (res.success) {
                    onSigunUpSuccess(res.data)
                    return
                }
                else {
                    setDialogActions({
                        title: tSwal("ERROR"),
                        content: tSwal("ERROR_FETCH"),
                        confirm: tSwal("OK"),
                        colorButton: "primary",
                        isMessage: true
                    })
                    setIsOpenDialog(true)
                }
            } else {
                setIsLoading(false)
                if (resAuth.err === 'Too many attempts') {
                    setIsVerification(false)
                }
                formik.setFieldError('verificationCode', tError('TEXT_VERIFICATION_CODE_ERROR_EXIST'))
            }

        } else {
            CheckUser(newUser)
            setMinute(3)
            setSeconds(0)
        }
    };

    const initialValues = new SignupForm();
    const validationSchema = SignupForm.getValidationSchema(tError, isVerification);


    const formik = useFormik({
        initialValues,
        onSubmit: handleSubmit,
        validationSchema
    });

    return (
        <div className="page login_signup">
            <div className="login_signup-header">
                <span className="logo">
                    <Logo />
                </span>
                {/* <span className="lang">
                    <LangSelect />
                </span> */}
            </div>
            <div className={classNames(['login_signup-box', { 'rtl': isRTL }])}>
                <header className="login_signup-box-header">
                    <h2 className="login_signup-title">
                        {(isVerification)
                            ? t('TEXT_CONFIRM_PHONE')
                            : t('TEXT_SIGNUP')}
                    </h2>
                </header>

                {isVerification && <span className="login_signup-timer">0{minute}:{seconds < 10 ? '0' : ''}{seconds}</span>}
                <form className="login_signup-form" onSubmit={formik.handleSubmit}>
                    {(isVerification)
                        ?
                        <FormVerificationCode<SignupForm> name="verificationCode"
                            formik={formik}
                            placeholder={t('TEXT_VERIFICATION_CODE')}
                            type="verificationCode"
                            className="signup"
                            inputMode="numeric"
                        />
                        :
                        <SignUpForm formik={formik} />}
                    <Button type="submit"
                        variant={ButtonVariant.PRIMARY}
                        isLoading={isLoading}
                        //size={ButtonSize.MEDIUM}
                        isBlock
                        className={classNames([{ 'rtl': isRTL }])}>
                        {(isVerification)
                            ? t('BUTTON_SUBMIT')
                            : t('BUTTON_SIGNUP')}
                    </Button>
                    {isVerification ?
                        <Button variant={ButtonVariant.LINK}
                            onClick={() => window.open(`https://wa.me/97237381772?text=${t('TEXT_ERROR_CODE')}`)}>
                            {t("ERROR_CODE")}
                        </Button>
                        :
                        <Button variant={ButtonVariant.LINK} onClick={() => navigate('/login')}
                            className={classNames(['button-center', { 'rtl': isRTL }])}>
                            {t("BUTTON_LOGIN")}
                        </Button>}
                </form>
                {!isVerification && <GoogleOAuth />}
            </div>
            {isOpenDialog && <Alert
                isOpen={isOpenDialog}
                onClose={() => setIsOpenDialog(false)}
                actions={dialogActions!}
            />}
            {Intercom({
                app_id: configuration.intercom_app_id
            })}
        </div>
    );
}
