

export class PythonForm {
    public code: string;

  
    constructor(code?:string) {
      this.code = code || '';
    }
  
  
  }