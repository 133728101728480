
import { immerable } from 'immer';
import { Node } from 'reactflow';
import { ChatbotNodeData } from '../../models';


export interface TranformSearch {
  x: number,
  y: number,
  zoom: number,
}

export class ChatbotActions {
  [immerable] = true;

  public isLoading: boolean;
  public inputSearchValue?: string;
  public transformSearch?: TranformSearch;
  public searchArray: Array<Node<ChatbotNodeData>>;
  public previewNodeID: { id: string | null, render: boolean };
  public activeSearch: number
  public activeNodeID: string | null;

  constructor() {
    this.isLoading = false;
    this.searchArray = [];
    this.activeSearch = 0;
    this.previewNodeID = { id: null, render: false };
    this.activeNodeID = null;
  }
}
