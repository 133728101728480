import { Node, XYPosition } from 'reactflow';
import { DataType, FormatDate, HeaderForamt, Method, NodeType } from '../enums/enums';
import { ChatbotNodeData } from '../models';
import { useSelector } from 'react-redux';
import { ChatBuilderSelectors } from '../redux/chat-builder';
import { useNextNodeNameByType, useNextNodeOrderOfType } from './use-next-node-order';
import { getNodeID, getOptionID } from '../helpers/wrokflow-builder/get-id';
import { last } from 'lodash';
import { useTranslation } from '../hooks/use-translate';
import { OperatorLabel } from '../components/node/filter_node';




export function useNewNode(): (type: NodeType, position?: { x: number, y: number }) => Node<ChatbotNodeData> {
  const t = useTranslation('COMPONENTS.DEFUALT_TEXT_NODE');
  const nodes = useSelector(ChatBuilderSelectors.nodes);
  const getNextNodeOrderOfType = useNextNodeOrderOfType();
  const getNextNodeNameByType = useNextNodeNameByType()
  const getNewNodePosition = (nodes: Array<Node<ChatbotNodeData>>): XYPosition => {
    const { x, y } = last(nodes)!.position;
    return { x: x + 450, y };
  };



  return (type: NodeType, position) => {
    position = position ? position : getNewNodePosition(nodes);
    const id = getNodeID();
    const orderOfType = getNextNodeOrderOfType(type);
    const name = getNextNodeNameByType(type, orderOfType)
    const text = t(type)


    const newNode: Node<ChatbotNodeData> = {
      id,
      data: {
        id,
        text,
        name,
        orderOfType,
        isSelected: true,
        isError: false
        //isSearch: false
      },
      position,
      type,
      selected:true
    };

    switch (newNode.type) {
      case NodeType.OPTIONS:
        newNode.data!.options = [{ id: getOptionID(), text: '' }];
        break;
      case NodeType.BUTTONS:
        newNode.data!.options = [{ id: getOptionID(), text: '' }];
        newNode.data!.obj = {
          header: '',
          footer: '',
          headerForamt: HeaderForamt.TEXT

        }
        break;
      case NodeType.POWER_WORDS:
        newNode.data!.options = [{ id: getOptionID(), text: t('REMOVE'), checked: false }];
        break
      case NodeType.MEDIA:
        //newNode.data!.options = [{ id: getOptionID(), text: '' }];
        newNode.data!.options = [];
        newNode.data!.obj = {
          mediaLink: '',
          mediaFileName: ''
        }
        break;
      case NodeType.WEBHOOK:
        newNode.data!.obj = {
          description: '',
          method: Method.POST,
          url: '',
          type: NodeType.TEXT_QUESTION,
          json: '{ "all_answers":true }'
        }
        break;
      case NodeType.API:
        newNode.data!.obj = {
          description: '',
          type: NodeType.START
        }
        break;
      case NodeType.JUMP:
        newNode.data!.obj = {
          jump: '',
          jumpTo: ''
        }
        break;
      case NodeType.TEMPLATES:
        newNode.data!.obj = { template: null }
        newNode.data!.options = [];
        break;
      case NodeType.WHATSAPP_LIST:
        newNode.data!.obj = {
          header: '',
          footer: '',
          waList: {
            button: t('BUTTON_LIST'),
            sections: [{
              title: '', isOpen: true, rows: [{
                title: '',
                description: '',
                id: getOptionID()
              }]
            }]
          }
        }
        newNode.data!.options = [{ id: newNode.data!.obj.waList!.sections[0].rows[0].id, text: '' }];
        break;
      case NodeType.SEND_LOCATION:
        newNode.data!.obj = {
          latitude: '',
          longitude: '',
          text: ''

        }
        newNode.data!.text = '';
        break;
      case NodeType.NOTIFICATION:
        newNode.data!.obj = {
          phoneNotification: '',
          messageNotification: ''
        }
        break;
      case NodeType.FILTER:
        newNode.data!.obj = {
          filterObject: {
            groups: [{
              id: getOptionID(),
              conditions: [{
                field: '',
                operator: { label: OperatorLabel.EQUAL, dataType: DataType.TEXT },
                value: '',
                formatDate:FormatDate.DD_MM_YYYY
              }]
            }],
            elseID: getOptionID()
          }

        }
        newNode.data!.options = [
          { id: newNode.data!.obj.filterObject!.groups[0].id, text: '' },
          { id: newNode.data!.obj.filterObject!.elseID, text: '' }
        ];
        break;
      case NodeType.PLUGIN_AGENT:
        newNode.data!.obj = {}
        
    }
    return newNode;
  };
}

