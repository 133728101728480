import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './assets/i18n/en.json';
import translationHE from './assets/i18n/he.json';


declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}


const resources = {
  en: {
    translation: translationEN
  },
  he: {
    translation: translationHE
  }
};



export default i18n.use(initReactI18next).init({
  returnNull: false,
  resources,
  lng: 'en',
  fallbackLng: ['en', 'he'],
  supportedLngs: ['en', 'he'],
  debug: false,
  interpolation: {
    escapeValue: false
  }
});

export const rtlLngs = ['he'];
