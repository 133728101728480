import React, { ReactElement, useState } from 'react'
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';
import { useRTL } from '../../hooks/use-rtl';
import classNames from 'classnames';



export function GoogleMaps({ position, text }:{
  position: {lat: number, lng: number}
  text: string
}): ReactElement {

  const isRTL = useRTL()
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDNXnxAmL5TSDW95zWP3PcA3vvLd3KCG_Y"
  })

  const [info, setInfo] = useState(false)

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        height: '400px'
      }}
      center={position}
      zoom={15}
      options={{
        mapTypeControl: false,
        scaleControl: false
      }}

    >
      <Marker position={position}

        animation={2}
        onClick={() => setInfo(true)}
      >
        {info && <InfoWindow
          position={position}
          onCloseClick={() => setInfo(false)}
          options={{maxWidth: 200}}


        >
          <div className={classNames({rtl:isRTL})}  style={{ marginLeft: 20 }}>
            <div><b>{text}</b></div>
            <br /> <br/>
            <a className="link"
              href={`http://www.google.com/maps/place/${position.lat},${position.lng}`}
              target="_blank"
              rel="noopener noreferrer">
              Google Maps</a>
          </div>
        </InfoWindow>}


      </Marker>
    </GoogleMap>
  ) : <></>
}




