import { useCallback } from 'react';
import { NodeProps, useReactFlow, getOutgoers, Node } from 'reactflow';
import { ModelType, TeamFlowNodeType, TeamNodeType } from 'src/components/team-builder';
import { LLMNodeData } from 'src/models';
import { v4 } from 'uuid'
import useRandomImage from './use-random-images';




export function useAddNode(id: NodeProps['id']) {
  const { setEdges, setNodes, getNodes, getEdges, getNode } = useReactFlow();
  const getRandomImage = useRandomImage();



  const getDataWorkFlow = (id: string) => {
    const data: LLMNodeData = {
      id,
      description: "",
      model: ModelType.GPT4_O,
      system_prompt: "",
      prompt: "",
      name: id,
      type: TeamFlowNodeType.LLM,
      with_schema: false,
      urlImage: getRandomImage()
    }
    return data

  }


  const onClick = useCallback((type: TeamNodeType) => {
    const parentNode = getNode(id);


    if (!parentNode) {
      return;
    }

    const childNodeId = v4();
    let data = {}

    switch (type) {
      case TeamNodeType.WORKFLOW:
        data = getDataWorkFlow(childNodeId);
        break;
      default:
        throw new Error('Unknown node type');
    }



    const childNode: Node = {
      id: childNodeId,

      position: { x: parentNode.position.x, y: parentNode.position.y + 500 },
      type,
      data
    };


    const childEdge = {
      id: `${parentNode.id}=>${childNodeId}`,
      source: parentNode.id,
      target: childNodeId,
      type: 'workflow'
    };

    const childPlaceholderId = v4();


    const childPlaceholderNode = {
      id: childPlaceholderId,
      // we place the placeholder 150 pixels below the child node, spacing can be adjusted in the useLayout hook
      position: { x: childNode.position.x, y: childNode.position.y + 300 },
      type: 'placeholder',
      data: { label: '+' }
    };

    const childPlaceholderEdge = {
      id: `${childNodeId}=>${childPlaceholderId}`,
      source: childNodeId,
      target: childPlaceholderId,
      type: 'placeholder'
    };



    const existingPlaceholders = getOutgoers(parentNode, getNodes(), getEdges())
      .filter((node) => node.type === 'placeholder')
      .map((node) => node.id);

    // add the new nodes (child and placeholder), filter out the existing placeholder nodes of the clicked node
    setNodes((nodes) =>
      nodes.filter((node) => !existingPlaceholders.includes(node.id)).concat([childNode, childPlaceholderNode])
    );

    // add the new edges (node -> child, child -> placeholder), filter out any placeholder edges
    setEdges((edges) =>
      edges.filter((edge) => !existingPlaceholders.includes(edge.target)).concat([childEdge, childPlaceholderEdge])
    );







  }, [getEdges, getNode, getNodes, id, setEdges, setNodes]);

  return onClick;
}

export default useAddNode;
