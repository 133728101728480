import { IngestStages } from "../../pages/agents/ingest-agents";
import AgentData from './agent-loaders';
import AgentDeatils from './agent-deatils';
import AgentContidions from './agent-conditions';
import AgentOverview from "./agent-overview";
import OpenAIDeatils from "./openai_deatils";
import AgentStyle from "./agent-style";








export const defaultStyle = `/* 1. Sets the background  color of the chat AI widget body */
.AI.widget{
    background-color: #CFD2F2;
}

/* 2. Styles the message text for the user within the chat content area */
.widget-content-chat .message-text{
    background: white; 
    color: black; 
}

/* 3. Styles the message text for the AI agent within the chat */
.AI .widget-content-chat .message.inbound .message-text{
    background-color: #5b61eb; 
    color: white; 
}`


export const GenerateLink = (id: string) => `${window.location.origin}/#/ai-chat/${id}`

export const GenerateEmbed = (id: string) => `<script src="https://app.officely.ai/chat-widget.js"></script>
<script>
window.addAIWidget({
   id:'${id}',
   position: "center", //can be side or center
   buttonColor:"#5b61eb" 
   //iconLink:"your icon link (optional)",
   //autoOpen: true //defualt is false
})
</script>
`


export const customAgentSatges: IngestStages[] = [
    {
        name: "Business Structure",
        subStages: ["Company Data"],
        done: false,
        component: AgentData
    },
    {
        name: "Agent Details",
        subStages: ["Company Name", "Agent Name", "First Message"],
        done: false,
        component: AgentDeatils
    },
    {
        name: "Other (Optional)",
        subStages: ["Conditions"],
        done: false,
        component: AgentContidions
    },
    // {
    //     name: "Configuration",
    //     subStages: ["Choose Model"],
    //     done: false,
    //     component: AgentConfiguration

    // },
    {
        name: "Style (Optional)",
        subStages: ["Custom CSS"],
        done: false,
        component: AgentStyle
    },
    {
        name: "Overview",
        subStages: [],
        done: false,
        component: AgentOverview
    }
]

export const openAIAssistantStages: IngestStages[] = [
    {
        name: "Open AI",
        subStages: ["API Token", "Choose Assistant"],
        done: false,
        component: OpenAIDeatils
    },
    {
        name: "Style (Optional)",
        subStages: ["Custom CSS"],
        done: false,
        component: AgentStyle
    },
    {
        name: "Overview",
        subStages: ["Overview AI Agent", 'Share'],
        done: false,
        component: AgentOverview
    }
]