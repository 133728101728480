import React, { ReactElement } from 'react';
import { NodeType } from '../../enums/enums';
import { ChatbotNodeData } from '../../models/chat-bot-node';
import Node from './node';
import './node.scss';


function PopUpNode({data}: { data: ChatbotNodeData }): ReactElement {
  return (
    <Node data={data} type={NodeType.POPUP} className="node-popup">
    </Node>
  );
}

const areEqual = (prevProps:any, nextProps:any) => prevProps.data === nextProps.data;

export default React.memo(PopUpNode, areEqual);