import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { NodeType } from '../../../enums/enums';
import { ChatbotNodeData } from '../../../models/chat-bot-node';
import { ChatBuilderActions } from '../../../redux/chat-builder';
import Button, { ButtonVariant } from '../../button/button';
import Node from '../node';
import SourceHandle from '../source-handle';
import TargetHandle from '../target-handle';
import { FilterAction } from './interfaces';
import  EditGroup  from './edit-group';
import FormText from '../../form/text';





function FilterNode({ data }: { data: ChatbotNodeData }): ReactElement {
  const dispatch = useDispatch();

  const onAddGroup = (): void => {
    dispatch(ChatBuilderActions.onChangeFilterNode({ nodeID: data.id, key: FilterAction.ADD_GROUP }));
  };



  return (
    <Node data={data}
      type={NodeType.FILTER}
      className="node-filter">
      <TargetHandle id={data.id} />
      <div className="node-options">
        {data.obj?.filterObject?.groups?.map((group, index) =>
          <div key={index} className={classNames(['node-option'])}>
            <EditGroup
              group={group}
              data={data}
            />
            <SourceHandle id={group.id} />
          </div>
        )}
        <div className="node-option">
          <FormText
            value="Else"
            readOnly
          />
          <SourceHandle id={data.obj!.filterObject!.elseID} />
        </div>
        {data.isSelected && <div className="node-options-add">
          <Button onClick={onAddGroup}
            onTouchStart={onAddGroup} variant={ButtonVariant.PRIMARY} isBlock={true}>
            Add Group
          </Button>
        </div>}
      </div>
    </Node>
  );
}

const areEqual = (prevProps: any, nextProps: any) => prevProps.data === nextProps.data;

export default React.memo(FilterNode, areEqual);
