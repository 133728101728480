import React,{useState, useEffect} from 'react';
import GoogleDocsViewer from 'react-google-docs-viewer';


export default function PdfViewer({ url, className }) {
  const [fileUrl, setFileUrl] = useState('');

  useEffect(() => {
    setTimeout(() => setFileUrl(url), 0)
  }, [url]);

  return (
    <div className={className}>
      <GoogleDocsViewer
        fileUrl={fileUrl} />
    </div>
  );
}



