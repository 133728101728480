import { DataType, FormatDate } from "../../../enums/enums";

export interface FilterObject {
    groups: Array<GroupCondtions>;
    elseID:string
}

export interface GroupCondtions {
    id: string;
    conditions: Array<Condition>
}

export interface Condition {
    field: string;
    fieldMap?:boolean;
    operator: OperatorInfo;
    value: string
    valueMap?:boolean
    formatDate?: FormatDate;
    logicalOperator?: LogicalOperator;
}
    

export enum LogicalOperator {
    OR = 'OR',
    AND = 'AND',
  }
  
export enum OperatorValue {
    EQUAL = "==",
    NOT_EQUAL = "!=",
    GREATER_THAN = ">",
    LESS_THAN = "<",
    GREATER_THAN_OR_EQUAL_TO = ">=",
    LESS_THAN_OR_EQUAL_TO = "<=",
    STARTS_WITH = "startsWith",
    ENDS_WITH = "endsWith",
    CONTAINS = "contains",
    EXISTS = "exists",
    IS_TRUE = "isTrue",
    IS_FALSE = "isFalse"
}

export enum FilterAction {
    ADD_GROUP,
    REMOVE_GROUP,
    CHANGE_GROUP_LOGICAL,
    ADD_CONDITION,
    REMOVE_CONDITION,
    CHANGE_FIELD,
    CHANGE_FIELD_MAP,
    CHANGE_OPERATOR,
    CHANGE_VALUE,
    CHANGE_VALUE_MAP,
    CHANGE_LOGICAL_OPERATOR,
    CHANGE_FORMAT_DATE
}

export enum Operator {
    EQUAL = "=",
    NOT_EQUAL = "!=",
    EQUAL_INSENSETIVE = "=",
    NOT_EQUAL_INSENSETIVE = "!=",
    CONTAINS_INSENSETIVE = 'contains',
    NOT_CONTAINS_INSENSETIVE = 'not contains',
    CONTAINS = 'contains',
    NOT_CONTAINS = 'not contains',
    STARTS_WITH = 'starts with',
    NOT_STARTS_WITH = 'not start with',
    STARTS_WITH_INSENSETIVE = 'starts with',
    NOT_STARTS_WITH_INSENSETIVE = 'not start with',
    ENDS_WITH_INSENSETIVE = 'ends with',
    NOT_ENDS_WITH_INSENSETIVE= 'not end with',
    ENDS_WITH = 'ends with',
    NOT_ENDS_WITH = 'not end with',
    GREATER_THAN = '>',
    LESS_THAN = '<',
    GREATER_THAN_OR_EQUAL_TO = '>=',
    LESS_THAN_OR_EQUAL_TO = '<=',
    MATCHES_REGEX = 'matches regex',
    NOT_MATCHES_REGEX = 'not match regex',
    MATCHES_REGEX_INSENSETIVE = 'matches regex ',
    NOT_MATCHES_REGEX_INSENSETIVE = 'not match regex',
    EXISTS = 'exists',
    NOT_EXISTS = 'not exist',
}

export enum OperatorLabel {
    EQUAL = 'equal to',
    NOT_EQUAL = 'not equal to ',
    EQUAL_INSENSETIVE = 'equal to (case insensitive)',
    NOT_EQUAL_INSENSETIVE = 'not equal to (case insensitive)',
    CONTAINS_INSENSETIVE = 'contains (case insensitive)',
    NOT_CONTAINS_INSENSETIVE = 'does not contain (case insensitive)',
    CONTAINS = 'contains',
    NOT_CONTAINS = 'does not contain',
    STARTS_WITH = 'starts with',
    NOT_STARTS_WITH = 'does not start with',
    STARTS_WITH_INSENSETIVE = 'starts with (case insensitive)',
    NOT_STARTS_WITH_INSENSETIVE = 'does not start with (case insensitive)',
    ENDS_WITH_INSENSETIVE = 'ends with (case insensitive)',
    NOT_ENDS_WITH_INSENSETIVE= 'does not end with (case insensitive)',
    ENDS_WITH = 'ends with',
    NOT_ENDS_WITH = 'does not end with',
    GREATER_THAN = 'greater than',
    LESS_THAN = 'less than',
    GREATER_THAN_OR_EQUAL_TO = 'greater than or equal to',
    LESS_THAN_OR_EQUAL_TO = 'less than or equal to',
    MATCHES_REGEX = 'matches regex',
    NOT_MATCHES_REGEX = 'does not match regex',
    MATCHES_REGEX_INSENSETIVE = 'matches regex (case insensitive)',
    NOT_MATCHES_REGEX_INSENSETIVE = 'does not match regex (case insensitive)',
    EXISTS = 'exists',
    NOT_EXISTS = 'does not exist',
}

export interface OperatorInfo {
    label: OperatorLabel;
    dataType: DataType;
}

export enum DataTypeLabel{
    TEXT = "Text",
    NUMBER = "Number",
    DATE = "Date",
    BASIC = "Basic"
}
