import { Badge as CHBadge } from '@chakra-ui/react'
import React from 'react'

export default function Badge({ text, colorScheme }: { text: string, colorScheme: string}) {
    return (
        <CHBadge
            ml="1.5"
            colorScheme={colorScheme}
            p={"0 9px 0"}
            borderRadius="3px"
            textTransform="unset">
            {text}
        </CHBadge>
    )
}
