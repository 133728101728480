import classnames from 'classnames'
import React, { ReactNode } from 'react'
import './widget.scss'



export enum WidgetMode {
    PREVIEW,
    PUBLISH,
    AI, 
    LIVE
}

type Props = {
    children: ReactNode
    className: any
    style?: string
}

export default function WidgetTemp({ children, className, style }: Props) {

    return (
        <div className={classnames(['widget', className ])}>
            <style type="text/css">{style}</style> 
            {children}
        </div>
    )
}
