import React, { ReactElement } from 'react';
import { addEdge, Handle, Position, Node, ConnectionLineType } from 'reactflow';
import { useDispatch, useSelector } from 'react-redux';
import { ChatbotNodeData } from '../../models';
import { ChatBuilderSelectors, ChatBuilderActions } from '../../redux/chat-builder';
import './node.scss';

function TargetHandle({ id }: { id: string }): ReactElement {
  const dispatch = useDispatch();

  const nodes = useSelector(ChatBuilderSelectors.nodes);
  const edges = useSelector(ChatBuilderSelectors.edges);
  const activeSourceID = useSelector(ChatBuilderSelectors.activeSourceID);

  const onConnect = async (): Promise<void> => {
    const node = nodes.find((node: Node<ChatbotNodeData>) => node.id === activeSourceID
      || (activeSourceID && node.data?.options?.map((option:any) => option.id).includes(activeSourceID)));
    if (node && node.id !== id) {
      const newEdges = addEdge(
        {
          source: node.id,
          target: id,
          sourceHandle: activeSourceID,
          targetHandle: id,
          type: ConnectionLineType.SmoothStep,
          animated: false
        },
        edges
      )
      const edge = newEdges.find((e) => e.sourceHandle === activeSourceID)!;
      dispatch(ChatBuilderActions.addElement({ element: edge }));
      dispatch(ChatBuilderActions.setActiveSource({ id: null }));
    }

  };

  return (
    <Handle
      type="target"
      position={Position.Left}
      id={id}
      className="node-handle node-handle-target"
    >
      <button onClick={onConnect} className="node-handle-action node-handle-connect" />
    </Handle>
  );
}

const areEqual = (prevProps:any, nextProps:any) => prevProps.data === nextProps.data;

export default React.memo(TargetHandle, areEqual);
