import React from "react";
import { AutoComplete as ChocAutoComplete, AutoCompleteInput, AutoCompleteItem, AutoCompleteList, AutoCompleteListProps, AutoCompleteGroup, AutoCompleteGroupTitle } from "@choc-ui/chakra-autocomplete";
import './auto-complete.scss'
import { Divider } from '@chakra-ui/react'

export type Item = {
    value: string;
    label: string;
    action: () => void;
}

export type GroupItems = {
    title?: string | React.ReactNode;
    items: Item[];
};

export type AutoCompleteProps = {
    value: string;
    isGroup?: boolean;
    items: Item[] | GroupItems[];
    disableFilter?: boolean;
    variant?: string;
    classNameInput?: string;
    isRTL?: boolean;
    centerInput?: boolean;
    centerItem?: boolean;
} & AutoCompleteListProps;




function AutoComplete({
    value,
    isGroup,
    items,
    disableFilter,
    variant,
    classNameInput,
    isRTL,
    centerInput,
    centerItem,
    ...props }: AutoCompleteProps) {





    const onSelect = (selectedValue: string) => {
        let selectedItem;
        if (items.length > 0 && typeof items[0] === 'object' && 'items' in items[0]) {
            // items is of type GroupItems[]
            for (const group of items as GroupItems[]) {
                selectedItem = group.items.find((item) => item.value === selectedValue);
                if (selectedItem) break;
            }
        } else {
            // items is of type Item[]
            selectedItem = (items as Item[]).find((item) => item.value === selectedValue);
        }
        selectedItem?.action()


    }

    const filter = (query: string, optionValue: Item["value"], optionLabel: Item["label"]): boolean => {
        // Convert both the query and the option values to lowercase for a case-insensitive search
        const lowerCaseQuery = query.trim().toLowerCase();
        return optionValue.toLowerCase().includes(lowerCaseQuery) || optionLabel.toLowerCase().includes(lowerCaseQuery);
    };


    return (
        <ChocAutoComplete
            openOnFocus
            disableFilter={disableFilter}
            listAllValuesOnFocus
            emphasize={{ color: "var(--primary)" }}
            onSelectOption={(params) => onSelect(params.item.value)}
            filter={filter}
        
        >
            <AutoCompleteInput
                readOnly={disableFilter}
                className={classNameInput}
                variant={variant ?? 'outline'}
                dir="ltr"
                placeholder={value}
                textAlign={centerInput ? "center" : 'start'}
                cursor={disableFilter ? "pointer" : "text"}     
                
            />
            <AutoCompleteList
                dir={!isRTL ? "ltr !important" : ""}
                //width="85%"
                shadow="md"
                {...props}
            >
                {isGroup ?
                    <>
                        {(items as GroupItems[]).map((group, index) => (
                            <AutoCompleteGroup key={index}>
                                <AutoCompleteGroupTitle textTransform="capitalize">
                                    {group.title}
                                </AutoCompleteGroupTitle>
                                {group.items.map((item) => (
                                    <AutoCompleteItem
                                        key={item.value}
                                        value={item.value}
                                        dir={!isRTL ? "ltr" : ""}
                                        _focus={{ background: "gray.100" }}
                                        //onClick={() => item.action()}
                                    >
                                        {item.label}
                                    </AutoCompleteItem>
                                ))}
                                {index !== items.length - 1 && <Divider />}
                            </AutoCompleteGroup>
                        ))}
                    </>
                    :
                    <>
                        {(items as Item[]).map((item) => (
                            <AutoCompleteItem
                                key={item.value}
                                value={item.value}
                                dir={!isRTL ? "ltr" : ""}
                                _focus={{ background: "gray.100" }}
                                justifyContent={centerItem ? "center" : "start"}
                                //onClick={() => item.action()}
                            >
                                {item.label}
                            </AutoCompleteItem>
                        ))}
                    </>


                }

                {/* 
                {items.map((item, index) => (
                    <AutoCompleteGroup key={index.toString()} showDivider>

                    </AutoCompleteGroup>
                ))} */}

            </AutoCompleteList>
        </ChocAutoComplete>
    );
}

export default React.memo(AutoComplete);