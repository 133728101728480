import React from "react";
import FormCheckBox from "../../components/form/checkbox";
import FormPhone from "../../components/form/phone";
import FormText from "../../components/form/text";
import TermsLinks from "../../components/terms-links/terms-links";
import { SignupForm } from "../../forms/signup";
import { useTranslation } from "../../hooks/use-translate";

export function SignUpForm({ formik }: { formik: any }) {
    const t = useTranslation('PAGES.SIGNUP');



    return (
        <>
            <FormText<SignupForm> name="bs_name"
                formik={formik}
                placeholder={t('TEXT_NAME')}
                inputMode="text"
                onTouchStart={(e) => e.preventDefault()}
                />
            <FormText<SignupForm> name="email"
                formik={formik}
                placeholder={t('TEXT_EMAIL')}
                inputMode="email"
                onTouchStart={(e) => e.preventDefault()}
                />
            <FormPhone<SignupForm> name="phone"
                formik={formik}
                placeholder={t('TEXT_WHATSAPP_PHONE_NUMBER')}
                onTouchStart={(e) => e.preventDefault()}
                className="gray"
                />
            <FormCheckBox<SignupForm> 
            name="checkbox" 
            formik={formik} 
            className="form-checkbox"
            onTouchStart={(e) => e.preventDefault()}
            >
                <TermsLinks />
            </FormCheckBox>
        </>
    )
}