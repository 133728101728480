
import { Theme } from '../enums/enums';
import { action, actionWithPayload } from './action-factory';

export class RootActions {
  public static init = action(
    '[Root] Init'
  );

  public static toggleTheme = actionWithPayload<{ theme: Theme }>(
    '[Root] Toggle theme'
  );

  public static setLanguage = actionWithPayload<{ language: string }>(
    '[Root] Set language'
  );
}
