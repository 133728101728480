import React, { useEffect, useState, useRef } from 'react';
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import AwsS3 from '@uppy/aws-s3';
import Compressor from '@uppy/compressor';
import { AgentActions, AgentSelectors, FileLoader, LoaderType } from 'src/redux/agents';
import { useDispatch, useSelector } from 'react-redux';
import { configuration } from "../../../configurations/configuration"
import { List, ListItem, Checkbox, Divider, Flex } from '@chakra-ui/react';
import Icon, { IconTheme, IconVariant } from 'src/components/icon/icon';
import Button from 'src/components/button/button';










function FileLoaderComponent() {
    const currentAgent = useSelector(AgentSelectors.currentAgent)
    const dispatch = useDispatch()



    const uppy = useRef<Uppy>();
    const [selectAll, setSelectAll] = useState(true)
    const [docs, setDocs] = useState<FileLoader[]>([])

    const txtOrPdf = ['text/plain', 'application/pdf', "text/html"]
    const csvOrExcel = ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']



    useEffect(() => {
        const uppyInstance = new Uppy({
            restrictions: {
                allowedFileTypes: [...txtOrPdf, ...csvOrExcel]
            }
        })
            .use(AwsS3, {
                companionUrl: `${configuration.api_url}/agent-data/${currentAgent!.agent_id}`
            })
            .use(Compressor)
            .use(Dashboard, {
                inline: true,
                showProgressDetails: true,
                proudlyDisplayPoweredByUppy: false,
                height: 210,
                width: "100%",
                target: '#myUploadContainer',
                hideCancelButton: true,
                hideUploadButton: true,
                hideProgressAfterFinish: true
            })

        uppy.current = uppyInstance;

        uppy?.current?.on('file-added', handleFileAdded);
        uppy?.current?.on('upload-success', uploadSuccess);

        return () => {
            uppyInstance.close();
        };
    }, []);





    useEffect(() => {
        if (!currentAgent?.loaders) {
            setDocs([]);
            return;
        }

        const filteredFiles = currentAgent.loaders.filter((loader) =>
            [LoaderType.PDF, LoaderType.TXT, LoaderType.CSV, LoaderType.HTMLHierchy].includes(loader.type) && !(loader as FileLoader).toDelete
        );

        setDocs(filteredFiles);
    }, [currentAgent?.loaders]);

    useEffect(() => {
        setSelectAll(docs.every((doc) => doc.checked))
    }, [docs])





    const getTypeLoader = (mimeType: string): LoaderType => {
        switch (mimeType) {
            case "text/plain":
                return LoaderType.TXT
            case "text/csv":
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            case "application/vnd.ms-excel":
                return LoaderType.CSV
            case "application/pdf":
                return LoaderType.PDF
            case "text/html":
                return LoaderType.HTMLHierchy
            default:
                return LoaderType.UNKNOWN
        }
    };



    const handleFileAdded = (file: any) => {
        const fileName = file.name
        const exsitFile = docs.find((d) => d.id === fileName && d.toDelete === false)
        if (exsitFile) {
            uppy!.current!.info(`The ${fileName} is alredy exsits`, 'error', 3000);
        }
        else { 
            uppy!.current!.upload();
        }

    };

    const uploadSuccess = (file: any) => {
        const loader: FileLoader = {
            id: file.name,
            type: getTypeLoader(file.type),
            checked: true
        }
        dispatch(AgentActions.onAddLoader({ loader }))
    }







    const deleteFile = async (fileKey: string): Promise<void> => {
        uppy!.current!.removeFile(fileKey);
        dispatch(AgentActions.onRemoveLoader({ loader_id: fileKey }))

    }




    const onHandleSelectAll = (checked: boolean) => {
        docs.forEach((doc) => {
            dispatch(AgentActions.onChangeCheckedLoader({ loaderType: doc.type, id: doc.id, checked }));
        });
    }




    const onChangeChecked = (docObj: FileLoader, checked: boolean) => {
        dispatch(AgentActions.onChangeCheckedLoader({ loaderType: docObj.type, id: docObj.id, checked }))
    }






    return (
        <div className="loader file-loader">

            <div id="myUploadContainer"></div>


            {!!docs.length && <List className="loader-list">
                <ListItem >
                    <Checkbox
                        onChange={(e) => onHandleSelectAll(e.target.checked)}
                        isChecked={selectAll}
                        zIndex={1}
                        colorScheme="primary"
                        mb={1}
                    >
                        Select All
                    </Checkbox>
                </ListItem>
                {docs.map((docObj, i) => <ListItem key={i} lineHeight={1}>
                    <Flex justifyContent="space-between" alignItems="center">
                        <Checkbox
                            onChange={(e) => onChangeChecked(docObj, e.target.checked)}
                            isChecked={docObj.checked}
                            zIndex={1}
                            colorScheme="primary"
                            mb={1}>
                            {docObj.id}
                        </Checkbox>
                        {/* <CIcon as={DeleteIcon} boxSize={5} /> */}
                        <Button isIcon onClick={() => deleteFile(docObj.id)}>
                        <Icon variant={IconVariant.TRASH} theme={IconTheme.GRAY} />
                        </Button>
                    </Flex>
                    {i !== (docs.length - 1) && <Divider />}
                </ListItem>
                )}
            </List>}





        </div >
    );
}


export default React.memo(FileLoaderComponent)