import { cloneDeep } from 'lodash';
import { Edge, Node } from 'reactflow';
import { NodeType } from '../enums/enums';
import { useNextNodeNameByType, useNextNodeOrderOfType } from './use-next-node-order';
import { ChatbotNodeData } from '../models';
import { getNodeID, getOptionID, getEdgeID } from '../helpers/wrokflow-builder/get-id';
import { getRelatedEdges } from '../helpers/wrokflow-builder/get-related-edges';

export function useCopyElements(): (sourceNodes: Array<Node<ChatbotNodeData>>, sourceEdges: Array<Edge>, generateNewID?: boolean) => any { // Elements is a type from reactflow
  const getNextNodeOrderOfType = useNextNodeOrderOfType();
  const getNextNodeNameByType = useNextNodeNameByType();

  return (sourceNodes: Array<Node<ChatbotNodeData>>, sourceEdges: Array<Edge>, generateNewID?: boolean) => {
    const nodes = sourceNodes.map(cloneDeep);
    const edges = sourceEdges.map(cloneDeep);
    const nodeTypeCounters = {
      [NodeType.TEXT_QUESTION]: getNextNodeOrderOfType(NodeType.TEXT_QUESTION),
      [NodeType.SEND_MESSAGE]: getNextNodeOrderOfType(NodeType.SEND_MESSAGE),
      [NodeType.NUMBER]: getNextNodeOrderOfType(NodeType.NUMBER),
      [NodeType.OPTIONS]: getNextNodeOrderOfType(NodeType.OPTIONS),
      [NodeType.VALID_MAIL]: getNextNodeOrderOfType(NodeType.VALID_MAIL),
      [NodeType.VALID_LOCATION]: getNextNodeOrderOfType(NodeType.VALID_LOCATION),
      [NodeType.SEND_LOCATION]: getNextNodeOrderOfType(NodeType.SEND_LOCATION),
      [NodeType.VALID_DATE]: getNextNodeOrderOfType(NodeType.VALID_DATE),
      [NodeType.DISTANCE]: getNextNodeOrderOfType(NodeType.DISTANCE),
      [NodeType.TASK]: getNextNodeOrderOfType(NodeType.TASK),
      [NodeType.MEDIA]: getNextNodeOrderOfType(NodeType.MEDIA),
      [NodeType.RECEIVING_MEDIA]: getNextNodeOrderOfType(NodeType.RECEIVING_MEDIA),
      [NodeType.WEBHOOK]: getNextNodeOrderOfType(NodeType.WEBHOOK),
      [NodeType.POPUP]: getNextNodeOrderOfType(NodeType.POPUP),
      [NodeType.API]: getNextNodeOrderOfType(NodeType.API),
      [NodeType.POWER_WORDS]: getNextNodeOrderOfType(NodeType.POWER_WORDS),
      [NodeType.JUMP]: getNextNodeOrderOfType(NodeType.JUMP),
      [NodeType.FAKE_END]: getNextNodeOrderOfType(NodeType.FAKE_END),
      [NodeType.BUTTONS]:getNextNodeOrderOfType(NodeType.BUTTONS),
      [NodeType.TEMPLATES]:getNextNodeOrderOfType(NodeType.TEMPLATES),
      [NodeType.WHATSAPP_LIST]:getNextNodeOrderOfType(NodeType.WHATSAPP_LIST),
      [NodeType.NOTIFICATION]:getNextNodeOrderOfType(NodeType.NOTIFICATION),
      [NodeType.MAKE]:getNextNodeOrderOfType(NodeType.TEXT_QUESTION),
      [NodeType.ZAPIER]:getNextNodeOrderOfType(NodeType.TEXT_QUESTION),
      [NodeType.FILTER]:getNextNodeOrderOfType(NodeType.FILTER),
      [NodeType.PLUGIN_AGENT]:getNextNodeOrderOfType(NodeType.PLUGIN_AGENT),
      [NodeType.END]: getNextNodeOrderOfType(NodeType.END)
    };

 

    nodes.forEach((node: Node<ChatbotNodeData>) => {
      const id = getNodeID();
      const options = node.data!.options?.map((option) => ({ ...option, id: getOptionID() })) ?? [];
      let switchIndex = 0
      switch (node.type) {
        case NodeType.WHATSAPP_LIST:
          node.data!.obj?.waList?.sections?.forEach((section) => {
            section?.rows.map((row) => {
              row.id = options[switchIndex].id;
              switchIndex++;
            })
          })
          break;
        case NodeType.FILTER:
          node.data!.obj?.filterObject?.groups?.forEach((group) => {
            group.id = options[switchIndex].id;
            switchIndex++;
          })
          node.data!.obj!.filterObject!.elseID = options[switchIndex].id ;
          break;
      }
      const connectedEdges = getRelatedEdges(edges, [node]);
      connectedEdges.forEach((edge) => {
        if (edge.target === node.id) {
          edge.target = id;
          edge.targetHandle = id;
        } else {
          if (node.data!.options?.length) {
            const index = node.data!.options!.findIndex((option) => option.id === edge.sourceHandle);
            edge.source = id;
            edge.sourceHandle = options[index].id;
          } else {
            edge.source = id;
            edge.sourceHandle = id;
          }
        }
        edge.id = getEdgeID(edge.source, edge.target, edge.sourceHandle);
      });
      node.id = id;
      node.data!.id = id;
      node.data!.options = options;
      node.draggable = true;
      if (!generateNewID) {
        node.data!.isSelected = true;
        node.position = { x: node.position.x, y: node.position.y + 500 };
        node.data!.orderOfType = nodeTypeCounters[node.type as keyof typeof nodeTypeCounters]++;
        node.data!.name = getNextNodeNameByType(node.type as NodeType, node.data!.orderOfType)
      } 

    });

    return [...nodes, ...edges];
  };
}
