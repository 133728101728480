import { ChatbotNodeData } from "src/models/chat-bot-node";
import { Edge, Node } from 'reactflow';


export interface Builder {
    builder_id: number;
    builder_name: string;
    chat_builder: Array<Node<ChatbotNodeData> | Edge>;
    last_updated?: Date;
    status: boolean;
    configuration: ConfigurationBuilder;
    //tempVariables?: Variables[];
  }
  
  export interface ConfigurationBuilder {
    style: string;
    logo?: string;
    language?:Language;
  }

  export enum ConfigurationBuilderType {
    STYLE = "style",
    LOGO = "logo",
    
  }

  export enum Language{
    EN = "en",
    HE = "he"
  
  }



 export const styleDefualt = `
 /* 1. Sets the background color of the widget body*/
 .widget {
     background: linear-gradient(270deg, #FFF8F2, #F1F3FF);
 }
 
 
 
 /* 2. Styles the message text for the user within the chat content area */
 .widget-content-chat .message-text {
     background: white; 
     color: black; 
 }
 
 /* 3. Styles the message text for the bot within the chat */
 .widget-content-chat .message.inbound .message-text {
     background-color: #5b61eb; 
     color: white; 
 }
 
 `





  
//   export interface Variables {
//     key: string;
//     value: string;
//     duplicate: boolean;
//   }