import React from 'react'
import { Icon as CIcon } from '@chakra-ui/react'
import { BsViewList, BsViewStacked } from 'react-icons/bs';
import { WidgetMode } from '../../pages/web-channel/widget-temp';
import Button from '../button/button';








type Props = {
  mode: WidgetMode
  isChat: boolean;
  logo?: string;
  toogleView?: () => void

}



export function WidgetHeader({ isChat, mode, logo, toogleView }: Props) {




  // const isRTL = useRTL()
  // const t = useTranslation('PAGES.WIDGET');
  //const logo = mode !== WidgetMode.AI





  return (
    <>
      <header className="widget-header">
        {!isChat && <div className="widget-header-logo">
          {logo && <img className="widget-logo" src={logo} />}

        </div>}

        {mode === WidgetMode.PREVIEW && <>
          <Button isIcon onClick={toogleView} >
            {<CIcon as={isChat ? BsViewList : BsViewStacked} boxSize={5} />}
          </Button>
        </>}
      </header>
    </>
  )
}
