import React from 'react'
import { Flex, Card, CardBody, CardFooter, Image } from "@chakra-ui/react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { PathName } from "src/enums/enums"
import { RoleTypes, AgentActions, Agent } from "src/redux/agents"
import { v4 } from 'uuid'
import OpenAI from '../../assets/images/open-ai-big.svg';
import NewChatBig from '../../assets/images/new-chat-big.svg';
import { defaultStyle } from 'src/components/agent-componnets/agent-variables'

const templates = [
    {
        id: "custom",
        title: 'Create AI Agent',
        image: NewChatBig,
        color:"#5B61eb"
    },
    {
        id: "openai",
        title: 'Embed GPT Assistant',
        image: OpenAI,
        color:"black"
    }

]

export default function AgentTempalte() {
    const dispatch = useDispatch()
    const navigate = useNavigate()


    const CreateNewAgent = (isCustomAgent: boolean): Agent => {
        let newAgent: Agent
        if (isCustomAgent) {
            newAgent = {
                agent_id: v4(),
                model:"GPT4",
                name: '',
                conditions: [
                    {
                        if: '',
                        then: ''
                    }
                ],
                loaders: [],
                company_name: '',
                conversation_purpose:'',
                role: Object.keys(RoleTypes).find((key) => RoleTypes[key as keyof typeof RoleTypes] === 'Sales')!,
                style: defaultStyle
            }

        } else {
            newAgent = {
                agent_id: v4(),
                openai_api_key: '',
                assistant_id: '',
                style: defaultStyle
            }
        }
        return newAgent

    }



    const handleClick = (isCustomAgent: boolean) => {
        const agent = CreateNewAgent(isCustomAgent)
        dispatch(AgentActions.SetCurrentAgent({ currentAgent: agent }))
        navigate(PathName.INGEST_AGENT, { state: { isCustomAgent } })

    }



    return (
        <Flex gap={10} p={10} minHeight="50vh" justifyContent="center" wrap="wrap" alignItems="center">
            {templates.map((template, i) =>
                <Card
                    key={i}
                    alignItems="center"
                    cursor="pointer"
                    minW={350}
                    maxHeight={320}
                    minHeight={250}
                    borderRadius={30}
                    _hover={{
                        filter: "drop-shadow(0px 4px 4px rgba(155, 155, 155, 0.5))"
                    }}
                    onClick={() => handleClick(template.id === 'custom')}
                >
                    <CardBody display="flex" alignItems="center">
                        <Image
                            objectFit="cover"
                            src={template.image}
                        />
                    </CardBody>
                    <CardFooter color={template.color} fontSize={18}>
                        {template.title}
                    </CardFooter>

                </Card>)}
        </Flex>
    )
}