import classNames from 'classnames';
import React, { ReactElement } from 'react';
import './logo-channel.scss';

export enum LogoChannelVariant {
  MAYTAPI = 'maytapi',
  OFFICELY = 'officely',
  TWILIO = 'twilio',
  TYNTEC = 'tyntec',
  META='meta',
  WHATSAPP = 'whatsapp',
  OFFICELY_SMALL = 'officely-small',
  MAYTAPI_SMALL = 'maytapi-small',
  META_SMALL='meta-small',
  WHATSAPP_SMALL = 'whatsapp-small',
  ZENDESK_SMALL = 'zendesk-small'
}

export enum LogoChannelTheme {
  DEFAULT = 'default',
  WHITE = 'white',
  BLACK = 'black',
  PRIMARY = 'primary',
  GRAY = 'gray'
}

export default function LogoChannel({ variant, theme }: { variant: LogoChannelVariant; theme?: LogoChannelTheme}): ReactElement {
  return (
    <span
      className={classNames(['logoChannel', 
      `logoChannel-${variant}`, 
      { 'logoChannel-mask': !!theme }, 
      { [`logoChannel-${theme}`]: !!theme }])
    }
    />
  );
}
