import { Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerBody, Box, StackDivider, Flex, VStack, IconButton } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Agent } from 'src/redux/agents';
import { AuthSelectors } from 'src/redux/auth';
import { AgentServices } from 'src/services';
import Editor from '@monaco-editor/react';
import { CgArrowsExpandLeft } from "react-icons/cg";
import { IoPlayOutline } from 'react-icons/io5';




function RunOnce({ isOpen, agent, nodeID, onClose }: {
    isOpen: boolean,
    agent: Agent;
    nodeID?: string;
    onClose: () => void

}) {
    const user = useSelector(AuthSelectors.user)
    const apiService = new AgentServices(user!.token!)
    const [inputs, setInputs] = React.useState<string>(JSON.stringify(agent?.inputs) || '{}')
    const [answer, setAnswer] = React.useState<string | null>(null)
    const isMounted = React.useRef(true)
    const editorRef = React.useRef<any>(null)
    const [showInputs, setShowInputs] = React.useState<boolean>(false)

    //const monaco = useMonaco();


    useEffect(() => {
        if (isMounted.current && isOpen) {
            run()
        }
        return () => { isMounted.current = false }


    }), [nodeID]

    const foldTopLevelJSON = (editor: any) => {
        const model = editor.getModel();
        const lineCount = model.getLineCount();
        editor.trigger('', 'editor.foldAll');
        // Unfold the root level (first line)
        editor.trigger('', 'editor.unfold', { startLineNumber: 2, endLineNumber: lineCount });
    }




    const handleEditorDidMount = (editor: any, _: any) => {
        editorRef.current = editor;
        editor.getAction('editor.action.formatDocument').run().then(() => {
            foldTopLevelJSON(editor);
        });
    };









    const run = async () => {
        if (!nodeID) return;
        const res = await apiService.runOnceTeam(agent!.agent_id, nodeID, inputs);
        if (res.success) {
            const response = res.data['response'];
            const inputs = JSON.stringify(res.data['inputs'], null, 2);
            setAnswer(response);
            if (editorRef.current) {
                editorRef.current.setValue(inputs);
                editorRef.current.getAction('editor.action.formatDocument').run().then(() => {
                    foldTopLevelJSON(editorRef.current);
                });
            }
            setInputs(inputs)
        } else {
            alert(res.err);
        }
     };

    return (
        <Drawer onClose={onClose} placement={"bottom"} isOpen={isOpen} size="xl">
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <IconButton
                    aria-label="toggle inputs"
                    icon={<CgArrowsExpandLeft />}
                    onClick={() => setShowInputs(!showInputs)}
                    backgroundColor="white"
                    boxSize={3}
                    position="absolute"
                    left="1rem"
                    top="1rem" 
                    _hover={{opacity: 0.5}}
                />
                <IconButton
                    aria-label="run again"
                    icon={<IoPlayOutline />}
                    onClick={run}
                    backgroundColor="white"
                    boxSize={3}
                    position="absolute"
                    left="3rem"
                    top="1rem" 
                    _hover={{opacity: 0.5}}

                />
                <DrawerBody h="90vh" overflow="hidden" >
                    <Flex flexDir="column" gap={10}>
                        <VStack
                            mt={10}
                            divider={<StackDivider borderColor="gray.200"
                                h="100%"
                            />

                            }
                        >

                            <Box w="100%" hidden={!showInputs} >
                                <Editor
                                    height="45vh"
                                    defaultLanguage="json"
                                    value={inputs}
                                    defaultValue={"{}"}

                                    //theme="vs-dark"

                                    onMount={handleEditorDidMount}
                                    className="node-option-input"
                                    options={{
                                        minimap: { enabled: false },
                                        scrollbar: {
                                            vertical: "hidden",
                                            horizontal: "hidden"
                                        }
                                    }}
                                    onChange={(value) => setInputs(value ?? '')}
                                />
                            </Box>
                            <Box pos="relative" mt={5} flexGrow={1} maxH="30vh" w="100%" alignSelf="baseline" overflowY="auto">

                                {answer ?? 'loading...'}
                            </Box>
                        </VStack>


                    </Flex>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}

export default RunOnce