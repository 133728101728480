import { Theme } from "../enums/enums";


export class RootState {
  public theme: Theme;
  public language: string;

  constructor() {
    this.theme = Theme.DEFAULT;
    this.language = "en";
  }
}
