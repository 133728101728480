import { maxBy } from 'lodash';
import { Node } from 'reactflow';
import { useSelector } from 'react-redux';
import { NodeType } from '../enums/enums';
import { ChatbotNodeData } from '../models';
import { ChatBuilderSelectors } from '../redux/chat-builder';

export function useNextNodeOrderOfType(): (type: NodeType) => number {
  const nodes = useSelector(ChatBuilderSelectors.nodes);
  return (type: NodeType) => (maxBy(nodes.filter((node: Node<ChatbotNodeData>) => node.type === type), 'data.orderOfType')
    ?.data
    ?.orderOfType ?? 0) + 1;
  
}


export function useNextNodeNameByType(): (type: NodeType, orderType:number) => string | undefined {
  return (type: NodeType, orderType:number) => {
    switch (type) {
      case NodeType.TEXT_QUESTION:
        return `text${orderType}`;
      case NodeType.SEND_MESSAGE:
        return `message${orderType}`;
      case NodeType.NUMBER:
        return `number${orderType}`;
      case NodeType.OPTIONS:
        return`options${orderType}`;
      case NodeType.VALID_MAIL:
        return `mail${orderType}`;
      case NodeType.VALID_LOCATION:
        return `location${orderType}`;
      case NodeType.VALID_DATE:
        return `date${orderType}`;
      case NodeType.DISTANCE:
        return `distance${orderType}`;
      case NodeType.MEDIA:
        return `media${orderType}`;
      case NodeType.RECEIVING_MEDIA:
          return `reaciveMedia${orderType}`;
      case NodeType.WEBHOOK:
        return `http${orderType}`;
      case NodeType.API:
       return  `catchHook${orderType}`;
      case NodeType.BUTTONS:
        return `buttons${orderType}`
      case NodeType.TEMPLATES:
        return `templates${orderType}`
      case NodeType.WHATSAPP_LIST:
        return `whatsappList${orderType}`
      case NodeType.NOTIFICATION:
        return `notification${orderType}`
      case NodeType.FILTER:
        return `filter${orderType}`
      case NodeType.PLUGIN_AGENT:
        return `AIAgent${orderType}`
      case NodeType.END:
        return `end${orderType}`

    }
  }
  
}

