import React from 'react';

const AddToSunshineConversations = () => {
    const authorizeUrl = "https://app.smooch.io/oauth/authorize?client_id=officelyai&response_type=code&redirect_uri=https://api.officely.ai/oauth/sunshine";

    const openInSmallWindow = (url:string) => {
        window.open(url, '_blank', 'width=600,height=500');
    };

    return (
        <div>
            <button onClick={() => openInSmallWindow(authorizeUrl)}>
                <img
                    alt="Add to Sunshine Conversations"
                    height="40"
                    width="278"
                    src="https://cdn.smooch.io/images/add_to_sunshine_conversations.png"
                    srcSet="https://cdn.smooch.io/images/add_to_sunshine_conversations.png 1x, https://cdn.smooch.io/images/add_to_sunshine_conversations@2x.png 2x"
                />
            </button>
            <p>Or, you can <a href={authorizeUrl} className="link" target="_blank" rel="noopener noreferrer">click here to open in a new tab</a> and edit the URL.</p>
        </div>
    );
};

export default AddToSunshineConversations;
