import React,  { useEffect, useRef } from "react";
import { Spinner } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { AuthSelectors } from "src/redux/auth";
import { useFetchOfficely } from "src/hooks/use-fetch-officely";
import { Method } from "src/enums/enums";
import { useLocation } from "react-router-dom";

function SunshineOauth() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');

    const user = useSelector(AuthSelectors.user)
    const isMounted = useRef(true);



    useEffect(() => {
        const fetchApi = async (): Promise<void> => {
          const res = await useFetchOfficely(`oauth/sunshine/${code}`, Method.POST, undefined, user.token)
          if (res.success && res.data) {
           window.close()
          }
        };
    
        if (isMounted.current) {
          fetchApi();
        }
        return () => {
          isMounted.current = false;
    
        }
    
      }, [code])
    return (
        <div>
            <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="primary.500"
                size="xl"
                margin="100px auto" />
        </div>
    )
}


export default SunshineOauth