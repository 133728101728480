import React from 'react';
import { createRoot } from 'react-dom/client';

import './styles/styles.scss';
import './index.scss';
import App from './App';
import './i18n';
import { store } from './redux';
import { Provider } from 'react-redux';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';



const theme = extendTheme({
  colors: {
    gray: {
      100: '#f8f8f8'
    },
    red:{
      400:"#E53E3E",
      500:"#F56565"
    },
    primary: {
      50: '#d6d8f8',
      100: '#a6aaed',
      200: '#787ee2',
      300: '#4b52d8',
      400: '#2b31cd',
      500: '#5b61eb',
      600: '#272fb3',
      700: '#1c2385',
      800: '#121856',
      900: '#070a29'
    },
    secondly: {
      50: '#F7F8FD',   
      100: '#EFF1FE',  
      200: '#E7E9FE',   
      300: '#DFE1FE',   
      400: '#D7D9FE',   
      500: '#F0F2FF',  
      600: '#C8CAFE',   
      700: '#A0A4FE',   
      800: '#787EFE',   
      900: '#5058FE'    
    },
    yellow:{
      500:"#FFC865"
    }
  }
})




store.subscribe(() => {
  localStorage.setItem('reduxState', JSON.stringify(store.getState()))
  localStorage.setItem('timeStore', JSON.stringify(Date.now()))

})



const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
    </Provider>
  </React.StrictMode>
  
  )




