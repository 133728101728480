import React, { useState, useRef, useEffect } from 'react'
import { WidgetHeader } from '../../../components/officely-web-componnents'
import WidgetContent from '../../../components/officely-web-componnents/widget-content'
import { Chat, ExtraElementChat, WidgetButtons } from '../interfaces'
import WidgetTemp, { WidgetMode } from '../widget-temp'



export interface DateRefEntry {
    element: HTMLDivElement | null;
    date: string;
  }


export default function LiveMode({ chatHistory, img }: {
    chatHistory: Array<Chat>
    img?: string
}) {

    const [answer, setAnswer] = useState<string | null>()
    const [loading, setLoading] = useState<boolean>()
    const [visibleDate, setVisibleDate] = useState('');
    const dateRefs = useRef<{ [key: string]: DateRefEntry }>({});

    useEffect(() => {
        setVisibleDate(chatHistory[0].timestamp?.date ?? '')
    }, [chatHistory])





    // const GetQuestion = async (body: any) => {
    //     console.log('getQuestion')
    // }

    // eslint-disable-next-line 
    const handleClickBtn = (payload: WidgetButtons) => {
        //console.log('handleClickBtn')
    }

    // eslint-disable-next-line 
    const handleSubmit = ({ payload, value, extraElement, skip }: {
        payload?: WidgetButtons,
        value?: string,
        extraElement?: ExtraElementChat,
        skip?: boolean
    }) => {
        //console.log('handleSubmit')


    }














    return (
        <WidgetTemp className="live">
            <WidgetHeader
                isChat
                mode={WidgetMode.LIVE}
            //settingsWebBuilder={settingsWebBuilder}
            />
            <div className="datetime">{visibleDate}</div>
            <WidgetContent
                isChat
                chatHistory={chatHistory}
                showInput={false}
                showPowerBy={false}
                answerState={{ answer, setAnswer }}
                loadingState={{ loading, setLoading }}
                handleClickBtn={handleClickBtn}
                handleSubmit={handleSubmit}
                dateVisableState={{ visibleDate, setVisibleDate }}
                img={img}
                dateRefs={dateRefs}


            >
            </WidgetContent>
        </WidgetTemp>
    )
}



