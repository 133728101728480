import { Method, NodeType } from '../enums/enums';
import { useFetchOfficely } from './use-fetch-officely';
import { ChatbotNodeData, User } from '../models';
import { AuthActions, AuthSelectors } from '../redux/auth';
import { Builder, ChatBuilderActions, ChatBuilderSelectors } from '../redux/chat-builder';
import { Edge, Node } from 'reactflow';
import { useDispatch, useSelector } from 'react-redux';
import { AgentSelectors } from 'src/redux/agents';




export enum ChatBotActions {
    PUBLISH = 'PUBLISH',
    SAVE = 'SAVE',
    PREVIEW = 'PREVIEW',
}

const handlePublishBuilder = async (
    user: User,
    elements: Array<Node<ChatbotNodeData> | Edge>,
    currentBuilder: Builder,
    dispatch: (dispatch: any) => void,
    toggleAction: () => void
): Promise<boolean> => {
    const tempElements = elements.map((elem) => ({ ...elem, data: { ...elem.data, isError: false } }))
    const body = {
        uphone_number: user.uphone_number,
        chat_builder: tempElements,
        builder_id: currentBuilder!.builder_id,
        configuration: currentBuilder!.configuration,
        status: true,
        with_reset: false
    };
    const res = await useFetchOfficely(`PublishBuilder`, Method.POST, body)
    if (res.success) {
        dispatch(AuthActions.setBuilderActive({ id: currentBuilder!.builder_id }))
        dispatch(AuthActions.UpdateChatBuildersByID({ chat_builder: elements, id: currentBuilder!.builder_id }))
        dispatch(ChatBuilderActions.setCurrentBuilderActive())
        toggleAction()
    }

    return res.success ?? false

};


const onAutoSaveDev = async (
    user: User,
    elements: Array<Node<ChatbotNodeData> | Edge>,
    currentBuilder: Builder,
    dispatch: (dispatch: any) => void
): Promise<boolean> => {
    const body = {
        uphone_number: user.uphone_number,
        builder_id: currentBuilder!.builder_id,
        chat_builder: elements,
        configuration: currentBuilder!.configuration
    }
    const res = await useFetchOfficely('autoSaveDev', Method.POST, body)
    if (res.success) {
        dispatch(AuthActions.UpdateChatBuildersByID({ chat_builder: elements, id: currentBuilder!.builder_id }))
    }
    return res.success ?? false

}


const handlePreviewAction = async (
    user: User,
    elements: Array<Node<ChatbotNodeData> | Edge>,
    currentBuilder: Builder,
    toggleAction?: () => void
): Promise<boolean> => {
    const tempElements = elements.map((elem) => ({ ...elem, data: { ...elem.data, isError: false } }))
    const body = {
        uphone_number: user.uphone_number,
        chat_builder: tempElements,
        builder_id: currentBuilder!.builder_id,
        configuration: currentBuilder!.configuration
    };
    const res = await useFetchOfficely('PostDevBuilder', Method.POST, body)
    if (res.success) {
        toggleAction && toggleAction();
    }
    return res.success ?? false
}


export function useCheckMap(): () => boolean {
    const edges = useSelector(ChatBuilderSelectors.edges);
    const nodes = useSelector(ChatBuilderSelectors.nodes);
    const agents = useSelector(AgentSelectors.agents)


    const dispatch = useDispatch();


    const ExistsSource = (id: string): boolean => !!edges.filter((edge) => edge.source === id || edge.sourceHandle === id).length
    const ExistsTarget = (id: string): boolean => !!edges.filter((edge) => edge.target === id || edge.targetHandle === id).length

    return () => {
        let returnType = false
        for (const x of nodes) {
            let onExsits = true;
            switch (x.type) {
                case NodeType.START:
                    onExsits = ExistsSource(x.id);
                    break;
                case NodeType.MEDIA:
                case NodeType.TEMPLATES:
                case NodeType.BUTTONS:
                case NodeType.WHATSAPP_LIST:
                case NodeType.OPTIONS:
                    for (const o of x.data.options) {
                        if (!ExistsSource(o.id)) { onExsits = false; break; }
                    }
                    onExsits = onExsits && ExistsTarget(x.id)
                    break;
                case NodeType.POWER_WORDS:
                    for (const o of x.data.options) {
                        if (!ExistsSource(o.id)) { onExsits = false; break; }
                    }
                    break;
                case NodeType.WEBHOOK:
                    onExsits = x.data.obj.type === NodeType.END && x.data.obj.method === Method.GET ?
                        true : ExistsSource(x.id) && ExistsTarget(x.id)
                    break;
                case NodeType.API:
                    onExsits = x.data.obj.type === NodeType.START ? ExistsSource(x.id) : true
                    break;
                case NodeType.END:
                case NodeType.FAKE_END:
                case NodeType.NOTIFICATION:
                    onExsits = ExistsTarget(x.id)
                    break;
                case NodeType.PLUGIN_AGENT:  
                    onExsits = ExistsTarget(x.id) && agents.findIndex((n) => n.agent_id === x.data?.obj?.agent?.agent_id) !== -1;
                    break;
                case NodeType.JUMP:
                    onExsits = ExistsTarget(x.id) && nodes.findIndex((n) => n.id === x.data.obj?.jump) !== -1;
                    break;
                // case NodeType.PLUGIN_AGENT:
                //     onExsits = ExistsTarget(x.id) &&  agents.findIndex((a) => a.agent_id === x.data.obj?.agent?.id ) !== -1
                //     break
                default:
                    onExsits = ExistsSource(x.id) && ExistsTarget(x.id)
                    break;
            }
            dispatch(ChatBuilderActions.onError({ id: x.id, bool: !onExsits }))
            returnType = !onExsits ? true : returnType
        }
        return !returnType //false is good map
    }


}

export function useChatBotFunctionActions(): (
    action: ChatBotActions,
    toggleAction?:() => void
) => Promise<boolean> {

    const user = useSelector(AuthSelectors.user);
    const elements = useSelector(ChatBuilderSelectors.chatScheme);
    const currentBuilder = useSelector(ChatBuilderSelectors.currentBuilder);
    const dispatch = useDispatch();

    return async (action: ChatBotActions, toggleAction) => {
        switch (action) {
            case ChatBotActions.PUBLISH:
                return await handlePublishBuilder(user, elements, currentBuilder!, dispatch, toggleAction!)
            case ChatBotActions.SAVE:
                return await onAutoSaveDev(user, elements, currentBuilder!, dispatch);
            case ChatBotActions.PREVIEW:
                return await handlePreviewAction(user, elements, currentBuilder!, toggleAction);

        }
    }
}

