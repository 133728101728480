import React, { ReactElement, useState } from "react";
import { useFormik } from 'formik';
import { MayTapiForm } from '../../forms/MayTapi';
import { useTranslation } from "../../hooks/use-translate";
import FormText from "../form/text";
import Button, { ButtonVariant } from "../button/button";
import { useDispatch, useSelector } from "react-redux";
import { AuthActions, AuthSelectors } from "../../redux/auth";
import { useFetchOfficely } from "../../hooks/use-fetch-officely";
import { Method } from "../../enums/enums";
import { useRTL } from "../../hooks/use-rtl";
import classNames from "classnames";
import { Alert, AlertActions } from "../../helpers/dialog";





export default function MayTapi(): ReactElement {
    const t = useTranslation('FORMS');
    const tSwal = useTranslation('COMPONENTS.SWAL')
    const isRTL = useRTL();
    const user = useSelector(AuthSelectors.user)
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false)
    const [isOpenDialog, setIsOpenDialog] = useState(false)
    const [dialogActions, setDialogActions] = useState<AlertActions | null>(null)



    const handleSubmit = async (values: MayTapiForm): Promise<void> => {
        setIsLoading(true);
        const channels = {
            ...user.channels,
            mayTapi: values
        }

        dispatch(AuthActions.onUpdateChannels({ channels }))
        const body = {
            uphone_number: user.uphone_number,
            channels
        }
        const res = await useFetchOfficely('updateChannel', Method.POST, body);
        if (!res.success) {
            setDialogActions({
                title: tSwal("ERROR"),
                content: tSwal("ERROR_FETCH"),
                confirm: tSwal("OK"),
                colorButton: "primary",
                isMessage: true
            })
            setIsOpenDialog(true)
        }
        setIsLoading(false)
    }

    const mayTapi = user.channels?.mayTapi
    const initialValues = new MayTapiForm(mayTapi?.phoneID, mayTapi?.productID, mayTapi?.token,
        mayTapi?.logCallBackUrl, mayTapi?.msgWhatsappCallBackUrl, mayTapi?.msgCallBackUrl);
    const validationSchema = MayTapiForm.getValidationSchema(t);

    const formik = useFormik({
        initialValues,
        onSubmit: handleSubmit,
        validationSchema,
        enableReinitialize: false
    });
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <FormText<MayTapiForm> name="webhook"
                    formik={formik}
                    value="https://api.officely.ai/mayTapi"
                    placeholder="Webhook URL"
                    disabled
                    onCopy={() => navigator.clipboard.writeText('https://api.officely.ai/mayTapi')}
                />
                <FormText<MayTapiForm> name="phoneID"
                    formik={formik}
                    placeholder="Phone ID"
                    type="number" />
                <FormText<MayTapiForm> name="productID"
                    formik={formik}
                    placeholder="Product ID" />
                <FormText<MayTapiForm> name="token"
                    type="password"
                    formik={formik}
                    placeholder="Token" />
                <FormText<MayTapiForm> name="msgCallBackUrl"
                    formik={formik}
                    placeholder="Message Bot CallBackUrl" />
                <FormText<MayTapiForm> name="msgWhatsappCallBackUrl"
                    formik={formik}
                    placeholder="Message Human CallBackUrl" />
                <FormText<MayTapiForm> name="logCallBackUrl"
                    formik={formik}
                    placeholder="Log CallBackUrl"
                />
                <footer>
                    <Button type="submit"
                        variant={ButtonVariant.PRIMARY}
                        className={classNames(['form-channel-button', { 'rtl': isRTL }])}
                        isLoading={isLoading}>
                        {t('SAVE')}
                    </Button>
                </footer>
            </form>
            {isOpenDialog && <Alert
                isOpen={isOpenDialog}
                onClose={() => setIsOpenDialog(false)}
                actions={dialogActions!}
            />}
        </>
    );
}


