import React, { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { Node, useReactFlow } from "reactflow";
import Button from "src/components/button/button";
import { ChakraMenu } from "src/components/chakra/menu-item";
import FormText from "src/components/form/text";
import { LLMNodeData } from "src/models/team-builder-node";
import { LLMForm } from "./llm-form";
import { useFormik } from "formik";
import "../config.scss"

import { get } from "lodash";
import { Editor } from "../../team-react-mde";
import {
  Icon as CIcon,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon, AccordionPanel,
  Box,
  Switch,
  FormControl,
  FormLabel,
  Input, VStack, Stack, Text
} from "@chakra-ui/react";
import Icon, { IconTheme, IconVariant } from "src/components/icon/icon";
import { HiOutlineTrash } from "react-icons/hi";
import { SchemaType, Schema, ModelType } from "src/components/team-builder";
import AutoComplete, { GroupItems, Item as AutoCompleteItem } from "src/components/auto-complete/auto-complete";
import { useTranslation } from "src/hooks/use-translate";
import { AgentService } from "src/helpers/fetch/fetch-agent";



const ModelItem = (title: string, icon?: IconVariant) => (<Flex align="center" key={title}>
  {icon && <Icon variant={icon} />}
  <Text m={0} >{title}</Text>
</Flex>
)






function EnumItem({ schema, onUpdateItem }: { schema: Schema, onUpdateItem: (newItem: Schema) => void }) {

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const enums = [...schema.enums!]
    enums[index] = e.target.value
    onUpdateItem({ ...schema, enums: enums })
  }



  const onAddEnum = () => {
    const newEnum = [...schema.enums || []]
    newEnum.push("")
    onUpdateItem({ ...schema, enums: newEnum })
  }

  const onRemoveEnum = (index: number) => {
    const newEnum = [...schema.enums || []]
    newEnum.splice(index, 1)
    onUpdateItem({ ...schema, enums: newEnum })
  }


  return (
    <Flex w="100%" flexDir="column" gap={2} alignItems="center">
      {schema.enums?.map((item, index) => (
        <Stack w="100%" key={index} direction="row" spacing={4} alignItems="center">
          <Input defaultValue={item} placeholder="Enter value"
            onChange={(e) => onChangeValue(e, index)} />
          <CIcon
            as={HiOutlineTrash}
            boxSize={5}
            cursor="pointer"
            onClick={() => onRemoveEnum(index)}
          />
        </Stack>
      ))}
      <Button isIcon onClick={onAddEnum}>
        <Icon variant={IconVariant.ADD} theme={IconTheme.GRAY} />
      </Button>
    </Flex>

  )

}

function Item({ schema, onUpdateItem }: { schema: Schema, onUpdateItem: (newItem: Schema) => void }) {

  const [stateType, setType] = useState<SchemaType>(schema.type || SchemaType.STRING)
  //const [isEnum, setIsEnum] = useState<boolean>(schema.type === SchemaType.ENUM)


  const onChangeKey = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    const regex = /^[a-zA-Z0-9_]+$/;
    const valid = regex.test(name) || name.length === 0;
    if (!valid) { return; }
    onUpdateItem({ ...schema, key: name });
  }, [schema, onUpdateItem]);

  const onChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    onUpdateItem({ ...schema, description: e.target.value })
  }

  const onChangeIsEnum = (is_enum: boolean) => {
    onUpdateItem({ ...schema, is_enum })

  }

  useEffect(() => {
    onUpdateItem({ ...schema, type: stateType })
  }, [stateType])


  return (
    <AccordionItem width="100%">
      <Flex m={1} alignItems="center">
        <Box flex="1" textAlign="left">
          <Input onChange={onChangeKey} value={schema.key} variant="unstyled" placeholder="Enter key name" />
        </Box>
        <AccordionButton flex="0">
          <AccordionIcon />
        </AccordionButton>
      </Flex>
      <AccordionPanel pb={4}>
        <VStack align="start" spacing={4}>
          <FormControl>
            <FormLabel>Type</FormLabel>
            <ChakraMenu<SchemaType>
              currentItem={stateType}
              onItemSelect={(item) => setType(item)}
              items={Object.values(SchemaType)}
              center
              fontWeight="100"
              width="100%"
              m={0}
              mb={5}
              backgroundColor="white"
              border={`1px solid #E2E8F0`}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Description</FormLabel>
            <Input onChange={onChangeDescription} defaultValue={schema.description} />
          </FormControl>
          <FormLabel htmlFor="enum" mb="0">
            Enum
          </FormLabel>
          <Switch id="enum" isChecked={schema.is_enum} onChange={(e) => onChangeIsEnum(e.target.checked)} />
          {schema.is_enum && <EnumItem schema={schema} onUpdateItem={onUpdateItem} />}


        </VStack>
      </AccordionPanel>
    </AccordionItem>
  );
}

function SchemaDict({ schema, setSchema }: {
  schema: Array<Schema>,
  setSchema: React.Dispatch<React.SetStateAction<Schema[]>>
}) {

  const onAddItem = () => {
    const newItem = {
      key: "",
      type: SchemaType.STRING,
      description: "",
      is_enum: false,
      enums: [""]
    }
    setSchema((currentItems) => [...currentItems, newItem])
  }

  const onRemove = (index: number) => {
    setSchema((currentItems) => {
      const newItems = [...currentItems]
      newItems.splice(index, 1)
      return newItems
    })
  }

  const onUpdateItem = (index: number, newItem: Schema) => {
    setSchema((currentItems) => {
      const newItems = [...currentItems]
      newItems[index] = newItem
      return newItems
    })
  }


  return (
    <Accordion allowToggle mt={5}>
      <Flex flexDir="column">
        {schema.map((item, index) => (<Flex key={index} alignItems="center">
          <CIcon
            as={HiOutlineTrash}
            boxSize={5}
            cursor="pointer"
            onClick={() => onRemove(index)}
          />
          <Item schema={item} onUpdateItem={(item) => onUpdateItem(index, item)} />
        </Flex >
        ))}
        <Box alignSelf="center">
          <Button isIcon onClick={onAddItem}>
            <Icon variant={IconVariant.ADD} theme={IconTheme.GRAY} />
          </Button>
        </Box>
      </Flex>
    </Accordion>
  );


}



function LLMConfiguration({ data }: {
  data: LLMNodeData,
}): ReactElement {
  const agentService = new AgentService()
  const [model, setModel] = useState<ModelType>(data.model || ModelType.GPT4_O);
  const { setNodes } = useReactFlow();
  const [with_schema, setIswithSchema] = useState<boolean>(data.with_schema);
  const [llm_map, setLlmMap] = useState<boolean>(data.llm_map || false)
  const [ownBedrock, setOwnBedrock] = useState<boolean>(data.ownBedrock || false)
  const [tempSchema, setSchema] = useState<Array<Schema>>(data.schema || [])
  const [togtherModels, setTogtherModels] = useState<AutoCompleteItem[]>([])
  const [togtherModel, setTogtherModel] = useState<string>(data.togtherModel || "")

  const t = useTranslation("TEAM_BUILDER.MODEL_TYPE")
  const isMounted = useRef(true);


  useEffect(() => {

    if (!isMounted.current) return;
    getTogtherModels()
    
    return () => {
      isMounted.current = false;

    }

  }, [])


  const groupModelItems: GroupItems[] = [
    {
      title: ModelItem("OpenAI", IconVariant.OPEN_AI_COLOR),
      items: [ModelType.AZURE, ModelType.GPT4_O_MINI, ModelType.GPT4_O, ModelType.GPT_4_TURBO, ModelType.GPT4, ModelType.GPT3_5_TURBO]
        .map((key) => ({
          value: t(key),
          label: t(key),
          action: () => setModel(key)
        }))
    },

    {
      title: ModelItem("Antratopic", IconVariant.ANTHROPIC),
      items: [ModelType.CLAUDE_INSTANT, ModelType.CLAUDE3_5, ModelType.CLAUDE3_SONNET, ModelType.CLAUDE3_HAIKU, ModelType.CLAUDE2_1]
        .map((key) => ({
          value: t(key),
          label: t(key),
          action: () => setModel(key)
        }))
    },
    {
      title: ModelItem("Cohere"),
      items: [ModelType.COMMAND_R_PLUS, ModelType.COMMAND_R, ModelType.COMMAND, ModelType.COMMAND_LIGHT]
        .map((key) => ({
          value: t(key),
          label: t(key),
          action: () => setModel(key)
        }))
    },
    {
      title: ModelItem("Google AI"),
      items: [ModelType.GEMINI_1_5_PRO]
        .map((key) => ({
          value: t(key),
          label: t(key),
          action: () => setModel(key)
        }))
    },
    {
      title: ModelItem("Mistral AI"),
      items: [ModelType.MISTRAL_7B, ModelType.MISTRAL_8X7B, ModelType.MISTRAL_LARGE, ModelType.MISTRAL_SMALL]
        .map((key) => ({
          value: t(key),
          label: t(key),
          action: () => setModel(key)
        }))
    },
    {
      title: ModelItem("Meta", IconVariant.LLAMA),
      items: [ModelType.LLAMA3_70B, ModelType.LLAMA3_8B]
        .map((key) => ({
          value: t(key),
          label: t(key),
          action: () => setModel(key)
        }))
    },
    {
      title: ModelItem("TogetherAI"),
      items: [ModelType.TOGETHER].map((key) => ({
        value: t(key),
        label: t(key),
        action: () => setModel(key)
      }))
    }


  ]




  const allowed_schema = [ModelType.GPT4, ModelType.GPT_4_TURBO, ModelType.GPT4_O_MINI, ModelType.GPT4_O, ModelType.AZURE,
  ModelType.COMMAND_R_PLUS, ModelType.COMMAND_R, ModelType.COMMAND, ModelType.COMMAND_LIGHT
  ].includes(model) || data.llm_map


  const isBedrock = [ModelType.CLAUDE2_1, ModelType.CLAUDE3_5, ModelType.CLAUDE3_HAIKU, ModelType.CLAUDE3_SONNET, ModelType.CLAUDE_INSTANT,
    ModelType.LLAMA2_13B, ModelType.LLAMA2_70B, ModelType.LLAMA3_70B, ModelType.LLAMA3_8B, ModelType.MISTRAL_7B, ModelType.MISTRAL_8X7B
  ].includes(model) || data.llm_map

  const initialValues = new LLMForm(data.system_prompt, data.prompt, data.model_string, data.azureDeploymentName);


  const formik = useFormik({
    initialValues,
    onSubmit: (_: LLMForm) => { }, // eslint-disable-line
    enableReinitialize: false
  });

  const onChange = (value: string, name: string) => {
    formik.setFieldValue(name, value)
  }

  const computeUpdatedNodes = useCallback((nodes: Array<Node>, values: LLMForm, id: string,
    model: ModelType, with_schema: boolean, schema: Schema[], llm_map: boolean, ownBedrock:boolean, togtherModel:string) => (
    nodes.map((node) => {
      if (node.id === id && node.type === "workflow") {
        return {
          ...node,
          data: {
            ...node.data,
            system_prompt: values.system_prompt,
            prompt: values.prompt,
            model_string: values.model_string,
            azureDeploymentName:values.azureDeploymentName,
            ownBedrock,
            model,
            with_schema,
            schema,
            llm_map,
            togtherModel
          }
        };
      }
      return node;
    })
  ), []);

  useEffect(() => {
    setNodes((prevNodes) => computeUpdatedNodes(prevNodes, formik.values, data.id, model, with_schema, tempSchema, llm_map, ownBedrock, togtherModel));
  }, [formik.values, model, with_schema, llm_map, ownBedrock, tempSchema, togtherModel, data.id, computeUpdatedNodes]);

  const prompts = [
    {
      name: "system_prompt",
      label: "System Prompt"
    },
    {
      name: "prompt",
      label: "Prompt"
    }
  ]


  const getTogtherModels = async () => {
    const res = await agentService.GetTogtherModels()
    if (res.success && res.data) {
      const models = res.data.map((item: any) => ({
        ...item,
        action: () => setTogtherModel(item.value)
      }))
      setTogtherModels(models)
    }
  }





  const getModelComponent = () =>  {
    switch (model) {
      case ModelType.AZURE:
        return ( 
        <>
          <FormLabel className="label">Azure Deployment Name</FormLabel>
          <FormText formik={formik} name="azureDeploymentName" />
          </>
      )
      case ModelType.TOGETHER:
        return( 
          <>
            <FormLabel className="label">Choose Your model</FormLabel>   
            <AutoComplete
            items={togtherModels}
            value={togtherModels.find((item) => item.value === togtherModel)?.label || ''}
            variant="filled"
            centerInput
            width="100%"
            maxHeight={220}
            classNameInput="text-field"
            
          />       
          </>
        )
      default:
        if (isBedrock){
            return (
            <>
              <FormLabel className="label">Use Your AWS </FormLabel>
              <Switch mb={2} colorScheme="primary" isChecked={ownBedrock} onChange={(e) => setOwnBedrock(e.target.checked)} />
            </>
          )
        }
        return <></>
    }

  }




  return (
    <form
      className="config config-llm"
      onSubmit={formik.handleSubmit}
    >
      <label className="label">Model</label>
      <Switch mb={2} colorScheme="primary" isChecked={llm_map} onChange={(e) => setLlmMap(e.target.checked)} />
      {llm_map ?
        <FormText<LLMForm> formik={formik} name="model_string">
          <Editor
            id={data.id}
            value={get(formik.values, "model_string")}
            onChange={(value) => onChange(value, "model_string")}
            textAreaClass="text-field field-text" />
        </FormText>
        :
        <div className="form-group">
          <AutoComplete
            items={groupModelItems}
            value={t(model.toString())}
            variant="filled"
            isGroup
            centerInput
            width="100%"
            maxHeight={220}
            classNameInput="text-field"
          />

        </div>
      }
        <>
       {getModelComponent()}
        </>

      



      <Accordion allowToggle mt={5} mb={5}>
        {prompts.map((prompt, index) => (
          <AccordionItem key={index}>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  {prompt.label}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <FormText<LLMForm> formik={formik} name={prompt.name}>
                <Editor textAreaClass="text-field" id={data.id} value={get(formik.values, prompt.name)} onChange={(value) => onChange(value, prompt.name)} />
              </FormText>
            </AccordionPanel>
          </AccordionItem>
        ))}

      </Accordion>

      {allowed_schema &&
        <>
          <FormControl display="flex" alignItems="center" >
            <FormLabel htmlFor="schema" mb="0">
              Schema
            </FormLabel>
            <Switch id="schema" isChecked={with_schema} onChange={(e) => setIswithSchema(e.target.checked)} />
          </FormControl>
          {with_schema && <SchemaDict schema={tempSchema} setSchema={setSchema} />}
        </>
      }





    </form>


  )
}



export default React.memo(LLMConfiguration);










// import React, { ReactElement, useCallback, useEffect, useState, useMemo } from "react";
// import { Node, useReactFlow } from "reactflow";
// import Button from "src/components/button/button";
// import { ChakraMenu } from "src/components/chakra/menu-item";
// import FormText from "src/components/form/text";
// import { LLMNodeData } from "src/models/team-builder-node";
// import { LLMForm } from "./llm-form";
// import { useFormik } from "formik";
// import "../config.scss";
// import { get } from "lodash";
// import { Editor } from "../../team-react-mde";
// import {
//   Icon as CIcon,
//   Flex,
//   Accordion,
//   AccordionItem,
//   AccordionButton,
//   AccordionIcon,
//   AccordionPanel,
//   Box,
//   Switch,
//   FormControl,
//   FormLabel,
//   Input,
//   VStack,
//   Stack,
//   Text,
// } from "@chakra-ui/react";
// import Icon, { IconTheme, IconVariant } from "src/components/icon/icon";
// import { HiOutlineTrash } from "react-icons/hi";
// import { SchemaType, Schema, ModelType } from "src/components/team-builder";
// import AutoComplete, { GroupItems } from "src/components/auto-complete/auto-complete";
// import { useTranslation } from "src/hooks/use-translate";

// const ModelItem = (title: string, icon?: IconVariant) => (
//   <Flex align="center" key={title}>
//     {icon && <Icon variant={icon} />}
//     <Text m={0}>{title}</Text>
//   </Flex>
// );

// const EnumItem = ({ schema, onUpdateItem }: { schema: Schema, onUpdateItem: (newItem: Schema) => void }) => {
//   const onChangeValue = useCallback((e: React.ChangeEvent<HTMLInputElement>, index: number) => {
//     const enums = [...schema.enums!];
//     enums[index] = e.target.value;
//     onUpdateItem({ ...schema, enums });
//   }, [schema, onUpdateItem]);

//   const onAddEnum = useCallback(() => {
//     const newEnum = [...schema.enums || []];
//     newEnum.push("");
//     onUpdateItem({ ...schema, enums: newEnum });
//   }, [schema, onUpdateItem]);

//   const onRemoveEnum = useCallback((index: number) => {
//     const newEnum = [...schema.enums || []];
//     newEnum.splice(index, 1);
//     onUpdateItem({ ...schema, enums: newEnum });
//   }, [schema, onUpdateItem]);

//   return (
//     <Flex w="100%" flexDir="column" gap={2} alignItems="center">
//       {schema.enums?.map((item, index) => (
//         <Stack w="100%" key={index} direction="row" spacing={4} alignItems="center">
//           <Input
//             defaultValue={item}
//             placeholder="Enter value"
//             onChange={(e) => onChangeValue(e, index)}
//           />
//           <CIcon
//             as={HiOutlineTrash}
//             boxSize={5}
//             cursor="pointer"
//             onClick={() => onRemoveEnum(index)}
//           />
//         </Stack>
//       ))}
//       <Button isIcon onClick={onAddEnum}>
//         <Icon variant={IconVariant.ADD} theme={IconTheme.GRAY} />
//       </Button>
//     </Flex>
//   );
// };

// const Item = React.memo(({ schema, onUpdateItem }: { schema: Schema, onUpdateItem: (newItem: Schema) => void }) => {
//   const [stateType, setType] = useState<SchemaType>(schema.type || SchemaType.STRING);

//   const onChangeKey = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
//     const name = e.target.value.replace(/\s+/g, ''); // Remove all spaces
//     const regex = /^[a-zA-Z0-9_]+$/;
//     const valid = regex.test(name) || name.length === 0;
  
//     if (!valid) {
//       return;
//     }
  
//     onUpdateItem({ ...schema, key: name });
//   }, [schema, onUpdateItem]);

//   const onChangeDescription = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
//     onUpdateItem({ ...schema, description: e.target.value });
//   }, [schema, onUpdateItem]);

//   const onChangeIsEnum = useCallback((is_enum: boolean) => {
//     onUpdateItem({ ...schema, is_enum });
//   }, [schema, onUpdateItem]);

//   useEffect(() => {
//     onUpdateItem({ ...schema, type: stateType });
//   }, [stateType]);

//   console.log(schema.key);

//   return (
//     <AccordionItem width="100%">
//       <Flex m={1} alignItems="center">
//         <Box flex="1" textAlign="left">
//           <Input
//             onChange={onChangeKey}
//             value={schema.key}
//             variant="unstyled"
//             placeholder="Enter key name"
//           />
//         </Box>
//         <AccordionButton flex="0">
//           <AccordionIcon />
//         </AccordionButton>
//       </Flex>
//       <AccordionPanel pb={4}>
//         <VStack align="start" spacing={4}>
//           <FormControl>
//             <FormLabel>Type</FormLabel>
//             <ChakraMenu<SchemaType>
//               currentItem={stateType}
//               onItemSelect={(item) => setType(item)}
//               items={Object.values(SchemaType)}
//               center
//               fontWeight="100"
//               width="100%"
//               m={0}
//               mb={5}
//               backgroundColor="white"
//               border={`1px solid #E2E8F0`}
//             />
//           </FormControl>
//           <FormControl>
//             <FormLabel>Description</FormLabel>
//             <Input onChange={onChangeDescription} defaultValue={schema.description} />
//           </FormControl>
//           <FormLabel htmlFor="enum" mb="0">
//             Enum
//           </FormLabel>
//           <Switch id="enum" isChecked={schema.is_enum} onChange={(e) => onChangeIsEnum(e.target.checked)} />
//           {schema.is_enum && <EnumItem schema={schema} onUpdateItem={onUpdateItem} />}
//         </VStack>
//       </AccordionPanel>
//     </AccordionItem>
//   );
// });

// const SchemaDict = ({ schema, setSchema }: { schema: Array<Schema>, setSchema: React.Dispatch<React.SetStateAction<Schema[]>> }) => {
//   const onAddItem = useCallback(() => {
//     const newItem = {
//       key: "",
//       type: SchemaType.STRING,
//       description: "",
//       is_enum: false,
//       enums: [""]
//     };
//     setSchema((currentItems) => [...currentItems, newItem]);
//   }, [setSchema]);

//   const onRemove = useCallback((index: number) => {
//     setSchema((currentItems) => {
//       const newItems = [...currentItems];
//       newItems.splice(index, 1);
//       return newItems;
//     });
//   }, [setSchema]);

//   const onUpdateItem = useCallback((index: number, newItem: Schema) => {
//     setSchema((currentItems) => {
//       const newItems = [...currentItems];
//       newItems[index] = newItem;
//       return newItems;
//     });
//   }, [setSchema]);

//   return (
//     <Accordion allowToggle mt={5}>
//       <Flex flexDir="column">
//         {schema.map((item, index) => (
//           <Flex key={index} alignItems="center">
//             <CIcon
//               as={HiOutlineTrash}
//               boxSize={5}
//               cursor="pointer"
//               onClick={() => onRemove(index)}
//             />
//             <Item schema={item} onUpdateItem={(item) => onUpdateItem(index, item)} />
//           </Flex>
//         ))}
//         <Box alignSelf="center">
//           <Button isIcon onClick={onAddItem}>
//             <Icon variant={IconVariant.ADD} theme={IconTheme.GRAY} />
//           </Button>
//         </Box>
//       </Flex>
//     </Accordion>
//   );
// };

// function LLMConfiguration({ data }: { data: LLMNodeData }): ReactElement {
//   const [model, setModel] = useState<ModelType>(data.model || ModelType.GPT4_O);
//   const { setNodes } = useReactFlow();
//   const [with_schema, setIswithSchema] = useState<boolean>(data.with_schema);
//   const [llm_map, setLlmMap] = useState<boolean>(data.llm_map || false);
//   const [tempSchema, setSchema] = useState<Array<Schema>>(data.schema || []);
//   const t = useTranslation("TEAM_BUILDER.MODEL_TYPE");

//   const groupModelItems: GroupItems[] = [
//     {
//       title: ModelItem("OpenAI", IconVariant.OPEN_AI_COLOR),
//       items: [ModelType.AZURE, ModelType.GPT4_O, ModelType.GPT_4_TURBO, ModelType.GPT4, ModelType.GPT3_5_TURBO]
//         .map((key) => ({
//           value: t(key),
//           label: t(key),
//           action: () => setModel(key)
//         }))
//     },
//     {
//       title: ModelItem("Anthropic", IconVariant.ANTHROPIC),
//       items: [ModelType.CLAUDE_INSTANT, ModelType.CLAUDE3_5, ModelType.CLAUDE3_SONNET, ModelType.CLAUDE3_HAIKU, ModelType.CLAUDE2_1]
//         .map((key) => ({
//           value: t(key),
//           label: t(key),
//           action: () => setModel(key)
//         }))
//     },
//     // Add other model items here as needed
//   ];

//   const allowed_schema = useMemo(() => [ModelType.GPT4, ModelType.GPT_4_TURBO, ModelType.GPT4_O, ModelType.AZURE,
//     ModelType.COMMAND_R_PLUS, ModelType.COMMAND_R, ModelType.COMMAND, ModelType.COMMAND_LIGHT].includes(model) || data.llm_map
//   , [model, data.llm_map]);

//   const initialValues = useMemo(() => new LLMForm(data.system_prompt, data.prompt, data.model_string, data.azureDeploymentName)
//   , [data.system_prompt, data.prompt, data.model_string, data.azureDeploymentName]);

//   const formik = useFormik({
//     initialValues,
//     onSubmit: (_: LLMForm) => { }, // eslint-disable-line
//     enableReinitialize: false
//   });

//   const onChange = useCallback((value: string, name: string) => {
//     formik.setFieldValue(name, value);
//   }, [formik]);

//   const computeUpdatedNodes = useCallback((nodes: Array<Node>, values: LLMForm, id: string,
//     model: ModelType, with_schema: boolean, schema: Schema[], llm_map: boolean) => (
//     nodes.map((node) => {
//       if (node.id === id && node.type === "workflow") {
//         return {
//           ...node,
//           data: {
//             ...node.data,
//             system_prompt: values.system_prompt,
//             prompt: values.prompt,
//             model_string: values.model_string,
//             azureDeploymentName: values.azureDeploymentName,
//             model,
//             with_schema,
//             schema,
//             llm_map
//           }
//         };
//       }
//       return node;
//     })
//   ), []);

//   useEffect(() => {
//     setNodes((prevNodes) => computeUpdatedNodes(prevNodes, formik.values, data.id, model, with_schema, tempSchema, llm_map));
//   }, [formik.values, model, with_schema, llm_map, tempSchema, data.id, computeUpdatedNodes]);

//   const prompts = [
//     {
//       name: "system_prompt",
//       label: "System Prompt"
//     },
//     {
//       name: "prompt",
//       label: "Prompt"
//     }
//   ];

//   return (
//     <form
//       className="config config-llm"
//       onSubmit={formik.handleSubmit}
//     >
//       <label className="label">Model</label>
//       <Switch mb={2} colorScheme="primary" isChecked={llm_map} onChange={(e) => setLlmMap(e.target.checked)} />
//       {llm_map ?
//         <FormText<LLMForm> formik={formik} name="model_string">
//           <Editor
//             id={data.id}
//             value={get(formik.values, "model_string")}
//             onChange={(value) => onChange(value, "model_string")}
//             textAreaClass="text-field field-text" />
//         </FormText>
//         :
//         <div className="form-group">
//           <AutoComplete
//             items={groupModelItems}
//             value={t(model.toString())}
//             variant="filled"
//             isGroup
//             centerInput
//             width="100%"
//             maxHeight={220}
//             classNameInput="text-field"
//           />
//         </div>
//       }

//       {model === ModelType.AZURE && <>
//         <FormLabel className="label">Azure Deployment Name</FormLabel>
//         <FormText formik={formik} name="azureDeploymentName" />
//       </>}

//       <Accordion allowToggle mt={5} mb={5}>
//         {prompts.map((prompt, index) => (
//           <AccordionItem key={index}>
//             <h2>
//               <AccordionButton>
//                 <Box as="span" flex="1" textAlign="left">
//                   {prompt.label}
//                 </Box>
//                 <AccordionIcon />
//               </AccordionButton>
//             </h2>
//             <AccordionPanel pb={4}>
//               <FormText<LLMForm> formik={formik} name={prompt.name}>
//                 <Editor textAreaClass="text-field" id={data.id} value={get(formik.values, prompt.name)} onChange={(value) => onChange(value, prompt.name)} />
//               </FormText>
//             </AccordionPanel>
//           </AccordionItem>
//         ))}
//       </Accordion>

//       {allowed_schema &&
//         <>
//           <FormControl display="flex" alignItems="center" >
//             <FormLabel htmlFor="schema" mb="0">
//               Schema
//             </FormLabel>
//             <Switch id="schema" isChecked={with_schema} onChange={(e) => setIswithSchema(e.target.checked)} />
//           </FormControl>
//           {with_schema && <SchemaDict schema={tempSchema} setSchema={setSchema} />}
//         </>
//       }
//     </form>
//   );
// }

// export default React.memo(LLMConfiguration);


