import { NodeProps, useReactFlow, Edge, Node } from 'reactflow';
import { Schema, TeamFlowNodeType, TeamNodeType } from 'src/components/team-builder/interfaces';




export function useGetNames() {
  const { getNodes, getEdges } = useReactFlow();


  function GetSchema(node: Node, parentNames: string[] = []) {
    const name = node.data.name ? node.data.name : node.data.id;
    switch (node.data.type) {
      case TeamFlowNodeType.LLM:
        if (node.data.with_schema) {
          node.data.schema.forEach((schemaItem: Schema) => {
            parentNames.push(`${name}.${schemaItem.key}`);
          });
        } else {
          parentNames.push(name);
        }
        break;
      case TeamFlowNodeType.RETRIVER:
        parentNames.push(`${node.data.name}`);
        parentNames.push(`${node.data.name}.context`);
        parentNames.push(`${node.data.name}.documents`);
        break;
      case TeamFlowNodeType.PYTHON:
        parentNames.push(`${name}`);
        parentNames.push(`${name}.status_code`);
        parentNames.push(`${name}.body.result`);
        parentNames.push(`${name}.body.log`);
        parentNames.push(`${name}.body.data`);



        break;
      default:
        parentNames.push(`${name}`);
        break;
    }
  }

  const findParents = (id: NodeProps['id'], edges: Array<Edge>, parents = new Set()) => {
    // Find edges where the current node is the target
    edges.forEach((edge: Edge) => {
      if (edge.target === id) {
        parents.add(edge.source); // Add the source (parent) to the set
        findParents(edge.source, edges, parents); // Recursively find parents of the parent
      }
    });
    return parents;
  };

  return (id: NodeProps['id']) => {
    const edges = getEdges();
    const parentIds = findParents(id, edges); // Get parent IDs as a Set to avoid duplicates
    const nodes = getNodes();

    // const parentNames = [...parentIds].map((pid) => {
    //   const node = nodes.filter((x) => x.type !== TeamNodeType.PLACEHOLDER).find((n) => n.id === pid);
    //   return node ? node.data.name : null;
    // }).filter((name) => name !== null); 

    const parentNames: string[] = [];

    [...parentIds].forEach((pid) => {
      const node = nodes.find((n) => n.id === pid);
      if (node && node.type !== TeamNodeType.PLACEHOLDER) {
        // Add the basic node name first
        GetSchema(node, parentNames);


        // if (node.data.with_schema) {
        //   node.data.schema.forEach((schemaItem:Schema) => {
        //     parentNames.push(`${node.data.name}.${schemaItem.key}`);
        //   });
        // }
      }
    });

    return parentNames;
  };
}



export default useGetNames;
