import { createSelector } from 'reselect';
import { AppState } from '../ConfigureStore';
import { AuthState } from './state';

const selectFeature = (state: AppState): AuthState => state.auth;

export class AuthSelectors {
  public static isAuthenticated = createSelector(
    selectFeature,
    (state) => state.isAuthenticated
  );

  public static isAuthenticating = createSelector(
    selectFeature,
    (state) => state.isAuthenticating
  );
  public static user = createSelector(
    selectFeature,
    (state) => state.user
  );
  public static officelyUser = createSelector(
    selectFeature,
    (state) => state.officelyUser
  );
  


  public static isFreeBasic = createSelector(
    selectFeature,
    (state) => state.isFreeBasic
  )

  public static accessTeam = createSelector(
    selectFeature,
    (state) => state.accessTeam || state.officelyUser
  )
 
}
