import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { ChatBuilderSelectors } from '../../redux/chat-builder';
import Icon, { IconVariant } from '../icon/icon';

import './auto-save.scss'







export default function AutoSave(): ReactElement {
    const isVisible = useSelector(ChatBuilderSelectors.isSaved);


    return (
        <div className={classNames(["auto-save", { isVisible }])} >
            <span className="auto-save-icon">
                <Icon variant={IconVariant.CHECK} />
            </span>
            <span className="auto-save-text">Auto-Save</span>
        </div>

    );
}

