import { Method } from "../../enums/enums"
import { FetchResult, useFetch } from "../../hooks/use-fetch"
import { ResponseOfficely, useFetchOfficely } from "../../hooks/use-fetch-officely"

const url = "https://api.green-api.com/waInstance"

enum GreenRoutes {
    CREATE_INSTANVE = 'CreateGreenInstance',
    GET_INSTANCE = 'GetGreenInstance',
    UPDATE_CALLBACK = 'UpdateGreenCallBack'


}

export async function FetchQrCode(instanceId:string, token:string): Promise<FetchResult<any>> {
    return await useFetch(`${url}${instanceId}/qr/${token}`, Method.GET)
}

export async function FetchAuthorization(instanceId:string, token:string): Promise<FetchResult<any>> {
    const headers = new Headers({
        'Content-Type': 'application/json'
    });
    return await useFetch(`${url}${instanceId}/getStateInstance/${token}`, Method.GET, headers)
}

export async function FetchCreateInstance(token:string): Promise<ResponseOfficely>{
    return await useFetchOfficely(GreenRoutes.CREATE_INSTANVE, Method.POST, null, token)
}

export async function GetGreenInstance(token:string): Promise<ResponseOfficely> {
    return await useFetchOfficely(GreenRoutes.GET_INSTANCE, Method.GET, null, token)
}

export async function FetchReebotInstance(instanceId:string, token:string): Promise<FetchResult<any>> {
    await clearQueue(instanceId, token)
    return await useFetch(`${url}${instanceId}/reboot/${token}`, Method.GET)
}

export async function UpdateGreenCallBack(body:any, token:string): Promise<ResponseOfficely>{
    return await useFetchOfficely(GreenRoutes.UPDATE_CALLBACK, Method.POST, body, token)
}

export async function clearQueue(instanceId:string, token:string): Promise<FetchResult<any>> {
    return await useFetch(`${url}${instanceId}/clearMessagesQueue/${token}`, Method.GET)
}
