import React, { ReactElement, useState, useEffect, useRef } from "react";
import { useFormik } from 'formik';
import { useTranslation } from "../../hooks/use-translate";
import FormText from "../form/text";
import Button, { ButtonVariant } from "../button/button";
import { useSelector } from "react-redux";
import { AuthSelectors } from "../../redux/auth";
import { Plan } from "../../enums/enums";
import { useRTL } from "../../hooks/use-rtl";
import classNames from "classnames";
import { Alert, AlertActions } from "../../helpers/dialog";
import { GreenAPIForm } from "../../forms/green_api";
import { clearQueue, FetchAuthorization, FetchCreateInstance, FetchQrCode, GetGreenInstance, UpdateGreenCallBack, FetchReebotInstance } from "./green_api_fetch";
import blurQrCode from "../../assets/images/big-icon/blurQrCode.svg"
import { useNavigate } from "react-router-dom";

import "./green_api.scss";
import { Box, Progress, Spinner, Icon as CIcon, Button as CButton } from "@chakra-ui/react";
import { RepeatIcon } from "@chakra-ui/icons";


export default function Green_API(): ReactElement {
    const t = useTranslation('FORMS');
    const tSwal = useTranslation('COMPONENTS.SWAL')
    const isRTL = useRTL();
    const user = useSelector(AuthSelectors.user)
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const [isOpenDialog, setIsOpenDialog] = useState(false)
    const [dialogActions, setDialogActions] = useState<AlertActions | null>(null)
    const [greenAPI, setGreenAPI] = useState<GreenAPIForm | null>(null)
    const [qrCode, setQRCode] = useState<string | null>(null)
    const [isReeboting, setIsReeboting] = useState(false)
    const [isSubmitting, setIsSubmiting] = useState(false)
    const [loadingQrCode, setLoadingQrCode] = useState(true)
    const [progress, setProgress] = useState(100)
    const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

    let isMounted = true;


    useEffect(() => {
        if (!isMounted) return
        (async () => {
            const res = await GetGreenInstance(user.token!);
            if (res.success && res.data) {
                checkAuthorization(res.data as GreenAPIForm);
            } else {
                setLoadingQrCode(false)
            }
        })();
        return () => {
            isMounted = false
        }
    }, []);

    useEffect(() => {
        if (!greenAPI) return
        const interval = qrCode ? 10000 : 30000;
        const timer = setInterval(() => {
            checkAuthorization(greenAPI);
        }, interval);

        return () => { clearInterval(timer); }
    }, [greenAPI, qrCode]);


    useEffect(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
    }, []);


    const handleSubmit = async (values: GreenAPIForm): Promise<void> => {
        setIsSubmiting(true)
        const body = {
            instanceID: greenAPI!.instanceID,
            logCallback: values.logCallback
        }
        const res = await UpdateGreenCallBack(body, user.token!)
        if (!res.success) {
            setDialogActions({
                title: tSwal("ERROR"),
                content: tSwal("ERROR_FETCH"),
                confirm: tSwal("OK"),
                colorButton: "primary",
                isMessage: true
            })
            setIsOpenDialog(true)
        }
        setGreenAPI({ ...greenAPI!, logCallback: values.logCallback })
        setIsSubmiting(false)
    }


    const initialValues = greenAPI || new GreenAPIForm();
    const validationSchema = GreenAPIForm.getValidationSchema(t);

    const formik = useFormik({
        initialValues,
        onSubmit: handleSubmit,
        validationSchema,
        enableReinitialize: false
    });


    const InitGreen = (greenAPI: GreenAPIForm) => {
        setGreenAPI(greenAPI)
        formik.setFieldValue('logCallback', greenAPI.logCallback || '')
    }


    const getQRCode = async (instanceID: string, token: string) => {
        const res = await FetchQrCode(instanceID, token);
        if (res.success) {
            const data = res.data as { message: string };
            setQRCode(`data:image/png;base64,${data.message}`);
            setLoadingQrCode(false)
            setProgress(100)
        }
    }

    const checkAuthorization = async (green: GreenAPIForm) => {
        // eslint-disable-next-line no-console
        console.log('checkAuthorization')
        const res = await FetchAuthorization(green.instanceID, green.token)
        if (res.success && res.data) {
            const data = res.data as { stateInstance: string };
            // eslint-disable-next-line no-console
            if (data.stateInstance === "authorized") {
                InitGreen(new GreenAPIForm(green.instanceID, green.token, green.logCallback, true))
                setQRCode(null)
                setLoadingQrCode(false)
            } else {
                clearQueue(green.instanceID, green.token);
                InitGreen(new GreenAPIForm(green.instanceID, green.token, green.logCallback, false))
                setLoadingQrCode(true)
                getQRCode(green.instanceID, green.token);
            }

        }

    }

    const CreateInstance = async () => {
        setIsLoading(true)
        // eslint-disable-next-line no-console
        console.log('CreateInstance')
        if (user.plan === Plan.FREE || user.plan === Plan.BASIC) {
            setDialogActions({
                title: tSwal("ERROR_UPGRADE"),
                content: tSwal("ERR_CHANNEL_UPGRADE"),
                confirm: tSwal("TO_UPGRADE"),
                onConfirm: () => navigate("/subscriptions"),
                cancel: tSwal("CANCEL"),
                colorButton: "primary"
            })
            setIsOpenDialog(true)
        } else {
            const res = await FetchCreateInstance(user.token!)
            if (!res.success) {
                setDialogActions({
                    title: tSwal("ERROR"),
                    content: tSwal("ERROR_FETCH"),
                    confirm: tSwal("OK"),
                    colorButton: "primary",
                    isMessage: true
                })
                setIsOpenDialog(true)
                return
            }
            const data = res.data as { instanceID: string, token: string };
            setGreenAPI(new GreenAPIForm(data.instanceID, data.token))
            setLoadingQrCode(true)
            setProgress(1)
            intervalRef.current = setInterval(() => {
                let increments = 0;
                const innerInterval = setInterval(() => {
                    setProgress((currentProgress) => {
                        if (currentProgress < 100) {
                            return currentProgress + 1;
                        }
                        return currentProgress;
                    });
                    increments++;
                    if (increments >= 10) {
                        clearInterval(innerInterval);
                    }
                }, 2000);  // Increase by 1 every 2 seconds
            }, 12500);
        }
        setIsLoading(false)


    }

    const ReebotInstance = async () => {
        setIsReeboting(true)
        await FetchReebotInstance(greenAPI!.instanceID, greenAPI!.token)
        setIsReeboting(false)
    }



    if (loadingQrCode) {
        return (
            <div className="green-api">
                <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="primary.500"
                    size="xl"
                    margin="100px auto" />
                {progress !== 100 && !qrCode && <>
                    <h2>{`${progress}%`}</h2>
                    <Progress value={progress} size="xs" colorScheme="primary" />
                </>
                }


            </div>
        )
    } else if (!greenAPI) {
        return (
            <div className="green-api">
                <img className="qrcode" src={blurQrCode} />
                <Button onClick={CreateInstance}
                    variant={ButtonVariant.PRIMARY}
                    className={classNames(['form-channel-button', { 'rtl': isRTL }])}
                    isLoading={isLoading}
                >
                    {t("CONNECT_GREEN")}
                </Button>
                {isOpenDialog && <Alert
                    isOpen={isOpenDialog}
                    onClose={() => setIsOpenDialog(false)}
                    actions={dialogActions!}
                />}
            </div>
        )
    } else {
        return (
            <div className="green-api">
                {!greenAPI.status ?
                    <img className="qrcode" src={qrCode ?? blurQrCode} />
                    :
                    <>
                        <form onSubmit={formik.handleSubmit}>

                            <FormText<GreenAPIForm> name="instanceID"
                                formik={formik}
                                value={greenAPI.instanceID}
                                placeholder="instanceID"
                                disabled
                                onCopy={() => navigator.clipboard.writeText(greenAPI.instanceID)}
                            />
                            <FormText<GreenAPIForm> name="token"
                                formik={formik}
                                placeholder="Token"
                                value={greenAPI.token}
                                disabled
                                onCopy={() => navigator.clipboard.writeText(greenAPI.token)}
                            />
                            <FormText<GreenAPIForm> name="logCallback"
                                formik={formik}
                                placeholder="Log Callback"
                            />
                            <footer>
                                <Button type="submit"
                                    variant={ButtonVariant.PRIMARY}
                                    className={classNames(['form-channel-button', { 'rtl': isRTL }])}
                                    isLoading={isSubmitting}>
                                    {t('SAVE')}
                                </Button>
                            </footer>


                        </form>
                        <Box width={0} position="relative" top="-100px">
                            <CButton onClick={() => ReebotInstance()}
                                border="none"
                                isLoading={isReeboting}
                                variant={ButtonVariant.OUTLINE}
                            >
                                <CIcon as={RepeatIcon} boxSize={6} />
                            </CButton>
                        </Box>
                    </>

                }
                <Alert
                    isOpen={isOpenDialog}
                    onClose={() => setIsOpenDialog(false)}
                    actions={dialogActions!}
                />

            </div >
        );
    }
}