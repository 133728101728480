

export class RetriverForm {
  public question: string;
  public index: string;
  public namespace: string;
  public k: number;
  public n: number;
  public rerank_score?: Float32Array;


  constructor(question?: string, index?: string, namespace?: string, k?: number, n?: number, rerank_score?: Float32Array) {
    this.question = question || '';
    this.index = index || 'basic';
    this.namespace = namespace || '';
    this.k = k || 10;
    this.n = n || 4;
    this.rerank_score = rerank_score ;
  }


}