import React, { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button, { ButtonVariant } from "../button/button";
import Icon, { IconTheme, IconVariant } from "../icon/icon";
import { AgentActions, AgentSelectors, FieldCoindtion } from "src/redux/agents";
import './agent.scss';





export default function AgentContidions({ onContinue }: {
    onContinue: () => void;
}): ReactElement {
    const currentAgent = useSelector(AgentSelectors.currentAgent)
    const dispatch = useDispatch();



    const onAddCondition = () => {
        dispatch(AgentActions.onAddCondition())
    }

    const onRemoveCondition = (index: number) => {
        dispatch(AgentActions.onRemoveCondition({ index }))
    }

    const onChangeCondition = ( index: number, field: FieldCoindtion, value: string) => {
        dispatch(AgentActions.onChangeCondition({ index, field, value }))
    }











    return (
        <div className="agent agent-conditions">
            <div className="agent-conditions-list">
                {currentAgent?.conditions!.map((item, index) => <div className="agent-conditions-container" key={index}>
                    <Button isHidden={index === 0} isIcon className="condition-part-remove" onClick={() => onRemoveCondition(index)}>
                        <Icon variant={IconVariant.REMOVE} theme={IconTheme.GRAY} />
                    </Button>
                    <div className="condition-part if-part">
                        <div className="condition-header">If</div>
                        <div className="condition-content">
                            <textarea
                                value={item.if}
                                onChange={(e) => onChangeCondition(index, FieldCoindtion.IF, e.target.value)}
                                className="condition-textarea"
                                placeholder="Write here..."></textarea>
                        </div>
                    </div>
                    <div className="condition-part else-part">
                        <div className="condition-header">Then</div>
                        <div className="condition-content">
                            <textarea
                                value={item.then}
                                onChange={(e) => onChangeCondition(index, FieldCoindtion.THEN, e.target.value)}
                                className="condition-textarea"
                                placeholder="Write here...">

                            </textarea>
                        </div>
                    </div>
                </div>)
                }
            </div>
            <Button isIcon variant={ButtonVariant.WHITE} className="agent-conditions-btn" onClick={onAddCondition}>
                <Icon variant={IconVariant.ADD} theme={IconTheme.GRAY} />
            </Button>



            <Button
                variant={ButtonVariant.PRIMARY}
                isBlock
                onClick={onContinue}
                className="continue-button"
            >

                Continue
            </Button>
        </div>
    );
}


