import { SagaIterator } from 'redux-saga';
import { all, takeLatest } from 'typed-redux-saga';
import { RootActions } from './actions';

function* init(): SagaIterator {
  // TODO: init saga
}

export function* rootSaga(): Generator {
  yield* takeLatest(RootActions.init, init);

  yield* all([
    // TODO: sagas
  ]);
}
