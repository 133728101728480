import React, { ReactElement } from 'react';
import classNames from 'classnames';
import './form.scss';
import { FormTextProps, getError } from './text';
import FormError from './error';
import { HStack, PinInput, PinInputField } from '@chakra-ui/react';

export default function FormVerificationCode<T>({
  formik,
  name,
  className
}: FormTextProps<T>): ReactElement {
  const error = getError<T>(formik, name);


  const handlePinChange = (value: string) => {
    formik?.setFieldValue(name ?? '', value);
  };

  return (
    <div
      className={classNames([
        'form-group',
        {
          'form-group-error': error
        },
        className
      ])}>
      {/* <IMaskInput
        mask="000000"
        onChange={formik?.handleChange}
        onBlur={() => onBlur(formik, name)}
        name={name}
        value={getValue<T>(formik, name)}
        type="number"
        className="form-control"
        {...inputProps}
      /> */}
      <HStack
        dir="ltr"
        margin="0 auto"
      >
        <PinInput
          size="lg"
          otp
          onChange={handlePinChange} >
          <PinInputField bg="white"/>
          <PinInputField bg="white" />
          <PinInputField bg="white" />
          <PinInputField bg="white" />
          <PinInputField bg="white" />
          <PinInputField bg="white" />
        </PinInput>
      </HStack>
      {error && <FormError error={error} />}
    </div>
  );
}
