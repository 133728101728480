

export class TextForm {
    public text: string;

    constructor( text?: string) {
      this.text = text || '';
    }
  
  
  }