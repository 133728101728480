
import { immerable } from 'immer';
import { Condition, IngestingParam, Loader } from './interfaces';
import { TeamObject } from "src/models"





export class AgentState {
    [immerable] = true;
    public agents: Array<Agent>;
    public currentAgent: Agent | null = null;

    constructor() {
        this.agents = []
        this.currentAgent = null;
    }
}


// export class Agent {


export interface Agent {
    agent_id: string;
    model?: string;
    name?: string;
    img?: string;
    company_name?: string;
    role?: string;
    custom_role?: string;
    conditions?: Array<Condition>;
    loaders?: Array<Loader>;
    style:string
    openai_api_key?:string
    assistant_id?:string,
    conversation_purpose?:string,
    stages?: Array<string>;
    ingesting_param?:IngestingParam
    team?: TeamObject
    inputs?: { [key: string]: any };

}

