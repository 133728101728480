import React, { useEffect, useState, useRef } from 'react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { List, ListItem, Checkbox, Divider, Link, Flex } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import Button, { ButtonVariant } from 'src/components/button/button';
import { Method } from 'src/enums/enums';
import { useFetchOfficely } from 'src/hooks/use-fetch-officely';
import { AgentActions, AgentSelectors, IURL, LoaderType, URLLoader } from 'src/redux/agents';
import Icon, { IconTheme, IconVariant } from 'src/components/icon/icon';






function UrlLoaderComponent() {



    const currentAgent = useSelector(AgentSelectors.currentAgent)

    const dispatch = useDispatch()
    const [urls, setURLs] = useState<IURL[]>([])
    const [urlInput, setUrlInput] = useState('')
    const [loading, setLoading] = useState(false)
    const [selectAll, setSelectAll] = useState(true)
    const [customUrlInput, SetCustomUrlInput] = useState('')

    const abortControllerRef = useRef(new AbortController());


    useEffect(() => {
        const loader = currentAgent?.loaders?.find((loader) => loader.type === LoaderType.URL) as URLLoader
        if (!loader) return
        setURLs(loader.urls)
        return () => {
            abortControllerRef.current.abort();
        }
    }, [])









    const scanURl = async () => {
        setLoading(true)
        setURLs([])
        const body = {
            url: urlInput
        }
        const res = await useFetchOfficely('fetchUrls', Method.POST, body)
        if (res.success) {
            if (res.data.length === 0) {
                alert('no urls found')
            } else {
                const urls = res.data.map((url: string) => ({ url, checked: true }))
                const loader: URLLoader = {
                    id: 'url-loader',
                    type: LoaderType.URL,
                    urls,
                    checked: true
                }
                dispatch(AgentActions.onAddLoader({ loader }))
            }

        }
        setLoading(false)
    }

    const onAddCustomUrl = () => {
        dispatch(AgentActions.onAddCustomUrl({ url:customUrlInput }))
        SetCustomUrlInput('')
    }


    const isValidUrl = (string:string) => {
        const regex = /^https:\/\/([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
        return regex.test(string);
    }
    


    const onChangeChecked = (id: string, checked: boolean) => {
        dispatch(AgentActions.onChangeCheckedLoader({ loaderType: LoaderType.URL, id, checked }))
        setURLs(urls.map((url) => url.url === id ? { ...url, checked } : url));

    }

    const onHandleSelectAll = (checked: boolean) => {
        urls.forEach((url) => {
            dispatch(AgentActions.onChangeCheckedLoader({ loaderType: LoaderType.URL, id: url.url, checked }));
        });
    }
    useEffect(() => {
        const loaders = currentAgent!.loaders?.filter((loader) => loader.type === LoaderType.URL);
        const urlsFromLoader = loaders?.length ? (loaders[0] as URLLoader).urls : [];
        setURLs(urlsFromLoader);
    }, [currentAgent?.loaders]);


    useEffect(() => {
        setSelectAll(urls.every((url) => url.checked))
    }, [urls])





    return (
        <div className="loader url-loader">

            <div className="url-loader-container">
                <input
                    type="text"
                    value={urlInput}
                    placeholder="Enter URL"
                    onChange={(e) => setUrlInput(e.target.value)}
                    className="url-loader-input"
                />
                <Button
                    variant={ButtonVariant.PRIMARY}
                    onClick={() => scanURl()}
                    disabled={!isValidUrl(urlInput)}
                    isLoading={loading}
                    className="url-loader-button"
                >
                    Scan
                    <ArrowForwardIcon ml={5} />
                </Button>
            </div>

            <List spacing={7} className="loader-list">
               {!!urls.length &&  <ListItem >
                    <Checkbox
                        onChange={(e) => onHandleSelectAll(e.target.checked)}
                        isChecked={selectAll}
                        zIndex={1}
                        colorScheme="primary"
                        mb={1}
                    >
                        Select All
                    </Checkbox>
                </ListItem>}
                {urls.map((urlObj, i) => <ListItem
                    key={i}
                    lineHeight={1}
                >
                    <Checkbox
                        onChange={(e) => onChangeChecked(urlObj.url, e.target.checked)}
                        isChecked={urlObj.checked}
                        zIndex={1}
                        colorScheme="primary"
                        mb={1}
                    />
                    <Link
                        isExternal
                        _hover={{ color: "#4078c0", textDecoration: "underline" }}
                        ml={1}
                        href={urlObj.url}
                        color={urlObj.error ? "red" : "#000"}
                    >
                        {urlObj.url.replace('%', '')}
                    </Link>
                    <Divider />
                </ListItem>
                )}
                <ListItem>
                    <Flex  justifyContent="space-between" alignItems="center">
                        <input style={{width:'100%'}} placeholder="Type URL" value={customUrlInput} onChange={(e) => SetCustomUrlInput(e.target.value)} />
                        <Button style={{width:'10%'}} isIcon disabled={!isValidUrl(customUrlInput)} onClick={onAddCustomUrl}>
                            <Icon variant={IconVariant.ADD} theme={IconTheme.BLACK} />
                        </Button>
                    </Flex>

                </ListItem>
            </List>





        </div>
    )
}


export default React.memo(UrlLoaderComponent)