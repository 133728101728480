import { Icon as CIcon, AccordionItem, AccordionButton, AccordionIcon, Box, AccordionPanel, VStack, FormControl, FormLabel, Accordion, Flex } from '@chakra-ui/react'
import AutoComplete from "src/components/auto-complete/auto-complete"
import React, { useEffect, useState, useCallback } from 'react'
import { HiOutlineTrash } from 'react-icons/hi'
import { ChakraMenu } from 'src/components/chakra/menu-item'
import Icon, { IconVariant, IconTheme } from 'src/components/icon/icon'
import { DataTypeLabel, FilterObject, GroupCondtions, LogicalOperator, OperatorInfo, OperatorLabel } from 'src/components/node/filter_node/interfaces'
import { TeamNodeType } from 'src/components/team-builder/interfaces'
import { DataType } from 'src/enums/enums'
import { Editor } from '../../team-react-mde'
import Button, { ButtonVariant } from 'src/components/button/button'
import { FilterNodeData } from 'src/models'
import { useReactFlow, Node } from 'reactflow'
import { SmallCloseIcon } from '@chakra-ui/icons'
import useAddNode from 'src/hooks/team-builder/useAddNode';


interface Child {
    name: string
    id: string
}





function Item({ id, group, childs, onUpdteGroup, onRemoveGroup }: {
    id: string,
    childs: Array<Child>,
    group: GroupCondtions,
    onUpdteGroup: (newGroup: GroupCondtions) => void
    onRemoveGroup: (id: string) => void
}) {

    const { getNode } = useReactFlow()
    const [tempIndex, setIndex] = useState<number | null>(null)

    const operators = [
        {
            title: "Basic Operators",
            items: [OperatorLabel.EXISTS, OperatorLabel.NOT_EXISTS].map((key) => ({
                value: `Basic Operator: ${key}`,
                label: key,
                action: () => onChangeOperator(key, DataType.BASIC)
            }))
        },
        {
            title: "Text Operators",
            items: [OperatorLabel.EQUAL, OperatorLabel.EQUAL_INSENSETIVE, OperatorLabel.NOT_EQUAL, OperatorLabel.NOT_EQUAL_INSENSETIVE,
            OperatorLabel.CONTAINS, OperatorLabel.CONTAINS_INSENSETIVE, OperatorLabel.NOT_CONTAINS, OperatorLabel.NOT_CONTAINS_INSENSETIVE,
            OperatorLabel.STARTS_WITH, OperatorLabel.STARTS_WITH_INSENSETIVE, OperatorLabel.NOT_STARTS_WITH, OperatorLabel.NOT_STARTS_WITH_INSENSETIVE,
            OperatorLabel.ENDS_WITH, OperatorLabel.ENDS_WITH_INSENSETIVE, OperatorLabel.NOT_ENDS_WITH, OperatorLabel.NOT_ENDS_WITH_INSENSETIVE].map((key) => ({
                value: `Text Operator: ${key}`,
                label: key,
                action: () => onChangeOperator(key, DataType.TEXT)
            }))
        },
        {
            title: "Number Operators",
            items: [OperatorLabel.EQUAL, OperatorLabel.NOT_EQUAL, OperatorLabel.GREATER_THAN, OperatorLabel.GREATER_THAN_OR_EQUAL_TO,
            OperatorLabel.LESS_THAN, OperatorLabel.LESS_THAN_OR_EQUAL_TO].map((key) => ({
                value: `Number Operator: ${key}`,
                label: key,
                action: () => onChangeOperator(key, DataType.NUMBER)
            }))
        }
    ]

    const names = childs.map((child) => ({
        value: child.name,
        label: child.name,
        action: () => onChildSelect(child.id)
    }))






    const onAddCondition = (index:number): void => {

        const cloneConditions = [...group.conditions]

        const newCondition = {
            field: '',
            operator: { label: OperatorLabel.EQUAL, dataType: DataType.TEXT },
            value: ''
        }
        cloneConditions[index].logicalOperator = LogicalOperator.AND
        cloneConditions.push(newCondition)

        const newGroup = {
            ...group,
            conditions: cloneConditions
        }
        onUpdteGroup(newGroup)
    }

    const onChildSelect = (item: string) => {
        const newGroup = {
            ...group,
            id: item
        }
        onUpdteGroup(newGroup)
    }

    const onFieldChange = (value: string, index:number) => {
        const newGroup = {
            ...group,
            conditions: group.conditions.map((condition, i) => i === index ? { ...condition, field: value } : condition)
        }
        onUpdteGroup(newGroup)
    }

    const onChangeOperator = (key: OperatorLabel, dataType: DataType) => {
        if (tempIndex === null) return
        const operatorInfo: OperatorInfo = {
            label: key,
            dataType
        }

        const newGroup = {
            ...group,
            conditions: group.conditions.map((condition, i) => i === tempIndex ? { ...condition, operator: operatorInfo } : condition)
        }
        onUpdteGroup(newGroup)
    }

    const onChangeValue = (value: string, index:number) => {
        if (index === null) return

        const newGroup = {
            ...group,
            conditions: group.conditions.map((condition, i) => i === index ? { ...condition, value } : condition)
        }
        onUpdteGroup(newGroup)
    }

    const onRemoveCondition = (index:number) => {
        const newGroup = {
            ...group,
            conditions: group.conditions.splice(0, index)
        }
        onUpdteGroup(newGroup)
    }

    const onChangelogicalOperator= (logicalOperator: LogicalOperator, index:number) => {
        if (index === null) return

        const newGroup = {
            ...group,
            conditions: group.conditions.map((condition, i) => i === index ? { ...condition, logicalOperator } : condition)
        }
        onUpdteGroup(newGroup)
    }




    return (
        <AccordionItem width="100%">
            <Flex alignItems="center">
                <CIcon
                    as={HiOutlineTrash}
                    boxSize={5}
                    cursor="pointer"
                    onClick={() => onRemoveGroup(group.id)}
                    m={"0 2pt"}
                />
                <Box flex="1" textAlign="left">
                    <AutoComplete
                        items={names}
                        value={getNode(group.id)?.data.name || 'Select Node'}
                        classNameInput="filter-group-name"
                        centerInput
                        centerItem
                        width="100%"
                        maxHeight={220}
                    />
                </Box>
                <AccordionButton flex="0">
                    <AccordionIcon />
                </AccordionButton>
            </Flex>
            <AccordionPanel pb={4}>

                {group.conditions.map((condition, index) => (
                    <VStack
                        key={index}
                        align="start"
                        spacing={4}
                        onFocus={() => setIndex(index)}
                        onBlur={() => setIndex(null)}
                    >
                        <CIcon
                            as={SmallCloseIcon}
                            boxSize={5}
                            cursor="pointer"
                            onClick={() => onRemoveCondition(index)}
                            position="absolute"
                            top={"50%"}
                            left={-3}
                        />
                        <FormControl>
                            <FormLabel>Field</FormLabel>
                            <Editor
                                id={id}
                                value={condition.field}
                                onChange={(value) => onFieldChange(value, index)}
                                textAreaClass="field-text" />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Operator</FormLabel>
                            <AutoComplete
                                items={operators}
                                value={`${DataTypeLabel[condition.operator.dataType as keyof typeof DataTypeLabel]} Operator: ${condition.operator.label}`}
                                variant="filled"
                                isGroup
                                centerInput
                                width="100%"
                                maxHeight={220}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Value</FormLabel>
                            <Editor
                                id={id}
                                value={condition.value}
                                onChange={(value) => onChangeValue(value, index)}
                                textAreaClass="field-text" />
                        </FormControl>

                        {condition?.logicalOperator ? <ChakraMenu
                            className="form-field-text"
                            currentItem={condition.logicalOperator}
                            onItemSelect={(item) => onChangelogicalOperator(item as LogicalOperator, index)}
                            items={Object.values(LogicalOperator)}
                            center
                            alignContent="center"
                            justifyContent="center"
                            width="50%"
                            m={"15pt auto"}
                        />
                            :
                            <div className="filter-group-add">
                                <Button isBlock variant={ButtonVariant.OUTLINE} onClick={() => onAddCondition(index)}>
                                    Add Condition
                                </Button>
                            </div>
                        }

                    </VStack>
                ))}
            </AccordionPanel>
        </AccordionItem>
    );
}

function FilterDict({ id, filter, childsNames, setFilter }: {
    id: string
    childsNames: Array<Child>
    filter: FilterObject,
    setFilter: React.Dispatch<React.SetStateAction<FilterObject>>
}) {

    const addNodeHandler = useAddNode(id);




    const onAddItem = () => {
        if (childsNames.length === filter.groups.length + 1) {
            setTimeout(() => {
                addNodeHandler(TeamNodeType.WORKFLOW);
            }, 500)
        }

        const newGroup = {
            id: '',
            conditions: [{
                field: '',
                operator: { label: OperatorLabel.EQUAL, dataType: DataType.TEXT },
                value: ''
            }]
        }

        setFilter((currentItems) => ({
            ...currentItems,
            groups: [...currentItems.groups, newGroup]
        }));
    };



    const onUpdateItem = (index: number, newItem: GroupCondtions) => {
        setFilter((currentItems) => {
            const newItems = [...currentItems.groups]
            newItems[index] = newItem
            return ({
                ...currentItems,
                groups: newItems
            })
        })
    }

    // const onRemoveGroup = (index: number) => {
    //     setFilter((currentItems) => ({
    //         ...currentItems,
    //         groups: currentItems.groups.filter((group) => group.id !== id)
    //     }))
    // }

    const onRemoveGroup = (index: number) => {
        setFilter((currentItems) => ({
            ...currentItems,
            groups: currentItems.groups.filter((_, i) => i !== index)
        }));
    };
    

    


    return (
        <Accordion allowToggle mt={5}>
            <Flex flexDir="column" position="relative">
                {filter.groups.map((item, index) => (<Item
                    key={index}
                    id={id}
                    group={item}
                    onUpdteGroup={(item) => onUpdateItem(index, item)} childs={childsNames}
                    onRemoveGroup={() => onRemoveGroup(index)} />
                ))}
                <Box alignSelf="center">
                    <Button isIcon onClick={onAddItem}>
                        <Icon variant={IconVariant.ADD} theme={IconTheme.GRAY} />
                    </Button>
                </Box>
            </Flex>
        </Accordion>
    );


}


function FilterConfig({ data }: { data: FilterNodeData }) {
    const { setNodes, getEdges, getNode } = useReactFlow();
    const childs = getEdges().filter((edge) => edge.source === data.id && edge.type === TeamNodeType.WORKFLOW).map((edge) => edge.target)

    const [tempFilter, setFilter] = useState<FilterObject>(data.filterObject || { groups: [], elseID: '' })




    const computeUpdatedNodes = useCallback((nodes: Array<Node>, id: string, filterObject: FilterObject) => (
        nodes.map((node) => {
            if (node.id === id && node.type === 'workflow') {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        filterObject

                    }
                };
            }
            return node;
        })
    ), []);

    useEffect(() => {
        setNodes((prevNodes) => computeUpdatedNodes(prevNodes, data.id, tempFilter));
    }, [tempFilter, data.id, computeUpdatedNodes]);

    const getNames = useCallback(() => (
        childs.filter((child) => !tempFilter.groups.some((group) => group.id === child) && child !== tempFilter.elseID)
            .map((child) => ({
                id: child,
                name: getNode(child)?.data.name
            }))
    ), [childs])

    const names = getNames().map((child) => ({
        value: child.name,
        label: child.name,
        action: () => setFilter((currentItems) => ({ ...currentItems, elseID: child.id }))
    }))

    return (
        <div className="config config-filter">
            <FilterDict id={data.id} filter={tempFilter} setFilter={setFilter} childsNames={getNames()} />
            <FormControl>
                <FormLabel>Else</FormLabel>
                <AutoComplete
                    items={names}
                    value={getNode(tempFilter.elseID)?.data.name || 'Select Node'}
                    centerItem
                    centerInput
                    width="100%"
                    maxHeight={220}
                    classNameInput="text-field"

                />
            </FormControl>

        </div>
    )
}

export default FilterConfig