import { TFunction } from 'i18next';
import React, { ReactElement } from 'react';
import { NodeObjectKey } from '../../../enums/enums';
import { ChatbotNodeData } from '../../../models';
import OptionInput from '../../form/option-input';



export default function ExtraSendLocation({ data, t, onChangeObj }: {
    data: ChatbotNodeData,
    t: TFunction,
    onChangeObj: (key: NodeObjectKey, value: string) => void
}): ReactElement {


    return (
        <>
            <OptionInput
                value={data?.obj?.latitude ?? ''}
                placeholder={'Latitude'}
                onChange={(value) =>
                    onChangeObj(NodeObjectKey.LATITUDE, value)}
                className="node-option-input"
            />
            <OptionInput
                value={data?.obj?.longitude ?? ''}
                placeholder="Longitude"
                onChange={(value) => onChangeObj(NodeObjectKey.LONGITUDE, value)}
                className="node-option-input"
            />
            <OptionInput
                value={data?.obj?.text ?? ''}
                placeholder={t("TEXT")}
                onChange={(value) => onChangeObj(NodeObjectKey.TEXT, value)}
                className="node-option-input"
            />
        </>
    );
}
