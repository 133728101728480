
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactFlow, {
    Background,
    Controls,
    Edge,
    MiniMap,
    Node,
    ProOptions,
    ReactFlowProvider,
    useEdgesState,
    useNodesState
} from 'reactflow';

import { Box, HStack, IconButton } from "@chakra-ui/react";

import useLayout from 'src/hooks/team-builder/useLayout';
import nodeTypes from './components/node-types';
import edgeTypes from './components/edge-types';
import ContextMenu from './components/context-menu';
import Button, { ButtonVariant } from '../button/button';

import 'reactflow/dist/style.css';
import './team-builder.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AgentActions, AgentDeatils, AgentSelectors } from 'src/redux/agents';
import { AuthSelectors } from 'src/redux/auth';
import { TeamFlowNodeType, TeamNodeType } from './interfaces';
import useUndoRedo from 'src/hooks/team-builder/useUndoRedo';

import { Alert, AlertActions, InputModal, ModalInput } from 'src/helpers/dialog';
import PreviewAgent from 'src/pages/agents/preview-agent';
import RunOnce from './run_once';
import { AgentServices } from 'src/services';
import { CiSettings } from "react-icons/ci";
import { ISettings } from 'src/models';
import SettingsTeam from './components/settings/settings';
import { ModelType } from './enums';
import Configuration from './components/config/config';
import { AgentService } from 'src/helpers/fetch/fetch-agent';
import { useTranslation } from 'src/hooks/use-translate';




const proOptions: ProOptions = { account: 'paid-pro', hideAttribution: true };



const fitViewOptions = {
    padding: 0.95
};

interface MenuState {
    id: string;
    top: number;
    left: number;

}

const defaultNodes = [
    {
        id: "1",
        position: { "x": 0, "y": 0 },
        data: {
            name: "John",
            id: "1",
            prompt: "{{question}}",
            model: ModelType.GPT4_O,
            type: TeamFlowNodeType.LLM,
            urlImage: "https://officelyfiles.s3.eu-west-1.amazonaws.com/2adc543c-9491-4b95-8f4c-afb28d4e1921.png"
        },
        type: TeamNodeType.WORKFLOW
    },
    {
        id: '2',
        data: { label: '+' },
        position: { x: 0, y: 500 },
        type: TeamNodeType.PLACEHOLDER
    }
]

const defaultEdges: Edge[] = [
    {
        id: '1=>2',
        source: '1',
        target: '2',
        type: TeamNodeType.PLACEHOLDER
    }
];




function TeamFlowBuilder() {
    useLayout();
    const agentService = new AgentService()
    const fullAccess = useSelector(AuthSelectors.accessTeam) as boolean;
    const user = useSelector(AuthSelectors.user)
    const { takeSnapshot } = useUndoRedo();
    const dispatch = useDispatch();
    const [menu, setMenu] = useState<MenuState | null>(null);
    const [settingsID, setSettingsID] = useState<string | null>(null);
    const ref = useRef<HTMLDivElement | null>(null);
    const currentAgent = useSelector(AgentSelectors.currentAgent)
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [settings, setSettings] = useState<{ isOpen: boolean, object: ISettings }>({ isOpen: false, object: currentAgent!.team?.settings ?? { startMessage: 'Hey, how Can I assist you?' } })
    const [modalInput, setModalInput] = useState<InputModal>({ isOpen: false })
    const [isPreview, setPreviewOpen] = useState<{ open: boolean, src?: string, title?: string }>({ open: false })
    const [runOnce, setRunOnce] = useState<{ isOpen: boolean, nodeID?: string }>({ isOpen: false })
    const apiService = new AgentServices(user!.token!)
    const [dialogActions, setDialogActions] = useState<AlertActions | null>(null)

    const tSwal = useTranslation("COMPONENTS.SWAL")



    const GenerateLink = (id: string) => `${window.location.origin}/#/team-chat/${id}/preview`

    const UpgradeAlert = () => {

        setDialogActions({
            title: tSwal("ERR_TEAM_UPGRADE_TITLE"),
            content: tSwal("ERR_TEAM_UPGRADE"),
            confirm: tSwal("TO_UPGRADE"),
            onConfirm: () =>  window.open('https://cal.com/officely-ai/demo', '_blank', 'noopener,noreferrer'),
            cancel: tSwal("CANCEL"),
            colorButton: "primary",
            isOpen:true
        })

    }


    useEffect(() => {
        setNodes(currentAgent?.team?.nodes ?? defaultNodes)
        setEdges(currentAgent?.team?.edges ?? defaultEdges)
    }, [onNodesChange, onEdgesChange])


    const onNodeContextMenu = useCallback((event: React.MouseEvent, node: Node) => {
        event.preventDefault();
        if (!fullAccess) { return }

        const pane = ref.current?.getBoundingClientRect();
        if (!pane) return;
        setMenu({
            id: node.id,
            top: event.clientY - pane.y,
            left: event.clientX - pane.x
        });
    }, []);


    const onCloseContext = useCallback(() => {
        if (menu) {
            takeSnapshot();
            setMenu(null);
        }

    }, [menu, setMenu, takeSnapshot]);

    const handlePublish = async (name: string, withPreview: boolean) => {
        withPreview && setModalInput((prevModalInput) => ({ ...prevModalInput, spinner: true }));
        const teamObject = { nodes, edges, settings: settings.object }
        const res = await agentService.InsertTeamBuilder(currentAgent!.agent_id, name ?? 'team', teamObject, user!.token!);
        if (res.success) {
            dispatch(AgentActions.onChangeAgentDeatils({ value: teamObject, agentDeatils: AgentDeatils.TEAM }))
            dispatch(AgentActions.onChangeAgentDeatils({ value: name, agentDeatils: AgentDeatils.NAME }))
            setModalInput({ isOpen: false, spinner: false });
            withPreview && onPreview()
        } else {
            setModalInput((prevModalInput) => ({ ...prevModalInput, spinner: false, error: res.err }));
        }
    }

    const onSave = () => {
        setModalInput({
            isOpen: true,
            title: "Select Name for the Team",
            defaultValue: currentAgent!.name,
            saveAction: (name) => handlePublish(name, true),
            error: null
        })
    }

    const onNodeDoubleClick = (_: React.MouseEvent, node: Node) => {
        if (!fullAccess) { return UpgradeAlert() }
        if (node.type === 'workflow') {
            setSettingsID(node.id)
        }
    }

    const onNodeClick = useCallback((_: React.MouseEvent, __: Node) => {
        takeSnapshot()
    }, [takeSnapshot])

    const onEdgeClick = useCallback((_: React.MouseEvent, __: Edge) => {
        takeSnapshot()
    }, [takeSnapshot])

    const onPreview = () => {
        const src = `${GenerateLink(currentAgent!.agent_id)}/preview`
        setPreviewOpen({ open: true, src, title: currentAgent!.name })
    }

    const onRunOnce = async (id: string) => {
        if (currentAgent?.inputs?.length === 0) {
            alert('Please load the inputs first by clicking on preview')
            return
        }
        await handlePublish(currentAgent!.name ?? 'team', false)
        setRunOnce({ isOpen: true, nodeID: id })

    }

    const onClosePreview = async () => {
        setPreviewOpen({ open: false })
        const newInputs = await apiService.getInputs(currentAgent!.agent_id)
        dispatch(AgentActions.onChangeAgentDeatils({ value: newInputs, agentDeatils: AgentDeatils.INPUTS }))
    }





    return (
        <div className="team-builder">
            <ReactFlow
                ref={ref}
                proOptions={proOptions}
                fitView
                nodes={nodes}
                edges={edges}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                fitViewOptions={fitViewOptions}
                minZoom={0.2}
                nodesDraggable={false}
                nodesConnectable={false}
                zoomOnDoubleClick={false}
                deleteKeyCode={null}
                onNodeContextMenu={onNodeContextMenu}
                onPaneClick={onCloseContext}
                onNodeDoubleClick={onNodeDoubleClick}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onNodeClick={onNodeClick}
                onEdgeClick={onEdgeClick}

            >
                <Background />
                {menu && <ContextMenu
                    onCloseContext={onCloseContext}
                    {...menu}
                    onRunOnce={onRunOnce}
                />}
                <MiniMap
                    zoomable
                    pannable
                    className="only-desktop"
                    nodeStrokeWidth={3}
                    ariaLabel={null}
                />
                <Controls />
                <Configuration id={settingsID} onClose={() => setSettingsID(null)} />
                <Box position="absolute" top={9} right={5} zIndex={10}>
                    <HStack>
                        <IconButton
                            aria-label="settings"
                            icon={<CiSettings size={20} />}
                            onClick={() => setSettings({ ...settings, isOpen: !settings.isOpen })}
                            _hover={{ opacity: 0.5 }}
                            hidden={!fullAccess}

                        />
                        <Button variant={ButtonVariant.PRIMARY} onClick={fullAccess ? onSave : onPreview}>
                            {fullAccess ? `Save & Preview` : 'Preview'}
                        </Button>

                    </HStack>

                </Box>
                {modalInput.isOpen && <ModalInput
                    isOpen={modalInput.isOpen}
                    onClose={() => setModalInput({ isOpen: false })}
                    headerTitle={modalInput.title ?? ''}
                    onSave={modalInput.saveAction}
                    textArea={modalInput.textArea}
                    spinner={modalInput.spinner}
                    error={modalInput.error}
                    defaultValue={modalInput.defaultValue}
                />}

                {isPreview.open && <PreviewAgent
                    isOpen={isPreview.open}
                    onClose={onClosePreview}
                    title={isPreview?.title ?? 'Preview Agent'}
                    src={isPreview.src}
                />}
                {settings.isOpen && <SettingsTeam
                    isOpen={settings.isOpen}
                    onClose={(values) => setSettings({ isOpen: false, object: values })}
                    data={settings.object}
                />}
                {runOnce.isOpen && <RunOnce
                    isOpen={runOnce.isOpen}
                    nodeID={runOnce.nodeID}
                    agent={currentAgent!}
                    onClose={() => setRunOnce({ isOpen: false })}
                />}

                <Alert
                    isOpen={dialogActions?.isOpen ?? false}
                    onClose={() => setDialogActions(null)}
                    actions={dialogActions!}
                />




            </ReactFlow>
        </div>
    );
}

function TeamBuilder() {
    return (
        <ReactFlowProvider>
            <TeamFlowBuilder />
        </ReactFlowProvider>
    );
}

export default React.memo(TeamBuilder);
