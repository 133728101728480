import { keyboardKey } from '@testing-library/user-event';
import classNames from 'classnames';
import React, { ChangeEvent, ReactElement, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRTLD } from '../../hooks/use-rtl';
import { useTransformByNode } from '../../hooks/use-transform-builder';
import { useTranslation } from '../../hooks/use-translate';
import { ChatbotNodeData } from '../../models/chat-bot-node';
import { ChatBuilderActions, ChatBuilderSelectors } from '../../redux/chat-builder';
import { ChatBotActionsActions } from '../../redux/chatbot-actions/actions';
import { ChatbotActionsSelectors } from '../../redux/chatbot-actions/selectors';
import Button, { ButtonSize, ButtonVariant } from '../button/button';
import Icon, { IconTheme, IconVariant } from '../icon/icon';
import './search.scss';


export default function SearchNode({isOpen, toggleClose}:{
    isOpen: boolean,
    toggleClose: () => void
}): ReactElement {
    const t = useTranslation('COMPONENTS.NODE_PREVIEW');
    const dispatch = useDispatch();
    const SetTransformByNode = useTransformByNode()
    const nodes = useSelector(ChatBuilderSelectors.nodes)
    const inputValue = useSelector(ChatbotActionsSelectors.inputSearchValue)
    const searchArr = useSelector(ChatbotActionsSelectors.searchArray)
    const activeSearch = useSelector(ChatbotActionsSelectors.activeSearch)
    const inputRef = useRef<HTMLInputElement>(null)
    const firstLoad = useRef(true)
    const isRTLD = useRTLD();

    useEffect(() => {
        if (isOpen) {
            inputRef.current?.focus()
        }
    }, [isOpen])

    useEffect(() => {
        window.addEventListener("keypress", handleKeyPress)
        return () => {
            window.removeEventListener("keypress", handleKeyPress)
        }
    }, [inputValue])

    useEffect(() => {
        if (firstLoad.current) {
            ChatBotActionsActions.changeActiveNode({ id: null })
            firstLoad.current = false;
            return;
        }
        if (searchArr.length && activeSearch > 0) {
            const searchNode = nodes.find((node) => node.id === searchArr[activeSearch - 1].id)
            if (searchNode) {
                SetTransformByNode(searchNode)
                //dispatch(ChatBuilderActions.onSelectSearchNode({ id: searchNode.id }))
            }
        }
    }, [activeSearch])


    const handleKeyPress = (k: keyboardKey) => {
        document.activeElement === inputRef.current && k.key === "Enter" && dispatch(ChatBotActionsActions.goNextSearchArray())
    }

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(ChatBotActionsActions.onChangeInputSearch({ input: e.target.value }));
        const searchInput = e.target.value.toLowerCase().trim()
        const serachNodeArr = searchInput ? nodes.filter((n) => (
            (t(`TEXT_${n?.type}`).toLowerCase().includes(searchInput)) ||
            ((n.data as ChatbotNodeData).text.toLowerCase().includes(searchInput)) ||
            ((n.data as ChatbotNodeData).name?.toLowerCase().includes(searchInput)) ||
            ((n.data as ChatbotNodeData).obj?.description?.toLowerCase().includes(searchInput)) ||
            ((n.data as ChatbotNodeData).obj?.url?.toLowerCase().trim() === searchInput) ||
            ((n.data as ChatbotNodeData).obj?.mediaLink?.toLowerCase().trim() === searchInput) ||
            ((n.data as ChatbotNodeData).obj?.jumpTo?.toLowerCase().includes(searchInput)) ||
            ((n.data as ChatbotNodeData).options?.filter((o) => o.text.toLowerCase().includes(searchInput)).length) 
            
        )) : []
        if (!serachNodeArr.length) {
            dispatch(ChatBotActionsActions.changeActiveNode({ id: null }))
        }
        dispatch(ChatBotActionsActions.onChangeSearchArray({ searchArray: serachNodeArr }));
    }


    const goNext = () => {
        dispatch(ChatBotActionsActions.goNextSearchArray());
    }

    const goPrev = () => {
        dispatch(ChatBotActionsActions.goPrevSearchArray());
    }
    
    const handleClose = () => {
        dispatch(ChatBotActionsActions.onChangeInputSearch({ input: '' }));
        dispatch(ChatBotActionsActions.onChangeSearchArray({ searchArray: [] }));
        dispatch(ChatBotActionsActions.changeActiveNode({ id: null }))
        dispatch(ChatBuilderActions.onSelectSearchNode({ id: '' }))
        toggleClose()
    }




    return (
        <div className="search">
            {isOpen && (
                <div className="search-box">
                    <input className={classNames(["search-input", { rtl: isRTLD(inputValue) }])}  type="text" ref={inputRef} value={inputValue} onChange={handleSearchChange}  />
                    <span className="search-count">
                        {`${activeSearch}/${searchArr.length}`}
                    </span>
                    <div className="search-box-buttons">
                        <Button isIcon={true} variant={ButtonVariant.LINK} onClick={goNext} size={ButtonSize.SMALL}>
                            <Icon theme={IconTheme.GRAY} variant={IconVariant.TOP} />
                        </Button>
                        <Button isIcon={true} variant={ButtonVariant.LINK} onClick={goPrev} size={ButtonSize.SMALL}>
                            <Icon theme={IconTheme.GRAY} variant={IconVariant.BOTTOM} />
                        </Button>
                        <Button isIcon={true} variant={ButtonVariant.LINK} onClick={handleClose} size={ButtonSize.SMALL}>
                            <Icon theme={IconTheme.GRAY} variant={IconVariant.REMOVE} />
                        </Button>

                    </div>
                </div>
            )}

        </div>
    );
}




