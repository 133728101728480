import React, { ReactElement } from 'react';


const GetIndex = (text: string, searchValue:string):number =>  text.toLowerCase().indexOf(searchValue.toLowerCase()) ?? -1


export function GetHighLightElement(searchValue:string, text: string, defualtElemnt?: ReactElement) {
    if (!searchValue) { return defualtElemnt ? defualtElemnt : text }
    searchValue = searchValue.trim()
    const index = GetIndex(text, searchValue)
    if (index === -1) { return defualtElemnt ? defualtElemnt : text}
    return (
        <span>
            {text.substring(0, index)}
            <span className="highlight">{text.substring(index, index + searchValue.length)}</span>
            {text.substring(index + searchValue.length)}
        </span>
    )
}

export function GetHighlightMdeText(searchValue:string, text: string, bool?:boolean) {
    if (!searchValue || bool) { return text }
    searchValue = searchValue.trim()
    const index = GetIndex(text,searchValue)
    if (index === -1){ return text}
    return text.substring(0, index) + 
    `<span class="highlight">${text.substring(index, index + searchValue.length)}</span>` +
    text.substring(index + searchValue.length)
}



