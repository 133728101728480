import { TFunction } from 'i18next';
import * as Yup from 'yup';

export class MayTapiForm {
    public webhook: string
    public phoneID:string;
    public productID:string
    public token: string;
    public msgWhatsappCallBackUrl:string //human
    public logCallBackUrl:string
    public msgCallBackUrl:string //bot

  constructor(phoneID?:string, productID?:string, token?:string, logCallBackUrl?:string, msgWhatsappCallBackUrl?:string, msgCallBackUrl?:string) {
    this.webhook = "Webhook URL"
    this.phoneID = phoneID || '';
    this.productID = productID || '';
    this.token = token || '';
    this.msgWhatsappCallBackUrl = msgWhatsappCallBackUrl || '';
    this.logCallBackUrl = logCallBackUrl || '';
    this.msgCallBackUrl = msgCallBackUrl || '';
  }


  public static getValidationSchema(t: TFunction): Yup.SchemaOf<MayTapiForm> {
    const regex = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/

    return Yup.object()
      .shape({
        phoneID:Yup.number().required(t('REQUIRED')),
        productID: Yup.string().required(t('REQUIRED')),
        token: Yup.string().required(t('REQUIRED')),
        msgWhatsappCallBackUrl:Yup.string().matches(regex, t('ENTER_CORRECT_URL')),
        logCallBackUrl: Yup.string().matches(regex, t('ENTER_CORRECT_URL')),
        msgCallBackUrl: Yup.string().matches(regex, t('ENTER_CORRECT_URL'))
      }).defined();
  }
}
