import { Tag } from '@chakra-ui/react';
import classNames from 'classnames';
import React, { ReactElement, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NodeObjectKey, NodeType } from '../../enums/enums';
import { GetHighLightElement } from '../../helpers/wrokflow-builder/highlight-search-text';
import { useRTL } from '../../hooks/use-rtl';
import { useTransformByNode } from '../../hooks/use-transform-builder';
import { ChatbotNodeData } from '../../models/chat-bot-node';
import { ChatBuilderActions, ChatBuilderSelectors } from '../../redux/chat-builder';
import { ChatbotActionsSelectors } from '../../redux/chatbot-actions/selectors';
import AutoComplete from '../auto-complete/auto-complete';
import Node from './node';
import './node.scss';
import TargetHandle from './target-handle';

function JumpNode({ data }: { data: ChatbotNodeData }): ReactElement {
  const nodesJump = useSelector(ChatBuilderSelectors.nodesJump);
  const nodes = useSelector(ChatBuilderSelectors.nodes);
  const searchValue = useSelector(ChatbotActionsSelectors.inputSearchValue)
  const [actionsType, setActionsType] = useState<any[]>([])
  const dispatch = useDispatch();
  const SetTransformByNode = useTransformByNode()
  const isRTL = useRTL()
  const node = nodes.find((n) => n.id === data.obj?.jump)
  const value = node?.data.name ?? ''

  const onChangeJump = (nodeName: string) => {
    const nodeJumpID = nodes.find((n) => n.data.name === nodeName)!.id
    dispatch(ChatBuilderActions.changeObj({ id: data.id, key: NodeObjectKey.JUMP, value: nodeJumpID, value2: nodeName }));
    dispatch(ChatBuilderActions.unselectAllNodes());

  }

//nodesJump.filter((val) => nodes.findIndex((x) => x.data.name === val.preview) === -1)
  useEffect(() => {
    setActionsType(nodesJump.sort((a, b) => a.value < b.value ? -1 : 1)
      //.map((x) => x.preview)
      .map((x) => ({
        value:x.preview,
        label: x.preview,
        action: () => onChangeJump(x.preview)
      }))
    )
  }, [nodesJump])




  const highLightCallBack = useCallback(() =>
    GetHighLightElement(searchValue, value ?? ''),
    [searchValue, value])

  const Jump = () => {
    SetTransformByNode(node!)
  }



  return (
    <Node
      data={data}
      type={NodeType.JUMP}
      className="node-jump">
      <TargetHandle id={data.id} />
      <>
        {data.isSelected ?
          <AutoComplete items={actionsType} value={value ?? ''} />
          :
          <>
            <span className={classNames(["node-description-jump", { 'rtl': isRTL }])}>
              {highLightCallBack()}
            </span>
            {node && <Tag position="relative" cursor="pointer" zIndex={5} onClick={Jump} size="lg" variant="solid" colorScheme="primary">
              Jump
            </Tag>}
          </>
        }
      </>
    </Node >
  );
}


const areEqual = (prevProps: any, nextProps: any) => prevProps.data === nextProps.data;

export default React.memo(JumpNode, areEqual);





