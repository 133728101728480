import { Divider } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { ModalPopup } from '../../helpers/dialog'

export default function PreviewAgent({ isOpen, title, src, divider, buttonsHeader, onClose }: {
    isOpen: boolean
    title: string
    buttonsHeader?: ReactNode
    divider?: boolean
    src?: string
    onClose: () => void
}) {

    //const isRTLD = useRTLD()

    return (
        <ModalPopup
            isOpen={isOpen}
            onClose={onClose}
            dir="ltr"
            className="preview-modal"
        >
            <div className="preview-iframe">
                <header className="preview-header">
                    <h1 className="title">{title}</h1>
                    {buttonsHeader && <div className="buttons-header">
                        {buttonsHeader}
                    </div>}
                </header>

                {divider && <Divider />}
                <div className="preview-iframe-content">
                    <iframe height="100%" width="100%"  src={src}></iframe>
                </div>
            </div>
        </ModalPopup>
    )
}


