import classNames from 'classnames';
import React, { ReactElement, useCallback} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NodeType } from '../../enums/enums';
import { getOptionID } from '../../helpers/wrokflow-builder/get-id';
import { GetHighLightElement } from '../../helpers/wrokflow-builder/highlight-search-text';
import { useRTL } from '../../hooks/use-rtl';
import { useTranslation } from '../../hooks/use-translate';
import { ChatbotNodeData } from '../../models/chat-bot-node';
import { ChatBuilderActions } from '../../redux/chat-builder';
import { ChatbotActionsSelectors } from '../../redux/chatbot-actions/selectors';
import Button, { ButtonVariant } from '../button/button';
import OptionInput from '../form/option-input';
import Node from './node';
import './node.scss';
import SourceHandle from './source-handle';
import TargetHandle from './target-handle';





function OptionsNode({ data }: { data: ChatbotNodeData }): ReactElement {
  const t = useTranslation('COMPONENTS.NODE');
  const dispatch = useDispatch();
  const searchValue = useSelector(ChatbotActionsSelectors.inputSearchValue)
  const isRTL = useRTL();


  const onChangeOption = (optionID: string, value: string): void => {
    dispatch(ChatBuilderActions.changeOption({ id: data.id, optionID, value }));
  };

  const onRemoveOption = (optionID: string): void => {
    dispatch(ChatBuilderActions.removeOption({ id: data.id, optionID }));
  };

  const onAddNewOption = (): void => {
    dispatch(ChatBuilderActions.addNewOption({ id: data.id, optionID: getOptionID() }));
  };

  const highLightCallBack = useCallback((value: string) =>
    GetHighLightElement(searchValue, value),
    [searchValue])

  return (
    <Node data={data}
      type={NodeType.OPTIONS}
      className="node-select">
      <TargetHandle id={data.id} />
      <div className="node-options">
        {data.options?.map((option, index) =>
          <div key={option.id} className={classNames(['node-option', { 'rtl': isRTL }])}>
            {(data.isSelected) ?
              <OptionInput
                onRemove={((data.options?.length ?? 0) > 1) ? () => onRemoveOption(option.id) : undefined}
                value={option.text}
                onChange={(value) => onChangeOption(option.id, value)}
                placeholder={`${t('TEXT_OPTION')} ${index + 1}`}
                className={classNames(['node-option-input', { 'rtl': isRTL }])}
              />


              :
              <>
                <span className="node-option-number">
                  {index + 1}.&nbsp;
                </span>
                <span className="node-option-text">
                  {highLightCallBack(option.text)}
                </span>
              </>
            }
            <SourceHandle id={option.id} />
          </div>
        )}
        {data.isSelected && <div className="node-options-add">
          <Button onClick={onAddNewOption} className={classNames({ 'rtl': isRTL })}
            onTouchStart={onAddNewOption} variant={ButtonVariant.PRIMARY} isBlock={true}>
            {t('BUTTON_ADD_OPTION')}
          </Button>
        </div>}
      </div>
    </Node>
  );
}

const areEqual = (prevProps: any, nextProps: any) => prevProps.data === nextProps.data;

export default React.memo(OptionsNode, areEqual);
