export enum AgentDeatils {
    AGENT_ID = 'agent_id',
    NAME = 'name',
    IMG = 'img',
    COMPANY_NAME = 'company_name',
    ROLE = 'role',
    OPENAI_API_KEY = 'openai_api_key',
    ASSISTANT_ID = 'assistant_id',
    CONVERSATION_PURPOSE = 'conversation_purpose',
    STAGES = 'stages',
    CUSTOM_ROLE = 'custom_role',
    MODEL = 'model',
    INGESTING_PARAM = 'ingesting_param',
    TEAM = 'team',
    INPUTS = 'inputs'
}

export enum FieldCoindtion {
    IF = "if",
    THEN = "then"
}

export enum LoaderType {
    PDF = "PDF",
    CSV = "CSV",
    TXT = "TXT",
    URL = 'URL',
    HTMLHierchy = "HTMLHierchy",
    UNKNOWN = "UNKNOWN"
  }



export enum RoleTypes {
    SALES = "Sales",
    ANALYST = "Analyst",
    SUPPORT = "Support",
    CUSTOM = "Custom" 
}

export enum ModelType{
    GPT3_5 = "gpt-3.5",
    GPT4 = "gpt-4",
    GPT4_1106 = 'gpt-4-1106-preview',
    CLAUDE2_1 = "anthropic.claude-v2:1",
    CLUADE_INSTANT = "anthropic.claude-instant-v1",
    CLUADE3_SONNET = "anthropic.claude-3-sonnet-20240229-v1:0",
    CLUADE3_HAIKU = "anthropic.claude-3-haiku-20240307-v1:0",
    LLAMA2_70B = 'LLAMA2-70b',
    MISTRAL_7B = "mistral.mistral-7b-instruct-v0:2",
    MISTRAL_8X7B = 'mistral.mixtral-8x7b-instruct-v0:1'

}

