
import React, { DragEvent, ReactElement, useState } from 'react'
import NodePreview from "../node-preview/node-preview";
import { NodeType } from "../../enums/enums"
import SideBarChatBuilder from './sidebar-chat-builder';
import Button, { ButtonSize, ButtonVariant } from '../button/button';
import Icon, { IconVariant } from '../icon/icon';
import classNames from 'classnames';
import { useNewNode } from '../../hooks/use-new-node';
import { useDispatch } from 'react-redux';
import { ChatBuilderActions } from '../../redux/chat-builder';
import BetaTag from '../beta-tag/beta-tag';




const onDragStart = (event: DragEvent, nodeType: NodeType) => {
    event?.dataTransfer?.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move' ?? null;
};







export default function NodeTypeList({ onFitView }: { onFitView: () => void }): ReactElement {
    const [isLimited, setIsLimited] = useState(window.innerWidth < 1140); // $mobile
    const getNewNode = useNewNode();
    const dispatch = useDispatch();


    const onTouchStart = (_: React.TouchEvent<HTMLDivElement>, nodeType: NodeType) => {
        const node = getNewNode(nodeType)
        dispatch(ChatBuilderActions.unselectAllNodes());
        dispatch(ChatBuilderActions.addElement({ element: node }));
        onFitView();
    };



    const listNodesTypes = [
        {
            "title": "Flow",
            "types": [
                NodeType.TEXT_QUESTION, NodeType.OPTIONS, NodeType.MEDIA, NodeType.RECEIVING_MEDIA,
                NodeType.VALID_LOCATION, NodeType.SEND_LOCATION, NodeType.VALID_DATE, NodeType.VALID_MAIL, NodeType.NUMBER, 
                NodeType.SEND_MESSAGE, NodeType.END
            ]
        },
        {
            "title": "Tools",
            "types": [NodeType.POWER_WORDS, NodeType.JUMP, NodeType.FILTER, NodeType.NOTIFICATION, NodeType.PLUGIN_AGENT]
        },
        {
            "title": "Webhook & API",
            "types": [NodeType.API, NodeType.WEBHOOK, NodeType.MAKE, NodeType.ZAPIER]
        },
        { 
            "title": "Meta (WhatsApp)",
            "types": [NodeType.BUTTONS, NodeType.WHATSAPP_LIST, NodeType.TEMPLATES]
        }
    ]

    const betaMode = [NodeType.PLUGIN_AGENT]

    return (
        <SideBarChatBuilder isLimited={isLimited} nodeListActions>
            <Button
                className={classNames(['minimize', 'tablet-desktop', { limited: isLimited }])}
                size={ButtonSize.SMALL}
                variant={ButtonVariant.LINK}
                isIcon={true}
                onClick={() => setIsLimited(!isLimited)}>
                <Icon variant={IconVariant.MINIMIZE_APPLE} />
            </Button>
            <div className="sidebar-chat-builder-content">
                {listNodesTypes.map((item, index) => <div key={index} className="list">
                    {!isLimited && <span className="list-title">{item.title}</span>}
                    <div className="list-items">
                        {item.types.map((key) => <div key={key} className="list-item"
                            onDragStart={(event: DragEvent) => onDragStart(event, key)} draggable
                            onTouchStart={(e) => onTouchStart(e, key)}
                        >
                            {betaMode.includes(key) &&  <BetaTag/>}
                            <NodePreview type={key} onlyIcon={isLimited} />
                        </div>)}
                    </div>


                </div>)}
            </div>

        </SideBarChatBuilder>

    )

}







