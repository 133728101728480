import { NodeTypes } from 'reactflow';

import WorkflowNode from './work-flow-node';
import PlaceholderNode from './placeholder-node';
import HelperNode from './helper-node';


// two different node types are needed for our example: workflow and placeholder nodes
const nodeTypes: NodeTypes = {
  placeholder: PlaceholderNode,
  workflow: WorkflowNode,
  helperNode: HelperNode
};









export default nodeTypes;
