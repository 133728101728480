import React, { ReactElement, ReactNode, useRef, useState } from 'react';
import classNames from 'classnames';
import { FormikProps, FormikValues } from 'formik';
import { get } from 'lodash';
import { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import FormError from './error';
import './form.scss';
import Button, { ButtonSize, ButtonVariant } from '../button/button';
import Icon, { IconTheme, IconVariant } from '../icon/icon';
import { useTranslation } from '../../hooks/use-translate';



export type FormTextProps<T = FormikValues> = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  formik?: FormikProps<T>;
  icon?: IconVariant;
  iconRealtive?: boolean;
  hiddenError?: boolean;
  children?: ReactNode;
  childPlaceholder?: string;
  regex?:RegExp
  onRemove?: () => void;
  onCopy?: () => void;
};

export type InputValue = string | number  | undefined | ReadonlyArray<string>;

export function getValue<T>(formik: FormikProps<T> | undefined, name: string | undefined): InputValue  {
  if (formik && name) {
    return get(formik.values, name);
  }
}



export function getTouched<T>(formik: FormikProps<T> | undefined, name: string | undefined): Array<string> | undefined {
  if (formik && name) {
    return get(formik.touched, name);
  }
}

export function getError<T>(formik: FormikProps<T> | undefined, name: string | undefined): string | undefined {

  if (formik && name) {
    return get(formik.errors, name);
  }
}

export function onBlur<T>(formik: FormikProps<T> | undefined, name: string | undefined): void {
  if (formik && name && getTouched(formik, name)) {
    formik.getFieldHelpers(name)
      ?.setTouched(true);
  }
}

export function onChange<T>(formik: FormikProps<T> | undefined, name: string | undefined, value:string, regex?:RegExp): void {

    if (formik && name) {
      let valid = true
      if (regex) {
        const res = regex.test(value) || value.length === 0;
        valid = res
      }
      valid && formik.setFieldValue(name, value) 
    }
}



export default function FormText<T>({
  type,
  formik,
  name,
  icon,
  iconRealtive,
  hiddenError,
  className,
  regex,
  onRemove,
  onCopy,
  maxLength,
  children,
  childPlaceholder,
  ...inputProps
}: FormTextProps<T>): ReactElement {
  const error = getError<T>(formik, name);
  const ref = useRef<HTMLInputElement>(null);
  const value = getValue<T>(formik, name)  || inputProps?.value 
  const [copied, setCopied] = useState(false)
  const t = useTranslation('FORMS');


  const handleCopy = (): void => {
    setCopied(true)
    setInterval(() => {
      setCopied(false)
    }, 2000)
  }



  return (
    <div
      className={classNames([
        'form-group',
        {
          'form-group-error': error,
          "realtive-icon-input": iconRealtive
        },

        className
      ])}>
      {(value || childPlaceholder) && <label className="form-label">{inputProps.placeholder || childPlaceholder}</label>}
      
      
     { children ? children : <input
        onChange={(e) => onChange(formik, name, e.target.value, regex)}
        onBlur={() => onBlur(formik, name)}
        onTouchStart={() => ref.current?.focus()}
        name={name}
        value={value ?? ''}
        type={type ?? 'text'}
        ref={ref}
        className={classNames(["form-control", {'realtive-icon-input':iconRealtive, 'empty':!value}])} 
        maxLength={maxLength}
        size={maxLength && value?.toString().length}
        {...inputProps}
      />
      }
      {icon && <span className={classNames(["form-control-icon", {'realtive-icon-input':iconRealtive}])} ><Icon variant={icon} /></span>}
      {maxLength && <span className="form-control-maxLength">{`${value?.toString().length}/${maxLength}`}</span>}
      {onCopy && <a className="form-control-copy" onClick={() => {handleCopy(); onCopy()}}>
        <>
          {copied ?
            <span>{t('COPIED')}</span>
            :
            <Icon variant={IconVariant.COPY_BIG} theme={IconTheme.GRAY} />}
        </>
      </a>}

      {!hiddenError && error && <FormError error={error} />}
      {onRemove && <Button onClick={onRemove} onTouchStart={onRemove} variant={ButtonVariant.OUTLINE} isRound={true} size={ButtonSize.SMALL} className="form-remove">
        <Icon variant={IconVariant.REMOVE_SMALL} theme={IconTheme.PRIMARY} />
      </Button>}
    </div>
  );
}
