import React, { ReactNode, useEffect, useRef, useState } from "react";

import { useDisclosure, Modal, ModalOverlay, Box, ModalContent, ModalHeader, ModalCloseButton, ModalBody, FormControl, Input, ModalFooter, Button, Spinner, FormErrorMessage } from "@chakra-ui/react"
import classNames from "classnames";
import { useRTL } from "../../hooks/use-rtl";
import { useTranslation } from "../../hooks/use-translate";

export interface InputModal {
    isOpen: boolean;
    textArea?: boolean;
    title?: string;
    saveAction?: (value: string) => void;
    spinner?: boolean | null;
    error?: string | null;
    defaultValue?: string;
  }

export function ModalInput({ isOpen, headerTitle, textArea, spinner, error, onSave, onClose, defaultValue }: {
    isOpen: boolean,
    headerTitle: string,
    textArea?: boolean,
    spinner?: boolean | null,
    error?: string | null,
    defaultValue?: string,
    onSave?: (value: string) => void
    onClose: () => void
}) {
    const { onOpen } = useDisclosure()
    const [value, setValue] = useState(defaultValue ?? '')
    const initialRef = useRef<any>(null)
    const finalRef = useRef(null)
    const isRTL = useRTL()
    const tSwal = useTranslation("COMPONENTS.SWAL")


    useEffect(() => {
        if (isOpen) {
            onOpen()
        }
    }, [isOpen])

    useEffect(() => {
        window.addEventListener("keypress", handleKeyPress)
        return () => {
            window.removeEventListener("keypress", handleKeyPress)
        }
    }, [value])

    const handleKeyPress = (k: KeyboardEvent) => {
        if (!isOpen) return
        (k.key === "Enter" && !textArea) && handleSave()
    }

    const handleSave = () => {
        onSave && onSave(value)
        spinner === null && onClose()
    }


    return (
        <>
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
                isCentered
                closeOnOverlayClick={false}
                
            >
                <ModalOverlay
                    bg="blackAlpha.300"
                    backdropFilter="blur(10px) "
                />
                <ModalContent className={classNames({ rtl: isRTL })} style={spinner ? {alignItems:"center", justifyContent:"center"}: {}} >
                    
                       {spinner && <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="primary.500"
                            size="xl"
                            position="absolute"
                            />}
                            <Box opacity={spinner ? 0.1 : 1}>
                                <ModalHeader>{headerTitle}</ModalHeader>
                                <ModalCloseButton style={isRTL ? { left: "var(--chakra-space-3)", right: "unset" } : {}} />
                                <ModalBody pb={6}>
                                    <FormControl isInvalid={!!error} >
                                        <Input value={value} ref={initialRef} lineHeight={2} onChange={(e) => setValue(e.target.value)} as={textArea ? "textarea" : "input"} />
                                        {error && <FormErrorMessage>{error}</FormErrorMessage>}
                                    </FormControl>
                                </ModalBody>

                                <ModalFooter gap={3}>
                                    <Button
                                        isDisabled={!value}
                                        onClick={handleSave}
                                        colorScheme="primary"
                                        disabled={spinner || false}
                                        >
                                        {tSwal("SAVE")}
                                    </Button>
                                    <Button onClick={onClose} disabled={spinner || false}>{tSwal("CANCEL")}</Button>
                                </ModalFooter>
                            </Box>
                    


                </ModalContent>

            </Modal>
        </>
    )
}

export function ModalPopup({ isOpen, children, dir, className, onClose, closeOnOverlayClick, modalOverlay }: {
    isOpen: boolean,
    children: ReactNode
    onClose: () => void
    dir?: "rtl" | "ltr"
    className?: string
    closeOnOverlayClick?: boolean 
    modalOverlay?:boolean

}) {
    const { onOpen } = useDisclosure()
    const isRTL = dir ? dir === 'rtl' : useRTL()

    useEffect(() => {
        if (isOpen) {
            onOpen()
        }
    }, [isOpen])

    return (
        <>
            <Modal closeOnOverlayClick={closeOnOverlayClick ?? true} onClose={onClose} isOpen={isOpen} isCentered size={"6xl"}>
                {(modalOverlay ?? true) && <ModalOverlay
                    bg="blackAlpha.300"
                    backdropFilter="blur(10px) "
                />}
                <ModalContent className={classNames([className,{ rtl: isRTL }])} p={5} m={0}>
                    <ModalCloseButton style={isRTL ? { left: "var(--chakra-space-3)", right: "unset" } : {}} />
                    <ModalBody >
                        {children}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

