import { TFunction } from 'i18next';
import * as Yup from 'yup';


export class LoginForm {
  public phone: string;
  public verificationCode: string;
  public countryCode: any;

  constructor() {
    this.phone = '';
    this.verificationCode = '';
    this.countryCode = '';
  }


  public static getValidationSchema(t: TFunction, isVerification: boolean): Yup.SchemaOf<LoginForm> {

    return Yup.object()
      .shape({
        phone: Yup.string().required(t("REQUIRED")),
        verificationCode: Yup.string()
          .when('phone', {
            is: () => isVerification,
            then: Yup.string().required(t('REQUIRED'))
          })
      }).defined();
  }
}
