import React, { ReactElement, useState } from 'react';
import classNames from 'classnames';
import { useRTL } from '../../hooks/use-rtl';
import { useTranslation } from '../../hooks/use-translate';
import Button, { ButtonSize, ButtonVariant } from '../../components/button/button';
import { useFetchOfficely } from '../../hooks/use-fetch-officely';
import { Method, Plan } from '../../enums/enums';
import { useDispatch, useSelector } from 'react-redux';
import { AuthSelectors } from '../../redux/auth/selectors';
import { AuthActions } from '../../redux/auth/actions';
import FormText from '../../components/form/text';
import { ProfileFrom } from '../../forms/profile';
import ProfileIMG from '../../assets/images/profile.svg'
import { useFormik } from 'formik';
import { Alert, AlertActions } from '../../helpers/dialog';
import { UploadUserProfile } from '../../helpers/fetch/fetch-user';
import FormPhone from 'src/components/form/phone';


export function ProfilePage(): ReactElement {
    const isRTL = useRTL();
    const dispatch = useDispatch();
    const t = useTranslation('PAGES.PROFILE');
    const tSwal = useTranslation('COMPONENTS.SWAL')
    const tForms = useTranslation('FORMS.SIGNUP');
    const user = useSelector(AuthSelectors.user)
    const [isLoading, setIsLoading] = useState(false)
    const [isOpenDialog, setIsOpenDialog] = useState(false)
    const [dialogActions, setDialogActions] = useState<AlertActions | null>(null)


    const onFileChange = async (event: React.ChangeEvent<HTMLInputElement | null>) => {
        const file = event.target.files && event.target.files[0]
        if (!file) return
        const res = await UploadUserProfile(user.token!, file)
        if (res.success && res.data){
            dispatch(AuthActions.onChangeImage({ image: res.data as string }))
        }
        // reader.onload = async () => {
        //     //formData.append('image', selectedImage);

        //     // const body = {
        //     //     uphone_number: user.uphone_number,
        //     //     file: reader.result
        //     // }
        //     //formData.append('image', selectedImage);

        //     const res = await UploadUserProfile(user.token, file)
        //     res.success && dispatch(AuthActions.onChangeImage({ image: reader.result?.toString() }))

        // }
        // file && reader.readAsDataURL(file)
    }

    const handleSubmit = async (values: ProfileFrom): Promise<void> => {
        setIsLoading(true)
        const email = values.email
        const bs_name = values.bs_name
        const partner = values.partner
        const new_phone = values.phone.replace('+', '')
        const body = {
            uphone_number: user.uphone_number,
            email,
            bs_name,
            new_phone
        }
        const res = await useFetchOfficely('updateUserDeatils', Method.POST, body)
        if (res.success) {
            dispatch(AuthActions.onUpdateDeatils({ bs_name, email, partner, new_phone }))
        }
        else if (res.err === 'ERR_PHONE_NUMBER') {
            formik.setFieldError('phone', tForms('ERR_PHONE_NUMBER'))
        } else if (res.err === 'ERR_EMAIL') {
            formik.setFieldError('email', tForms('ERR_EMAIL'))
        } else {
            setDialogActions({
                title: tSwal("ERROR"),
                content: tSwal("ERROR_FETCH"),
                confirm: tSwal("OK"),
                colorButton: "primary",
                isMessage: true
            })
            setIsOpenDialog(true)
        }
        setIsLoading(false)

    }
    const uphoneNumber = user.uphone_number.startsWith("user_") ? "" : "+" + user.uphone_number 
    const initialValues = new ProfileFrom(user.bs_name, user.email, uphoneNumber, user.partner)
    const validationSchema = ProfileFrom.getValidationSchema(tForms);

    const formik = useFormik({
        initialValues,
        onSubmit: handleSubmit,
        validationSchema,
        enableReinitialize: false
    });



    return (
        <div className="page menu-page">
            <header className="page-header min-height">
                <div className="page-header-left"></div>
                <div className="page-header-right">
                </div>
            </header>
            <div className="page-content">
                <div className="menu-page-items">
                    <div className="menu-page-items-upload-file">
                        <label>
                            <span>
                                <img className="avatar avatar-profile"
                                    src={user.image ? user.image : ProfileIMG} />
                            </span>
                            <input type="file" onChange={onFileChange} accept="image/*" />
                        </label>
                    </div>

                    <form
                        className={classNames(['menu-page-items-form', { rtl: isRTL }])}>
                        <FormText name="token"
                            value={user.token!}
                            placeholder={t('TEXT_TOKEN')}
                            disabled
                            onCopy={() => navigator.clipboard.writeText(user.token!)}
                        />
                        {user.plan === Plan.PARTNER && <FormText name="partner_code"
                            value={user.partner_code!}
                            formik={formik}
                            placeholder={t('PARTNER_CODE')}
                            disabled
                            onCopy={() => navigator.clipboard.writeText(user.partner_code!)}
                        />}
                        <FormText<ProfileFrom> name="bs_name"
                            formik={formik}
                            placeholder={t('TEXT_NAME')}
                        />
                        <FormText<ProfileFrom> name="email"
                            formik={formik}
                            placeholder={t('TEXT_EMAIL')}
                            inputMode="email"
                        />
                        <FormPhone<ProfileFrom> name="phone"
                                            formik={formik}
                                            placeholder={t('TEXT_WHATSAPP_PHONE_NUMBER')}
                                            inputMode="tel"
                                        />
                        {user.partner &&
                            <FormText<ProfileFrom> name="partner"
                                formik={formik}
                                placeholder={t("PARTNER")}
                                readOnly={true}
                            />}


                        {/* <Dropdown items={actions_type} allowScrolling={true} className="form-group" >
                            <FormText<ProfileFrom> name="partner"
                                formik={formik}
                                placeholder={t("PARTNER")}
                                onChange={(e) => onChangeInputValue(e.target.value)}
                            />
                        </Dropdown> */}


                    </form>
                    <div className="menu-page-items-team">
                    </div>
                </div>
                <footer className="menu-page-items-footer">
                    <Button
                        className={classNames([{ 'rtl': isRTL }])}
                        variant={ButtonVariant.OUTLINE}
                        size={ButtonSize.MEDIUM}
                        isLoading={isLoading}
                        isHalfBlock={true}
                        onClick={() => formik.handleSubmit()}
                        type="submit"
                        >
                        {t('BUTTON_SAVE')}
                    </Button>
                </footer>

            </div>
            {isOpenDialog && <Alert
                isOpen={isOpenDialog}
                onClose={() => setIsOpenDialog(false)}
                actions={dialogActions!}
            />}

        </div >


    );
}



