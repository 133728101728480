import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useReactFlow, Node } from 'reactflow';
import { JumpNodeData } from 'src/models/team-builder-node';
import '../config.scss'
import { FormLabel } from '@chakra-ui/react';
import AutoComplete from 'src/components/auto-complete/auto-complete';

interface IJumpConfig {
    to_id: string,
    to_name: string,
}



function JumpConfig({ data }: {
    data: JumpNodeData,
}): ReactElement {
    const { setNodes, getNodes } = useReactFlow();
    const [jump, setJump] = useState<IJumpConfig>({ to_id: data.jump_to_id, to_name: data.jump_to_name });


    const getAllNodesNames = useMemo(() => {
        const nodes = getNodes();

        if (!nodes.length) {
            return [];
        } 

        const nodeNames = nodes
            .filter((node) => node.type === 'workflow' && node.id !== data.id)
            .map((node) => ({
                value: node.data.name,
                label: node.data.name,
                action: () => setJump({ to_id: node.id, to_name: node.data.name })
            }));


        return nodeNames;
    }, [getNodes]);











    const computeUpdatedNodes = useCallback((nodes: Array<Node>, jump: IJumpConfig | null, id: string) => (
        nodes.map((node) => {
            if (node.id === id && node.type === 'workflow') {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        jump_to_id: jump?.to_id || '',
                        jump_to_name: jump?.to_name || ''
                    }
                };
            }
            return node;
        })
    ), []);

    useEffect(() => {
        setNodes((prevNodes) => computeUpdatedNodes(prevNodes, jump, data.id));
    }, [jump, data.id, computeUpdatedNodes]);










    return (
        <form className="config config-jump">

                <FormLabel className="label">Jump To</FormLabel>
                <AutoComplete
                    items={getAllNodesNames}
                    value={jump.to_name}
                    variant="filled"
                    centerInput
                    width="100%"
                    maxHeight={220}
                    classNameInput="text-field"
                />




        </form >


    )
}





export default JumpConfig;



