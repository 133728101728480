import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { CalculateShowInput, ClickBtn, GetNewQuestionOfficelyChannel, WidgetHeader } from '../../../components/officely-web-componnents'
import WidgetContent from '../../../components/officely-web-componnents/widget-content'
import { ChatBuilderSelectors } from '../../../redux/chat-builder'
import { ExtraElementChat, selfData, WidgetBody, WidgetButtons } from '../interfaces'
import WidgetTemp, { WidgetMode } from '../widget-temp'
import { Node } from 'reactflow';
import { useTransformByNode } from '../../../hooks/use-transform-builder'
import { AuthSelectors } from '../../../redux/auth'



export default function WidgetPreviewMode({ nodeID, render }: {
  nodeID?: string;
  render?: boolean;
}) {

  const user = useSelector(AuthSelectors.user)
  const currentBuilder = useSelector(ChatBuilderSelectors.currentBuilder)

  const [isChat, setIsChat] = useState(true)
  const [answer, setAnswer] = useState<string | null>()
  const [loading, setLoading] = useState<boolean>()
  const [selfData, setSelfData] = useState<selfData>()
  const nodes = useSelector(ChatBuilderSelectors.nodes)
  const SetTransformByNode = useTransformByNode()

  let isMounted = true;



  useEffect(() => {
    const fetchApi = async (): Promise<void> => {
      const body: WidgetBody = { codeUser: user.code_user, isPreview: true }
      body.IP = user.uphone_number
      body.quesName = nodeID

      await GetQuestion(body);
    };

    if (isMounted) {
      setSelfData(undefined)
      fetchApi();
    }
    return () => { isMounted = false; };

  }, [render]);


  const GetQuestion = async (body: any) => {
    setAnswer(null);
    setLoading(true)

    const resData: selfData | boolean = await GetNewQuestionOfficelyChannel(body, isChat, selfData)
    if (!resData) return
    setTimeout(() => {
      setSelfData(resData as selfData)
      const node = nodes?.find((node: Node) => node.id === (resData as selfData)?.ques_data?.ques.ques_name)
      node && SetTransformByNode(node)
      setLoading(false)
    }, 500);
  }


  const handleClickBtn = (payload: WidgetButtons) => {
    ClickBtn(payload, selfData?.chat ?? [])
    handleSubmit({ payload })
  }

  const handleSubmit = ({ payload, value, extraElement, skip }: {
    payload?: WidgetButtons,
    value?: string,
    extraElement?: ExtraElementChat,
    skip?: boolean
  }) => {
    if ((!answer?.trim() && !payload && !value && !extraElement)) return;

    const ans = payload ? payload.title : value ? value : answer?.trim();

    if (!(skip)) {
      selfData!.chat = [...selfData!.chat!, {
        type: "inbound",
        text: ans ?? '',
        extra_elements: extraElement
      }];
    }


    selfData!.payload = payload;

    const tempSelfData = isChat ? selfData : { ...selfData, chat: [] }

    const body = { answer: ans, selfData: tempSelfData, isPreview: true };

    GetQuestion(body);


  }




  const showInput = useMemo(() => CalculateShowInput(isChat, selfData), [isChat, selfData]);


  const toggleView = () => {
    if (selfData) {
      setIsChat(!isChat)
      const newChat = selfData?.chat?.slice(-1) ?? []
      const newSelfData = { ...selfData!, chat: newChat }
      setSelfData(newSelfData);
    }

  }



  return (
    <WidgetTemp className={{ preview: true, form: !isChat }} style={currentBuilder!.configuration.style} >
      <WidgetHeader
        isChat={isChat}
        mode={WidgetMode.PREVIEW}
        toogleView={toggleView}
        logo={currentBuilder?.configuration.logo}
      />
      <WidgetContent
        isChat={isChat}
        chatHistory={selfData?.chat ?? []}
        showInput={showInput}
        answerState={{ answer, setAnswer }}
        loadingState={{ loading, setLoading }}
        handleClickBtn={handleClickBtn}
        handleSubmit={handleSubmit}
        img={currentBuilder?.configuration.logo}
      />





    </WidgetTemp >
  )
}


