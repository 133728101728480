import { Method } from "../../enums/enums"
import { ResponseOfficely, useFetchOfficely } from "../../hooks/use-fetch-officely"



enum UserRoutes {
    GET_USER = 'GetUser',
    UPLOUD_PROFILE_IMAGE = 'uploadProfileImage',
    GET_DASHBOARD = 'getDashboard',
    GET_BUILDERS = 'GetBuilders',
    GET_CHAT_BUILDER = "getChatBuilder"

}



export async function UploadUserProfile(token: string, file: File): Promise<ResponseOfficely> {
    const formData = new FormData();
    formData.append('image', file);
    return await useFetchOfficely(UserRoutes.UPLOUD_PROFILE_IMAGE, Method.POST, formData, token)
}

export async function GetUser(phone: string, sendCode: boolean, token: string): Promise<ResponseOfficely> {
    return await useFetchOfficely(`${UserRoutes.GET_USER}/${phone}/${sendCode}`, Method.GET, token)
}

export async function GetDashboard(token: string, phone?:string): Promise<ResponseOfficely> {
    const route = phone ? `${UserRoutes.GET_DASHBOARD}/${phone}` : UserRoutes.GET_DASHBOARD
    return await useFetchOfficely(route, Method.GET, null, token)
}

export async function GetBuilders(token: string): Promise<ResponseOfficely> {
    return await useFetchOfficely(UserRoutes.GET_BUILDERS, Method.GET, null, token)
}

export async function GetChatBuilders(builder_id: number, token:string): Promise<ResponseOfficely> {
    return await useFetchOfficely(`${UserRoutes.GET_CHAT_BUILDER}/${builder_id}`, Method.GET, null, token)
}

export async function GetConversations(token:string): Promise<ResponseOfficely> {
    return await useFetchOfficely('GetConversations', Method.GET, null, token)
}


