// export enum ModelType {
//   GPT3_5_Turbo = "GPT-3.5 Turbo",
//   GPT4 = "GPT-4",
//   GPT_4_TURBO = "GPT-4 Turbo",
//   CLAUDE3_SONNET = "Claude-3 Sonnet",
//   CLAUDE3_HAIKU = "Claude-3 Haiku",
//   CLAUDE2_1 = "Claude-2.1",
//   CLAUDE_INSTANT = "Claude Instant",
//   MISTRAL_7B = "Mistral 7B",
//   MISTRAL_8X7B = "Mistral 8x7B",
//   MISTRAL_LARGE = "Mistral Large",
//   LLAMA3_8B = "Llama-3 8B",
//   LLAMA3_70B = "Llama-3 70B",
//   LLAMA2_13B = "Llama-2 13B",
//   LLAMA2_70B = "Llama-2 70B",
//   COMMAND_R_PLUS = "Command R+",
//   COMMAND_R = "Command R",
//   COMMAND = "Command",
//   COMMAND_LIGHT = "Command Light",
//   JURASSIC2_ULTRA = "Jurassic-2 Ultra",
//   JURASSIC2_MID = "Jurassic-2 Mid",

// }

export enum ModelType {
  AZURE = "AZURE",
  TOGETHER = "TOGETHER",
  GPT3_5_TURBO = "GPT3_5_TURBO",
  GPT4 = "GPT4",
  GPT_4_TURBO = "GPT_4_TURBO",
  GPT4_O = "GPT4_O",
  GPT4_O_MINI = "GPT4_O_MINI",
  CLAUDE3_SONNET = "CLAUDE3_SONNET",
  CLAUDE3_HAIKU = "CLAUDE3_HAIKU",
  CLAUDE3_5 = "CLAUDE3_5",
  CLAUDE2_1 = "CLAUDE2_1",
  CLAUDE_INSTANT = "CLAUDE_INSTANT",
  MISTRAL_7B = "MISTRAL_7B",
  MISTRAL_8X7B = "MISTRAL_8X7B",
  MISTRAL_LARGE = "MISTRAL_LARGE",
  MISTRAL_SMALL = "MISTRAL_SMALL",
  LLAMA3_8B = "LLAMA3_8B",
  LLAMA3_70B = "LLAMA3_70B",
  LLAMA2_13B = "LLAMA2_13B",
  LLAMA2_70B = "LLAMA2_70B",
  COMMAND_R_PLUS = "COMMAND_R_PLUS",
  COMMAND_R = "COMMAND_R",
  COMMAND = "COMMAND",
  COMMAND_LIGHT = "COMMAND_LIGHT",
  JURASSIC2_ULTRA = "JURASSIC2_ULTRA",
  JURASSIC2_MID = "JURASSIC2_MID",
  GEMINI_1_5_PRO = "GEMINI_1_5_PRO"
}


export enum EmbeddingModel {
  AZURE = "AZURE",
  ADA2 = "ADA2",
  OPEN_AI_SMALL = "OPEN_AI_SMALL",
  TITAN_1 = "TITAN_1",
  TITAN_2 = "TITAN_2",
  COHERE_MULTY = "COHERE_MULTY",
}
