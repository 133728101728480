import { Action, createReducer } from 'deox';
import { RootActions } from './actions';
import { RootState } from './state';

const initialState = new RootState();


const reducer = createReducer(initialState, (handleAction) => [
  handleAction(RootActions.toggleTheme, (state, { payload: { theme } }) => ({
    ...state,
    theme
  })),
  handleAction(RootActions.setLanguage, (state, { payload: { language } }) => ({
    ...state,
    language
  }))
]);


export function rootReducer(state: RootState | undefined, action: Action<any>): RootState {
  return reducer(state, action);
}
