import { Action, createReducer } from 'deox';
import { AuthActions } from './actions';
import { AuthState } from './state';
import produce from 'immer';
import { remove } from 'lodash';
import { Plan } from '../../enums/enums';
import { User } from '../../models/user';

const initialState = new AuthState();
const findByID = (id: number) => (item: { builder_id: number }) => item.builder_id === id

const reducer = createReducer(initialState, (handleAction) => [
  handleAction(AuthActions.setUser, (state, {payload :{ user }}) => ({
    ...state,
    user:user,
    isFreeBasic: [Plan.FREE, Plan.BASIC].includes(user.plan),
    accessTeam:  user?.plan === Plan.VIP || user?.early_access ? true : false
  })),
  handleAction(AuthActions.setOfficelyUser, (state, {payload: { officely_token }}) => ({
    ...state,
    officelyUser:officely_token
  })),
  handleAction(AuthActions.authorize, (state) => ({
    ...state,
    isAuthenticating: true
  })),
  handleAction(AuthActions.authorizeSuccess, (state) => ({
    ...state,
    isAuthenticating: false,
    isAuthenticated: true
  })),
  handleAction(AuthActions.authorizeFailure, (state) => ({
    ...state,
    isAuthenticating: false,
    isAuthenticated: false,
    officelyUser:false,
    user: new User('','','','')
  })),
  handleAction(AuthActions.unauthorize, (state) => ({
    ...state,
    isAuthenticated: false,
    officelyUser:false,
    user: new User('','','','')
  })), 
  handleAction(AuthActions.SetBuilders, (state, { payload: { builders } }) => produce(state, (draft) => {
    draft.user.chat_builders = builders
  })),
  handleAction(AuthActions.onChangePlan, (state, { payload: { plan } }) => produce(state, (draft) => {
    draft.user.plan = plan
    draft.isFreeBasic = [Plan.FREE, Plan.BASIC].includes(plan)
  })),
  handleAction(AuthActions.UpdateChatBuildersByID, (state, { payload: { chat_builder, id } }) => produce(state, (draft) => {
    draft.user.chat_builders!.filter(findByID(id)).forEach((builder) => {
      builder.chat_builder = chat_builder
    })
  })),
  handleAction(AuthActions.onChangeTitleBuilder, (state, { payload: { id, newTitle } }) => produce(state, (draft) => {
    draft.user.chat_builders!.filter(findByID(id)).forEach((builder) => {
      builder.builder_name = newTitle
    })
  })),
  handleAction(AuthActions.onAddBuilder, (state, { payload: { element } }) => produce(state, (draft) => {
    draft.user.chat_builders!.push(element)
  })),
  handleAction(AuthActions.onDeleteBuilder, (state, { payload: { id } }) => produce(state, (draft) => {
    remove(draft.user.chat_builders!, findByID(id));
  })),
  handleAction(AuthActions.setBuilderActive, (state, { payload: { id } }) => produce(state, (draft) => {
    draft.user.chat_builders!.forEach((builder) => {
      builder.status = builder.builder_id === id ? true : false
    })
  })),
  handleAction(AuthActions.setBuilderUnActive, (state, { payload: { id } }) => produce(state, (draft) => {
    draft.user.chat_builders!.filter(findByID(id)).forEach((builder) => {
      builder.status = false
    })
  })),
  handleAction(AuthActions.onChangeImage, (state, { payload: { image } }) => produce(state, (draft) => {
    draft.user.image = image
  })),
  handleAction(AuthActions.onUpdateDeatils, (state, { payload: { bs_name, email, partner, new_phone} }) => produce(state, (draft) => {
    draft.user.bs_name = bs_name;
    draft.user.partner = partner;
    if (email){
      draft.user.email = email
    }
    if(new_phone){
      draft.user.uphone_number = new_phone
    }
  })),
  handleAction(AuthActions.onUpdateChannels, (state, { payload: { channels } }) => produce(state, (draft) => {
    draft.user.channels = channels
  }))
]);

export function authReducer(state: AuthState | undefined, action: Action<any>): AuthState {
  return reducer(state, action);
}
