import { Method } from "src/enums/enums"
import { useFetchOfficely, OfficelyRoutes, ResponseOfficely } from "src/hooks/use-fetch-officely"






export class AgentServices {
    public token: string 

    constructor(token: string) {
        this.token = token
    }

    // async  RunAgent(body: any): Promise<Array<Chat> | boolean> {
    //     const res = await useFetchOfficely(OfficelyRoutes.RUN_AGENT, Method.POST, body)
    //     if (res.success && res.data) {
    //         return res.data as Array<Chat>
    //     }
    //     return false
    // }
    
    // async  DeleteAgent(agent_id: string, token: string): Promise<Array<Chat> | boolean> {
    //     const res = await useFetchOfficely(`${OfficelyRoutes.DELETE_AGENT}/${agent_id}`, Method.POST, null, token)
    //     return res.success
    // }
    
    // async  IngestAgent(agent: Agent, token: string): Promise<ResponseOfficely> {
    //     return await useFetchOfficely(OfficelyRoutes.INGEST_AGENT, Method.POST, agent, token)
    // }
    
    // async  InsertTeamBuilder(agent_id: string, name:string, teamObject: TeamObject, token: string): Promise<ResponseOfficely> {
    //     const body = { agent_id, teamObject, name }
    //     return await useFetchOfficely(`${OfficelyRoutes.INSERT_TEAM_BUILDER}`, Method.POST, body, token)
    // }
    
    // async  runOnceTeamBuilder(agent_id:string, nodeID:string, token: string): Promise<ResponseOfficely> {
    //     const body = { agent_id, nodeID }
    //     return await useFetchOfficely(`${OfficelyRoutes.RUN_ONCE_TEAM_BUILDER}`, Method.POST, body, token)
    // }
    
    // async  GetAgentDeatilsByID(id: string): Promise<ResponseOfficely> {
    //     return await useFetchOfficely(`${OfficelyRoutes.GET_AGENT_DEATILS}/${id}`, Method.GET, null)
    // }
    
    // async  RunAssistantAgent(body: any): Promise<Array<Chat> | boolean> {
    //     const res = await useFetchOfficely(OfficelyRoutes.AI_CHAT, Method.POST, body)
    //     if (res.success && res.data) {
    //         return res.data as Array<Chat>
    //     }
    //     return false
    // }

    async runOnceTeam(agentID:string, nodeID:string, inputs:string): Promise<ResponseOfficely> {
        const body = { agentID, nodeID, inputs:inputs }
        return await useFetchOfficely(`${OfficelyRoutes.RUN_ONCE_TEAM_BUILDER}`, Method.POST, body, this.token)
    }

    async getInputs(agentID:string): Promise<string> {
        const res =  await useFetchOfficely(`${OfficelyRoutes.GET_TEAM_INPUTS}/${agentID}`, Method.GET, null, this.token)
        if (res.success && res.data) {
            return res.data as string
        }
        return ""
    }
    
    
}