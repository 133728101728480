import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { useRTL } from '../../hooks/use-rtl';
import { useTranslation } from '../../hooks/use-translate';
import Icon, { IconTheme, IconVariant } from '../icon/icon';
import MayTapi from '../may-tapi/may-tapi';
import Meta from '../meta/meta';
import './sidebar.scss';
import Green_API from '../green_api/green_api';
import Sunshine from '../sunshine/sunshine';


export enum SideBarView {
  MAYTAPI = 'MAYTAPI',
  GREEN_API = 'GREEN_API',
  MARKET = "MARKET",
  META = "META",
  SUNSHINE = "SUNSHINE",
}



function SideBar({ isOpen, toggleMenu, itemID, itemName, link, overflowHidden }: {
  isOpen: boolean,
  toggleMenu: () => void,
  itemID?: SideBarView | null,
  itemName: string | null,
  link?: ReactElement | null
  //items?: Array<{ node: ReactNode; key: string | number; action?: () => void; }>;
  overflowHidden?: boolean;
}): ReactElement {

  const isRTL = useRTL();
  const t = useTranslation('SIDE_BAR');

  const getCurrentView = (item: SideBarView | null) => {
    switch (item) {
      case SideBarView.MAYTAPI:
        return <MayTapi />
      case SideBarView.META:
        return <Meta />
      case SideBarView.GREEN_API:
        return <Green_API />
      case SideBarView.SUNSHINE:
        return <Sunshine/>
    }
  };

  const closeMenu = (): void => {
    toggleMenu();
  };



  return (
    <div className="sidebar">

      <aside className={classNames(['sidebar-content', { overflowHidden, 'open': isOpen, 'sidebar-content-main': !itemID }])}>
      <a onClick={closeMenu} className="close-button">
          <Icon variant={IconVariant.REMOVE_BIG} theme={IconTheme.DEFAULT} />
        </a>
        <header className="sidebar-header" >

          <h2 className={classNames(['sidebar-title', { 'rtl': isRTL }])}>
            {itemName}
          </h2>


        </header>
        <div className={classNames(['sidebar-description', { 'rtl': isRTL }])}> {t(`${itemID}_DESCRIPTION`)} {link}</div>
        <div className="sidebar-component">
          {getCurrentView(itemID!)}
        </div>

      </aside>
    </div>
  );
}



export default SideBar


