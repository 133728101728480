import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import ReactMde, { getDefaultCommandMap, ExecuteOptions, Suggestion } from 'react-mde';
import Icon, { IconVariant, IconTheme } from '../icon/icon';
import { useRTLD } from '../../hooks/use-rtl';
import classNames from 'classnames';
import { format } from '@flasd/whatsapp-formatting';
import { useSelector } from 'react-redux';
import { ChatBuilderSelectors } from '../../redux/chat-builder';
import { GetHighlightMdeText } from '../../helpers/wrokflow-builder/highlight-search-text';
import { ChatbotActionsSelectors } from '../../redux/chatbot-actions/selectors';
import 'react-mde/lib/styles/css/react-mde-all.css';
import './node.scss';






function NodeInput({ value, isSelected, onChange, maxLength }: {
  value: string;
  isSelected: boolean;
  onChange: (value: string) => void;
  maxLength?: number | undefined;
}): ReactElement {
  const [text, setText] = useState(value); // TODO: we have to duplicate state for mde to work
  const commands = getDefaultCommandMap();
  const isRTLD = useRTLD();
  const allVariables = useSelector(ChatBuilderSelectors.allVariables);
  const editorRef = useRef<ReactMde>(null);

  const touchToFocus = (event: TouchEvent): void => {
    (event.target as HTMLTextAreaElement).focus();
  };

  useEffect(() => {
    const textarea = editorRef.current?.finalRefs.textarea?.current;
    textarea?.addEventListener('touchstart', touchToFocus, { passive: true });
    return function (): void {
      textarea?.removeEventListener('touchstart', touchToFocus);
    };
  }, []);



  const commandsWithIcons = {
    bold: {
      ...commands.bold,
      icon: () => <Icon variant={IconVariant.BOLD} theme={IconTheme.DEFAULT} />,
      execute: (opt: ExecuteOptions): void | Promise<void> => {
        const state = opt.initialState.selectedText
        opt.textApi.replaceSelection('*' + state + '*')
      }
    },
    strikethrough: {
      ...commands.strikethrough,
      icon: () => <Icon variant={IconVariant.STRIKE} theme={IconTheme.DEFAULT} />,
      execute: (opt: ExecuteOptions): void | Promise<void> => {
        const state = opt.initialState.selectedText
        opt.textApi.replaceSelection('~' + state + '~')
      }
    },
    italic: {
      ...commands.italic,
      icon: () => <Icon variant={IconVariant.ITALIC} theme={IconTheme.DEFAULT} />,
      execute: (opt: ExecuteOptions): void | Promise<void> => {
        const state = opt.initialState.selectedText
        opt.textApi.replaceSelection('_' + state + '_')
      }
    }

  };

  const handleChange = (value: string): void => {
    setText(value);
    onChange(value);
  };


  

  const loadSuggestions = async (text: string) =>
    new Promise<Suggestion[]>((accept) => {
      setTimeout(() => {
        const suggestions: Suggestion[] = allVariables
          .filter((i) =>
            i.value.includes(text.toLowerCase()))
          .sort((a, b) => a.value < b.value ? -1 : 1)
        accept(suggestions);
      }, 250);


    });


  



  const searchValue = useSelector(ChatbotActionsSelectors.inputSearchValue)

  const highLightCallBack = useCallback(() => GetHighlightMdeText(searchValue, text, isSelected), [searchValue, text, isSelected])

  return (
    <div className={classNames(['node-text-input nopan', { rtl: isRTLD(value) }])}>
      <ReactMde
        ref={editorRef}
        value={highLightCallBack()}
        onChange={handleChange}
        selectedTab={(isSelected) ? 'write' : 'preview'}
        generateMarkdownPreview={(markdown) => {
          const regex = /\{\{(@|#)([^{}]+)\}\}/g;
          const highlightedMarkdown = markdown.replace(regex, (_, type, name) => {
            const className = type === '@' ? 'variable local' : 'variable global';
            return `<span class='${className}'>${name}</span>`;
          });
          const formattedPreview = format(highlightedMarkdown);
          return Promise.resolve(formattedPreview);
        }
          //Promise.resolve(format(markdown))

        }
        commands={commandsWithIcons}
        toolbarCommands={[['bold', 'strikethrough', 'italic']]}
        childProps={{
          writeButton: {
            hidden: true
          },
          previewButton: {
            hidden: true
          },
          textArea: {
            maxLength: maxLength
          }
        }}
        loadSuggestions={loadSuggestions}
        classes={{
          suggestionsDropdown: 'suggestions',
          textArea: classNames({ rtl: isRTLD(value) })
        }}
      suggestionsAutoplace={false}
      suggestionTriggerCharacters={["@", "#", '{','}']}


      />
      {maxLength && isSelected && <span className="node-text-maxLength">{`${value?.toString().length}/${maxLength}`}</span>}
    </div>
  );
}



export default NodeInput;
