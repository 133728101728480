import { Edge, isEdge, isNode, Node } from 'reactflow';
import { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import { NodeType } from '../../enums/enums';
import { getSourceHandleIDS, getTargetHandleID } from '../../helpers/wrokflow-builder/get-handle-ids';
import { ChatbotNodeData } from '../../models';
import { AppState } from '../ConfigureStore';
import { ChatBuilderState } from './state';

const selectFeature = (state: AppState): ChatBuilderState => state.chatBuilder;


export class ChatBuilderSelectors {

  public static startScheme = createSelector(
    selectFeature,
    (state: ChatBuilderState) => state.startScheme
  );

  public static chatScheme = createSelector(
    selectFeature,
    (state: ChatBuilderState) => state.chatScheme
  );

  public static removedArr = createSelector(
    selectFeature,
    (state: ChatBuilderState) => state.removedArr
  );

  public static currentBuilder = createSelector(
    selectFeature,
    (state: ChatBuilderState) => state.currentBuilder
  );

  public static activeSourceID = createSelector(
    selectFeature,
    (state: ChatBuilderState) => state.activeSourceID
  );

  public static nodes = createSelector(
    ChatBuilderSelectors.chatScheme,
    (elements: Array<Node<ChatbotNodeData> | Edge>) => elements.filter(isNode) ?? []
  );

  // public static variables = createSelector(
  //   selectFeature,
  //   (state: ChatBuilderState) => state.currentBuilder?.settings?.variables ?? []
  // );


  // public static suggestion = createSelector(
  //   ChatBuilderSelectors.nodes,
  //   ChatBuilderSelectors.Variabels,
  //   (nodes: Array<Node<ChatbotNodeData>>) => nodes.filter((node) => 
  //   !node.data?.isSelected && node.data!.name && node.type !== NodeType.TASK && node.type !== NodeType.API).map((node) => (
  //     {
  //       preview: `@${node.data!.name}` ?? '',
  //       value: `{{@${node.data!.name}}}`
  //     }
  //   ))
  // );

  public static nodeSuggestions = createSelector(
    ChatBuilderSelectors.nodes,
    (nodes: Array<Node<ChatbotNodeData>>) => nodes.filter((node) =>
      !node.data?.isSelected && node.data!.name && ![NodeType.TASK, NodeType.API, NodeType.WEBHOOK, 
        NodeType.END, NodeType.FILTER].includes(node.type as NodeType)).map((node) => (
        {
          preview: node.data?.name ?? '',
          value: `@${node.data!.name}`
        }
      ))
  );

  public static nodesJump = createSelector(
    ChatBuilderSelectors.nodes,
    (nodes: Array<Node<ChatbotNodeData>>) => nodes.filter((node) =>
      !node.data?.isSelected && node.data!.name).map((node) => (
        {
          preview: node.data?.name ?? '',
          value: `@${node.data!.name}`
        }
      ))
  );

  public static systemVariables = createSelector(
    selectFeature,
    () => [
      {
        key: 'channel',
        value: 'Web | Meta | mayTapi',
        isSystem: true
      },
      {
        key: 'cphone',
        value: '',
        isSystem: true
      }
    ]
  );

  public static allVariables = createSelector(
    ChatBuilderSelectors.nodeSuggestions,
    ChatBuilderSelectors.systemVariables,
    (
      suggestion: Array<{ preview: string, value: string }>,
      systemVariables: Array<{ key: string; value: string }>
    ) => {
      const suggestions = suggestion
        .map((s) => ({
          preview: `@${s.preview}`,
          value: `{{${s.value}}}`
        }));

      // const variableSuggestions = variables.filter((v) => v.key.trim() !== '').map((variable) => ({
      //   preview: `#${variable.key}`,
      //   value: `{{#${variable.key}}}`
      // }));

      const systemVariableSuggestions = systemVariables.map((variable) => ({
        preview: `#${variable.key}`,
        value: `{{#${variable.key}}}`
      }));
      return [...systemVariableSuggestions, ...suggestions];
    }
  );


  public static selectedNodes = createSelector(
    ChatBuilderSelectors.nodes,
    (nodes: Array<Node<ChatbotNodeData>>) => nodes.filter((node) => node.data?.isSelected && node.type !== NodeType.START) ?? []
  );

  public static edges = createSelector(
    ChatBuilderSelectors.chatScheme,
    (elements: Array<Node<ChatbotNodeData> | Edge>) => elements.filter(isEdge) ?? []
  );

  public static edgesRelatedWithSelectedNodes = createSelector(
    ChatBuilderSelectors.edges,
    ChatBuilderSelectors.selectedNodes,
    (edges: Array<Edge>, nodes: Array<Node<ChatbotNodeData>>) => {
      const sources = nodes.reduce((acc: Array<string>, node) => acc.concat(getSourceHandleIDS(node)), []);
      const target = nodes.map(getTargetHandleID);

      return edges.filter((edge) => sources.includes(edge?.sourceHandle as string) && target.includes(edge.target)) ?? [];
    }
  );

  public static elementByID = (id: string): Selector<AppState, Node<ChatbotNodeData> | Edge | undefined> => createSelector(
    ChatBuilderSelectors.chatScheme,
    (elements: Array<Node<ChatbotNodeData> | Edge>) => elements.find((element) => element.id === id)
  );

  public static edgeBySourceID = (id: string): Selector<AppState, Edge | undefined> => createSelector(
    ChatBuilderSelectors.edges,
    (edges: Array<Edge>) => edges.find((edge) => edge.sourceHandle === id)
  );

  public static configuration = createSelector(
    ChatBuilderSelectors.currentBuilder,
    (currentBuilder) => currentBuilder?.configuration ?? {}
  );

  public static isSaved = createSelector(
    selectFeature,
    (state: ChatBuilderState) => state.isSaved
  );



}
