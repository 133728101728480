import classNames from 'classnames';
import React, { ReactElement, ReactNode, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Icon, { IconVariant, IconTheme } from '../../components/icon/icon';
import Logo from '../../components/logo/logo';
import { PathName } from '../../enums/enums';
import './account.scss';
import AccountMenu from './menu';

export default function AccountLayout({ children }: { children: ReactNode }): ReactElement {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const location = useLocation();
  const toggleMenu = (): void => setIsOpenMenu((isOpen) => !isOpen);
  const isChatBotPage = location.pathname.endsWith(PathName.CHATBOT.slice(1))
  const isIngestAgentPage = location.pathname.endsWith(PathName.INGEST_AGENT.slice(1))






  return (
    <>
      <div className={classNames(['account',{"ingestAgent":isIngestAgentPage}])}>
        {!isChatBotPage && <AccountMenu isOpen={isOpenMenu} toggleMenu={toggleMenu} />}
        <div className="account-content ">
         {!isChatBotPage && <>
            <Logo className="only-mobile account-logo" />
            <a onClick={toggleMenu} className="only-mobile account-menu-trigger">
              <Icon variant={IconVariant.MENU} theme={IconTheme.DEFAULT} />
            </a>
          </>}
          {children}
        </div>
      </div>
    </>
  );
}
