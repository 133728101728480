import React from 'react';

import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import { he } from 'date-fns/locale';
import 'react-day-picker/dist/style.css';



export default function DateWeb({ handleChange }: { handleChange: (value: string) => void }) {


    const onChange = (date: Date) => {
        handleChange(format(date, 'dd/MM/yyyy', { locale: he }))
    }

    return (
        <>
            {/* <button disabled={disable} onClick={handleClick}>Select</button> */}
            <DayPicker
                captionLayout="dropdown"
                fromYear={1900}
                toYear={2050}
                className="DayPicker extra-element"
                mode="single"
                onSelect={(date:any) => onChange(date!)}
            />
        </>
    );
}