import { Tag } from '@chakra-ui/react'
import React from 'react'

export default function BetaTag({ text, colorScheme, left }: { text?:string, colorScheme?:string, left?: number }) {
    return (
        <Tag
            variant="solid"
            colorScheme= {colorScheme ? colorScheme : "yellow"}
            position="absolute"
            top="-10px"
            left={left ? `${left}px` : "120px"}
            boxShadow="0px 4px 4px rgba(255, 200, 101, 0.19)"
            fontFamily="var(--font-family-ltr)"
        >
            {text ? text : "Beta"}
        </Tag>
    )
}
