import Editor from '@monaco-editor/react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AgentActions, AgentSelectors } from 'src/redux/agents'
import Button, { ButtonVariant } from '../button/button';

export default function AgentStyle({ onContinue }: {
    onContinue: () => void;
}) {
    const agent = useSelector(AgentSelectors.currentAgent)
    const dispatch = useDispatch()

    const onChange = (value?: string) => {
        value && dispatch(AgentActions.onChangeStyle({ value }))
    }




    return (
        <div className="agent">

            <Editor
                width={"90%"}
                height={"50vh"}
                defaultLanguage="css"
                value={agent?.style ?? ''}
                theme="vs-light"
                onChange={onChange}
                className="css-editor"
                options={{
                    minimap: { enabled: false },
                    scrollbar: {
                        vertical: "hidden",
                        horizontal: "hidden"
                    },
                    padding: {
                        top: 10,
                        bottom: 10
                    }
                }} />

            <Button
                variant={ButtonVariant.PRIMARY}
                isBlock
                onClick={onContinue}
                className="continue-button"
            >

                Continue
            </Button>
        </div>
    )
}
