import { Avatar, Heading} from '@chakra-ui/react'
import classNames from 'classnames'
import React, { useState, useEffect, useRef, ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button, { ButtonVariant } from '../../components/button/button'
import Icon, { IconTheme, IconVariant } from '../../components/icon/icon'
import { Alert, AlertActions, ModalPopup } from '../../helpers/dialog'
import { useRTL } from '../../hooks/use-rtl'
import { useTranslation } from '../../hooks/use-translate'
import { Agent, AgentActions, AgentSelectors } from '../../redux/agents'
import { AuthSelectors } from '../../redux/auth'
import { useNavigate } from 'react-router-dom'
import { useFetchOfficely } from '../../hooks/use-fetch-officely'
import { Method, PathName, Plan } from '../../enums/enums'
import PreviewAgent from './preview-agent'
import './agents-page.scss'
import AgentTempalte from 'src/components/agent-componnets/agent-tempaltes'
import { v4 } from 'uuid'
import { AgentService } from 'src/helpers/fetch/fetch-agent'



interface IAgentAction {
    id: string
    icon?: IconVariant
    onClick: (e: React.MouseEvent, item: Agent) => void
    text?: string
}




export const GenerateLink = (id: string) => `${window.location.origin}/#/ai-chat/${id}`

export const GenerateEmbed = (id: string) => `<script src="https://app.officely.ai/chat-widget.js"></script>
<script>
window.addAIWidget({
   id:'${id}',
   position: "center", //can be side or center
   buttonColor:"#5b61eb" 
   //iconLink:"your icon link (optional)",
   //autoOpen: true //defualt is false
})
</script>
`





function AgentsPage() {
    const agentService = new AgentService()
    const t = useTranslation("PAGES.AGENTS");
    const tSwal = useTranslation("COMPONENTS.SWAL")
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector(AuthSelectors.user)
    const agents = useSelector(AgentSelectors.agents)
    const currentAgent = useSelector(AgentSelectors.currentAgent)
    const isRTL = useRTL()
    const [isCopied, setIsCopied] = useState<{ itemID: string, actionID: string } | null>(null)
    const [isPreview, setPreviewOpen] = useState<{ open: boolean, src?: string, title?: string }>({ open: false })
    const [isOpenDialog, setIsOpenDialog] = useState(false)
    const [dialogActions, setDialogActions] = useState<AlertActions | null>(null)
    const [isModalOpen, setIsModalOpen] = useState<{ open: boolean, content: ReactNode }>({ open: false, content: null })
    const isMountedRef = useRef(true);


    const fetchAgents = async (): Promise<void> => {
        const res = await useFetchOfficely('GetAgents', Method.GET, null, user?.token)
        if (res.success && res.data) {
            dispatch(AgentActions.SetAgents({ agents: res.data }))
        }
    }



    useEffect(() => {
        if (isMountedRef.current) {
            document.title = t("TITLE")
            dispatch(AgentActions.SetCurrentAgentNull({}))
            fetchAgents()
        }

        return () => {
            isMountedRef.current = false
        }
    }, [])

    const itemActions: IAgentAction[] = [
        {
            id:"duplicate",
            onClick: (e: React.MouseEvent, item: Agent) => {
                e.stopPropagation();
                onDuplicateAgent(item)
            },
            text: "Duplicate"
        },
        {
            id: 'preview',
            onClick: (e: React.MouseEvent, item: Agent) => {
                e.stopPropagation();
                setPreviewOpen({ open: true, src: `${GenerateLink(item.agent_id)}/preview`, title: item.name })
            },
            text: "Preview"
        },
        {
            id: 'copy-link',
            icon: IconVariant.COPY,
            onClick: (e: React.MouseEvent, item: Agent) => { e.stopPropagation(); handleCopy(item, 'copy-link') },
            text: "Copy Link"
        },
        {
            id: 'copy-embed',
            icon: IconVariant.COPY,
            onClick: (e: React.MouseEvent, item: Agent) => { e.stopPropagation(); handleCopy(item, 'copy-embed') },
            text: "Copy Embed"
        },
        {
            id: 'delete',
            icon: IconVariant.TRASH,
            onClick: (e: React.MouseEvent, item: Agent) => { e.stopPropagation(); OpenDeleteDialog(item.agent_id) }
        }
    ]




    const OpenDeleteDialog = (id: string) => {
        setIsOpenDialog(true)
        setDialogActions({
            title: tSwal("PAY_ATTENTION"),
            content: tSwal("VALID_DELETE_AI_CHAT"),
            confirm: tSwal("DELETE"),
            cancel: tSwal("CANCEL"),
            colorButton: "red",
            onConfirm: () => onDeleteAgent(id)
        })
    }

    const onDeleteAgent = async (id: string) => {
        if (await agentService.DeleteAgent(id, user.token!)) {
            dispatch(AgentActions.DeleteAgent({ id }))
            dispatch(AgentActions.SetCurrentAgentNull({}))
        }

    }

    const handleCopy = (agent: Agent, actionID: string) => {
        if (user.plan === Plan.FREE) {
            setIsOpenDialog(true)
            setDialogActions({
                title: tSwal("ERROR_UPGRADE"),
                content: tSwal("ERR_AGENTS_UPGRADE_LINK"),
                confirm: tSwal("TO_UPGRADE"),
                onConfirm: () => navigate("/subscriptions"),
                cancel: tSwal("CANCEL"),
                colorButton: "primary"
            })
            return
        }
        setIsCopied({ itemID: agent.agent_id, actionID })
        setTimeout(() => {
            setIsCopied(null)
        }, 1000)
        const copyText = actionID === 'copy-link' ? GenerateLink(agent.agent_id) : GenerateEmbed(agent.agent_id)
        navigator.clipboard.writeText(copyText)
    }


    const onSelectAgent = (agent: Agent) => {
        dispatch(AgentActions.SetCurrentAgent({ currentAgent: agent }))
        const isCustomAgent = Boolean(!agent.assistant_id)
        navigate(PathName.INGEST_AGENT, { state: { isCustomAgent } })

    }

    const allowedCreateAgent = () => {
        if ((user.plan === Plan.FREE || user.plan === Plan.BASIC) && agents.length) {
            setIsOpenDialog(true)
            setDialogActions({
                title: tSwal("ERROR_UPGRADE"),
                content: tSwal("ERR_AGENTS_UPGRADE"),
                confirm: tSwal("TO_UPGRADE"),
                onConfirm: () => navigate("/subscriptions"),
                cancel: tSwal("CANCEL"),
                colorButton: "primary"
            })
            return false
        } else if ((user.plan === Plan.PARTNER || user.plan === Plan.PREMIUM) && agents.length >= 5) {
            setIsOpenDialog(true)
            setDialogActions({
                title: tSwal("ERROR_UPGRADE"),
                content: tSwal("ERR_AGENTS_UPGRADE_PRO"),
                confirm: tSwal("TO_UPGRADE"),
                onConfirm: () => navigate("/subscriptions"),
                cancel: tSwal("CANCEL"),
                colorButton: "primary"
            })
            return false
        }
        return true
    }

    const onDuplicateAgent = async (agent: Agent) => {
        if (!allowedCreateAgent()) return
        const copiedAgent = { ...agent, agent_id: v4(), name: `${agent.name} - Copy` }
        const res = await agentService.DuplicateAgent(agent.agent_id, copiedAgent, user.plan, user.token!)
        if(!res.success){
            setIsOpenDialog(true)
            setDialogActions({
                title: tSwal("ERROR"),
                content:  tSwal("ERROR_FETCH"),
                confirm: tSwal("OK"),
                colorButton: "primary",
                isMessage: true
            })
            return
        }
        onSelectAgent(copiedAgent)
    }


    const onCreateAgent = () => {
        if (!allowedCreateAgent()) return
        setIsModalOpen({
            open: true,
            content: <AgentTempalte />
        })
    }




    const onEditAgent = (agent: Agent) => {
        onSelectAgent(agent)
    }



    return (
        <div className="page teamAI">
            <header className="page-header">
                <div className="page-header-left">
                </div>
                <div className="page-header-right">
                    <Button variant={ButtonVariant.OUTLINE}
                        className={classNames(["page-action", { rtl: isRTL }])}
                        onClick={onCreateAgent}
                        hidden={!agents.length}
                    >
                        {t("BUTTON_CREATE_AGENT")}
                    </Button>
                </div>
            </header>
            <div className="page-content">

                <div className="agents">
                    {!agents.length ? <AgentTempalte /> :
                        <>
                            {agents.map((item) => <div
                                className={classNames(["item", { active: item.agent_id === currentAgent?.agent_id }])}
                                key={item.agent_id}
                                onClick={() => onEditAgent(item)}
                            >
                                <div className="item-icons">
                                    <div className="agents-item-icon">
                                        <Avatar
                                            cursor="pointer"
                                            size="md"
                                            src={item.img} />
                                    </div>
                                </div>
                                <div className="item-title">
                                    <Heading size="s" className="item-title-heading" >
                                        {item.name}
                                    </Heading>
                                </div>
                                <div className="item-actions">
                                    {itemActions.map((action, ai) => <React.Fragment key={ai}>
                                        <Button className={`action ${action.id}`} isIcon onClick={(e) => action.onClick(e, item)}>
                                            {action.icon && <Icon variant={action.icon} theme={IconTheme.GRAY} />}
                                            {action.text && <span> {
                                                isCopied?.actionID === action.id && isCopied.itemID === item.agent_id ?
                                                    'Copied' :
                                                    action.text
                                            } </span>}

                                        </Button>
                                    </React.Fragment>

                                    )}
                                </div>



                            </div>)}
                        </>

                    }
                </div>

            </div>
            <PreviewAgent
                isOpen={isPreview.open}
                onClose={() => setPreviewOpen({ open: false })}
                title={isPreview?.title ?? 'Preview Agent'}
                src={isPreview.src}
            />
            <Alert
                isOpen={isOpenDialog}
                onClose={() => setIsOpenDialog(false)}
                actions={dialogActions!}
            />


            <ModalPopup
                isOpen={isModalOpen.open}
                onClose={() => setIsModalOpen({ open: false, content: null })}
                dir="ltr"
            >
                {isModalOpen.content}
            </ModalPopup>




        </div >
    );
}


export default React.memo(AgentsPage);
