// import { useCallback } from 'react';
// import { NodeProps, useReactFlow, getOutgoers, getIncomers, getConnectedEdges, Node } from 'reactflow';
// import { TeamFlowNodeType, TeamNodeType } from 'src/components/team-builder/interfaces';



// this hook implements the logic for clicking a placeholder node
// on placeholder node click: turn the placeholder and connecting edge into a workflow node
// export function useRemoveClick(id: NodeProps['id']) {
//     const { getNode, getNodes, getEdges, setNodes, setEdges } = useReactFlow();






//     const onClick = useCallback(() => {
//         // we need the parent node object for getting its position
//         const node = getNode(id);
//         const nodes = getNodes();
//         const edges = getEdges();

//         if (!node) {
//             return;
//         }
//         const incomers = getIncomers(node, nodes, edges).map((node) => node.id);
//         const outgoers = getOutgoers(node, nodes, edges).map((node) => node.id);

//         const parentNode = getNode(incomers[0])
//         const siblings = getOutgoers(parentNode!, nodes, edges).map((node) => node.id);




//         // if (parentNode?.data.type === TeamFlowNodeType.FILTER && siblings.length > 1) {
//         //     setNodes((prevNodes) => prevNodes.filter((node) => node.id !== id && !outgoers.includes(node.id)));
//         //     // setEdges((prevEdges) => prevEdges.filter(
//         //     //         (edge) => !(
//         //     //             (incomers.includes(edge.source) && edge.target === id) ||
//         //     //             (outgoers.includes(edge.target) && edge.source === id)
//         //     //         ))
//         //     //     );

//         // } else {
//             setNodes((nodes) =>
//                 nodes.map((node) => {
//                     if (node.id === id) {
//                         return {
//                             ...node,
//                             type: TeamNodeType.PLACEHOLDER,
//                             data: { label: '+' }
//                         };
//                     }
//                     return node;
//                 })
//                     .filter((node) => !outgoers.includes(node.id))
//             )
//             // setEdges((edges) =>
//             //     edges
//             //         .map((edge) => {
//             //             // here we are changing the type of the connecting edge from placeholder to workflow
//             //             if (edge.target === id) {
//             //                 return {
//             //                     ...edge,
//             //                     type: TeamNodeType.PLACEHOLDER
//             //                 };
//             //             }
//             //             return edge;
//             //         }).filter((edge) => (edge.source !== id))


//             // );
//         //}




//     }, [getEdges, getNode, getNodes, id, setEdges, setNodes])

//     return onClick;
// }

// export default useRemoveClick;

import { useCallback } from 'react';
import { NodeProps, useReactFlow, getOutgoers, getIncomers, getConnectedEdges, EdgeProps } from 'reactflow';
import { TeamFlowNodeType, TeamNodeType } from 'src/components/team-builder/interfaces';

export function useRemoveClick(id: NodeProps['id']) {
    const { getNode, getNodes, getEdges, setNodes, setEdges } = useReactFlow();

    const onClick = useCallback(() => {
        const nodes = getNodes();
        const edges = getEdges();
        const node = getNode(id);

        if (!node) return;

        const incomers = getIncomers(node, nodes, edges)
        const outgoers = getOutgoers(node, nodes, edges)
        const connectedEdges = getConnectedEdges([node], edges)

        const parentNode = incomers.length && getNode(incomers[0].id)
        const siblings = parentNode ? getOutgoers(parentNode!, nodes, edges).map((node) => node.id) : []


        if (parentNode && parentNode?.data.type === TeamFlowNodeType.FILTER && siblings.length > 1 && outgoers[0].type === TeamNodeType.PLACEHOLDER) {
            setNodes((prevNodes) => prevNodes.filter(
                (node) => node.id !== id && !(
                    outgoers.map((edge) => edge.id).includes(node.id) && node.type === TeamNodeType.PLACEHOLDER
                )
            ));
            setEdges((prevEdges) => prevEdges.filter(
                (edge) => !(
                    (incomers.map((edge) => edge.id).includes(edge.source) && edge.target === id) ||
                    (outgoers.map((edge) => edge.id).includes(edge.target) && edge.source === id)
                ))
            );


        } else {
            setNodes((prevNodes) => prevNodes.filter((node) => node.id !== id));
            setEdges((prevEdges) => {
                const remainingEdges = prevEdges.filter((edge) => !connectedEdges.map((edge) => edge.id).includes(edge.id));
    
                const type = outgoers[0].type
                const createdEdges = incomers.flatMap(({ id: source }) =>
                    outgoers.map(({ id: target }) => ({ id: `${source}->${target}`, source, target, type }))
                );
    
                return [...remainingEdges, ...createdEdges];
            });
        }


      
    }, [getNode, getNodes, getEdges, setNodes, setEdges, id]);

    return onClick;
}

export function useRemoveNode(): (id: NodeProps['id']) => void {
    const { setNodes } = useReactFlow();
    return (id: NodeProps['id']) => {
        setNodes((prevNodes) => prevNodes.filter((node) => node.id !== id));
      };
}

export function useRemoveEdge(): (id: EdgeProps['id']) => void {
    const { setEdges } = useReactFlow();
    return (id: EdgeProps['id']) => {
        setEdges((prevEdges) => prevEdges.filter((edge) => edge.id !== id));
    };
}


export default useRemoveClick;

