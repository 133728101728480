import React, { useEffect, useMemo, useState } from 'react'
import { CalculateShowInput, ClickBtn, GetNewQuestionOfficelyChannel, WidgetHeader } from '../../../components/officely-web-componnents'
import WidgetContent from '../../../components/officely-web-componnents/widget-content'
import { ExtraElementChat, selfData, WidgetBody, WidgetButtons } from '../interfaces'
import WidgetTemp, { WidgetMode } from '../widget-temp'
import { useTranslation } from '../../../hooks/use-translate'
import { useLocation, useParams } from 'react-router-dom'



export default function WidgetPublishMode() {
  const { codeUserParam, chat } = useParams<{ codeUserParam: string, chat: string }>();
  const location = useLocation();
  const queryParams = Object.fromEntries(new URLSearchParams(location.search) || false)
  const t = useTranslation('PAGES.WIDGET');
  const [answer, setAnswer] = useState<string | null>()
  const [loading, setLoading] = useState<boolean>()
  const [selfData, setSelfData] = useState<selfData>()

  const isChat = chat !== 'form'

  let isMounted = true;




  useEffect(() => {
    const fetchApi = async (): Promise<void> => {
      const body: WidgetBody = { codeUser: codeUserParam, isPreview: false, queryParams }
      // const resIP = await (await fetch('https://geolocation-db.com/json/')).json();
      // body.IP = resIP.IPv4
      const resIP = await (await fetch('https://api.ipify.org?format=json')).json();
      body.IP = resIP.ip

      await GetQuestion(body);
      document.title = t("TITLE")
    };

    if (isMounted) {
      setSelfData(undefined)
      fetchApi();
    }
    return () => { isMounted = false; };

  }, []);


  const GetQuestion = async (body: any) => {
    setAnswer(null);
    setLoading(true)


    const resData: selfData | boolean = await GetNewQuestionOfficelyChannel(body, isChat, selfData)
    if (!resData) return
    setTimeout(() => {
      setSelfData(resData as selfData)
      setLoading(false)
    }, 500);
  }


  const handleClickBtn = (payload: WidgetButtons) => {
    if (loading) return;
    ClickBtn(payload, selfData?.chat ?? [])
    handleSubmit({ payload })
  }

  const handleSubmit = ({ payload, value, extraElement, skip }: {
    payload?: WidgetButtons,
    value?: string,
    extraElement?: ExtraElementChat,
    skip?: boolean
  }) => {
    if (loading) return;
    if ((!answer?.trim() && !payload && !value && !extraElement)) return;

    const ans = payload ? payload.title : value ? value : answer?.trim();

    if (!skip) {
      selfData!.chat = [...selfData!.chat!, {
        type: "inbound",
        text: ans ?? '',
        extra_elements: extraElement
      }];
    }

    selfData!.payload = payload;

    const tempSelfData = isChat ? selfData : { ...selfData, chat: [] }

    const body = { answer: ans, selfData: tempSelfData, isPreview: false };

    GetQuestion(body);


  }




  const showInput = useMemo(() => CalculateShowInput(isChat, selfData), [isChat, selfData]);
  //const showPowerBy = [Plan.FREE, Plan.BASIC].includes(selfData?.user?.plan || Plan.FREE) || NodeType.NOT_ACTIVE === selfData?.ques_data?.ques.type




  //const style: React.CSSProperties = isChat && !isKape ? { background: settingsWebBuilder?.backgroundColor } : {};



  if (selfData) {
    return (
      <WidgetTemp className={["publish", { form: !isChat }]} >
         <style type="text/css">
          {selfData.with_branding ? selfData?.configuration?.style : ''}
        </style>
        <WidgetHeader
          isChat={isChat}
          mode={WidgetMode.PUBLISH}
          logo={selfData.with_branding ? selfData?.configuration?.logo : ''}

        />
        <WidgetContent
          isChat={isChat}
          chatHistory={selfData?.chat ?? []}
          showInput={showInput}
          showPowerBy={!selfData.with_branding}
          answerState={{ answer, setAnswer }}
          loadingState={{ loading, setLoading }}
          handleClickBtn={handleClickBtn}
          handleSubmit={handleSubmit}
          img={selfData.with_branding ? selfData.configuration?.logo : ''}


        >
        </WidgetContent>
      </WidgetTemp>
    )
  }
  return <></>
}


