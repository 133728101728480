import { useTranslation } from 'react-i18next';
import { rtlLngs } from '../i18n';

export function useRTL(): boolean {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return rtlLngs.includes(currentLanguage);
}

// export function useRTLD():(input:string) =>boolean{
//   const isRTL = useRTL();
//   return (input:string) => {
//     const charCode = input.charCodeAt(0);
//     return input ? (charCode >= 0x0590 && charCode <= 0x08FF) : isRTL;
//   }

// }

export function useRTLD(): (input: string) => boolean {
  const isRTL = useRTL();
  return (input: string) => {
    if(!input) return isRTL;
    let charCode = input.charCodeAt(0);

    for (let i = 1; i < input.length; i++) {
      if (charCode >= 0x0590 && charCode <= 0x08FF) break;
      charCode = input.charCodeAt(i);
    }
    return (charCode >= 0x0590 && charCode <= 0x08FF)
  };
}