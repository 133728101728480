import React from "react";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useFetchOfficely } from "./hooks/use-fetch-officely";
import { Method } from "./enums/enums";

export default function DraftPage() {
  const clientId = "907002654579-1g74g93kgdn2njk237mnbrv8fbbvou8n.apps.googleusercontent.com";


  const onSuccess = (credentialResponse:any) => {
    useFetchOfficely('/auth_google', Method.POST, credentialResponse)

  }

  return (
    <div>
      <GoogleOAuthProvider clientId={clientId}>
        <GoogleLogin
          onSuccess={onSuccess}
        />
      </GoogleOAuthProvider>
    </div>
  );
}
