import React from 'react'
import { Menu, MenuButton, IconButton, Icon as CIcon, MenuList, MenuItem } from '@chakra-ui/react'
import { MdOutlineFileUpload } from 'react-icons/md'
import { VscSymbolColor } from 'react-icons/vsc'
import { ChatBuilderActions, ChatBuilderSelectors, ConfigurationBuilderType, Language } from 'src/redux/chat-builder'
import { CiSettings } from "react-icons/ci";
import { GrLanguage } from "react-icons/gr";

import { useDispatch, useSelector } from 'react-redux'


export default function SettingsBuilder({ setOpenSetting }: { setOpenSetting: (type: ConfigurationBuilderType) => void }) {

  const currentBuilder = useSelector(ChatBuilderSelectors.currentBuilder)
  const dispatch = useDispatch()

  const onChangeLanguage = () => {
    const language = currentBuilder?.configuration.language === Language.EN ? Language.HE : Language.EN
    dispatch(ChatBuilderActions.onChangeBuilderLanguage({ language }))
  }

  return (
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<CIcon as={CiSettings} boxSize={6} />}
          variant="outline"
          _hover={{ opacity: 0.3 }}
          _active={{ bg: "transpernt" }}
          border="none"
          className="page-action'"
        />
        <MenuList >
          <MenuItem icon={<CIcon as={VscSymbolColor} boxSize={5} />} onClick={() => setOpenSetting(ConfigurationBuilderType.STYLE)} >
            Style
          </MenuItem>
          <MenuItem icon={<CIcon as={MdOutlineFileUpload} boxSize={5} />} onClick={() => setOpenSetting(ConfigurationBuilderType.LOGO)} >
            Replace Logo
          </MenuItem>
          <MenuItem icon={<CIcon as={GrLanguage} boxSize={5} />} onClick={onChangeLanguage} >
            {`Error Chat Language: ${currentBuilder?.configuration.language}`}
          </MenuItem>
        </MenuList>
      </Menu>
  )
}
