export enum NodeType {
  START = 'START',
  NUMBER = "NUMBER",
  TEXT_QUESTION = 'TEXT_QUESTION',
  SEND_MESSAGE = 'SEND_MESSAGE',
  OPTIONS = 'OPTIONS',
  VALID_MAIL = 'VALID_MAIL',
  VALID_LOCATION = 'VALID_LOCATION',
  VALID_DATE = 'VALID_DATE',
  TASK = 'TASK',
  MEDIA = 'MEDIA',
  RECEIVING_MEDIA = 'RECEIVING_MEDIA',
  WEBHOOK = 'WEBHOOK',
  FAKE_END = 'FAKE_END',
  END = 'END',
  DISTANCE = 'DISTANCE',
  POPUP = "POPUP",
  API = "API", 
  MARKET = "MARKET",
  POWER_WORDS = "POWER_WORDS",
  NOT_ACTIVE = "NOT_ACTIVE",
  JUMP="JUMP",
  BUTTONS="BUTTONS",
  TEMPLATES="TEMPLATES",
  WHATSAPP_LIST="WHATSAPP_LIST",
  SEND_LOCATION="SEND_LOCATION",
  NOTIFICATION="NOTIFICATION",
  MAKE="MAKE",
  ZAPIER="ZAPIER",
  FILTER="FILTER",
  VARIABELS = "VARIABELS",
  PLUGIN_AGENT = "PLUGIN_AGENT"
}

export enum Theme {
  DEFAULT,
  DARK
}

export enum Method {
  GET = 'GET',
  POST = 'POST',
  GET_POST = 'GET&POST', 
  UPDATE = 'UPDATE',
  DELETE  = 'DELETE'
}

export enum NodeObjectKey{
  DESCRIPTION = 'description',
  METHOD = 'method',
  URL = 'url',
  TYPE = 'type',
  JSON = 'json',
  MEDIA_LINK = 'mediaLink',
  MEDIA_FILE_NAME = 'mediaFileName',
  JUMP = 'jump',
  HEADER = 'header',
  FOOTER = 'footer',
  TEMPLATE = 'template',
  HEADER_FORMAT = 'headerForamt',
  LATITUDE = "latitude",
  LONGITUDE = "longitude",
  TEXT = "text",
  PHONE_NOTIFICATION="phoneNotification",
  MESSAGE_NOTIFICATION = "messageNotification",
  AGENT = "agent",
  
}

export enum Plan{
  FREE = 'FREE', 
  BASIC = 'BASIC',
  PREMIUM = 'PREMIUM',
  VIP = 'VIP',
  PARTNER = 'PARTNER'

}

export enum TemplateComponentKey {
  HEADER = "HEADER",
  BODY = "BODY",
  FOOTER = "FOOTER",
  BUTTONS = "BUTTONS",
}



export enum HeaderForamt{
  TEXT = "TEXT",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  DOCUMENT = "DOCUMENT"
}


export enum PathName{
  DASHBOARD = '/dashboard',
  AGENTS = '/agents',
  BUILDERS = '/builders',
  CHANNELS = '/channels',
  SUBSCRIPTIONS = "/subscriptions",
  CHATBOT = "/chatbot",
  PROFILE = "/profile",
  LOGIN = "/login",
  SIGNUP = "/signup",
  WIDGET = "/widget",
  HELP = "/help",
  REQUEST_FEATURE = "/request-feature",
  BOOK_DEMO = "/book-demo",
  INGEST_AGENT = "/ingest-agent",
  LIVE = "/live",


}

export enum DataType {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  BASIC = 'BASIC',
}


export enum FormatDate{
  DD_MM_YYYY = 'DD MM YYYY',
  MM_DD_YYYY = 'MM DD YYYY',
  YYYY_MM_DD = 'YYYY MM DD',
  YYYY_DD_MM = 'YYYY DD MM'
}

export enum ChannelType{
  MAYTAPI = "mayTapi",
  META = "meta",
  WEB = "web",
  GREEN_API = "greenAPI",
  AI="ai",
  OPEN_AI_ASSISTANT = "openAIAssistant",
  ZENDESK = "zendesk"


}

