
export class LLMForm {
  public system_prompt: string;
  public prompt: string;
  public model_string: string;
  public azureDeploymentName: string;

  constructor(system_prompt?:string, prompt?: string, model_string?: string, azureDeploymentName?: string) {
    this.system_prompt = system_prompt || '';
    this.prompt = prompt || '';
    this.model_string = model_string || '';
    this.azureDeploymentName = azureDeploymentName || '';
  }


}
