import { NodeType } from '../../enums/enums';
import { ChatbotNodeData } from '../../models';
import { Node } from 'reactflow';

export function getTargetHandleID(node: Node<ChatbotNodeData>): string {
  return node.id;
}

export function getSourceHandleIDS(node: Node<ChatbotNodeData>): Array<string> {
  return (node.type !== NodeType.END) ? node.data?.options?.map((option) => option.id) ?? [node.id] : [];
}
