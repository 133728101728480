
import { Builder } from 'src/redux/chat-builder';
import { Plan } from '../enums/enums';
import { MayTapiForm } from '../forms/MayTapi';
import { MetaForm } from '../forms/meta';


export class User {
  public uphone_number: string;
  public code_user?: string
  public bs_name: string;
  public verificationCode?: string;
  public officely_user?: boolean;
  public email: string;
  public channels: Channels;
  public chat_builders?: Array<Builder>;
  public plan:Plan;
  public image?:string;
  public token?:string;
  public partner?:string;
  public customers?:Customers
  public partner_code?:string
  public creationDate?:string
  public stripe_id?:string | null
  public early_access?:boolean
  

  constructor(uphone_number?:string, bs_name?:string, email?:string, partner?:string) {
      this.uphone_number = uphone_number || '',
      this.bs_name = bs_name || '',
      this.email = email || '',
      this.channels = {
          mayTapi: new MayTapiForm(),
          meta: new MetaForm()
      },
      this.partner = partner || ''
      this.plan = Plan.FREE
    
  }


  
  
}

export interface Channels {
  mayTapi: MayTapiForm,
  meta:MetaForm
}

interface Customers{
  percent:number;
  label:string;
}

export interface Partner{
  partner_id:number;
  partner_name:string;
  partner_phone:string
} 




