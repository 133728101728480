import { Action, createReducer } from 'deox';
import produce from 'immer';
import { remove } from 'lodash';

import { AgentActions } from './actions';
import { LoaderType } from './enums';
import { FileLoader, URLLoader } from './interfaces';
import { AgentState } from './state';


const initialState = new AgentState();

const findByID = (agent_id: string) => (item: { agent_id: string }) => item.agent_id === agent_id


const reducer = createReducer(initialState, (handleAction) => [
  handleAction(AgentActions.SetAgents, (state, { payload: { agents } }) => ({
    ...state,
    agents
  })),
  handleAction(AgentActions.SetCurrentAgent, (state, { payload: { currentAgent } }) => ({
    ...state,
    currentAgent
  })),
  handleAction(AgentActions.SetCurrentAgentNull, (state) => produce(state, (draft) => {
    draft.currentAgent = null
  })),
  handleAction(AgentActions.AddAgent, (state, { payload: { agent } }) => produce(state, (draft) => {
    const existingAgent = draft.agents?.find(findByID(agent.agent_id));
    if (existingAgent) {
      Object.assign(existingAgent, agent);
    } else {
      draft.agents.push(agent);
    }

  })),
  handleAction(AgentActions.DeleteAgent, (state, { payload: { id } }) => produce(state, (draft) => {
    remove(draft.agents, findByID(id))
  })),
  handleAction(AgentActions.onChangeAgentDeatils, (state, { payload: { value, agentDeatils } }) => produce(state, (draft) => {
    draft.currentAgent![agentDeatils] = value as any
  })),

  handleAction(AgentActions.onAddCondition, (state) => produce(state, (draft) => {
    draft.currentAgent?.conditions?.push({
      if: '',
      then: ''
    })
  })),
  handleAction(AgentActions.onAddLoader, (state, { payload: { loader } }) => produce(state, (draft) => {
    const exsitingLoader = draft.currentAgent!.loaders?.find((item) => item.id === loader.id);
    if (exsitingLoader) {
      if (exsitingLoader.type === LoaderType.URL) { (exsitingLoader as URLLoader).urls = (loader as URLLoader).urls }
      else { 
        (exsitingLoader as FileLoader).toDelete = false 
        exsitingLoader.checked = true
      }
    } 
    else {
      draft.currentAgent?.loaders?.push(loader)
    }
  })),
  handleAction(AgentActions.onChangeCondition, (state, { payload: { value, index, field } }) => produce(state, (draft) => {
    draft.currentAgent!.conditions![index][field] = value
  })),
  handleAction(AgentActions.onChangeStyle, (state, { payload: { value } }) => produce(state, (draft) => {
    draft.currentAgent!.style = value
  })),

  handleAction(AgentActions.onRemoveCondition, (state, { payload: { index } }) => produce(state, (draft) => {
    remove(draft.currentAgent!.conditions!, (item, i) => i === index)
  })),
  handleAction(AgentActions.onRemoveLoader, (state, { payload: { loader_id } }) => produce(state, (draft) => {
    //draft.currentAgent!.loaders = draft.currentAgent!.loaders.filter((item) => item.id !== loader_id);
    const loader = draft.currentAgent!.loaders!.find((item) => item.id === loader_id);
    if (!loader) return
    if (loader.type === LoaderType.URL) { remove(draft.currentAgent!.loaders!, loader.id) }
    else { 
      loader.checked = false;
      (loader as FileLoader).toDelete = true 
    }
  })),
  handleAction(AgentActions.onChangeCheckedLoader, (state, { payload: { loaderType, id, checked } }) => produce(state, (draft) => {
    if (loaderType === LoaderType.URL) {
      const loader = draft.currentAgent!.loaders!.find((item) => item.type === LoaderType.URL)! as URLLoader;
      const url = loader.urls.find((u) => u.url === id)!;
      url.checked = checked
    } else {
      const loader = draft.currentAgent!.loaders!.find((item) => item.id === id)! as FileLoader;
      loader.checked = checked
    }
  })),
  handleAction(AgentActions.onAddCustomUrl, (state, { payload: { url } }) => produce(state, (draft) => {
    const urlLoader = draft.currentAgent!.loaders?.find((item) => item.type === LoaderType.URL) as URLLoader;
    if (urlLoader){
      const urls = urlLoader.urls.some((u) => u.url === url);
      if (!urls) urlLoader.urls.push({ url, checked: true })
    }else{
      draft.currentAgent!.loaders?.push({
        type: LoaderType.URL,
        id: 'url-loader',
        checked: true,
        urls: [{ url, checked: true }]
      })
    }
  }))




]);


export function dataSourcesReducer(state: AgentState | undefined, action: Action<any>): AgentState {
  return reducer(state, action);
}
