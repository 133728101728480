import React, { ReactElement } from 'react';
import { Handle, Position } from 'reactflow';
import { useDispatch, useSelector } from 'react-redux';
import { Theme } from '../../enums/enums';
import { ChatBuilderSelectors, ChatBuilderActions } from '../../redux/chat-builder';
import { RootSelectors } from '../../redux/selectors';
import Button, { ButtonSize, ButtonVariant } from '../button/button';
import Icon, { IconTheme, IconVariant } from '../icon/icon';
import './node.scss';

function SourceHandle({ id }: { id: string }): ReactElement {
  const dispatch = useDispatch();

  const theme = useSelector(RootSelectors.theme);
  const connectedEdge = useSelector(ChatBuilderSelectors.edgeBySourceID(id));
  const activeSourceID = useSelector(ChatBuilderSelectors.activeSourceID);
  const [isDeleting, setIsDeleting] = React.useState(false);

  const onRemoveEdge = (e:any): void => {
    e.stopPropagation();
    if (connectedEdge) {
      dispatch(ChatBuilderActions.changeAnimated({ edgeID: connectedEdge.id, remove:true }))
      dispatch(ChatBuilderActions.removeElement({ id: connectedEdge.id }));
    }
    dispatch(ChatBuilderActions.setActiveSource({ id: null }));
    setIsDeleting(!isDeleting)
  };

  const onSetHandle = (): void => {
    dispatch(ChatBuilderActions.setActiveSource({ id }));
    dispatch(ChatBuilderActions.unselectAllNodes());


  };

  return (
    <Handle
      type="source"
      position={Position.Right}
      id={id}
      className="node-handle"
      onClick={onSetHandle}
    >
      {(activeSourceID === id || connectedEdge) && <Button onClick={onRemoveEdge}
        isRound={true}
        size={ButtonSize.SMALL}
        variant={(theme === Theme.DEFAULT) ? ButtonVariant.PRIMARY : ButtonVariant.WHITE}
        className="node-handle-remove">
        <Icon variant={IconVariant.REMOVE_SMALL} theme={(theme === Theme.DEFAULT) ? IconTheme.WHITE : IconTheme.PRIMARY} />
      </Button>}
    </Handle>
  );
}


const areEqual = (prevProps: any, nextProps: any) => prevProps.data === nextProps.data;

export default React.memo(SourceHandle, areEqual);