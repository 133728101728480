import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { NodeType } from '../../enums/enums';
import { useRTL } from '../../hooks/use-rtl';
import { useTranslation } from '../../hooks/use-translate';
import Icon, { IconVariant } from '../icon/icon';
import './node-preview.scss';


export const getIconNodeVariant = (type: NodeType): IconVariant => {
  switch (type) {
    case NodeType.START:
      return IconVariant.START;
    case NodeType.TEXT_QUESTION:
      return IconVariant.TEXT;
    case NodeType.NUMBER:
      return IconVariant.NUMBER;
    case NodeType.OPTIONS:
      return IconVariant.OPTIONS;
    case NodeType.TASK:
      return IconVariant.TASK;
    case NodeType.MEDIA:
      return IconVariant.MEDIA
    case NodeType.RECEIVING_MEDIA:
      return IconVariant.RECEIVING_MEDIA
    case NodeType.WEBHOOK:
      return IconVariant.WEBHOOK
    case NodeType.VALID_MAIL:
      return IconVariant.VALID_MAIL
    case NodeType.VALID_LOCATION:
      return IconVariant.LOCATION
    case NodeType.VALID_DATE:
      return IconVariant.VALID_DATE;
    case NodeType.DISTANCE:
      return IconVariant.LOCATION
    case NodeType.SEND_LOCATION:
      return IconVariant.SEND_LOCATION
    case NodeType.MARKET:
      return IconVariant.MARKET
    case NodeType.API:
      return IconVariant.API
    case NodeType.POWER_WORDS:
      return IconVariant.POWER_WORDS
    case NodeType.FAKE_END:
      return IconVariant.FAKE_END
    case NodeType.JUMP:
      return IconVariant.JUMP
    case NodeType.BUTTONS:
      return IconVariant.BUTTONS
    case NodeType.TEMPLATES:
      return IconVariant.TEMPLATES
    case NodeType.WHATSAPP_LIST:
      return IconVariant.WA_LIST
    case NodeType.NOTIFICATION:
      return IconVariant.NOTIFICATION
    case NodeType.ZAPIER:
      return IconVariant.ZAPIER
    case NodeType.MAKE:
      return IconVariant.MAKE
    case NodeType.FILTER:
      return IconVariant.FILTER
    case NodeType.VARIABELS:
      return IconVariant.VARIABELS
    case NodeType.PLUGIN_AGENT:
      return IconVariant.OPEN_AI
    default:
      return IconVariant.END

  }
};

function NodePreview({ type, onlyIcon }: {
  type: NodeType,
  onlyIcon?: boolean
}): ReactElement {
  const t = useTranslation('COMPONENTS.NODE_PREVIEW');
  const isRTL = useRTL();



  return (
    <div className={classNames(['node-preview' ])}>
      <Icon variant={getIconNodeVariant(type)} />
      {!onlyIcon && <span className={classNames(['node-preview-label', { rtl: isRTL }])}>
        {t(`TEXT_${type}`)}
      </span>}
    </div>
  );
}




export default NodePreview