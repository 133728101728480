import React, { useEffect, useState, useRef } from 'react'
import { WidgetHeader } from '../../../components/officely-web-componnents'
import WidgetContent from '../../../components/officely-web-componnents/widget-content'
import { Chat } from '../interfaces'
import WidgetTemp, { WidgetMode } from '../widget-temp'
import { useTranslation } from '../../../hooks/use-translate'
import { useParams, useLocation } from 'react-router-dom'
import { configuration } from "../../../configurations/configuration"
import { AgentService } from 'src/helpers/fetch/fetch-agent'
import { useFetchOfficely } from 'src/hooks/use-fetch-officely'
import { Method, NodeType, Plan } from 'src/enums/enums'



interface AgentDeatils {
  style: string;
  img?: string;
  thread_id?: string;
  plan:Plan;
  file_access:boolean;
}

export default function WidgetAIMode() {
  const agentService = new AgentService()

  const { codeAIParam, preview, nodeID } = useParams<{ codeAIParam: string, preview?: string, nodeID?:string }>();
  const location = useLocation();
  const t = useTranslation('PAGES.WIDGET');
  const [answer, setAnswer] = useState<string | null>()
  const [loading, setLoading] = useState<boolean>()
  const [chatHistory, setChatHistory] = useState<Array<Chat>>([])
  const isPreview = preview === 'preview'
  const [chatTextRef, setChatTextRef] = useState(false);
  const [agentDeatils, setAgentDeatils] = useState<AgentDeatils>();
  const isMounted = useRef(true);
  const abortControllerRef = useRef(new AbortController());
  const [IP, setIP] = useState('')
  const [fileLink, setFileLink] = useState<string | null>(null)  

  const isTeam = location.pathname.includes('team-chat')



  useEffect(() => {
    const fetchApi = async (): Promise<void> => {
      //const resIP = await (await fetch('https://geolocation-db.com/json/')).json();
      //setIP(resIP.IPv4)
      const resIP = await (await fetch('https://api.ipify.org?format=json')).json();
      const IP = resIP.ip
      setIP(IP)
      const res = await agentService.GetAgentDeatilsByID(codeAIParam ?? '')
      const text = isTeam ? "" : "Describe yourself in one sentence, Start with 'Hey,' use your name and finish with 'How can I help you?'"
      const body: any = { codeAIParam, query: text, preview: isPreview, IP, team: isTeam, nodeID}
      if (res.success && res.data) {
        setAgentDeatils(res.data)
        if (res.data.thread_id) {
          body['thread_id'] = res.data.thread_id;
        }
      }


      await GetQuestion(body);
      document.title = t("TITLE")
    };

    if (isMounted.current) {
      setChatHistory([])
      fetchApi();
    }
    return () => {
      isMounted.current = false;
      abortControllerRef.current.abort();

    };

  }, []);


  const GetQuestion = async (body: any) => {
    setAnswer(null);
    setFileLink(null)
    setLoading(true)
    handleStream(body)
  }




  function parseJSONWithRetry(jsonString:string, attempts = 3) {
    while (attempts > 0) {
        try {
            return JSON.parse(jsonString);
        } catch (error:any) {
            console.error(`Parse error on attempt ${4 - attempts}: ${error.message}`); //eslint-disable-line
            attempts--;
        }
    }
    throw new Error('Failed to parse JSON after multiple attempts.');
}


  const handleStream = async (body: any) => {
    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();


    try {
      const response = await fetch(`${configuration.api_url}/ai_chat`, {
        signal: abortControllerRef.current.signal,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      });


      if (response.body === null) { throw new Error("Error"); }
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      setLoading(false)
      setChatTextRef(true)
      const bool = true
      while (bool) {
        const { done, value } = await reader.read();
        if (done) {  break }
        const messages = decoder.decode(value, { stream: true }).split('\n').filter(Boolean);  // Split by newline and remove any empty strings
        messages?.forEach((messageStr) => {
          try {
            const message = parseJSONWithRetry(messageStr);
            setChatHistory((prevHistory: Chat[]) => {
                const lastMessage = prevHistory[prevHistory.length - 1];
        
                if (lastMessage?.type === 'outbound') {
                    return prevHistory.map((item, index) => 
                        index === prevHistory.length - 1 
                            ? {...item, text: item.text + message} 
                            : item
                    );
                } 
        
                const newMessage: Chat = {type: "outbound", text: message};
                return [...prevHistory, newMessage];
            });
            
          } catch (parseError) {
            const errorMessage = `Error occurred in parser: ${parseError} from ${codeAIParam}`;
            console.error(errorMessage); //eslint-disable-line


          }
        })
      }
    } catch (err) {
      if (err instanceof Error && err.name !== 'AbortError') {
        console.error(err.message); //eslint-disable-line
        const errorMessage = `Error occurred: ${err} from ${codeAIParam}`;
        useFetchOfficely(`logMessage`, Method.POST, { "message": errorMessage })
      }
    }
    finally {
      loading && setLoading(false)
      setChatTextRef(false)
    }
};







  const handleSubmit = () => {
    let query = answer?.trim() ?? ''
    if (!(query || fileLink) || chatTextRef || loading) return;

    const tempChat: Array<Chat> = [...chatHistory, {type:"inbound", text:query ?? ''}]


    if (fileLink) {
      const extraElement = {
        type: NodeType.MEDIA,
        data: { [NodeType.MEDIA]: fileLink }
      };
      query +=` ${fileLink}`
      tempChat[tempChat.length - 1].extra_elements = extraElement
    }


    setChatHistory(tempChat)

    const body: any = { codeAIParam, query, chatHistory: tempChat, preview: isPreview, IP, team: isTeam};
    if (agentDeatils?.thread_id) {
      body['thread_id'] = agentDeatils?.thread_id;
    }


    GetQuestion(body);
  }


  // const stopGenerate = () => {
  //   setLoading(false)
  //   generateRef.current = false
  // }



  const notActive = chatHistory[0]?.noActive



  return (
    <WidgetTemp className="AI">
      <style type="text/css">
        {agentDeatils?.style}
      </style>
      <WidgetHeader
        isChat
        mode={WidgetMode.AI}
      />
      <WidgetContent
        isChat
        chatHistory={chatHistory}
        showInput={!notActive}
        showPowerBy
        answerState={{ answer, setAnswer }}
        loadingState={{ loading, setLoading }}
        chatTextRef={chatTextRef}
        handleSubmit={handleSubmit}
        img={agentDeatils?.img}
        fileLinkState={agentDeatils?.file_access ? { fileLink, setFileLink } : undefined} 

      >
      </WidgetContent>

    </WidgetTemp>
  )
}


