
import { useFormik } from 'formik';
import React, { ReactElement, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Button, { ButtonSize, ButtonVariant } from '../../components/button/button';
import FormPhone from '../../components/form/phone';
import { LoginForm } from '../../forms/login';
import { useTranslation } from '../../hooks/use-translate';
import { AuthActions } from '../../redux/auth';
import classNames from 'classnames';
import { useRTL } from '../../hooks/use-rtl';
import Logo from '../../components/logo/logo';
import FormVerificationCode from '../../components/form/verificationCode';
import { useFetchOfficely } from '../../hooks/use-fetch-officely';
import { Method, PathName, Plan } from '../../enums/enums';
import { useTranslation as Translation } from 'react-i18next';
import Dropdown from '../../components/dropdown/dropdown';
import FormText from '../../components/form/text';
import { User } from '../../models/user';
import { isValidPhoneNumber } from 'react-phone-number-input'
import { RootActions } from '../../redux';
import GoogleOAuth from 'src/components/google-login/google-oauth';
import Intercom from '@intercom/messenger-js-sdk';
import { configuration } from "../../configurations/configuration";





//declare function stonlyTrack(type: string, id: string, user_object: any): void
//declare function smartlook(type: string, id: string, user_object: any): void

export function LoginPage(): ReactElement {
    const { lang } = useParams<{ lang?: string }>();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const t = useTranslation('PAGES.LOGIN');
    const tError = useTranslation('FORMS.LOGIN');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { i18n } = Translation();
    const isRTL = useRTL();
    const [isVerification, setIsVerification] = useState(false);
    const [actionsType, setActionsType] = useState<any[]>([]);
    const [seconds, setSeconds] = useState(0)
    const [minute, setMinute] = useState(0)
    const [authUser, setAuthUser] = useState<{ phone: string, session_code: string }>()
    const [isLoading, setIsLoading] = useState(false)


    useEffect(() => {
        document.title = t("TITLE");
        if (lang === 'he' || lang === 'en') {
            i18n.changeLanguage(lang)
            dispatch(RootActions.setLanguage({ language: lang }))
        }

    }, [])

    useEffect(() => {
        if (isVerification) {
            const timer = setInterval(() => {
                setSeconds(seconds - 1)
                if (seconds === 0) {
                    setSeconds(59)
                    setMinute(minute - 1)
                }
                if (minute === 0 && seconds === 0) {
                    setIsVerification(false)
                    dispatch(AuthActions.authorizeFailure)
                }
            }, 1000)
            return () => { clearInterval(timer) }
        }

    }, [seconds, isVerification])





    const Partner = async (partner: User): Promise<void> => {
        const res = await useFetchOfficely(`GetUser/${partner.uphone_number}/${false}`, Method.GET, null, partner.token)
        if (res.success) {
            res.data && dispatch(AuthActions.setUser({ user: res.data }))
            navigate(PathName.DASHBOARD)

        }
    }


    const GetUser = async (phone: string): Promise<void> => {
        const res = await useFetchOfficely(`GetUser/${phone}/${true}`, Method.GET)
        if (res.success) {
            if (res.data) {
                setAuthUser({ phone, session_code: res.data })
                setIsVerification(true);
                return
            }
            formik.setFieldError('phone', tError('TEXT_WHATSAPP_PHONE_NUMBER_ERROR_EXIST'))
            return
        }
    }

    const GetPartnerOf = async (user: User): Promise<any[]> => {
        const res = await useFetchOfficely(`GetPartnerOf/${user.uphone_number}`, Method.GET)
        if (res.success && res.data) {
            res.data.unshift(user)
            return res.data.map((partner: User) => ({
                key: partner.bs_name,
                node: <button className="method-actions-item" onClick={() => Partner(partner)}>{partner.bs_name}</button>
            }))
        }
        //navigate('/builders')

        navigate(PathName.DASHBOARD)
        return []

    }








    const OnSuccessLoginUser = async (user: User) => {
        dispatch(AuthActions.setUser({ user }))
        //setUserTracking(user)
        dispatch(AuthActions.authorizeSuccess());
        dispatch(AuthActions.setOfficelyUser({ officely_token: user.officely_user ? user.token! : false }))
        if (user.plan === Plan.PARTNER) {
            const partners = await GetPartnerOf(user)
            setActionsType(partners)
            setIsVerification(false)
            return
        }

        navigate(PathName.DASHBOARD)


    }

    // const setUserTracking = (user: User): void => {
    //     // const user_object = {
    //     //     bs_name: user.bs_name,
    //     //     plan: user.plan,
    //     //     email: user.email,
    //     //     code_user: user.code_user,
    //     //     token: user.token,
    //     //     creationDate: user.creationDate
    //     // }
    //     // Intercom({
    //     //     app_id: 'nyujowgk',
    //     //     user_id: user.uphone_number, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
    //     //     name: user.bs_name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
    //     //     email: user.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
    //     //     created_at: user.creationDate, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
    //     //   });
    //     //stonlyTrack('identify', user.uphone_number, user_object);
    //     //smartlook('identify', user.uphone_number, user_object);
    // }


    const handleSubmit = async (values: LoginForm): Promise<void> => {
        if (isVerification) {
            setIsLoading(true)
            let link = `AuthUser/${authUser?.phone}/${values.verificationCode.toString()}/${authUser?.session_code}`
            if (queryParams.get('authPay')) {
                link = `${link}?authPay=${queryParams.get('authPay')}`
            } else if (queryParams.get('auth') === 'intercom') {
                link = `${link}?intercom_id=${queryParams.get('intercom_id')}&code=${queryParams.get('code')}`
            }
            //link = queryParams.get('authPay') ? `${link}?authPay=${queryParams.get('authPay')}` : link
            const res = await useFetchOfficely(link, Method.GET)
            if (res.success) {
                const resData = res.data as User
                OnSuccessLoginUser(resData)
                return;
            }
            setIsLoading(false)
            const err_msgs = ['invalid intercom code', 'Too many attempts']
            if (err_msgs.includes(res.err)) {
                setIsVerification(false)
            }
            formik.setFieldError('verificationCode', tError('TEXT_VERIFICATION_CODE_ERROR_EXIST'))
        } else {
            if (!isValidPhoneNumber(values.phone, values.countryCode)) {
                formik.setFieldError('phone', tError('TEXT_INVALID_PHONE'))
                return
            }
            GetUser(values.phone.replace('+', ''))
            setMinute(3)
            setSeconds(0)
        }

    };

    const initialValues = new LoginForm();
    const validationSchema = LoginForm.getValidationSchema(tError, isVerification);

    const formik = useFormik({
        initialValues,
        onSubmit: handleSubmit,
        validationSchema
    });


    return (
        <div className="page login_signup">
            <div className="login_signup-header">
                <span className="logo">
                    <Logo />
                </span>
                {/* <span className="lang">
                    <LangSelect />
                </span> */}
            </div>
            <div className={classNames(['login_signup-box', { 'rtl': isRTL }])}>
                {

                    !actionsType.length ?
                        <>
                            <header className="login_signup-box-header">
                                <h2 className="login_signup-title">
                                    {(isVerification)
                                        ? t('TEXT_CONFIRM_PHONE')
                                        : t('TEXT_LOGIN')}
                                </h2>
                                <span className={classNames(['login_signup-description', { 'rtl': isRTL }])}>
                                    {isVerification ? t("IS_VERIFICATION") : t("IS_NOT_VERIFICATION")}
                                </span>

                            </header>
                            {isVerification && <span className="login_signup-timer">0{minute}:{seconds < 10 ? '0' : ''}{seconds}</span>}
                            <div className="login_signup-content">
                                <form className="login_signup-form" onSubmit={formik.handleSubmit}>
                                    {(isVerification)
                                        ? <FormVerificationCode<LoginForm> name="verificationCode"
                                            formik={formik}
                                            placeholder={t('TEXT_VERIFICATION_CODE')}
                                            type="verificationCode"
                                            inputMode="numeric" />
                                        : <FormPhone<LoginForm> name="phone"
                                            formik={formik}
                                            placeholder={t('TEXT_WHATSAPP_PHONE_NUMBER')}
                                            inputMode="tel"
                                            className="gray"
                                        />}
                                    <Button type="submit"
                                        //size={ButtonSize.MEDIUM}
                                        variant={ButtonVariant.PRIMARY}
                                        isBlock
                                        className={classNames(['', { 'rtl': isRTL }])}
                                        isLoading={isLoading}
                                    >
                                        {(isVerification)
                                            ? t('BUTTON_SUBMIT')
                                            : t('BUTTON_LOGIN')}
                                    </Button>
                                    {isVerification ?
                                        <Button variant={ButtonVariant.LINK}
                                            onClick={() => window.open(`https://wa.me/97237381772?text=${t('TEXT_ERROR_CODE')}`)}>
                                            {t("ERROR_CODE")}
                                        </Button>
                                        : <Button variant={ButtonVariant.LINK} onClick={() => navigate('/signup')}
                                            className={classNames(['button-center', { 'rtl': isRTL }])}>
                                            {t("BUTTON_SIGNUP")}
                                        </Button>}
                                </form>
                                {!isVerification && <GoogleOAuth queryParams={queryParams} />}

                            </div>
                        </>
                        :
                        <>
                            <header className="login_signup-box-header">
                                <h2 className="login_signup-title">
                                    {t("PARTNER")}
                                </h2>
                            </header>
                            <Dropdown items={actionsType} allowScrolling={true} className="form-group partner-dropdown" >
                                <FormText
                                    value={t("PARTNER_OF")}
                                    type="button"
                                    readOnly={true}
                                />
                            </Dropdown>
                            <Button variant={ButtonVariant.PRIMARY} size={ButtonSize.LARGE} onClick={() => navigate(PathName.DASHBOARD)}
                                className={classNames(['button-center', { 'rtl': isRTL }])}>
                                {t("TEXT_ENTER")}
                            </Button>
                        </>
                }
                {Intercom({
                    app_id: configuration.intercom_app_id
                })}
            </div>



        </div>
    );
}




