import React, { ReactElement } from 'react';
import { NodeType } from '../../enums/enums';
import { ChatbotNodeData } from '../../models/chat-bot-node';
import Node from './node';
import './node.scss';
import SourceHandle from './source-handle';

function StartNode({data}: { data: ChatbotNodeData }): ReactElement {
  return (
    <Node data={data} type={NodeType.START} className="node-start">
      <SourceHandle id={data.id} />
    </Node>
  );
}



const areEqual = (prevProps:any, nextProps:any) => prevProps.data === nextProps.data;

export default React.memo(StartNode, areEqual);