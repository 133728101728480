

export class SettingsForm {
    public startMessage: string;
    public azureKey?:string;
    public azureEndpoint?:string; 
    public azureEmbeddingKey?:string
    public awsAccessKeyId?:string
    public awsSecretAccessKey?:string
    public awsRegion?:string

  
    constructor(startMessage: string, azureKey?:string, azureEndpoint?:string, azureEmbeddingKey?:string, awsAccessKeyId?:string, awsSecretAccessKey?:string, awsRegion?:string) {
      this.startMessage = startMessage;
      this.azureKey = azureKey || '';
      this.azureEndpoint = azureEndpoint || '';
      this.awsAccessKeyId = awsAccessKeyId || '';
      this.awsSecretAccessKey = awsSecretAccessKey || '';
      this.awsRegion = awsRegion || '';
      this.azureEmbeddingKey = azureEmbeddingKey || '';
    }
  }