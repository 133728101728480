import classNames from 'classnames';
import React, { ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import ReactFlow, { Node, Controls, Edge, MiniMap, isEdge, Background, useNodesState, ConnectionLineType, Connection, addEdge, useEdgesState, useReactFlow, NodeDragHandler } from 'reactflow';
import './chat-builder.scss';
import EndNode from '../node/end-node';
import FakeEndNode from '../node/fake-end-node';
import TextQuestionNode from '../node/text-question-node';
import SendMessageNode from '../node/send-message';
import ValidMailNode from '../node/valid-mail-node';
import OptionsNode from '../node/options-node';
import StartNode from '../node/start-node';
import NumberNode from '../node/number-node';
import Media from '../node/media-node';
import ReceivingMediaNode from '../node/receiving-media-node';
import TaskNode from '../node/task-node';
import WebhookNode from '../node/webhook-node';
import ValidLocationNode from '../node/valid-location-node';
import ValidDateNode from '../node/valid-date-node';
import DistanceNode from '../node/distance-node';
import PopupNode from '../node/popup-node';
import ApiNode from '../node/api-node';
import FilterNode from '../node/filter_node/filter-node';
import { NodeType, Theme } from '../../enums/enums';
import { useDispatch, useSelector } from 'react-redux';
import { ChatBuilderSelectors, ChatBuilderActions } from '../../redux/chat-builder';
import { ChatbotNodeData } from '../../models';
import { useMonaco } from '@monaco-editor/react';
import { RootSelectors } from '../../redux/selectors';
import { useNewNode } from '../../hooks/use-new-node';
import PowerWordsNode from '../node/power-words-node';
import JumpNode from '../node/jump-node';
import ButtonsNode from '../node/buttons-node';
import PluginAgentNode from '../node/plugin_agent/plugin_agent';
import { ChatbotActionsSelectors } from '../../redux/chatbot-actions';
import TemplatesNode from '../node/templates-node';
import WhatsAppListNode from '../node/whatsapp-list-node';
import SendLocation from '../node/send-location';
import NotificationNode from '../node/notifcation-node';
import AutoSave from '../auto-save/auto-save';
import { ChatBotActions, useChatBotFunctionActions } from '../../hooks/user-chatbot-actions';
import { Suggestion } from 'react-mde';
import 'reactflow/dist/base.css';
import { useUndoRedo } from '../../hooks/use-undo'
import { useDebouncedCallback } from 'use-debounce';
import { ModalPopup } from '../../helpers/dialog';




const nodeTypes = {
  [NodeType.START]: StartNode,
  [NodeType.TEXT_QUESTION]: TextQuestionNode,
  [NodeType.SEND_MESSAGE]: SendMessageNode,
  [NodeType.OPTIONS]: OptionsNode,
  [NodeType.NUMBER]: NumberNode,
  [NodeType.VALID_MAIL]: ValidMailNode,
  [NodeType.VALID_LOCATION]: ValidLocationNode,
  [NodeType.VALID_DATE]: ValidDateNode,
  [NodeType.TASK]: TaskNode,
  [NodeType.MEDIA]: Media,
  [NodeType.RECEIVING_MEDIA]: ReceivingMediaNode,
  [NodeType.WEBHOOK]: WebhookNode,
  [NodeType.DISTANCE]: DistanceNode,
  [NodeType.POPUP]: PopupNode,
  [NodeType.API]: ApiNode,
  [NodeType.FAKE_END]: FakeEndNode,
  [NodeType.POWER_WORDS]: PowerWordsNode,
  [NodeType.JUMP]: JumpNode,
  [NodeType.BUTTONS]: ButtonsNode,
  [NodeType.TEMPLATES]: TemplatesNode,
  [NodeType.WHATSAPP_LIST]: WhatsAppListNode,
  [NodeType.SEND_LOCATION]: SendLocation,
  [NodeType.NOTIFICATION]: NotificationNode,
  [NodeType.MAKE]: TextQuestionNode,
  [NodeType.ZAPIER]: TextQuestionNode,
  [NodeType.FILTER]: FilterNode,
  [NodeType.PLUGIN_AGENT]: PluginAgentNode,
  [NodeType.END]: EndNode

};

const proOptions = {
  account: 'paid-pro',
  hideAttribution: true
};



function ChatBuilder({ isActive, onFitView, toggleVar }: {
  isActive: boolean,
  onFitView: boolean
  toggleVar: () => void

}): ReactElement {
  const dispatch = useDispatch();
  const getNewNode = useNewNode();
  const monaco = useMonaco()
  const transform = useSelector(ChatbotActionsSelectors.transformSearch);
  const elements = useSelector(ChatBuilderSelectors.chatScheme)
  const allVariables = useSelector(ChatBuilderSelectors.allVariables);
  const configuration = useSelector(ChatBuilderSelectors.configuration)
  const theme = useSelector(RootSelectors.theme);
  const firstLoad = useRef(true);
  const [reactFlowInstance, setReactFlowInstance] = useState<any | null>(null);
  const reactFlowWrapper = useRef<any>(null);
  const ChatBotFunctionAction = useChatBotFunctionActions()
  const initNodes = useSelector(ChatBuilderSelectors.nodes)
  const initEdges = useSelector(ChatBuilderSelectors.edges)
  const [nodes, setNodes, onNodesChange] = useNodesState(initNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initEdges);
  const { setCenter } = useReactFlow();
  const { undo, redo, canUndo, canRedo, takeSnapshot } = useUndoRedo();
  const isSaved = useSelector(ChatBuilderSelectors.isSaved)
  const [isModalOpen, setIsModalOpen] = useState<{ open: boolean, content: ReactNode }>({ open: false, content: null })
  const { fitView } = useReactFlow();


  const OnUndo = () => {
    !canUndo && dispatch(ChatBuilderActions.UpdateChatChema({ newChatScheme: undo() }))
  }

  const OnRedo = () => {
    !canRedo && dispatch(ChatBuilderActions.UpdateChatChema({ newChatScheme: redo() }))
  }

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key.toLowerCase() === 'y' && (event.ctrlKey || event.metaKey)) {
        OnRedo();
      } else if (event.key.toLowerCase() === 'z' && (event.ctrlKey || event.metaKey)) {
        OnUndo();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  useEffect(() => {
    fitView({ duration: 400 });
  }, [onFitView]);


  useEffect(() => {
    if (transform) {
      setCenter(transform.x, transform.y, { zoom: transform.zoom, duration: 1000 });
    }
  }, [transform])


  useEffect(() => {
    if (!monaco && reactFlowWrapper.current) { return }
    const editor = monaco?.languages.registerCompletionItemProvider('json', {
      triggerCharacters: ["@", "#"],
      provideCompletionItems: () => {
        const suggestions: any[] = []
        allVariables.forEach((s: Suggestion) => {
          suggestions.push({
            label: `${s.preview}`,
            kind: monaco?.languages.CompletionItemKind.Text,
            insertText: `${s.value}`
          })
        })
        return { suggestions }
      }
    })
    return () => {
      editor?.dispose()
    }
  }, [allVariables, monaco])


  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      return;
    }
    if (!isActive && isSaved) {
      onToggleSave(false)
      debounced()
    }
    setNodes(() => initNodes)
    setEdges(initEdges)
  }, [elements, configuration])


  useEffect(() => {
    setNodes(nodes)
    setEdges(edges)
  }, [onNodesChange, onEdgesChange])



  const debounced = useDebouncedCallback(async () => {
    !isSaved && onToggleSave(await ChatBotFunctionAction(ChatBotActions.SAVE))
  }, 3000)


  const onToggleSave = (isSaved: boolean) => {
    dispatch(ChatBuilderActions.ToggleSave({ isSaved }))
  }


  const onNodeDrag = useCallback((_: React.MouseEvent, node: Node): void => {
    dispatch(ChatBuilderActions.setElement({ element: node }));
  }, []);

  const onPaneClick = useCallback((_: React.MouseEvent): void => {
    dispatch(ChatBuilderActions.unselectAllNodes());
  }, []);

  const onChangeAnimated = useCallback((_: React.MouseEvent, element: Edge | Node): void => {
    if (isEdge(element)) {
      dispatch(ChatBuilderActions.changeAnimated({ edgeID: element.id }));
    }
  }, [])




  const onAddNode = (type: NodeType, position: { x: number, y: number }) => {
    const node = getNewNode(type, position)
    dispatch(ChatBuilderActions.unselectAllNodes());
    dispatch(ChatBuilderActions.addElement({ element: node }));
    takeSnapshot()
  }


  const handleContextMenu = useCallback((e: React.MouseEvent): void => { e.preventDefault() }, [])


  const onDragOver = useCallback((event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);



  const onDrop = useCallback((event: any) => {
    event.preventDefault();
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type = event.dataTransfer.getData('application/reactflow');
    // check if the dropped element is valid
    if (typeof type === 'undefined' || !type || [NodeType.MAKE, NodeType.ZAPIER, NodeType.VARIABELS].includes(type)) {
      let content = null;
      switch (type) {
        case NodeType.MAKE:
          content = (
            <iframe id="ston-dyWeAdGvwk"
              width="100%"
              height="100%"
              frameBorder="0"
              name="StonlyExplanation"
              allowFullScreen
              src="https://stonly.com/embed/he/dyWeAdGvwk/view/"> </iframe>
          )
          break;
        case NodeType.ZAPIER:
          content = (
            <iframe id="ston-fAQojMiFSq" width="100%" height="100%"
              frameBorder="0" name="StonlyExplanation"
              allowFullScreen src="https://stonly.com/embed/he/fAQojMiFSq/view/"> </iframe>
          )
          break;
        case NodeType.VARIABELS:
          toggleVar();
          return
        case 'undefined':
          return
      }
      setIsModalOpen({
        open: true,
        content
      })
      return;
    }
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top
    });
    onAddNode(type, position)
  }, [reactFlowInstance, onAddNode]);


  const onConnect = useCallback((params: Connection) => {
    const newEdges = addEdge({ ...params, type: ConnectionLineType.SmoothStep }, edges)
    const edge = newEdges.find((e) => e.sourceHandle === params.sourceHandle)!;
    dispatch(ChatBuilderActions.addElement({ element: edge }));
    takeSnapshot()
  }, [takeSnapshot]);


  const onNodeDragStart: NodeDragHandler = useCallback(() => { takeSnapshot() }, [takeSnapshot]);


  return (
    <div className={classNames(["chat-builder"])} ref={reactFlowWrapper}>
      <ReactFlow
        onInit={setReactFlowInstance}
        fitView
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onNodeDrag={onNodeDrag}
        onPaneClick={onPaneClick}
        onPaneContextMenu={handleContextMenu}
        nodeTypes={nodeTypes as any}
        minZoom={0.1}
        maxZoom={1}
        onDrop={onDrop}
        onDragOver={onDragOver}
        proOptions={proOptions}
        onConnect={onConnect}
        onNodeDragStart={onNodeDragStart}
        connectionRadius={50}
        connectionLineType={ConnectionLineType.SmoothStep}
        selectionKeyCode={null}
        panOnScroll
        onEdgeClick={onChangeAnimated}
        onlyRenderVisibleElements
        nodesFocusable={false}
        edgesFocusable={false}
        elevateNodesOnSelect
      >
        <Background color="#aaa" gap={50} size={1} />
        <MiniMap
          zoomable
          pannable
          className="only-desktop"
          nodeColor={(n: Node<ChatbotNodeData>) => {
            if (n.data?.isError) { return 'red' }
            else { return theme === Theme.DARK ? "#5b61eb" : '#DAE7FF' }
          }}
          nodeStrokeWidth={3}
          ariaLabel={null}
        />
        <Controls />
      </ReactFlow>
      {!isActive && <AutoSave />}
      <ModalPopup
        isOpen={isModalOpen.open}
        onClose={() => setIsModalOpen({ open: false, content: null })}
      >
        {isModalOpen.content}
      </ModalPopup>
    </div>

  );
}




export default React.memo(ChatBuilder);

