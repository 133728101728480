import { TFunction } from 'i18next';
import React, { ReactElement } from 'react';
import { NodeObjectKey } from '../../../enums/enums';
import { ChatbotNodeData } from '../../../models';
import OptionInput from '../../form/option-input';



export default function ExtraMedia({ data, t, onChangeObj }: {
    data: ChatbotNodeData,
    t: TFunction,
    onChangeObj: (key: NodeObjectKey, value: string) => void
}): ReactElement {


    return (
        <>
            <OptionInput
                value={data?.obj!.mediaLink ?? ''}
                placeholder={t('MEDIA_LINK')}
                onChange={(value) => onChangeObj(NodeObjectKey.MEDIA_LINK, value)}
                className="node-option-input"
            />
            <OptionInput
                value={data?.obj!.mediaFileName ?? ''}
                placeholder={t("MEDIA_FILE_NAME")}
                onChange={(value) => onChangeObj(NodeObjectKey.MEDIA_FILE_NAME, value)}
                className="node-option-input"
            />
        </>
    );
}
