import React, { ReactElement, useCallback, useEffect } from 'react';
import { useReactFlow, Node } from 'reactflow';
import FormText from 'src/components/form/text';
import { TextNodeData } from 'src/models/team-builder-node';
import { TextForm } from './text-form';
import { useFormik } from 'formik';
import '../config.scss'
import { get } from 'lodash';
import { Editor } from '../../team-react-mde';
import { FormLabel } from '@chakra-ui/react';





function TextConfig({ data }: {
    data: TextNodeData,
}): ReactElement {
    const { setNodes } = useReactFlow();


    const initialValues = new TextForm(data.text)





    const formik = useFormik({
        initialValues,
        onSubmit: (_: TextForm) => { }, // eslint-disable-line
        enableReinitialize: false
    });



    const onChange = (value?: string) => {
        formik.setFieldValue('text', value)
    }

    const computeUpdatedNodes = useCallback((nodes: Array<Node>, values: TextForm, id: string) => (
        nodes.map((node) => {
            if (node.id === id && node.type === 'workflow') {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        text: values.text
                    }
                };
            }
            return node;
        })
    ), []);

    useEffect(() => {
        setNodes((prevNodes) => computeUpdatedNodes(prevNodes, formik.values, data.id));
    }, [formik.values, data.id, computeUpdatedNodes]);










    return (
        <form className="config config-text">
            <FormLabel className="label">Text</FormLabel>
            <FormText<TextForm> formik={formik} name="text">
                <Editor textAreaClass="text-field" id={data.id} value={get(formik.values, "text")} onChange={onChange} />
            </FormText>



        </form >


    )
}





export default TextConfig;



