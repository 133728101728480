import classNames from 'classnames';
import React, { ReactElement, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { NodeObjectKey, NodeType } from '../../../enums/enums';
import { useRTL } from '../../../hooks/use-rtl';
import { useTranslation } from '../../../hooks/use-translate';
import { ChatbotNodeData } from '../../../models';
import { ChatBuilderActions } from '../../../redux/chat-builder';
import HeaderFooter from './header-footer';
import ExtraMedia from './extra-media';
import ExtraTemplates from './extra-templates';
import ExtraSendLocation from './extra-send-location';
import ExtraNotification from './extra-notification';



export default function ExtraFields({ data, type }: { data: ChatbotNodeData, type: NodeType }): ReactElement {
    const dispatch = useDispatch();
    const t = useTranslation('COMPONENTS.NODE');
    const isRTL = useRTL()



    const onChangeObj = (key: NodeObjectKey, value: any): void => {
        dispatch(ChatBuilderActions.changeObj({ id: data.id, key, value }));
    }


    const GetExtraFileds = () => {
        switch (type) {
            case NodeType.MEDIA:
                return <ExtraMedia data={data} t={t} onChangeObj={onChangeObj} />;
            case NodeType.BUTTONS:
            case NodeType.WHATSAPP_LIST:
                return <HeaderFooter data={data} t={t} onChangeObj={onChangeObj} headerForamt={type === NodeType.BUTTONS} />;
            case NodeType.TEMPLATES:
                return <ExtraTemplates data={data} t={t} onChangeObj={onChangeObj}/>;
            case NodeType.SEND_LOCATION:
                return <ExtraSendLocation data={data} t={t} onChangeObj={onChangeObj} />;
            case NodeType.NOTIFICATION:
                return <ExtraNotification data={data} t={t} onChangeObj={onChangeObj} />;
            default:
                return <></>
        }
    }

    const memoizedValue = useMemo(() => GetExtraFileds(), [type, data, isRTL])

    return (
        <div className={classNames(['node-option-obj', { 'rtl': isRTL }])}>
            {memoizedValue}
        </div>
    );

}
