import { DeleteIcon, SmallAddIcon } from '@chakra-ui/icons';
import { Menu, MenuList, MenuItem, Icon as CIcon } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { getOutgoers, useReactFlow } from 'reactflow';
import useRemoveClick from 'src/hooks/team-builder/use-remove-node';
import useAddNode from 'src/hooks/team-builder/useAddNode';
import { TeamFlowNodeType, TeamNodeType } from '../interfaces';
import { IoPlayOutline } from 'react-icons/io5';

// Define the type for the props
interface ContextMenuProps {
    id: string;
    top: number;
    left: number;
    onCloseContext: () => void;
    onRunOnce: (id: string) => void;
}

const ContextMenu: React.FC<ContextMenuProps> = ({
    id,
    top,
    left,
    onCloseContext,
    onRunOnce
}) => {

    const { getNode, getEdges, getNodes } = useReactFlow();

    const [isOpen, setIsOpen] = useState(false);
    const addNodeHandler = useAddNode(id);
    const removeNode = useRemoveClick(id);

    const edges = getEdges();
    const nodes = getNodes();

    const node = getNode(id)
    if (!node) return null;

    const childEdges = getOutgoers(node, nodes, edges)

    //const notAllowedRemove = childEdges.length > 0 || !edges.length;
    const notAllowedRemove = childEdges.length > 1 && node?.data.type === TeamFlowNodeType.FILTER
    const allowedAddChild = node?.data.type === TeamFlowNodeType.FILTER


    useEffect(() => {
        setIsOpen(true)
    }, []);


    const closeContextMenu = () => {
        setIsOpen(false);
        onCloseContext();
    };

    const deleteNode = () => {
        removeNode();
        closeContextMenu();
    }



    const onAddWorkFlowNode = () => {
        addNodeHandler(TeamNodeType.WORKFLOW);
        closeContextMenu();
    }

    const RunOnce = () => {
        onRunOnce(id);
        closeContextMenu();
    }







    return (
        <Menu isOpen={isOpen} >
            <MenuList
                style={{ position: 'absolute', left, top }}
                zIndex={5}
                minWidth={130}
                hidden={node?.type === TeamNodeType.PLACEHOLDER}
                
            >
                <MenuItem icon={<CIcon as={IoPlayOutline} boxSize={5} />}
                    onClick={RunOnce}
                >
                    Run Once
                </MenuItem>
                <MenuItem icon={<CIcon as={SmallAddIcon} boxSize={5} />}
                    onClick={onAddWorkFlowNode}
                    hidden={!allowedAddChild}
                >
                    Add Child
                </MenuItem>
                <MenuItem icon={<CIcon as={DeleteIcon} boxSize={5} />}
                    onClick={deleteNode}
                    hidden={notAllowedRemove}
                >
                    Remove
                </MenuItem>


            </MenuList>
        </Menu>
    );
};

export default ContextMenu;
