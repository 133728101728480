import React, { ReactElement, useRef, useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button, { ButtonVariant } from "../button/button";
import { Avatar, Switch } from "@chakra-ui/react";
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import Compressor from '@uppy/compressor';
import AwsS3 from '@uppy/aws-s3';
import ImageEditor from '@uppy/image-editor'
import { AgentActions, AgentDeatils as AgentField, AgentSelectors, RoleTypes } from "src/redux/agents";
import './agent.scss'
import "@uppy/core/dist/style.css";
import '@uppy/image-editor/dist/style.min.css';
import { configuration } from "../../configurations/configuration"
import { AuthSelectors } from "src/redux/auth";
import { EmbeddingModel } from "../team-builder";
import { useTranslation } from "src/hooks/use-translate";
import AutoComplete from "../auto-complete/auto-complete";
import { Plan } from "src/enums/enums";




export default function AgentDeatils({ onContinue }: {
    onContinue: () => void
}): ReactElement {
    const currentAgent = useSelector(AgentSelectors.currentAgent)
    const user = useSelector(AuthSelectors.user)
    const t = useTranslation("TEAM_BUILDER.EMBEDDINGS_TYPE")

    const dispatch = useDispatch();
    const uppy = useRef<Uppy>();


    const DEFAULT_STAGE_COUNT = 7;
    const createDefaultStages = (count: number) => Array.from({ length: count }, () => '');
    const [tempStages, _] = useState<string[]>(() => currentAgent?.stages ?? createDefaultStages(DEFAULT_STAGE_COUNT));


    // const embedingModelItems = [EmbeddingModel.ADA2, EmbeddingModel.OPEN_AI_SMALL, EmbeddingModel.TITAN_1]
    // if (user.plan === Plan.VIP){
    //     embedingModelItems.push(EmbeddingModel.AZURE)
    // }

    // embedingModelItems.map((key) => ({
    //         value: t(key),
    //         label: t(key),
    //         action: () => onEmbeddingsModelItemsSelect(key)
    //     }))

    const GetEmbedingModels = useCallback(() => {
        const embedingModelItems = [EmbeddingModel.ADA2, EmbeddingModel.OPEN_AI_SMALL, EmbeddingModel.TITAN_1]
        if (user.plan === Plan.VIP) {
            embedingModelItems.push(EmbeddingModel.AZURE)
        }
        return embedingModelItems.map((key) => ({
            value: t(key),
            label: t(key),
            action: () => onEmbeddingsModelItemsSelect(key)
        }))
    }, [user.plan])





    const onChangeAgentDeatils = (value: string | object, agentDeatils: AgentField) => {
        dispatch(AgentActions.onChangeAgentDeatils({ value, agentDeatils }))
    }

    useEffect(() => {
        const uppyInstance = new Uppy({
            restrictions: {
                allowedFileTypes: ['image/*'],
                maxNumberOfFiles: 1
            }
        })
            .use(AwsS3, {
                companionUrl: `${configuration.api_url}/agent-porfile`
            })
            .use(Compressor)
            .use(Dashboard, {
                inline: false,
                trigger: '.avatar-trigger',
                closeAfterFinish: true
            })
            .use(ImageEditor, { target: Dashboard });


        uppy.current = uppyInstance;

        uppy.current.on('file-added', (file) => {
            uppy.current!.setFileMeta(file.id, { name: currentAgent!.agent_id });
        });

        uppy.current.on('complete', (result) => {
            if (result.successful.length) {
                const end_point = "https://agent-picture.s3.amazonaws.com/"
                const name = result.successful[0].meta.name
                const url = end_point + name
                onChangeAgentDeatils(url, AgentField.IMG)
            }
        });



        return () => {
            uppyInstance.close();
        };
    }, []);


    const onAvatarClick = () => {
        if (uppy.current) {
            const dashboardPlugin = uppy.current.getPlugin('Dashboard') as Dashboard;
            dashboardPlugin.openModal();
        }
    };

    // const handleRoleSelect = (item: RoleTypes) => {
    //     const index = Object.values(RoleTypes).findIndex((v) => v === item);
    //     const key = index !== -1 ? (Object.keys(RoleTypes)[index] as keyof typeof RoleTypes) : null;
    //     if (key !== null) {
    //         onChangeAgentDeatils(key, AgentField.ROLE);
    //     }
    // };

    // const handleChangeStages = (value: string, index: number) => {
    //     const newValues = [...tempStages]; // Create a copy of the values array
    //     newValues[index] = value; // Update the value at the specific index
    //     setTempStages(newValues); // Set the new values array
    // };

    const onFinish = () => {
        // if (RoleTypes[currentAgent?.role as keyof typeof RoleTypes] === RoleTypes.CUSTOM) {
        //     dispatch(AgentActions.onChangeAgentDeatils({ value: tempStages, agentDeatils: AgentField.STAGES }))
        // }
        onContinue()
    }



    const basicDisableConditions = Boolean(currentAgent?.name && currentAgent?.role && currentAgent?.company_name)
    const disabledButton = (basicDisableConditions && RoleTypes[currentAgent?.role as keyof typeof RoleTypes] !== RoleTypes.CUSTOM) ||
        (basicDisableConditions &&
            RoleTypes[currentAgent?.role as keyof typeof RoleTypes] === RoleTypes.CUSTOM &&
            tempStages.every((str) => str.trim().length > 0))
        ? false : true


    const onEmbeddingsModelItemsSelect = (item: string) => {

        const new_ingesting_param = { ...currentAgent?.ingesting_param, embedding_model: item }
        onChangeAgentDeatils(new_ingesting_param, AgentField.INGESTING_PARAM);
    }

    const onChangeStartMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
        const team = { ...currentAgent?.team, settings: { ...currentAgent?.team?.settings, startMessage: e.target.value } }
        onChangeAgentDeatils(team, AgentField.TEAM)
    }

    const onChangeFileAccess = (e: React.ChangeEvent<HTMLInputElement>) => {
        const team = { ...currentAgent?.team, settings: { ...currentAgent?.team?.settings, fileAccess: e.target.checked } }
        onChangeAgentDeatils(team, AgentField.TEAM)
    }







    return (
        <div className="agent agent-deatils">
            <div className="img" onClick={onAvatarClick}>
                <label className="avatar-trigger">
                    <span>
                        <Avatar src={currentAgent?.img} cursor="pointer" size="2xl" />
                    </span>
                </label>
            </div>
            <div className="form">
                <div className="form-field" >
                    <span className="form-field-label"> Company Name</span>
                    <input
                        className="form-field-text"
                        value={currentAgent?.company_name}
                        onChange={(e) => onChangeAgentDeatils(e.target.value, AgentField.COMPANY_NAME)}
                    />
                </div>
                <div className="form-field">
                    <span className="form-field-label"> Agent Name</span>
                    <input
                        className="form-field-text"
                        value={currentAgent?.name}
                        onChange={(e) => onChangeAgentDeatils(e.target.value, AgentField.NAME)} />
                </div>
                <div className="form-field">
                    <span className="form-field-label"> First Message</span>
                    <input
                        className="form-field-text"
                        value={currentAgent?.team?.settings?.startMessage ?? ""}
                        onChange={onChangeStartMessage} />
                </div>
                {user.officely_user && <div className="form-field">
                    <div className="form-field">
                        <span className="form-field-label"> File Access</span>
                        <Switch mb={2} colorScheme="primary" 
                            isChecked={currentAgent?.team?.settings?.fileAccess || false} 
                            onChange={onChangeFileAccess} 
                        />

                    </div>


                    <span className="form-field-label"> Embeddings</span>
                    <AutoComplete
                        items={GetEmbedingModels()}
                        value={t((currentAgent!.ingesting_param?.embedding_model || EmbeddingModel.ADA2).toString())}
                        variant="outline"
                        width="100%"
                        maxHeight={220}
                        classNameInput="text-field"
                    />
                    <span className="form-field-label"> Chunk Size</span>
                    <input
                        className="form-field-text"
                        value={currentAgent?.ingesting_param?.chunk_size || 700}
                        onChange={(e) => {
                            const new_ingesting_param = { ...currentAgent?.ingesting_param, chunk_size: e.target.value }
                            onChangeAgentDeatils(new_ingesting_param, AgentField.INGESTING_PARAM)
                        }}
                        type="number"
                    />
                    <span className="form-field-label"> Chunk Overlap</span>
                    <input
                        className="form-field-text"
                        value={currentAgent?.ingesting_param?.chunk_overlap || 30}
                        onChange={(e) => {
                            const new_ingesting_param = { ...currentAgent?.ingesting_param, chunk_overlap: e.target.value }
                            onChangeAgentDeatils(new_ingesting_param, AgentField.INGESTING_PARAM)
                        }}
                        type="number"
                    />
                </div>}

            </div>
            <Button
                variant={ButtonVariant.PRIMARY}
                isBlock
                className="continue-button"
                disabled={disabledButton}
                onClick={onFinish}
            >

                Continue
            </Button>

        </div>
    );
}


