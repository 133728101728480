import { Method } from "../enums/enums";
import { configuration } from "../configurations/configuration"



export interface ResponseOfficely {
    success: boolean
    data?: any
    err?: any
}


export enum OfficelyRoutes {
    UPDATE_BUILDER_SETTINGS = 'updateBuilderSetting',
    UPDATE_LOGO_WEB = 'updateLogoWeb',
    UPDATE_VARIABLES = 'updateVariablesSetting',
    INGEST_AGENT = 'ingestCustomAgent',
    RUN_AGENT = 'runAgent',
    DELETE_AGENT = 'deleteAgent',
    GET_AGENT_DEATILS = 'getAgentDeatils',
    AI_CHAT = 'ai_chat',
    INSERT_TEAM_BUILDER = 'insertTeamBuilder',
    RUN_ONCE_TEAM_BUILDER = "runOnceTeam",
    GET_TEAM_INPUTS = "getInputs",
    DUPLICATE_AGENT = "duplicateAgent",
    GET_TOGETHER_MODELS = "getTogtherModels",
}



export async function useFetchOfficely(route: string, method: Method, body?: any, token?: string): Promise<ResponseOfficely> {
    const url = `${configuration.api_url}/${route}`;
    const headers: { [key: string]: string } = {
        "x-officely-token": token || process.env.REACT_APP_TOKEN || ""
    }
    let payload;
    if (body instanceof FormData) {
        payload = body;
    } else {
        headers["Content-Type"] = "application/json; charset=UTF-8";
        payload = JSON.stringify(body);
    }

    let res;
    try {
        if (method === Method.GET) {
            res = await fetch(url, { method, headers })
        } else {
            res = await fetch(url, { method, headers, body: payload })
        }
        res = await res.json()
        if (!res['success']) { throw res['err']; }
        return res
    } catch (err) {
        // console.log('error fetch', err)
        const resOfficety: ResponseOfficely = {
            success: false,
            err
        }
        return resOfficety
    }
}






