import React, { ReactElement, useState } from 'react';
import classNames from 'classnames';
import { FormTextProps, getError, getValue } from './text';
import FormError from './error';
//import PhoneInput from 'react-phone-input-2'
import PhoneInput from 'react-phone-number-input'

import 'react-phone-number-input/style.css'

export default function FormPhone<T>({
  formik,
  name,
  className,
  ...inputProps
}: FormTextProps<T>): ReactElement {
  const error = getError<T>(formik, name);
  const value = getValue<T>(formik, name)?.toString()
  const [contryCode, _] = useState()


  // useEffect(() => {
  //   async function fetchData() {
  //     const response = await (await fetch('https://geolocation-db.com/json/')).json()
  //     setContryCode(response.country_code)
  //   }
  //   fetchData();
  // }, [])

  const handlePhoneChange = (value: any) => {
    formik?.setFieldValue(name ?? '', value);
  };

  const handleContryChange = (value: any) => {
    formik?.setFieldValue('contry', value);

  }



  return (
    <div dir="ltr"
      className={classNames([
        'form-group', 'form-phone',
        {
          'form-group-error': error
        },
        className
      ])}>
      {getValue(formik, name) && <label className="form-label">{inputProps.placeholder}</label>}
      <PhoneInput
        international={true}
        defaultCountry={contryCode}
        value={value}
        onChange={handlePhoneChange}
        onCountryChange={handleContryChange}
        name={name}
        className="form-control"
      />
      {error && <FormError error={error} />}
    </div>
  );
}
