import React, { useState, useRef, ChangeEvent, ReactNode, useEffect, MutableRefObject } from 'react'
import WidgetChat from './widget-chat/widget-chat';
import { Chat, ExtraElementChat, WidgetButtons } from '../../pages/web-channel/interfaces';
import classNames from 'classnames';
import Button from '../button/button';
import Icon, { IconTheme, IconVariant } from '../icon/icon';
import { TextInputAnswer } from './chat-componnets';
import MadeByOfficely from "../../assets/images/made_by_officely.png"
import { DateRefEntry } from 'src/pages/web-channel/modes/live-mode';


interface Answer {
    answer: string | null | undefined;
    setAnswer: React.Dispatch<React.SetStateAction<string | null | undefined>>;
}

interface Loading {
    loading: boolean | undefined
    setLoading: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

interface DateVisableState {
    visibleDate: string;
    setVisibleDate: React.Dispatch<React.SetStateAction<string>>;
}

export interface FileLinkState {
    fileLink: string | null;
    setFileLink: React.Dispatch<React.SetStateAction<string | null>>;
}


type Props = {
    children?: ReactNode
    chatHistory: Array<Chat>
    isChat: boolean
    showPowerBy?: boolean
    answerState: Answer
    dateVisableState?: DateVisableState
    fileLinkState?: FileLinkState
    loadingState: Loading
    showInput: boolean
    img?: string
    handleSubmit: ({ answer, payload, value, extraElement, skip }: { answer?: string, payload?: WidgetButtons, value?: string, extraElement?: ExtraElementChat, skip?: boolean }) => void;
    handleClickBtn?: (btn: WidgetButtons) => void;
    chatTextRef?: boolean;
    dateRefs?: MutableRefObject<{ [key: string]: DateRefEntry }>;

}



function WidgetContent({
    children,
    chatHistory,
    isChat,
    showPowerBy,
    answerState,
    loadingState,
    dateVisableState,
    fileLinkState,
    showInput,
    chatTextRef,
    img,
    dateRefs,
    handleSubmit,
    handleClickBtn
}: Props) {

    const [showTopBtn, setShowTopBtn] = useState(false)
    const { answer, setAnswer } = answerState
    //const { fileLink, setFileLink } = fileLinkState ?? {}
    const { loading } = loadingState
    const { visibleDate, setVisibleDate } = dateVisableState ?? {}
    const chatRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const smoothScroll = dateRefs?.current ? false : true;
        setTimeout(() => scrollToBottom(smoothScroll), 250);
    }, [chatHistory, loading]);

    useEffect(() => {
        chatRef.current?.addEventListener("scroll", scrollEvent);
        return () => {
            chatRef.current?.removeEventListener("scroll", scrollEvent);
        }
    }, [chatRef.current]);






    const scrollEvent = () => {
        if (!chatRef?.current) return;
        const { scrollTop, scrollHeight, clientHeight } = chatRef.current;
        const isScrolledToBottom = Math.round(scrollTop + clientHeight) >= scrollHeight;
        setShowTopBtn(!isScrolledToBottom);

        if (dateRefs?.current) {
            const dates = Object.keys(dateRefs.current);
            for (let i = dates.length - 1; i >= 0; i--) { // Iterate in reverse order
                const date = dates[i];
                const refDate = dateRefs.current[date];
                if (refDate.element && refDate?.element?.offsetTop <= scrollTop + 50) {
                    if (date !== visibleDate) {
                        setVisibleDate!(refDate.date);
                    }
                    break;
                }
            }

        }
    }


    const scrollToBottom = (smoothScroll?: boolean): void => {
        setTimeout(() => {
            chatRef?.current?.scrollTo({
                top: chatRef?.current?.scrollHeight,
                behavior: smoothScroll ? 'smooth' : 'auto'
            })
            //document.body.style.height = '100vh';
            window.scrollTo(0, 1);
        }, 250)

    }

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setAnswer(e.target.value)
    }






    const disableButton = !(Boolean(answer?.trim()) || fileLinkState?.fileLink) || (loading ?? false) || (chatTextRef ?? false)


    return (
        <div className="widget-content">
            <WidgetChat
                isChat={isChat}
                img={img}
                ref={chatRef}
                chatHistory={chatHistory}
                loading={loading ?? false}
                handleClickBtn={handleClickBtn}
                handleSubmit={handleSubmit}
                dateRefs={dateRefs}
            />
            {children}
            <footer className={classNames(["widget-footer", { free: showPowerBy }])}>
                <Button isHidden={!showTopBtn} onClick={() => scrollToBottom()} isIcon className="scroll-btn animate fade-in-down">
                    <Icon variant={IconVariant.BOTTOM} theme={IconTheme.GRAY} />
                </Button>
                {showInput && <TextInputAnswer
                    disableButton={disableButton}
                    answer={answer ?? ''}
                    chatTextRef={chatTextRef}
                    handleChange={handleChange}
                    handleClick={() => handleSubmit({})}
                    fileLinkState={fileLinkState ? { fileLink: fileLinkState.fileLink, setFileLink: fileLinkState.setFileLink } : undefined}
                    />}
                <a href="https://officely.ai" target="_blank" rel="noopener noreferrer" >
                    <img className="powerd-by-officely" src={MadeByOfficely} />
                </a>

            </footer>
        </div>
    )
}


export default React.memo(WidgetContent)