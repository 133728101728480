import classNames from 'classnames';
import React, { ReactElement, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NodeType } from '../../enums/enums';
import { getOptionID } from '../../helpers/wrokflow-builder/get-id';
import { GetHighLightElement } from '../../helpers/wrokflow-builder/highlight-search-text';
import { useRTL } from '../../hooks/use-rtl';
import { useTranslation } from '../../hooks/use-translate';
import { ChatbotNodeData } from '../../models/chat-bot-node';
import { ChatBuilderActions } from '../../redux/chat-builder';
import { ChatbotActionsSelectors } from '../../redux/chatbot-actions';
import Button, { ButtonVariant } from '../button/button';
import OptionInput from '../form/option-input';
import Node from './node';
import './node.scss';
import SourceHandle from './source-handle';
import TargetHandle from './target-handle';

function MediaNode({ data }: { data: ChatbotNodeData }): ReactElement {
  const t = useTranslation('COMPONENTS.NODE');
  const dispatch = useDispatch();
  const searchValue = useSelector(ChatbotActionsSelectors.inputSearchValue)
  const isRTL = useRTL();

  const onChangeOption = (optionID: string, value: string): void => {
    dispatch(ChatBuilderActions.changeOption({ id: data.id, optionID, value }));
  };

  const onRemoveOption = (optionID: string): void => {
    const optionsIsEmpty = !data.options?.length
    if (!optionsIsEmpty) {
      dispatch(ChatBuilderActions.SwitchBetweenNodeAndOpionEdge({ id: data.id, optionID, optionsIsEmpty }))
    }
    dispatch(ChatBuilderActions.removeOption({ id: data.id, optionID })); optionsIsEmpty
  };

  const onAddNewOption = (): void => {
    const optionID = getOptionID()
    const optionsIsEmpty = !data.options!.length
    if (optionsIsEmpty) {
      dispatch(ChatBuilderActions.SwitchBetweenNodeAndOpionEdge({ id: data.id, optionID, optionsIsEmpty }))
    }
    dispatch(ChatBuilderActions.addNewOption({ id: data.id, optionID }));
  };

  const highLightCallBack = useCallback((value: string) =>
    GetHighLightElement(searchValue, value),
    [searchValue])

  return (
    <Node data={data}
      type={NodeType.MEDIA}
      className="node-media">
      <TargetHandle id={data.id} />
      <div className="node-options">
        {data.options?.map((option, index) =>
          <div key={option.id} className={classNames(['node-option', { 'rtl': isRTL }])}>
            {(data.isSelected)
              ?
              <OptionInput onChange={(value) => onChangeOption(option.id, value)}
                // onRemove={((data.options?.length ?? 0) > 1) ? () => onRemoveOption(option.id) : undefined}
                onRemove={((data.options?.length ?? 0) >= 1) ? () => onRemoveOption(option.id) : undefined}
                value={option.text}
                placeholder={`${t('TEXT_OPTION')} ${index + 1}`}
                className="node-option-input" />
              : <>
                <span className="node-option-number">
                  {index + 1}.&nbsp;
                </span>
                <span className="node-option-text">
                  {highLightCallBack(option.text)}
                </span>
              </>
            }
            <SourceHandle id={option.id} />
          </div>
        )}
        {!data.options?.length && <SourceHandle id={data.id} />}
        {data.isSelected && <div className="node-options-add">
          <Button className={classNames({ rtl: isRTL })} onClick={onAddNewOption} onTouchStart={onAddNewOption} variant={ButtonVariant.PRIMARY} isBlock={true}>
            {t('BUTTON_ADD_OPTION')}
          </Button>
        </div>}
      </div>
    </Node>
  );
}


const areEqual = (prevProps: any, nextProps: any) => prevProps.data === nextProps.data;

export default React.memo(MediaNode, areEqual);