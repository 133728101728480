import { Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, FormControl, FormLabel, Avatar, Divider } from "@chakra-ui/react";
import React, { useEffect, useState, ReactElement, useCallback } from "react";
import { useReactFlow } from "reactflow";
import { FilterNodeData, JumpNodeData, LLMNodeData, PythonNodeData, RetriverNodeData, TeamNodeData, TextNodeData } from "src/models";
import { TeamFlowNodeType } from "../../interfaces";
import LLMConfiguration from "./llm.tsx/llm";
import { ChakraMenu } from "src/components/chakra/menu-item";
import RetriverConfig from "./retriver.tsx/retriver";
import FilterConfig from "./filter/filter";
import PythonConfig from "./python/python";
import FormText from "src/components/form/text";
import useRandomImage from "src/hooks/team-builder/use-random-images";
import "./config.scss";
import TextConfig from "./text/text";
import JumpConfig from "./jump/jump";
 

export default function Configuration({ id, onClose }: {
    id: string | null;
    onClose: () => void;

}): ReactElement {

    const { getNode, setNodes, getEdges } = useReactFlow();
    const getRandomImage = useRandomImage();


    const [config, setConfig] = useState<TeamNodeData | null>();


    useEffect(() => {
        if (!id) { return }
        const node = getNode(id);
        if (!node) {
            return;
        }
        setConfig((node.data as TeamNodeData))

    }, [id]);

    const onCloseHandler = () => {
        setConfig(null);
        onClose()
    }

    const getConfiguration = () => {
        if (!config) {
            return <></>
        }
        switch (config.type) {
            case TeamFlowNodeType.LLM:
                return <LLMConfiguration data={config as LLMNodeData} />
            case TeamFlowNodeType.FILTER:
                return <FilterConfig data={config as FilterNodeData} />
            case TeamFlowNodeType.RETRIVER:
                return <RetriverConfig data={config as RetriverNodeData} />
            case TeamFlowNodeType.PYTHON:
                return <PythonConfig data={config as PythonNodeData} />
            case TeamFlowNodeType.TEXT:
                return <TextConfig data={config as TextNodeData} />
            case TeamFlowNodeType.JUMP:
                return <JumpConfig data= {config as JumpNodeData} />


            default:
                return <></>
        }
    }

    const ReplaceNodeImage = (type: TeamFlowNodeType):string | boolean => {
        switch (type) {
            case TeamFlowNodeType.FILTER:
                return "https://officelyfiles.s3.eu-west-1.amazonaws.com/c77bd1dc-a80e-48b8-a551-fdaa1f36be86.png"
            case TeamFlowNodeType.PYTHON:
                return "https://officelyfiles.s3.eu-west-1.amazonaws.com/e2b13fb1-9339-49b8-84ef-20f4f0e99722.png"
            case TeamFlowNodeType.TEXT:
                return "https://officelyfiles.s3.eu-west-1.amazonaws.com/aab188ad-d1db-4e31-9c16-f5cf5717cbba.png"
            case TeamFlowNodeType.RETRIVER:
                return "https://officelyfiles.s3.eu-west-1.amazonaws.com/46b60c41-8586-4824-8df5-ce9c97705692.png"
            case TeamFlowNodeType.JUMP:
                return "https://officelyfiles.s3.eu-west-1.amazonaws.com/574a290c-7f20-4a1a-a761-a86b562a4555.png"
            default:
                return getRandomImage()
        }
    }


    const onNodeTypeChange = (type: TeamFlowNodeType) => {
        const urlImage = ReplaceNodeImage(type)
        //let removeNodeID = ''
        setNodes((prevNodes) => prevNodes.map((node) => {
            if (node.id === id) {
                let updatedNodeData = { ...node.data, type };
                if (urlImage) {
                    updatedNodeData = { ...updatedNodeData, urlImage };
                }
                if (type !== TeamFlowNodeType.FILTER) {
                    const edges = getEdges();
                    const childEdges = edges.filter((edge) => edge.source === id);
                    if (childEdges.length > 1) {
                        alert('You can only have one child node for this type')
                        return node
                    }
                    // if (type === TeamFlowNodeType.JUMP) {
                    //     if (childEdges.length == 1 && childEdges[0].type === TeamNodeType.PLACEHOLDER){
                    //         removeNodeID = (childEdges[0].target)
                    //         onRemoveEdge(childEdges[0].id)
                    //     } else{
                    //         alert('To Jump cannot have child nodes')
                    //         return node
                    //     }
                    // }else if(!childEdges.length){
                    //     console.log('adding placeholder')
                    //     onAddPlaceholder(id)
                    // }
                }

                const updatedNode = {
                    ...node,
                    data: updatedNodeData
                };

                setConfig(updatedNodeData);

                return updatedNode;
            }
            return node;
        }));
        // if (removeNodeID){
        //     onRemoveNode(removeNodeID)
        // }
    };


    

    const onChangeNodeName = useCallback((name: string) => {
        const regex = /^[a-zA-Z0-9_]+$/
        const valid = regex.test(name) || name.length === 0;
        if (!valid) { return }

        setNodes((prevNodes) => prevNodes.map((node) => {
            if (node.id === id) {
                const updatedNodeData = { ...node.data, name };
                const updatedNode = {
                    ...node,
                    data: updatedNodeData
                };
                setConfig(updatedNodeData);
                return updatedNode;
            }
            return node;
        }));
    }, [config?.name])

    const onAvatarClick = useCallback(() => {
        setNodes((prevNodes) => prevNodes.map((node) => {
            if (node.id === id) {
                const updatedNodeData = { ...node.data, urlImage: getRandomImage() };
                const updatedNode = {
                    ...node,
                    data: updatedNodeData
                };
                setConfig(updatedNodeData);
                return updatedNode;
            }
            return node;
        }));
    }, [config?.urlImage])

    const onChangeNodeConfiguration = useCallback((description: string) => {
        setNodes((prevNodes) => prevNodes.map((node) => {
            if (node.id === id) {
                const updatedNodeData = { ...node.data, description };
                const updatedNode = {
                    ...node,
                    data: updatedNodeData
                };
                setConfig(updatedNodeData);
                return updatedNode;
            }
            return node;
        }));
    }, [config?.description])



    if (!config) {
        return <></>
    }
    return (
        <Drawer onClose={onCloseHandler} isOpen={Boolean(id)} size="lg">
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader flexDir="column" alignItems="center" justifyContent="center" display="flex" fontWeight="bold">

                    <div className="img" onClick={onAvatarClick}>
                        <label className="avatar-trigger">
                            <span>
                                <Avatar src={config.urlImage} cursor="pointer" size="xl" />
                            </span>
                        </label>
                    </div>

                </DrawerHeader>
                <DrawerBody>
                    <FormControl className="config" mt={5}>
                        <FormLabel className="label">Role</FormLabel>
                        <ChakraMenu<TeamFlowNodeType>
                            currentItem={config.type}
                            onItemSelect={(type) => onNodeTypeChange(type as TeamFlowNodeType)}
                            items={Object.values(TeamFlowNodeType)}
                            center
                            fontWeight="100"
                            className="text-field"
                            _hover={{ opacity: 0.5 }}
                        />
                    </FormControl>
                    <FormControl className="config" mt={5}>
                        <FormLabel className="label">Name</FormLabel>
                        <FormText
                            value={config.name}
                            onChange={(e) => onChangeNodeName(e.target.value)}
                        />
                    </FormControl>
                    <FormControl className="config" >
                        <FormLabel className="label">Description</FormLabel>
                        <FormText
                            value={config.description}
                            onChange={(e) => onChangeNodeConfiguration(e.target.value)}
                        />
                    </FormControl>
                    <Divider mb={5} />

                    {getConfiguration()}
                </DrawerBody>
            </DrawerContent>
        </Drawer>

    );
}