import { createSelector } from 'reselect';
import { AppState } from '../ConfigureStore';
import { ChatbotActions } from './state';

const selectFeature = (state: AppState): ChatbotActions => state.chatbotPage;

export class ChatbotActionsSelectors {
  public static isLoading = createSelector(
    selectFeature,
    (state: ChatbotActions) => state.isLoading
  );

  public static inputSearchValue = createSelector(
    selectFeature,
    (state: ChatbotActions) => state.inputSearchValue ?? ''
  );

  public static transformSearch = createSelector(
    selectFeature,
    (state: ChatbotActions) => state.transformSearch
  );

  public static searchArray = createSelector(
    selectFeature,
    (state: ChatbotActions) => state.searchArray ?? []
  );

  public static activeSearch = createSelector(
    selectFeature,
    (state: ChatbotActions) => state.activeSearch
  );
  public static previewNodeID = createSelector(
    selectFeature,
    (state: ChatbotActions) => state.previewNodeID ?? { id: null, render: false}
  );

  public static activeNodeID = createSelector(
    selectFeature,
    (state: ChatbotActions) => state.activeNodeID ?? ''
  );
}

