import React, { useEffect } from "react";
import Cal, { getCalApi } from "@calcom/embed-react";

export function RequestDemoPage() {
  useEffect(() => {
    (async function () {
      const cal = await getCalApi();
      cal("ui", { "styles": { "branding": { "brandColor": "#5b61eb" } }, "hideEventTypeDetails": false });
    })();
  }, [])


  return (
    <div>
      <Cal calLink="officely-ai/demo" style={{ margin: "8% 0%", width: "100%", height: "100%", overflow: "scroll" }} />
    </div>

  )

}