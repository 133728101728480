import React, { ReactElement, useState } from "react"
import {
    Stack,
    Tag,
    Box,
    PopoverTrigger,
    Popover,
    Portal,
    PopoverContent,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    Icon as CIcon,
    Flex
} from "@chakra-ui/react"
import { DeleteIcon } from '@chakra-ui/icons';
import { Condition, DataTypeLabel, FilterAction, GroupCondtions, LogicalOperator, Operator, OperatorInfo, OperatorLabel } from "./interfaces"
import { ChatbotNodeData } from "../../../models"
import AutoComplete from "../../auto-complete/auto-complete"
import Button, { ButtonSize, ButtonVariant } from "../../button/button"
import Icon, { IconTheme, IconVariant } from "../../icon/icon"
import { ChatBuilderActions } from "../../../redux/chat-builder"
import { useDispatch } from "react-redux"
import { DataType, FormatDate } from "../../../enums/enums"
import './node-filter.scss';
import FieldValueInput from "./field-value-input";




function EditGroup({ group, data }: {
    group: GroupCondtions
    data: ChatbotNodeData
}): ReactElement {

    const [openedItemId, setOpenedItemId] = useState<string | null>(null)
    const dispatch = useDispatch()
    const [indexItem, setIndex] = useState<number | null>(null)


    const operators = [
        {
            title: "Basic Operators",
            items: [OperatorLabel.EXISTS, OperatorLabel.NOT_EXISTS].map((key) => ({
                value: `Basic Operator: ${key}`,
                label: key,
                action: () => onChangeOperator(key, DataType.BASIC)
            }))
        },
        {
            title: "Text Operators",
            items: [OperatorLabel.EQUAL, OperatorLabel.EQUAL_INSENSETIVE, OperatorLabel.NOT_EQUAL, OperatorLabel.NOT_EQUAL_INSENSETIVE,
            OperatorLabel.CONTAINS, OperatorLabel.CONTAINS_INSENSETIVE, OperatorLabel.NOT_CONTAINS, OperatorLabel.NOT_CONTAINS_INSENSETIVE,
            OperatorLabel.STARTS_WITH, OperatorLabel.STARTS_WITH_INSENSETIVE, OperatorLabel.NOT_STARTS_WITH, OperatorLabel.NOT_STARTS_WITH_INSENSETIVE,
            OperatorLabel.ENDS_WITH, OperatorLabel.ENDS_WITH_INSENSETIVE, OperatorLabel.NOT_ENDS_WITH, OperatorLabel.NOT_ENDS_WITH_INSENSETIVE].map((key) => ({
                value: `Text Operator: ${key}`,
                label: key,
                action: () => onChangeOperator(key, DataType.TEXT)
            }))
        },
        {
            title: "Number Operators",
            items: [OperatorLabel.EQUAL, OperatorLabel.NOT_EQUAL, OperatorLabel.GREATER_THAN, OperatorLabel.GREATER_THAN_OR_EQUAL_TO,
            OperatorLabel.LESS_THAN, OperatorLabel.LESS_THAN_OR_EQUAL_TO].map((key) => ({
                value: `Number Operator: ${key}`,
                label: key,
                action: () => onChangeOperator(key, DataType.NUMBER)
            }))
        },
        {

            title: "Date Operators",
            items: [OperatorLabel.EQUAL, OperatorLabel.NOT_EQUAL, OperatorLabel.GREATER_THAN, OperatorLabel.GREATER_THAN_OR_EQUAL_TO,
            OperatorLabel.LESS_THAN, OperatorLabel.LESS_THAN_OR_EQUAL_TO].map((key) => ({
                value: `Date Operator: ${key}`,
                label: key,
                action: () => onChangeOperator(key, DataType.DATE)
            }))
        }
    ]

    const logicalOperatorKeys = Object.keys(LogicalOperator).map((key) => ({
        value: key,
        label: key,
        action: () => onChangeLogicalOperator(LogicalOperator[key as keyof typeof LogicalOperator])
    }))

    const dateFormat = Object.keys(FormatDate).map((key) => ({
        value: FormatDate[key as keyof typeof FormatDate],
        label: FormatDate[key as keyof typeof FormatDate],
        action: () => onChangeFormatDate(FormatDate[key as keyof typeof FormatDate])
    }))

    const onClose = () => {
        setOpenedItemId(null)
    }


    const openPopover = () => setOpenedItemId(group.id)

    const handleMouseDown = (e: React.MouseEvent) => e.stopPropagation()

    const GetPreviewGroup = (group: GroupCondtions): ReactElement => {
        const getOperatorInfo = (operator: string): string => {
            const indexOfS = Object.values(OperatorLabel).indexOf(operator as unknown as OperatorLabel);
            const key = Object.keys(OperatorLabel)[indexOfS];
            return Operator[key as keyof typeof Operator];

        }

        const getBoxValue = (boxValue: string) =>
            <Box
                as="span"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                display="block"
            >
                {boxValue}
            </Box>
        return (
            <Box>
                {group.conditions.map((condition, index) =>
                    <Flex key={index} direction="row" flexWrap="nowrap" textAlign="center" mb={5} pl={15}>
                        <Tag
                            size="lg"
                            variant="solid"
                            colorScheme="primary"
                            maxW="30%"
                        >
                            {getBoxValue(condition.field)}

                        </Tag>
                        <Tag size="lg" maxW="25%" >
                            {getOperatorInfo(condition.operator.label)}
                        </Tag>
                        {condition.operator.dataType !== DataType.BASIC && (
                            <Tag
                                size="lg"
                                variant="solid"
                                colorScheme="yellow"
                                maxW="30%">
                                {getBoxValue(condition.value)}
                            </Tag>
                        )}
                        {(condition.logicalOperator && index !== group.conditions.length - 1) && (
                            <Tag size="lg" maxW="25%">
                                {condition.logicalOperator}
                            </Tag>
                        )}
                    </Flex>
                )}
            </Box >
        )
    }

    const onRemoveGroup = (groupID: string): void => {
        dispatch(ChatBuilderActions.onChangeFilterNode({ nodeID: data.id, key: FilterAction.REMOVE_GROUP, groupID }));
    };

    const onChangeField = (val: string, groupID: string, conditionIndex: number) => {
        dispatch(ChatBuilderActions.onChangeFilterNode({ nodeID: data.id, key: FilterAction.CHANGE_FIELD, groupID, conditionIndex, val }));
    }

    const onChangeValue = (val: string, groupID: string, conditionIndex: number) => {
        dispatch(ChatBuilderActions.onChangeFilterNode({ nodeID: data.id, key: FilterAction.CHANGE_VALUE, groupID, conditionIndex, val }));
    }

    const onChangeOperator = (key: OperatorLabel, dataType: DataType) => {
        if (indexItem === null) return
        const operatorInfo: OperatorInfo = {
            label: key,
            dataType
        }
        dispatch(ChatBuilderActions.onChangeFilterNode({
            nodeID: data.id, key: FilterAction.CHANGE_OPERATOR,
            groupID: group.id, conditionIndex: indexItem, val: operatorInfo
        }));
    }

    const onChangeLogicalOperator = (key: LogicalOperator) => {
        if (indexItem === null) return
        dispatch(ChatBuilderActions.onChangeFilterNode({
            nodeID: data.id, key: FilterAction.CHANGE_LOGICAL_OPERATOR,
            groupID: group.id, conditionIndex: indexItem, val: key
        }));
    }

    const onAddCondition = (groupID: string, conditionIndex: number) => {
        dispatch(ChatBuilderActions.onChangeFilterNode({ nodeID: data.id, key: FilterAction.ADD_CONDITION, groupID, conditionIndex }));
    }

    const onChangeFormatDate = (key: FormatDate) => {
        if (indexItem === null) return
        dispatch(ChatBuilderActions.onChangeFilterNode({
            nodeID: data.id, key: FilterAction.CHANGE_FORMAT_DATE,
            groupID: group.id, conditionIndex: indexItem, val: key
        }));
    }

    const onDeleteCondition = (groupID: string, conditionIndex: number) => {
        dispatch(ChatBuilderActions.onChangeFilterNode({ nodeID: data.id, key: FilterAction.REMOVE_CONDITION, groupID, conditionIndex }));
    }

    const onChangeMap = (groupID: string, conditionIndex: number, key: FilterAction, val: boolean) => {
        dispatch(ChatBuilderActions.onChangeFilterNode({ nodeID: data.id, key, groupID, conditionIndex, val }));
    }





    return (
        <Popover
            placement="left"
            isLazy
            isOpen={openedItemId === group.id}
            onClose={onClose}
            closeOnBlur={false}

        >
            <PopoverTrigger>
                <Box className="form-group node-option-input" cursor="pointer" onClick={openPopover}>
                    {data.isSelected && <Button
                        onClick={() => onRemoveGroup(group.id)}
                        onTouchStart={() => onRemoveGroup(group.id)}
                        variant={ButtonVariant.OUTLINE}
                        isRound={true}
                        size={ButtonSize.SMALL}
                        className="form-remove">
                        <Icon variant={IconVariant.REMOVE_SMALL} theme={IconTheme.PRIMARY} />
                    </Button>}
                    <div className="form-control">
                        {GetPreviewGroup(group)}
                    </div>
                </Box>

            </PopoverTrigger>
            <Portal >
                <PopoverContent onMouseDown={handleMouseDown} pt={10} minWidth={"400px"}>
                    <PopoverArrow />
                    <PopoverCloseButton onClick={onClose} />
                    <PopoverBody py="6" width="100%" overflowY="scroll" maxH="400px" shadow="lg" >
                        {group.conditions.map((condition: Condition, index: number) =>
                            <Stack key={index}
                                className="filter-group"
                                onFocus={() => setIndex(index)}
                                onBlur={() => setIndex(null)}>
                                <span className="filter-group-title">
                                    <Button
                                        isIcon
                                        onClick={() => onDeleteCondition(group.id, index)}
                                        isHidden={group.conditions.length === 1}
                                        className="filter-group-title-remove"
                                    >
                                        <CIcon as={DeleteIcon} boxSize={5} />
                                    </Button>
                                    {`Group ${index + 1}`}
                                </span>
                                <FieldValueInput
                                    value={condition.field}
                                    onChange={(value) => onChangeField(value, group.id, index)}
                                    placeholder="Field"
                                    onSwitch={(bool) => onChangeMap(group.id, index, FilterAction.CHANGE_FIELD_MAP, bool)}
                                    isMap={condition.fieldMap}
                                />
                                <AutoComplete items={operators}
                                    value={`${DataTypeLabel[condition.operator.dataType as keyof typeof DataTypeLabel]} Operator: ${condition.operator.label}`}
                                    variant="filled"
                                    isGroup
                                    centerInput
                                    width="100%"
                                    maxHeight={220}
                                    position="relative"
                                    classNameInput="filter-group-operator"
                                />
                                {condition.operator.dataType === DataType.DATE &&
                                    <AutoComplete
                                        items={dateFormat}
                                        value={condition?.formatDate || ''}
                                        disableFilter
                                        variant="filled"
                                        centerInput
                                        position="relative"
                                        width="100%"
                                        centerItem
                                        margin={"0 auto"}
                                    />
                                }
                                {condition.operator.dataType !== DataType.BASIC &&
                                    <FieldValueInput
                                        value={condition.value}
                                        onChange={(value) => onChangeValue(value, group.id, index)}
                                        placeholder="Value"
                                        onSwitch={(bool) => onChangeMap(group.id, index, FilterAction.CHANGE_VALUE_MAP, bool)}
                                        isMap={condition.valueMap}
                                    />
                                }

                                {condition?.logicalOperator ? <AutoComplete
                                    items={logicalOperatorKeys}
                                    value={condition.logicalOperator}
                                    variant="filled"
                                    disableFilter
                                    centerInput
                                    position="relative"
                                    classNameInput="filter-group-logical"
                                    width="50%"
                                    centerItem
                                    margin={"0 auto"}
                                />
                                    :
                                    <div className="filter-group-add">
                                        <Button isBlock variant={ButtonVariant.OUTLINE} onClick={() => onAddCondition(group.id, index)}>
                                            {/* <Icon variant={IconVariant.ADD} theme={IconTheme.PRIMARY} /> */}
                                            Add Condition
                                        </Button>
                                    </div>
                                }
                            </Stack>


                        )}
                    </PopoverBody>
                </PopoverContent>
            </Portal>
        </Popover>
    )

}

export default React.memo(EditGroup, (prevProps: any, nextProps: any) => prevProps.group === nextProps.group && prevProps.data === nextProps.data);