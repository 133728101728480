import classNames from 'classnames';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icon, { IconTheme, IconVariant } from '../../components/icon/icon';
import Logo from '../../components/logo/logo';
import { useTranslation } from '../../hooks/use-translate';
import { AuthActions, AuthSelectors } from '../../redux/auth';
import './account.scss';
import { useRTL } from '../../hooks/use-rtl';
import { useNavigate, useLocation } from 'react-router-dom';
import ProfileIMG from '../../assets/images/profile.svg'
import { PathName, Plan } from '../../enums/enums';
import { User } from '../../models';
import { Icon as CIcon } from '@chakra-ui/react'
import { CiCalendarDate } from "react-icons/ci";
import Badge from 'src/components/badge/badge';







export default function AccountMenu({ isOpen, toggleMenu }: { isOpen: boolean, toggleMenu: () => void }): ReactElement {
  const t = useTranslation('LAYOUTS.ACCOUNT.MENU');
  const dispatch = useDispatch();
  const isRTL = useRTL();
  const navigate = useNavigate();
  const location = useLocation();
  const user: User = useSelector(AuthSelectors.user)
  const [currentPage, setCurrentPage] = useState('');

  //const isPremiumUser = ![Plan.FREE, Plan.BASIC].includes(user.plan);


  useEffect(() => {
    setCurrentPage(location.pathname)
  }, [location])

  const menuItems = [
    ...(user.plan !== Plan.FREE ? [{
      id: PathName.DASHBOARD,
      label: t('TEXT_DASHBOARD'),
      icon: IconVariant.DASHBOARD
    }] : []),
    {
      id: PathName.AGENTS,
      label: t('TEXT_AGENT'),
      icon: IconVariant.USER,
      isPro: false
    },
    {
      id: PathName.BUILDERS,
      label: t('TEXT_CHATBOT'),
      icon: IconVariant.CHAT_BUILDER,
      isPro: true,
      isBasic: false
    },
    {
      id:PathName.LIVE,
      label: t('TEXT_LIVE'),
      icon: IconVariant.MESSAGE,
      isPro: false,
      isBasic: false
    },
    {
      id: PathName.CHANNELS,
      label: t('TEXT_CHANNELS'),
      icon: IconVariant.INTEGRETION,
      isPro: true
    },
    {
      id: PathName.SUBSCRIPTIONS,
      label: t('TEXT_BILING'),
      icon: IconVariant.CARD,
      isPro: false
    }
  ];

  // const embededItems = [
  //   // {
  //   //   id: PathName.HELP,
  //   //   label: t('TEXT_HELP'),
  //   //   //icon: IconVariant.INFO
  //   //   icon: VscQuestion
  //   // },
  //   // {
  //   //   id: PathName.REQUEST_FEATURE,
  //   //   label: t('TEXT_REQUEST_FEATURE'),
  //   //   //icon: IconVariant.ADD_SMALL
  //   //   icon: HiOutlineTicket
  //   // },
  //   {
  //     id: PathName.BOOK_DEMO,
  //     label: t('TEXT_BOOK_DEMO'),
  //     //icon: IconVariant.CALENDAR
  //     icon: CiCalendarDate
  //   }
  // ]

  const profile = {
    id: PathName.PROFILE,
    label: t('TEXT_PROFILE'),
    image: user.image ? user.image : ProfileIMG
  }



  const handleItemClick = (id: string): void => {
    toggleMenu();
    setCurrentPage(id);
    localStorage.setItem("currentPage", id)
    navigate(`${id}`)
  };

  const handleLogout = (): void => {
    dispatch(AuthActions.unauthorize());
    localStorage.clear()
  };


  return (
    <aside className={classNames(['account-menu-scroll', { open: isOpen }])}>
      <div className="account-menu">
        <div className="account-menu-wrapper">
          <header className="account-menu-header">
            <div className="account-menu-logo tablet-desktop ">
              <Logo />
            </div>
          </header>
          <div className={classNames(['account-menu-items', { 'rtl-mobile': isRTL }])}>
            {menuItems.map((item) => <button key={item.id}
              onClick={() => handleItemClick(item.id)}
              className={classNames(['account-menu-item', { active: currentPage === item.id }])}>
              <span className="account-menu-item-icon">
                <Icon variant={item.icon} theme={currentPage === item.id ? IconTheme.BLACK : IconTheme.GRAY} />
              </span>
              <span className={classNames(['account-menu-item-label', { rtl: isRTL }])}>
                {item.label}
              </span>
              {/* <span className="account-menu-item-arrow only-mobile">
                <Icon variant={IconVariant.RIGHT} theme={IconTheme.BLACK} />
              </span> */}
              {item.isPro && [Plan.FREE, Plan.BASIC].includes((user.plan ?? Plan.FREE)) && (
                <Badge colorScheme="secondly" text="Pro" />
              )}
              {item.isBasic && Plan.FREE === user.plan  && (
                <Badge colorScheme="secondly" text="Basic" />
              )}

            </button>)}
          </div>



          <footer className={classNames(['account-menu-footer', { 'rtl-mobile': isRTL }])}>
            <div className="features">
              {/* <div className={classNames(['account-menu-items', { 'rtl-mobile': isRTL }])}>
                {embededItems.map((item) => <a key={item.id}
                  onClick={() => handleItemClick(item.id)}
                  className={classNames(['account-menu-item', { active: currentPage === item.id }])}>
                  <span className="account-menu-item-icon">
                    <CIcon as={item.icon} w="20px" h="20px" />
                  </span>
                  <span className={classNames(['account-menu-item-label', { rtl: isRTL }])}>
                    {item.label}
                  </span>
                </a>)
                }
              </div> */}
            </div>

            <div className={classNames(['account-menu-item avatar', { active: currentPage === profile.id }])}
              onClick={() => handleItemClick(profile.id)}>
              <img className="account-menu-item-icon avatar avatar-menu" src={profile.image} />
              <span className={classNames([' account-menu-item-label', { 'rtl': isRTL }])}>
                {user.bs_name}
              </span>
              {/* <span className="account-menu-item-arrow only-mobile">
                <Icon variant={IconVariant.RIGHT} theme={IconTheme.BLACK} />
              </span> */}
            </div>
            <div className="logout">
              <a onClick={handleLogout} className="account-menu-item account-menu-logout">
                <span className="account-menu-item-icon">
                  <Icon variant={IconVariant.LOGOUT} theme={IconTheme.BLACK} />
                </span>
                <span className={classNames(['account-menu-item-label logout ', { 'rtl': isRTL }])}>
                  {t('TEXT_LOG_OUT')}
                </span>
              </a>
              {/* <LangSelect top /> */}
            </div>
          </footer>

        </div>
      </div>
    </aside>
  );
}
