import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useReactFlow, Node } from 'reactflow';
import FormText from 'src/components/form/text';
import { RetriverNodeData } from 'src/models/team-builder-node';
import { RetriverForm } from './retriver-form';
import { useFormik } from 'formik';
import '../config.scss'
import { get } from 'lodash';
import { Editor } from '../../team-react-mde';
import { FormLabel, Input } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { AgentSelectors, LoaderType, URLLoader } from 'src/redux/agents';
import { ChakraMenu } from 'src/components/chakra/menu-item';
import { Checkbox } from '@chakra-ui/react'


export interface FilterRetriver {
  id: string,
  checked: boolean,
  type: LoaderType
}



function RetriverConfig({ data }: {
  data: RetriverNodeData,
}): ReactElement {
  //const [embedingModel, setEmbedingModel] = useState<EmbeddingModel>(data.embedingModel || EmbeddingModel.ADA2);
  const { setNodes } = useReactFlow();
  const currentAgent = useSelector(AgentSelectors.currentAgent)
  const [arrayData, setArrayData] = useState<FilterRetriver[]>([])
  const [filter, setFilter] = useState<FilterRetriver[]>([])
  const [selectAll, setSelectAll] = useState(true);
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef<HTMLInputElement>(null);




  const onHandleSelectAll = (checked: boolean) => {
    if (checked) {
      setArrayData((prev) => prev.map((item) => ({ ...item, checked: true })))
      setSelectAll(true)
    } else {
      setArrayData((prev) => prev.map((item) => ({ ...item, checked: false })))
      setSelectAll(false)
    }
  }

  const handleSelectAllChange = (item_id: string) => {
    setArrayData((prev) => prev.map((item) => {
      if (item.id === item_id) {
        return { ...item, checked: !item.checked }
      }
      return item
    }))
  }

  const handleInputChange = (value: string) => {
    if (value === '') {
      setFilter([...arrayData])
    }
    setFilter([...arrayData].filter((item) => item.id.toLowerCase().includes(value.toLocaleLowerCase())))
    setInputValue(value)
    if (inputRef.current) {
      setTimeout(() => inputRef.current?.focus(), 100);
    }
  };


  const selectAllElement = (
    <Checkbox
      onChange={(e) => onHandleSelectAll(e.target.checked)}
      isChecked={selectAll}
      zIndex={1}
      colorScheme="primary"
      mb={1}
    >
      Select All
    </Checkbox>
  );

  const InputElement = (
    <Input
      ref={inputRef}
      onClick={(e) => e.stopPropagation()}
      value={inputValue}
      onChange={(e) => handleInputChange(e.target.value)}
      width="100%" 
      border="none"
      _hover={{ background: "white" }}
      placeholder="Type To Filter" />
  )


  const elements = [
    InputElement,
    selectAllElement,
    ...filter.map((item) => (
      <Checkbox
        key={item.id}
        onChange={() => handleSelectAllChange(item.id)}
        isChecked={item.checked}
        colorScheme="primary"
        mb={1}
        width="100%"
      >

        {item.id}
      </Checkbox>
    ))
  ];

  const initialValues = new RetriverForm(data.question, data.index,
    data.namespace, data.k, data.n, data.rerank_score)

  useEffect(() => {
    const allFiles = currentAgent?.loaders
    ?.flatMap((loader) => {
      if (loader.type === LoaderType.URL) {
        return (loader as URLLoader).urls
          .filter((url) => url.checked)
          .map((url) => ({ id: url.url, type: LoaderType.URL}));
      }
      return loader.checked ? [{ id:loader.id, type:loader.type }] : [];
    }) || [];

    if (!allFiles.length) return;

    if (!data.filter?.length) {
      setArrayData(allFiles.map((file) => ({ ...file, checked: true })));
    } else {


      const existingFilterIds = data.filter.map((item) => item.id);
      const validExistingFilters = data.filter.filter((item) =>
        allFiles.some((file) => file.id === item.id)
      );
      const newFiles = allFiles.filter((file) => !existingFilterIds.includes(file.id)).map((file) => ({ ...file, checked: true }));
      const updatedFilter = [...validExistingFilters, ...newFiles];
      setArrayData(updatedFilter);
    }
  }, [currentAgent]);

  useEffect(() => setFilter(arrayData), [arrayData]);

  useEffect(() => {
    if (inputRef.current) {
      setTimeout(() => inputRef.current?.focus(), 100);
    }
  }, [filter]);



  const formik = useFormik({
    initialValues,
    onSubmit: (_: RetriverForm) => { }, // eslint-disable-line
    enableReinitialize: false
  });

  // const embedingModelItems = [EmbeddingModel.ADA2, EmbeddingModel.OPEN_AI_SMALL, EmbeddingModel.TITAN_1]
  // .map((item) => t(item))


  const onChange = (value: string) => {
    formik.setFieldValue('question', value)
  }

  const computeUpdatedNodes = useCallback((nodes: Array<Node>, values: RetriverForm, id: string, arrayData:Array<FilterRetriver>) => (
    nodes.map((node) => {
      if (node.id === id && node.type === 'workflow') {
        return {
          ...node,
          data: {
            ...node.data,
            question: values.question,
            index: values.index,
            namespace: values.namespace,
            k: values.k,
            n: values.n,
            rerank_score: values.rerank_score,
            filter: arrayData.filter((item) => item.checked)
          }
        };
      }
      return node;
    })
  ), []);

  useEffect(() => {
    setNodes((prevNodes) => computeUpdatedNodes(prevNodes, formik.values, data.id, arrayData));
  }, [formik.values, data.id, arrayData, computeUpdatedNodes]);




  return (
    <form className="config config-retriver">
      {/* <FormText<RetriverForm> name="name"
        formik={formik}
        placeholder="Name"
        regex={/^[a-zA-Z0-9_]+$/}
      /> */}



      <FormLabel className="label">Question</FormLabel>
      <FormText name="question" formik={formik} >
        <Editor
          id={data.id}
          value={get(formik.values, "question")}
          onChange={onChange}
          textAreaClass="text-field field-text" />
      </FormText>

      {/* <FormLabel className="label">Index</FormLabel>
      <FormText<RetriverForm> name="index"
        formik={formik}
        disabled
      />

      <FormLabel className="label">Namespace</FormLabel>
      <FormText<RetriverForm> name="namespace"
        formik={formik}
        
      /> */}

      <FormLabel className="label">Top K</FormLabel>
      <FormText<RetriverForm> name="k"
        formik={formik}
        type="number"
      />

      <FormLabel className="label">Top N</FormLabel>
      <FormText<RetriverForm> name="n"
        formik={formik}
        type="number"
      />

    <FormLabel className="label">Files</FormLabel>
      <ChakraMenu<ReactElement>
        items={elements as any}
        center={false}
        textAlign="start"
        background="white"
        fontWeight="100"
        placeholder="Choose Files"
        closeOnSelect={false}
        className="text-field"
        itemProps={{ _hover: { background: "white" }, _focus: { background: "white" }}}
        mb={5}

      />

      {/* <FormControl className="config">
        <FormLabel className="label">Embedding</FormLabel>
        <ChakraMenu
          currentItem={embedingModel}
          onItemSelect={(item) => setEmbedingModel(item as EmbeddingModel)}
          items={embedingModelItems}
          center={false}
          fontWeight="100"
          className="text-field"
        // width="100%"
        // m={0}
        // mb={5}
        />
      </FormControl> */}


    </form >


  )
}





export default RetriverConfig;



