import React, { ReactElement } from 'react';
import PdfViewer from '../../pdf-viewer/pdf-viewer';
import ReactAudioPlayer from 'react-audio-player';






export default function MediaWeb({ url }: { url: string }): ReactElement {
    const className = 'message-media'
    const handleLoad = () => {
        if (url.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp)(\?(.*))?$/gmi) !== null) { // eslint-disable-line
            return <img src={url} className={className} onClick={() => window.open(url)} />
        }
        else if (url.endsWith('pdf') || url.endsWith('PDF')) {
            return <PdfViewer url={url} className={`${className}  pdf`} />
        }
        else if (url.toLowerCase().endsWith('oga')) {
            return <ReactAudioPlayer src={url} autoPlay controls className={className} />
        }
        else if(!url){
            return <></>
        }
        else {
            return <iframe src={url} frameBorder="0" scrolling="no" allowFullScreen className={className} />
        }
    }



    return (
        <>
            {handleLoad()}
        </>
    )

}


