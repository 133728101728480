import React, { ReactElement, useEffect, useState } from 'react';
import '../menu-page.scss'
import SideBar, { SideBarView } from '../../components/sidebar/sidebar';
import classNames from 'classnames';
import LogoChannel, { LogoChannelVariant } from '../../components/logoChannel/logo-channel';
import { useTranslation } from '../../hooks/use-translate';
import { useParams } from 'react-router-dom';


interface SubItem {
  id: SideBarView
  label: string
  logo: LogoChannelVariant 
  link?: ReactElement
}

export function ChannelPage(): ReactElement {
  const { channel } = useParams<{ channel?: SideBarView }>();
  const [currentItem, setCurrentItem] = useState<SubItem | null>(null);
  const [isOpen, setIsOpen] = useState(false)
  const t = useTranslation('PAGES.CHANNEL');

  
  useEffect(() => {
    document.title = t("TITLE")
  },[])
  
  useEffect(() => {
    if (channel) {
      const item = channels.find((x) => x.id === channel)
      handleSubItemClick(item as SubItem)
    }
  },[channel])

  const channels = [
    {
      id:SideBarView.META,
      label: 'Meta WhatsApp',
      logo: LogoChannelVariant.META
    },
    {
      id: SideBarView.GREEN_API,
      label: 'Officely.ai By Green API',
      logo: LogoChannelVariant.WHATSAPP
    },
    {
      id: SideBarView.MAYTAPI,
      label: 'May-Tapi',
      logo: LogoChannelVariant.MAYTAPI,
      link: <a className="link" href="https://maytapi.com/" target="_blank" rel="noopener noreferrer"> May-Tapi WebSite</a>
    }
    // {
    //   id: SideBarView.SUNSHINE,
    //   label: 'Sunshine Conversations',
    //   logo: LogoChannelVariant.MAYTAPI,
    //   link: <a className="link" href="https://maytapi.com/" target="_blank" rel="noopener noreferrer"> May-Tapi WebSite</a>
    // }
  ]

  const handleSubItemClick = (item: SubItem): void => {
    setCurrentItem(item)
    setIsOpen(true)
  }

  return (
    <div className="page menu-page">
      <header className="page-header min-height ">
      <div className="page-header-left"></div>
        <div className="page-header-right">
        </div>
      </header>
      <div className="page-content">
        <div className={classNames(['menu-page-items', { open: isOpen }])}>
          {channels.map((item) => <a key={item.id} onClick={() => handleSubItemClick(item)}
            className={classNames(['menu-page-items-box cover-box', { active: currentItem?.id === item.id && isOpen, open: isOpen }])}>
            <span>
              <LogoChannel variant={item.logo} />
            </span>
            <span className="menu-page-items-label" >
              {item.label}
            </span>
          </a>)}
        </div>
      </div>
      <SideBar
        isOpen={isOpen}
        toggleMenu={() => setIsOpen(!isOpen)}
        itemID={currentItem?.id || null}
        itemName={currentItem?.label || null}
        link={currentItem?.link || null} />
    </div>
  );
}


