import React, { ReactElement } from 'react';
import { NodeType } from '../../enums/enums';
import { ChatbotNodeData } from '../../models/chat-bot-node';
import Node from './node';
import './node.scss';
import SourceHandle from './source-handle';
import TargetHandle from './target-handle';

function TextQuestionNode({ data }: { data: ChatbotNodeData }): ReactElement {
  return (
    <Node data={data}
      type={NodeType.TEXT_QUESTION}
      className="node-question">
      <TargetHandle id={data.id} />
      <SourceHandle id={data.id} />
    </Node>
  );
}

const areEqual = (prevProps:any, nextProps:any) => prevProps.data === nextProps.data;

export default React.memo(TextQuestionNode, areEqual);
